.man-chart {
    position: relative;
	svg {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}
.radialBar{
	p{
		position:relative;
		z-index:1;
	}
	#radialBar{
		@include respond ('tab-land'){
			margin-bottom:-40px;
		}
	}
}