.mapouter{
	iframe{
		width:100%;
	}
}
.activity-icon{
	height:50px;
	width:50px;
	min-width:50px;
	line-height:50px;
	text-align:center;
	border-radius:100%;
}