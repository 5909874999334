.dropzone-container {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column; /* Adjust to column layout */
  align-items: center; /* Center elements horizontally */
}

.dropzone {
  border: 2px dashed #cccccc;
  border-radius: 8px; /* Increased border radius */
  padding: 20px;
  text-align: center;
  background-color: #e9eef0;
  cursor: pointer;
  margin-bottom: 20px; /* Increased spacing */
  transition: border-color 0.3s, background-color 0.3s;
}

.dropzone:hover {
  border-color: #008cba;
  background-color: #e0f7fa;
}

.dropzone p {
  font-size: 16px;
  color: #666666;
}

.file-info {
  margin-bottom: 20px; /* Increased spacing */
}

input[type="text"] {
  width: 100%;
  padding: 12px; /* Increased padding */
  border: 1px solid #cccccc;
  border-radius: 8px;
}

input[type="text"]:focus {
  outline: none;
  border-color: #008cba;
}

.upload-button {
  background-color: #008cba;
  color: #ffffff;
  padding: 12px 24px; /* Increased padding */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-button:hover {
  background-color: #0077a3;
}

.upload-button:active {
  background-color: #006080;
  margin-top: 1%;
}

.added-files-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center; /* Center files horizontally */
}

.added-file {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 8px 12px;
  width: 40%; /* Adjust width dynamically */
  border: 1px solid #ced4da;
  border-radius: 8px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.added-file span {
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.added-file button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #dc3545;
  font-size: 16px;
  transition: color 0.3s ease;
}

.added-file button:hover {
  color: #bd2130;
}
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 80%; /* Limit width for better readability */
}

.popup p {
  margin-bottom: 15px; /* Increase spacing between paragraphs */
  color: #333; /* Set text color for better contrast */
}

.close-button {
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #0056b3;
}
