[data-typography="poppins"] {
    font-family: 'poppins', sans-serif;
  }

[data-typography="opensans"] {
    font-family: 'Open Sans', sans-serif;
  }
  
[data-typography="roboto"] {
    font-family: 'Roboto', sans-serif;
  }
  

[data-typography="helvetica"] {
    font-family: 'HelveticaNeue';
  }
  


  

  
