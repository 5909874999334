.photo-content{
    position: relative;
    .cover-photo{
        background: url(../images/profile/cover.jpg);
        background-size: cover;
        background-position: center;
        min-height: 250px;
        width: 100%;
		border-radius:$radius;

    }

}
.profile .profile-photo {
	max-width: 100px;
	position: relative;
	z-index: 1;
	margin-top: -40px;
	margin-right: 10px;
	

	
	@include respond('tab-land') {
		
	}
	@include respond('phone') {
		width: 80px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom:20px;
	}
}
[direction="rtl"]{
    .profile .photo-content .profile-photo {
		left: auto;
		right: 50px;
		
	}
	.profile .profile-photo {
		left:auto;
		right: 30px;
		@include respond('tab-land') {
			right: 15px;
		}
		@include respond('phone') {
			width: 80px;
			right: calc(50% - 40px);
			top: -100px;
			
			
		}
	}
}
.profile-info{
    padding: 15px 20px;
	@include respond('phone') {
		padding: 0 0 20px;
		text-align:center;
	}
    h4 {
        color: $l-ctd!important;
    }

    h4.text-primary {
        color: $primary!important;
    }

    p {
        color: $l-ctl;
    }

    .prf-col{
        min-width: 250px;
        padding: 10px 50px 0;
    }
}
.profile-interest{
    .row{
        margin: 0 -.70px;
        .int-col{
            padding: 0 .70px;
            .interest-cat{
                margin-bottom: 1.40px;
                position: relative;
                display: block;
                &:after{
                    background: $black;
                    bottom: 0;
                    content: "";
                    left: 0;
                    opacity: 0.5;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 0;
                }
               
                p{
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    padding: 5px;
                    left: 0;
                    margin: 0;
                    z-index: 1;
                    color:white;
                    font-size: 1.20px;
                }
            }
            
        }
    }
}
.profile-tab{
    .nav-item{
        .nav-link{
            font-size: 16px;
            margin-right: 30px;
            transition: all 0.5s ease-in-out;
            border: none;
            border-bottom: .20px solid transparent;
            color: $l-ctl;
            &:hover, &.active{
                border: 0;
                background: transparent;
                background: transparent;
                border-bottom: .20px solid $primary;
                color: $primary;
            }
			@include respond ('phone'){
				margin-right: 0px;
			}

        }
        
    }
}
.profile-info{
	display:flex;
	@include respond('phone') {
		display:block;
	}
	.profile-details {
		display: flex;
		width: 100%;
		@include respond('phone') {
			display:block;
			.dropdown{
				position: absolute;
				top: 30px;
				right: 30px;
			}
		}
	}
}

.post-input{
    margin-bottom: 30px;
    .form-control{
        height: 75px;
        font-weight: 400;
        margin: 15px 0;
    }
    
}  
.profile-uoloaded-post{
    
	.post-title{
		h3{
			font-size: 24px;
			@include respond ('phone'){
				font-size:18px;
			}
		}
	}
    img{
        margin-bottom: 20px;
    }
    a{
        h4{
            margin-bottom: 10px;
            color: $l-ctd;
        }
    }
}
.post-input{
    margin-bottom: 1.875rem;
    .form-control{
        font-weight: 400;
	  
    }
	.btn-social{
		font-size: 1.25rem;
		height: 3.4375rem;
		display:inline-block;
		padding: 0;
		text-align:center;
		border-radius:$radius;
		color:$white;
		width: 3.4375rem;
		line-height: 3.375rem;
		@include respond ('phone'){
			font-size: 0.95rem;
			height: 2.2375rem;
			width: 2.2375rem;
			line-height: 2.375rem;
		}
		&.facebook{
			background-color: #3B5998;
		}
		&.google-plus{
			background-color: #DE4E43;
		}
		&.linkedin{
			background-color: #007BB6;
		}
		&.instagram{
			background-color: #8A5A4E;
		}
		&.twitter{
			background-color: #1EA1F3;
		}
		&.youtube{
			background-color: #CE201F;
		}
		&.whatsapp{
			background-color: #01C854;
		}
		i{
			margin:0!important;
		}
	}
    
} 
.profile-blog{
	a{
		&:hover{
			color:$primary;
		}
	}
}