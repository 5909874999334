@media (min-width: 767px) {

    [data-sidebar-style="icon-hover"][data-layout="horizontal"] {
		.metismenu {
			padding: 0 30px;
		}
		.header .header-content {
		    padding-left: 1.875rem;	
		}
	}
    [data-sidebar-style="icon-hover"][data-layout="vertical"] {
        .nav-header {
            width: 5.063rem;

            .brand-logo {
                padding-left: 20px;
				@at-root [direction="rtl"]#{&} {
					padding-left:1.25rem;
					padding-right:1.25rem;
				}
                .logo-abbr {
                    display: block;
                }

                .brand-title {
                    display: none;
                }
            }

            .nav-control {
                display: none;
            }
        }

        .header {
            padding-left: 5.063rem;

            @at-root [direction="rtl"]#{&} {
                padding-right: 4.38rem;
                padding-left: 0.9375rem;
            }

            .header-content {
                padding-left: 1.375rem;

                @at-root [direction="rtl"]#{&} {
                    padding-right: 1.375rem;
                    padding-left: 0;
                }
            }
        }

        .deznav {
            overflow: visible;
            position: absolute;
            left: -12.125rem;
			@include custommq($min: 1200px, $max: 1350px) {
                left: -10rem;
            }

            @at-root [direction="rtl"]#{&} {
                left: auto;
                right: -12.125rem;
            }

            .nav-label {
                display: none;
            }

            .metismenu {
                &>li {
                    &>a {
                        display: flex;
                        justify-content: space-between;
    
                        &>svg,
                        &>i {
                            order: 1;
							margin-right:0;
							margin-top:0;
                        }
                    }
                }

                ul {
					border-left: 0;
					padding-left:0;
					padding-right:28px;
					@at-root [direction="rtl"]#{&} {
						padding-right:0;
						padding-left:28px;
					}
					@at-root [data-theme-version="dark"]#{&} {
						border-color:$d-border;
					}
					&:after{
						left:auto;
						right:28px;
						@at-root [direction="rtl"]#{&} {
							left:28px;
							right:auto;
						}
					}
                    a {
                        position: relative;

                        &::before {
							left: auto;
							right: -5px;
						
                            @at-root [direction="rtl"]#{&} {
                                right: auto;
                                left:-5px;
                            }
                        }
                    }
                }

                .has-arrow {
                    &::after {
                        right: 5rem;

                        @at-root [direction="rtl"]#{&} {
                            right: auto;
                            left: 5rem;
                        }
                    }
                }
            }
        }

        .icon-hover-toggle {

            .nav-header {
                width: 17.1875rem;
    
                .brand-logo {
                    padding-left: 1.6rem;
    
    
                    .brand-title {
                        display: block;
                    }
                }
            }
    
            .header {
                padding-left: 4.38rem;

                @at-root [direction="rtl"]#{&} {
                    padding-right: 4.38rem;
                    padding-left: 0.9375rem;
                }
            }

            .deznav {
                left: 0;

                @at-root [direction="rtl"]#{&} {
                    left: auto;
                    right: 0;
                }
            }
        }

        .content-body {
            margin-left: 5rem;

            @at-root [direction="rtl"]#{&} {
                margin-left: 0;
                margin-right: 5rem;
            }
        }

        .footer {
            padding-left: 4.375rem;

            @at-root [direction="rtl"]#{&} {
                margin-left: 0;
                margin-right: 4.375rem;
            }
        }
    }



}