@tailwind base;
@tailwind components;
@tailwind utilities;



.Dashboad-bloodpressure-card {
  padding: 15px;

}

.Dashboad-diabetes-card {
  padding: 15px;

}

.dashboard-fruit-card {
  padding: 15px;
}

@media screen and (max-width:1200px) {
  .dashboard-fruit-card {
    width: 49% !important;

  }
}

@media screen and (min-width:1201px) and (max-width: 1600px) {
  .dashboard-fruit-card {
    width: 150% !important;
  }

  /* .dashboard-bpsugar-container{
    display: flex;
    
  }
  .Dashboad-bloodpressure-card{
    padding: 15px;
 
  }
  .Dashboad-diabetes-card{
    padding: 15px;
  } */
}


.datepicker-input {
  cursor: pointer;
  /* Show a pointer cursor to indicate it's clickable */
  background-color: #f0f0f0;
  /* Optional: Background color for the input */
  border: 1px solid #ccc;
  /* Optional: Border styling */
}

.datepicker-input:focus {
  outline: none;
  /* Remove the default outline on focus */
}


.modal-header .close {
  background-color: red !important;
  transition: background-color 0.3s;
}

.modal-header .close:hover {
  background-color: darkred;
}


/* Blood Pressure  */
.Bpsuger_lable {
  text-align: right;
}

#add_blood_button {
  max-width: 600px;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  top: 10%;
  left: 80%;
}

.BP_contant_container {
  /* background-color: #b2c6db; */
  position: absolute;
  bottom: 125px;
  left: 20%;
  color: gray;
  text-align: center;
  justify-content: center;
}


.BP_nodata_container {
  position: relative;
  left: 8%;
  color: gray;
  text-align: center;
  width: 100%;
  height: 70%;
  justify-content: center;
}

/* Admin First Aid Screen Deepan */
.containers {
  padding: 20px;
  /* max-width: 800px; */
  max-width: 1200px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.tabers {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
  padding: 10px;
}

.tab {
  padding: 8px 12px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.formContainers {
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.commonSection {
  margin-top: 15px;
  padding: 10px;
  border: 1px dashed #aaa;
  border-radius: 5px;
}

.inputs {
  width: 100%;
  padding: 8px;
  margin: 5px 0;
}

.textareas {
  width: 100%;
  padding: 8px;
  margin: 5px 0;
}

.fileInputs {
  margin: 10px 0;
}

.imagePreview {
  width: 80px;
  height: 80px;
  margin-top: 10px;
  border-radius: 5px;
}

.saveButton {
  background-color: #4CAF50;
  color: white;
  padding: 8px 12px;
  cursor: pointer;
  margin-top: 15px;
  margin-left: 80%;
  width: 20%;
}

.card {
  border: 1px solid #ddd;
  padding: 15px;
  margin: 10px 0;
  border-radius: 5px;
}

.image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

/* Admin First Aid Screen Deepan */


@media screen and (max-width:1600px)and (min-width:1280px) {
  .BP_contant_container {
    bottom: 215px;
    left: 30%;
  }
}

@media screen and (max-width:1025px) {
  .BP_contant_container {
    bottom: 150px !important;
    left: 30%;
  }
}

@media screen and (max-width:1023px) {
  .BP_contant_container {
    bottom: 100px !important;
    left: 20%;
  }
}

@media screen and (max-width:600px) {
  .BP_contant_container {
    bottom: 80px !important;
    left: 20%;
  }


}

@media screen and (max-width:460px) {
  .BP_contant_container {
    bottom: 50px !important;
    left: 18%;
  }

  .bp-header-font {
    font-size: 8px;
  }
}


@media screen and (max-width:365px) {
  .BP_contant_container {
    bottom: 35px !important;
    right: 15%;
  }
}

@media screen and (max-width:328px) {
  .BP_contant_container {
    bottom: 20px !important;
    right: 13%;
  }

  .bp-header-font {
    font-size: 8px;
  }
}

@media screen and (max-width:268px) {
  .BP_contant_container {
    display: none;
  }

  .bp-header-font {
    font-size: 8px;
  }
}



select.form-controltext-black {
  width: 100%;
  height: 104%;
  border-radius: 5px;
}

/* Vaccination.css*/
.vaccine-card {
  display: flex !important;
  align-items: center !important;
}

.vaccine-select {
  width: 200px !important;
  height: 37px !important;
  border-radius: 5px !important;
  background: white !important;
  position: relative !important;
  bottom: 10px !important;
  border-color: rgb(204, 204, 204) !important;
}

.vaccine-btn {
  width: 17% !important;
  margin-left: 70% !important;
  margin-top: 1% !important;
}

.vaccine-card {
  width: 40%;
  /* Set the width to 50% */
  display: inline-block;
  /* Display as inline-block to allow multiple cards to appear in a row */
  margin: 20px;
  /* Adjust margin as needed */
  border: 1px solid #ccc;
  /* Add a border for a card-like appearance */
  padding: 10px;
  /* Add padding for spacing */
  box-sizing: border-box;
  /* Include padding in the width calculation */
  background-color: #9199fa;
  /* Optional: Set background color */
  border-radius: 8px;
  /* Optional: Add border radius for rounded corners */
  box-shadow: 0px 2px 12px 20px rgb(14 14 14 / 10%) !important;
  /* Optional: Add box shadow for depth */
  height: 20% !important;
}

/* ------------------------------------moodmonitor------------------------------------ */

/* Add this CSS to your stylesheet */


/* Style for selected mood emoji */
.mood-item span.selected {
  padding: 5px;
  transition: all 0.3s ease;
  border-radius: 50%;
  background-color: #4CAF50;
  color: #ffffff;
  box-shadow: 0 0 10px rgba(76, 175, 80, 0.5);
}

.mood-item span.selected:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(76, 175, 80, 0.7);
}

.mood-item span.selected:active {
  transform: scale(0.95);
  box-shadow: none;
}

/* Style for selected reason label */
.mood-item p.selected {
  padding: 10px;
  border-radius: 5px;
  background-color: #4CAF50;
  /* Same background color as selected mood emoji */
  color: #ffffff;
  /* Same text color as selected mood emoji */
  box-shadow: 0 0 10px rgba(76, 175, 80, 0.5);
  font-weight: bold;
  transition: all 0.3s ease;
}

.mood-item p.selected:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(76, 175, 80, 0.7);
}

.mood-item p.selected:active {
  transform: scale(0.95);
  box-shadow: none;
}

/* General mood-item style */
.mood-item {
  display: inline-block;
  margin: 10px;
  text-align: center;
}

/* Grid container styles */
.mood-grid,
.mood-grid-reason {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
}

/* Font size and padding adjustments for emojis and labels */
.mood-item span,
.mood-item p {
  font-size: 20px;
  cursor: pointer;
}




/* Style for selected mood emoji */
.mood-item span.selected {
  padding: 5px;
  /* Add padding to selected mood emoji */
  transition: all 0.3s ease;
  /* Smooth transition for better visual effect */
  border-radius: 50%;
  /* Make it circular */
  background-color: #4CAF50;
  /* Background color */
  color: #ffffff;
  /* Text color */
  box-shadow: 0 0 10px rgba(76, 175, 80, 0.5);
  /* Soft box shadow */
}

/* Hover effect */
.mood-item span.selected:hover {
  transform: scale(1.05);
  /* Slightly scale up the emoji on hover for emphasis */
  box-shadow: 0 0 15px rgba(76, 175, 80, 0.7);
  /* Add a softer box shadow */
}

/* Active effect */
.mood-item span.selected:active {
  transform: scale(0.95);
  /* Scale down the emoji slightly when clicked for feedback */
  box-shadow: none;
  /* Remove shadow when clicked */
}

.mood-emojidash {
  /* font-size: 80px;
  position: relative;
  right: 10px; */
}

.mood-item {
  display: inline-block;
  margin: 10px;
}

/* MoodMonitor.css */
.mood-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.mood-grid-reason {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.mood-item {
  text-align: center;
}

.mood-item span {
  font-size: 30px;
  cursor: pointer;
  /* Adjust the font size to make the emojis bigger */
}

.small-input {
  width: 40%;
  margin-bottom: 10px;
}

.small-button {
  width: 7%;
  height: 5%;
  font-size: 12px;
}

.data-mood {
  font-size: 15px !important;
  /* text-align: right; */
  padding-right: 22px;
  padding-top: 10px;
  font-weight: 300;
}

.mood_date {
  position: relative;
  top: 28%;
  color: rgb(214, 49, 49) !important;
  left: 6%;
  /* max-width: 50%; */
}

.mood-ReadMore {
  color: #201f1f;
  cursor: pointer !important;
}

























.circle-button {
  border-radius: 50% !important;
  /* Make it a circle */
  width: 40px !important;
  /* Adjust as needed */
  height: 40px !important;
  /* Adjust as needed */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 300% !important;
  /* Adjust as needed */
  margin-left: auto !important;
  margin-bottom: 10px !important;
}

.circle-button_suger {
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 300% !important;
  margin-bottom: 40px !important;
  position: relative;
  left: 35%;
}

.circle-button_bp {
  border-radius: 50% !important;
  /* Make it a circle */
  width: 40px !important;
  /* Adjust as needed */
  height: 40px !important;
  /* Adjust as needed */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 300% !important;
  /* Adjust as needed */
  /* margin-left: auto !important; */
  /* margin-bottom: 10px !important; */
  position: relative;
  left: 95.5%;
  top: 10% !important;
}

.mood-monitor {
  padding: 18px !important;
  margin: 22px !important;
  color: rgb(250, 250, 250) !important;
}

.mood-monitor-text {
  color: aliceblue !important;
}

.mood-monitor-ReadMore {
  color: rgb(58, 5, 18) !important;
  cursor: pointer !important;
  margin-left: 65% !important;
}

.mood-text {
  margin-top: 5% !important;
  margin-left: 5% !important;
}


.card1 {
  box-shadow: rgb(153, 201, 144) 0px 0px 3px 0px !important;
  height: 93% !important;
}

.demo-app-calendar {
  max-width: 100vw !important;
}

.mood-responsive-card {
  max-width: 33% !important;
}

.bpsugar-responsive-wavechart {
  justify-content: center;
  margin: 0 auto;
  max-width: 100% !important;
}

.language-responsive-icon {
  position: fixed;
  left: 90%;
  bottom: 90%;
  display: flex;
}

.logo-abbr {
  padding: 15px;
}

.bpsugar-responsive-add-button {
  position: relative;
  bottom: 40px;
  left: 85%;
  max-width: 10%;
}

.text-wrap-responsive {
  text-wrap: nowrap !important;
}

.mood-breadcrumbs-minwidth {
  min-width: 75%;
}

@media screen and (max-width:1014px) {
  .mood-responsive-card {
    max-width: 50% !important;
  }
}

@media screen and (max-width:700px) {
  .mood-responsive-card {
    max-width: 100% !important;
  }

  .bpsugar-responsive-add-button {
    left: 77%;
  }

  .mood-breadcrumbs-minwidth {
    min-width: 5%;
  }

  .mood-item span,
  .mood-item p {
    font-size: 8px;
    cursor: pointer;
  }

  .login-form {
    width: auto !important;
    height: auto !important;
    margin: 0 auto;
  }

  .logo-abbr {
    padding: 1px;
  }

  .login-container {
    background-color: #def3f0;
    min-width: 405px !important;
    overflow: hidden;
  }

  .responsive-login-card {
    max-width: 300px;
  }

  .login-slider-responsive {
    display: none;
  }

  .language-responsive-icon {
    position: fixed;
    left: 10%;
    bottom: 95%;
    display: flex;
  }

}



/* Blood Pressure */
.small-but {
  width: 15% !important;
  height: 5% !important;
  font-size: 15px !important;
  margin-left: 10px !important;
  margin-top: 2% !important;
  color: black !important;
  /* cursor: pointer; */
}

.btn-add {
  width: 15% !important;
  height: 5% !important;
  font-size: 12px !important;
  margin-left: 80% !important;
  color: black !important;
  cursor: pointer;
}

.btn-edit {
  cursor: pointer;
}

.breadhome {
  cursor: pointer;
  margin-top: 1% !important;
}

.breadtitle {
  font-weight: 700;
  color: #042eff;
  margin-left: 17% !important;
}

/* mooddelete */

/* firstaid */
.card-zoom:hover {
  transform: scale(1.1);
}






.add-detail-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.add-detail-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.add-detail-container label {
  display: block;
  margin-bottom: 10px;
  color: #333;
}

.add-detail-container textarea,
.add-detail-container input[type='text'],
.add-detail-container select {
  width: calc(100% - 40px);
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #db2121;
  border-radius: 5px;
  resize: vertical;
  font-size: 16px;
}

.add-detail-container select {
  cursor: pointer;
}

.add-detail-container button {
  width: calc(100% - 40px);
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
}

.add-detail-container button:hover {
  background-color: #0056b3;
}


/* demography */
.blood-group.selected img {
  border: 5px solid rgb(32, 81, 172);
  /* Adjust border color for the selected effect */
}

.demo {
  font-family: SFProDisplay-Regular, Helvetica, Arial, sans-serif;
  font-size: 28px;
  font-weight: normal;
  line-height: 32px;
  width: 500px;
}


/* ------------------------------------Register& login----------------------------------------------------------------------------------------- */
/* Register & login-styles.css */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: #def3f0;
  min-width: 600px;
}

.login-logo {
  position: relative;
  bottom: 200px;
  left: 50%;
  width: 10%;
}

.login-text {
  position: relative;
  bottom: 0px;
  left: 30%;
  width: 0%;
}

.login-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.login-form {
  width: 500px;
  background-color: #fcfcfc;
  margin-bottom: 25%;
  border-radius: 2.25rem;
}




.text-center {
  text-align: center;
}

.new-account {
  margin-top: 20px;
  color: #000000;
}

.new-account p {
  margin: 0;
}

.new-account .text-primary {
  color: #ffffff !important;
  text-decoration: none;
}

.new-account .text-primary:hover {
  text-decoration: underline;
}

/* ----------------------------------------------------------------------------------OTPPage------------------------------------------------------------------------------- */

/* OTPPage.css */

/* otp-page.css */

.otp-authentication-container {
  background-color: #e6eeed;
  padding: 50px 0;
  width: 100%;
  height: 100%;
}

/* .otp-authentication-content {
  background-color: #daabab;
  border-radius: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  padding: 4px;
  margin-left: 25%;
  width: 100%;
  height: 100%;
  justify-content: center;
  } */

.otp-authentication-form {
  background-color: #17a2b8;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 50%;
  margin-left: 35%;
  margin-top: 20%;
}

.otp-logo {
  width: 50px;
}

.text-white {
  color: #ffffff;
}

.btn {
  border-radius: 25px;
  padding: 10px 20px;
  cursor: pointer;
}

.btn.bg-white {
  background-color: #000000;
}

.btn.bg-white:hover {
  background-color: #f0f0f0;
}

.btn.text-primary {
  color: #007bff;
}

.btn.text-primary:hover {
  color: #0056b3;
}

.form-group label {
  font-weight: bold;
}

.form-control {
  border-radius: 25px;
}

.text-center {
  text-align: center;
}

.otp-input {
  border: 1px solid #e9ecf0;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
}


/* ----------------------------------------------------------------------------------lockscreen------------------------------------------------------------------------------- */


.lockscreen-authentication {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #ffffff;
}

.lockscreen-authentication-content {
  background-color: #3428d8;
  border-radius: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 400px;
  margin-left: 25%;
}

.lockscreen-authentication-content h4 {
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.lockscreen-authentication-content form {
  text-align: center;
}

.lockscreen-authentication-content input[type='password'] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #e6e1e1;
  border-radius: 5px;
}

.lockscreen-authentication-content input[type='submit'] {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.lockscreen-authentication-content input[type='submit']:hover {
  background-color: #0056b3;
}

.lockscreen-authentication-content .error-message {
  color: red;
  font-size: 14px;
}

/* ----------------------------------------------------------------------------------Demography main------------------------------------------------------------------------------- */



.demography_backButton a {
  color: #007bff;
  /* Change the color to your preference */
  text-decoration: none;
  font-size: 32px;
  /* Adjust the font size as needed */
  display: inline-block;
  position: absolute;
  bottom: 80%;
  right: 95.5%;
}


.demography_backButton a i {
  margin-right: 10px;
  /* Adjust the spacing between icons */
}

.demography_backButton a:hover {
  color: #0056b3;
  /* Change the hover color to your preference */
}

.icon_backButton a {
  color: #007bff;
  /* Change the color to your preference */
  text-decoration: none;
  font-size: 32px;
  /* Adjust the font size as needed */
  display: inline-block;
}

.icon_backButton a i {
  margin-right: 10px;
  /* Adjust the spacing between icons */
}

.icon_backButton a:hover {
  color: #0056b3;
  /* Change the hover color to your preference */
}

.dmography-next-button {
  /* padding: 0.25rem 0.5rem; */
  font-size: 0.875rem;
  height: 7% !important;
  width: 10% !important;
  position: absolute;
  bottom: 12%;
  right: 13%;
  background-color: #99c990;
  justify-content: center;
  text-align: center;
  color: #000;
}

.dmography-next-button:hover {
  background-color: #88b888;
  /* Change to a darker shade of green on hover */
  color: #fff;
  /* Change text color to white on hover */
}


.D_container {
  justify-content: center;
  background-color: #def3f0;
  color: #000;
  /* background-color: hsl(168, 28%, 90%); */
  width: 80%;
  margin: 0 auto;
  border-color: #000;
  border: 1px;
  display: flex;
  min-height: 50vh;
  border-radius: 15px;
  /* min-height: 50vh!important; */

}

.D_column {

  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;

}

.demography_main_contant {

  justify-content: center;
  padding: 4%;

}

/* .demography_main_contant_maritial {
  justify-content: center;
  padding: 4%;
  background-color: #b98f8f;
  width: 65%;
  min-height: 80%;

} */



#Demography_card {
  background-color: #f8f3f3;
  width: 100% !important;
  height: 55%;
  text-align: center;
  padding: 7%;
}

#profession_Demography_card {
  background-color: #f8f3f3;
  width: 100% !important;
  height: 60% !important;
  text-align: center;
  padding: 7%;
}


#height_Demography_card {
  background-color: #f8f3f3;
  width: 100% !important;
  height: 90% !important;
  text-align: center;
  padding: 7%;
  border-radius: 15px;

}



.D_contant_container {
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  justify-content: center;

}

.D_contant_container h4 {
  margin-bottom: 10px;
  font-size: 22px;
  color: #333;

}

.D_contant_container input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #555;

}

.error-message {
  font-size: 14px;
  color: red;

}

#gender-error-message {
  text-align: center;
  bottom: 10%;
  position: absolute;
  bottom: 16%;
  left: 44%;

}

#maritial-error-message {
  text-align: center;
  bottom: 10%;
  position: absolute;
  bottom: 16%;
  left: 44%;

}

.profession-image {
  width: 80%;
}







@media screen and (max-width: 1600px) and (min-width : 1440px) {
  .Demograhy_image {
    padding-top: 20%;
    margin-top: 2%;
    /* background-color: hsl(46, 74%, 49%);  */
  }

  /* .demography_main_contant_maritial {
  background-color: #dba9a9;
} */
  #maritial-error-message {
    text-align: center;
    position: absolute !important;
    left: 43% !important;
    bottom: 12%;
  }

  #Demography_card {
    height: 35%;
  }

  #profession_Demography_card {
    background-color: #f8f3f3;
    /* background-color: #ca2525; */
    width: 100% !important;
    height: 55% !important;
    text-align: center;
    padding: 7%;
  }

  #height_Demography_card {
    /* background-color: #e21818; */
    width: 100% !important;
    height: 100% !important;
    text-align: center;
    padding: 7%;
  }

  #height_Demography_card {
    /* background-color: #e23018; */
    width: 120% !important;
    height: 90% !important;
    text-align: center;
    padding: 7%;
    position: relative;
    right: 20%;
  }

  .content-container {
    max-width: 800px;
    width: 125% !important;
    padding: 5px;
    justify-content: center;
    /* background-color: #4db8d8; */
    position: absolute;
    left: 13%;
  }

}

@media screen and (max-width: 1919px) and (min-width : 1601px) {
  .Demograhy_image {
    padding-top: 15%;
    /* background-color: hsl(108, 74%, 49%);  */
  }



  #Demography_card {
    height: 37%;
    padding: 5%;
  }

  #profession_Demography_card {
    background-color: #f8f3f3;
    /* background-color: #ccba16; */
    width: 100% !important;
    height: 45% !important;
    text-align: center;
    padding: 7%;
  }

  .dmography-next-button {
    position: absolute;
    bottom: 20%;
    right: 15%;
  }

  #height_Demography_card {
    /* background-color: #f1cf0e; */
    width: 100% !important;
    height: 90% !important;
    text-align: center;
    padding: 7%;
    position: relative;
    right: 20%;
    bottom: 5%;
  }

  .content-container {
    max-width: 800px;
    width: 125% !important;
    padding: 5px;
    justify-content: center;
    /* background-color: #e26262; */
    position: absolute;
    left: 20%;
  }

  .Height_boy_image {
    position: absolute;
    /* bottom: 80px; */
    bottom: 180px !important;
    min-width: 400px;
    width: 200px;
    transition: height 0.5s;
    /* background-color: #000; */
  }


}

@media screen and (max-width: 1366px) and (min-width : 1280px) {
  .Demograhy_image {
    padding-top: 15%;
    /* background-color: hsl(182, 74%, 49%);  */
  }

  /* .demography_main_contant_maritial {
    background-color: #1ea030;
  } */
  #maritial-error-message {
    text-align: center;
    position: absolute !important;
    left: 43% !important;
    bottom: 10%;
  }

  #Demography_card {
    height: 40%;
  }

  #profession_Demography_card {
    background-color: #f8f3f3;
    /* background-color: #1dcc43; */
    width: 100% !important;
    height: 60% !important;
    text-align: center;
    padding: 7%;
  }

  #height_Demography_card {
    /* background-color: #47e218; */
    width: 120% !important;
    height: 90% !important;
    text-align: center;
    padding: 7%;
    position: relative;
    right: 20%;
  }

  .Height_boy_image {
    position: absolute;
    min-width: 400px;
    width: 200px;
    max-height: 300px !important;
  }

  .content-container {
    max-width: 800px;
    width: 125% !important;
    padding: 5px;
    justify-content: center;
    /* background-color: #dfe211; */
    position: absolute;
    left: 14%;
    bottom: 16%;
  }

  .blood-group img {
    width: 60% !important;
    border-radius: 50%;
    position: relative;
    /* left: 32px; */
  }
}

@media only screen and (max-height: 600px) and (max-width:'1270') {
  #height_Demography_card {
    /* background-color: #c6e0e0!important; */
    width: 110% !important;
    height: 95% !important;
    text-align: center;
    padding: 3%;
    position: relative;
    right: 20%;
    bottom: 12%;
  }

  #profession_Demography_card {
    background-color: #f8f3f3;
    /* background-color: #253eca; */
    width: 100% !important;
    height: 60% !important;
    text-align: center;
    padding: 7%;
  }

  /* .demography_main_contant_maritial {
    background-color: #1c93a8;
  } */
  .dmography-next-button {
    position: absolute !important;
    bottom: 10% !important;
    right: 15%;
  }

  .Height_boy_image {
    position: absolute;
    min-width: 400px;
    width: 200px;
    max-height: 180px !important;
  }

  .content-container {
    max-width: 800px;
    width: 125% !important;
    /* padding: 20px; */
    justify-content: center;
  }

  .blood-group img {
    width: 50% !important;
    border-radius: 50%;
    position: relative !important;
    right: 60% !important;
    /* padding: 5%!important; */
  }

  .blood-group {
    max-width: 110px;
    transition: transform 0.3s, border-color 0.3s;
  }

  .blood-group-label {
    display: block;
    margin-top: 5px;
    text-align: center;
    position: relative;
    right: 85%;
  }


  .content-container {
    max-width: 800px;
    width: 125% !important;
    padding: 5px;
    justify-content: center;
    position: absolute;
    left: 8% !important;
    /* background-color: #deebdf!important ; */
  }




  .blood-group-images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 3px;
    margin-top: 25px;
  }

  /* .vegan {
    margin-top: 40px;
    position: absolute !important;
    top: 45% !important;
    width: 6% !important;

  }


  .vegetarian {
    margin-top: 45px;
    position: absolute !important;
    top: 56% !important;
    width: 10% !important;
  }


  .eggetarian {
    margin-top: 45px;
    position: absolute !important;
    top: 68% !important;
    width: 10% !important;
  }


  .non-vegetarian {
    margin-top: 45px;
    position: absolute !important;
    top: 80% !important;
    width: 15% !important;
  } */



  .vegetarian {
    position: absolute;
    top: 48%;
    left: 40%;
    min-width: 14%;
  }

  .non-vegetarian {
    position: absolute;
    top: 70%;
    left: 40%;

  }

  .eggetarian {
    position: absolute;
    top: 59%;
    min-width: 14%;
    left: 40%;

  }


  .vegan {
    position: absolute;
    top: 35%;
    min-width: 14%;
    left: 40%;

  }



  #gender-error-message {
    text-align: center;
    position: absolute !important;
    left: 43% !important;
    bottom: 1% !important;
  }

  #maritial-error-message {
    text-align: center;
    position: absolute !important;
    left: 43% !important;
    bottom: 1% !important;
  }

  .Single {
    width: 15% !important;
    margin-top: 45px;
  }

  .Married {
    width: 15% !important;
    margin-top: 45px;

  }

  .Widow {
    width: 15% !important;
    margin-top: 35px;

  }

  .Divorced {
    width: 15% !important;
    margin-top: 35px;

  }

}

@media screen and (max-width: 1279px) and (min-width : 1024px) {
  .Demograhy_image {
    padding-top: 20%;
    /* background-color: #d40b0b;  */
  }

  /* .demography_main_contant_maritial {
    background-color: #b621ae;
  } */
  #maritial-error-message {
    text-align: center;
    position: absolute !important;
    left: 43% !important;
    bottom: 10%;
  }

  .dmography-next-button {
    position: absolute;
    bottom: 10%;
    right: 15%;
  }

  #Demography_card {
    height: 50%;
  }

  #height_Demography_card {
    /* background-color: #18e2d8; */
    width: 110% !important;
    height: 98% !important;
    text-align: center;
    padding: 3%;
    position: relative;
    right: 15%;
    bottom: 12%;
  }

  .Height_boy_image {
    position: absolute;
    min-width: 400px;
    width: 200px;
    /* max-height: 250px !important;
    padding-top: 6%; */
  }

  .D_contant_container input {
    width: 86%;
    padding: 0px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: #555;
  }

  .weight_boy_image {
    margin-right: 5% !important;
  }

  /* gendertype */

  /* .male {
    position: absolute;
    top: 42% !important;
  }

  .female {
    position: absolute;
    top: 56% !important;
  }

  .transgender {
    position: absolute;
    top: 70% !important;
  } */

  /* foodtype */

  .vegan {
    position: absolute;
    top: 35% !important;
  }

  .vegetarian {
    position: absolute;
    top: 46% !important;
  }

  .eggetarian {
    position: absolute;
    top: 58% !important;
  }

  .non-vegetarian {
    position: absolute;
    top: 70% !important;
  }

  #gender-error-message {
    text-align: center;
    position: absolute !important;
    left: 43% !important;
    bottom: 10%;
  }


  .content-container {
    max-width: 800px;
    width: 125% !important;
    padding: 5px;
    justify-content: center;
    /* background-color: #042eff; */
    position: absolute;
    left: -1%;
  }

  .blood-group img {
    width: 36% !important;
    border-radius: 50%;
    position: relative !important;
    left: 37% !important;
  }

  .blood-group-label {
    display: block;
    margin-top: 5px;
    text-align: center;
    position: relative;
    left: 35%;
  }


}

@media screen and (max-width: 1280px) and (min-width : 1024px) {
  .male {
    position: fixed;
    top: 43%;
    right: 38% !important;
    max-width: 20% !important;
  }

  .female {
    position: fixed;
    top: 53%;
    right: 38% !important;
    max-width: 20% !important;
  }

  .transgender {
    position: fixed;
    top: 63%;
    right: 38% !important;
    max-width: 20% !important;
  }

}

@media screen and (min-width: 1365px) and (max-width : 1920px) {
  .male {
    position: fixed;
    top: 43%;
    right: 30% !important;
    max-width: 20% !important;
  }

  .female {
    position: fixed;
    top: 53%;
    right: 30% !important;
    max-width: 20% !important;
  }

  .transgender {
    position: fixed;
    top: 63%;
    right: 30% !important;
    max-width: 20% !important;
  }
}




/* ----------------------------------------------------------------------------------CUSTOMIZED FOR DEMOPGRAPHY------------------------------------------------------------------------------- */

.dob-input {
  width: 53%;
  padding: 15px;
  position: relative;
  top: 50%;
  border: 2px solid #007bff;
  border-radius: 5px;
  font-size: 1.5rem;
}

.weight_boy_image {
  min-height: 200px;
  margin-right: 15%;
  margin-top: 10%;
  transition: width 0.5s;
  width: 50%;
  height: 350px;
  transition: transform 0.5s ease;
}

.Height_boy_image {
  position: absolute;
  bottom: 80px;
  min-width: 400px;
  height: 50%;
  top: 25%;
  width: 200px;
  transition: height 0.5s;
}


.male {
  position: fixed;
  top: 43%;
  right: 35%;
  max-width: 20% !important;
}

.female {
  position: fixed;
  top: 53%;
  right: 35%;
  max-width: 20% !important;
}

.transgender {
  position: fixed;
  top: 63%;
  right: 35%;
  max-width: 20% !important;
}

/* .vegetarian {
  position: absolute;
  top: 48%;
}

.non-vegetarian {
  position: absolute;
  top: 70%;
}

.eggetarian {
  position: absolute;
  top: 59%;
}


.vegan {
  position: absolute;
  top: 35%;
} */



.vegetarian {
  position: absolute;
  top: 48%;
  left: 40%;
  min-width: 14%;
  max-width: 14%;
}

.non-vegetarian {
  position: absolute;
  top: 70%;
  left: 40%;
  min-width: 14%;
  max-width: 14%;

}

.eggetarian {
  position: absolute;
  top: 59%;
  left: 40%;
  min-width: 14%;
  max-width: 14%;

}


.vegan {
  position: absolute;
  top: 37%;
  left: 40%;
  min-width: 14%;
  max-width: 14%;

}






/* ----------------------------------------------------------------------------------------blood-group.css-------------------------------------------------------------------- */

.blood-group-container {
  width: 100%;
  margin-left: 15%;
}

.blood-group {
  display: inline-block;
  cursor: pointer;
  margin-right: 20px;
  transition: transform 0.3s, border-color 0.3s;
}

.blood-group:hover img {
  border: 5px solid transparent;
  box-shadow: 0 0 10px rgba(125, 180, 218, 0.8);
  transform: scale(1.2);
}

.blood-group.selected img {
  border: 5px solid rgb(32, 81, 172);
  /* Adjust border color for the selected effect */
}

.blood-group-label {
  display: block;
  margin-top: 5px;
  text-align: center;
}

.content-container {
  max-width: 800px;
  width: 106%;
  padding: 20px;
  text-align: center;
}

.blood-group-images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  margin-top: 10px;
}

.blood-group {
  display: inline-block;
  cursor: pointer;
  margin-right: 20px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s, border-color 0.3s;
}

.blood-group img {
  width: 70%;
  border-radius: 50%;
}

.blood-group span {
  display: block;
  margin-top: 5px;
  color: #000000;
}

.notification {
  margin-top: 20px;
  text-align: center;
}

.notification-text {
  margin: 0;
}

.button-container {
  margin-top: 20px;
  text-align: right;
}



/* ----------------------------------------------------------------------------------------maritial-------------------------------------------------------------------- */
/* MaritalStatusPage.css */


.label-single,
.label-married,
.label-widow,
.label-divorced {
  width: 55%;
  /* Adjust label width */
  text-align: center;
  border: 1px solid #000;
  /* Example border */
  padding: 5px;
}


.maritial-heading {
  text-align: center;
  position: absolute;
  bottom: 65%;
  left: 37%;
}

.Single {
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;
  width: 15%;
  text-align: center;
  background-color: rgb(228, 212, 212);
  position: absolute;
  top: 44%;
  left: 30%;

}

.Married {
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;
  width: 15%;
  text-align: center;
  background-color: rgb(228, 212, 212);
  position: absolute;
  top: 44%;
  right: 30%;
}

.Widow {
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;
  width: 15%;
  text-align: center;
  background-color: rgb(228, 212, 212);
  position: absolute;
  top: 64%;
  left: 30%;
}

.Divorced {
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;
  width: 15%;
  text-align: center;
  background-color: rgb(228, 212, 212);
  position: absolute;
  top: 64%;
  right: 30%;
}

/* Selected status background color */
.Single.selected,
.Married.selected,
.Widow.selected,
.Divorced.selected {
  background-color: #99c990;
}











/* ----------------------------------------------------------------------------------------blood-group.css-------------------------------------------------------------------- */

/* fullDetailPage.css */
.container {
  max-width: 600px;
  margin: 20px auto;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: flex;
  /* Use flexbox for alignment */
  flex-direction: column;
  /* Stack elements vertically */
  align-items: center;
  /* Center align items horizontally */
}

.title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  /* Center align title text */
}

.detail {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 15px;
  text-align: center;
  /* Center align detail text */
  width: 100%;
  /* Ensure full width */
}

.detail span {
  font-weight: bold;
  color: #777;
}

/* Apply styles for odd and even paragraphs */
.detail:nth-child(odd) {
  background-color: #f0f0f0;
  padding: 10px;
}

.detail:nth-child(even) {
  background-color: #e0e0e0;
  padding: 10px;
}

/* fullDetailPage.css */
.finish-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.finish-button:hover {
  background-color: #0056b3;
}

/* document upload */
.small-but {
  width: 10% !important;
  height: 5% !important;
  font-size: 12px !important;
  margin-left: 10px !important;
  margin-top: 2% !important;
  /* cursor: pointer; */
}

.btn-add {
  width: 15% !important;
  /* height:5% !important; */
  font-size: 12px !important;
  /* margin-left: 80% !important; */
  cursor: pointer;
}


.btn-edit {


  cursor: pointer;
}

.error {
  color: red;
  /* Set the color of the error message */
  text-align: left;
  /* Align the error message to the left */
  /* Add any additional styling as needed */
}

.mentalhealth {
  font-size: 20px;
}

.fixed-buttons-container {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: 10%;
  z-index: 1000;
}

.fixed-buttons-container .btn {
  margin-right: 10px;
}


/* calendar css ---------------------------------------------------- */

.back-button {
  margin-bottom: 20px;
}

/* .calendar-data {
  padding: 20px;
  background-color: #f1f5f0;  
  border: 1px solid #ced4da;
  border-radius: 10px;
} */

.data-section {
  margin-bottom: 20px;
  background-color: #ffffff;
  /* Change background color to white */
  border: 1px solid #ced4da;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.data-title {
  font-size: 24px;
  margin-bottom: 15px;
  color: #108fe4;
}

.data-item {
  padding: 15px;
  border: 1px solid #ced4da;
  border-radius: 8px;
  /* margin-bottom: 15px; */
}

.data-label {
  margin: 0;
  font-weight: bold;
}

.no-data-msg {
  font-size: 18px;
  font-weight: bold;
  color: #dc3545;
}







.data-col {
  position: relative;
  background-color: #f7f7f7;
  /* border: 1px solid #e0e0e0; */
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
  max-width: 32% !important;
  max-height: 30% !important;
  margin-left: 15px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.data-col h2 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
}

.data-col p {
  margin: 0;
  margin-bottom: 5px;
  color: #666;
}

.data-col p:last-child {
  margin-bottom: 0;
}

.data-col:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

.data-item p.note {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* comment for mood data note alignment issue */
  /* white-space: pre-wrap; 
  word-wrap: break-word;  
  overflow-wrap: break-word;  */
}

.data-item p {
  word-wrap: break-word;
}






/* AddPeriodForm.css */

.add-period-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.add-period-form h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

.add-period-form form {
  display: flex;
  flex-direction: column;
}

.add-period-form label {
  font-weight: bold;
  margin-bottom: 8px;
  color: #555;
}

.add-period-form input[type="text"],
.add-period-form input[type="number"],
.add-period-form .custom-datepicker {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
  box-sizing: border-box;
}

.add-period-form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.add-period-form button:hover {
  background-color: #0056b3;
}




.prediction-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.custom-datepicker,
.custom-input {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  margin-top: 10px;
}

.custom-button {
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
}

.calendar-container {
  margin-top: 20px;
}

.prediction-details {
  margin-top: 20px;
}

.predicted-date {
  background-color: aqua !important;
  color: black !important;
}


.food-details-header {
  text-align: center !important;
}



.find-donor-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 20px; */
}

.find-donor-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  /* background-color:#e6e2e2; */
  padding: 15px;
  border-radius: 15px;
  width: 104%;
}

.find-donor-form .form-group {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  flex: 1;
  min-width: 200px;
}

.find-donor-form .form-group label {
  margin-bottom: 5px;
}

.find-donor-form button {
  margin-left: 10px;
  margin-top: 20px;
}

.bloodgroup-contant {
  min-width: 18.66%;
  max-width: 18.66% !important;
}


.table-container {
  position: relative;
  overflow: hidden;
  margin-top: 20px;
  z-index: 0;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container thead {
  display: table;
  width: 100%;
  table-layout: fixed;
  position: sticky;
  top: 0;
  background: #c6e1c6;
  /* Slightly different background color for better visibility */
  z-index: 1;
}

.table-container thead th {
  text-align: center;
  padding: 10px;
  /* Add padding for better readability */
  border: 1px solid #ddd;
  /* Optional: Add border for better separation */
}

.table-container tbody {
  display: block;
  max-height: 300px;
  /* Set a max height for the table content */
  overflow-y: scroll;
  width: 100%;
  border-top: none;
}

.table-container tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-container tbody td {
  text-align: center;
  padding: 10px;
  /* Add padding for better readability */
  border: 1px solid #ddd;
  /* Optional: Add border for better separation */
}

.checkbox-table-head {
  width: 50px;
  /* Adjust width to better fit the checkbox */
}

.fixed-button {
  position: fixed;
  left: 85%;
  bottom: 4%;
  max-width: 10%;
}

.blood-card {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #f5c6cb;
  border-radius: 10px;
  padding: 10px;
  max-width: 30%;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
}

.blood-card.on {
  background-color: #d4edda;
}

.blooddonation-topmenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  overflow-x: scroll;
}

@media screen and (max-width:600px) {
  .blood-card {
    min-width: 45%;
  }
}

@media screen and (max-width:1025px) and (min-width:601px) {
  .blood-card {
    min-width: 25%;
  }

  .blooddonation-topmenu {
    gap: 15px;
  }
}

@media screen and (min-width:1026px) and (max-width:1559px) {
  .blood-card {
    min-width: 20%;
  }

  .blooddonation-topmenu {
    gap: 25px;
  }
}

.toggle-switch {
  width: 55px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.switch {
  width: 25px;
  /* Adjust width of the switch handle */
  height: 25px;
  /* Adjust height of the switch handle */
  border-radius: 50%;
  /* Makes the switch handle circular */
  background-color: #556b2f;
  /* Dark green color for the switch handle */
  position: absolute;
  left: 0;
  text-align: center;
  transition: left 0.3s ease;
  /* Smooth transition for left movement */
}

.switch.on {
  left: 30px;
  background-color: #28ee28;
}

.switch.off {
  left: 0;
  background-color: red;

}

.toggle-text {
  color: white;
  font-size: 12px;
  user-select: none;
}




.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
  /* min-width:50%!important; */
  margin-top: 2%;
}

.card-custom {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.card-custom:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card-header-custom {
  background-color: #f0f0f0;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-header-custom-mood {
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-header-custom h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;

}

.blood-group-blood {
  background-color: #d8404f;
  /* background-color: #ff0019;     */
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
}

.card-body-custom {
  padding: 15px;
}

.card-body-custom p {
  margin: 5px 0;
  font-size: 0.9rem;
  color: #555;
}

.card-footer-custom {
  /* background-color: #f0f0f0; */
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.donate-button {
  background-color: #6bbfc5 !important;
  border-color: #6bbfc5 !important;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.donate-button:hover {
  background-color: #28b146;
}

.cancel-button {
  background-color: #fbce9c !important;
  border-color: #fbce9c !important;
  color: #000;
}

@media (max-width: 768px) {
  .card-container {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
}

.notification-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.blood-request-card {
  flex: 1 1 calc(33% - 1rem);
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #f3f8f3 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  max-width: 450px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.blood-request-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2) !important;
}

.blood-request-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blood-request-card-title {
  font-size: 1rem;
}

.blood-request-card-date {
  font-size: 0.875rem;
  color: gray !important;
}

.blood-request-card-body-content {
  margin-top: 0.5rem;
}

.blood-request-card-mobile {
  display: flex;
  align-items: center;
}

.blood-request-card-icon {
  margin-right: 0.5rem;
}

.blood-request-card-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.blood-request-cancel-message {
  flex: 1;
  color: red;
  font-size: 0.875rem;
  margin-right: 1rem;
}

.blood-request-remove-button,
.blood-request-no-thanks-button,
.blood-request-completed-button {
  flex: none;
  margin-left: 1rem;
  transition: background-color 0.2s, color 0.2s;
}

.blood-request-remove-button:hover,
.blood-request-no-thanks-button:hover,
.blood-request-completed-button:hover {
  background-color: darkgray;
  color: white;
}

.blood-request-no-thanks-button {
  background-color: lightgray;
  color: black;
}

.blood-request-no-thanks-button:hover {
  background-color: gray;
  color: white;
}

.blood-request-completed-button {
  background-color: green;
  color: white;
}

.blood-request-completed-button:hover {
  background-color: darkgreen;
  color: white;
}



.blood-request-no-thanks-button {
  background-color: #FFA500 !important;
  border-color: #FFA500 !important;
}

.blood-request-completed-button {
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}


.blood-request-cancel-message {
  color: rgb(238, 90, 90);
  margin-bottom: 10px;
  font-weight: bold;
}


.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  flex: 1;
  color: red;
}

.blood-request-completed-button,
.blood-request-Cancel-button {
  margin-left: 10px;
}



.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  /* Adjust the gap as needed */
}

.blood-card {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* TrackHistory.css */
.back-icon {
  color: #007bff;
  font-size: 32px;
  margin-bottom: 2%;
}



/* TrackHistory.css */
.toggle-button {
  padding: 10px 30px;
  border: none;
  cursor: pointer;
  background-color: #e49595;
  color: #fff;
  /* transition: background-color 0.3s ease, transform 0.3s ease; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  position: relative;
  left: 30%;
  width: 250px;
}

.toggle-button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.toggle-button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.toggle-button.active {
  background-color: #ff4b4b;
  /* transform: scale(1.05); */
}

.toggle-button:hover {
  background-color: #ff4b4b;
  color: #fff;
  /* transform: scale(1.05); */
}






.mood-input {
  display: flex;
  flex-direction: column;
}

.small-input {
  margin-bottom: 10px;
}

.small-button {
  align-self: flex-end;
}

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.important-height {
  min-height: 200px !important;
}

.nav-control {
  cursor: pointer !important;
  /* Add any other styling you need */
}

/* Ensure there are no media queries hiding the nav-control */
@media (max-width: 1280px)and (min-width:500px) {
  .nav-control {
    margin-left: 30% !important;
  }

  .hamburger {
    margin-left: 30% !important;
  }
}

/* Example of other media queries if needed */
@media (max-width: 800px) {
  .nav-control {
    /* display: block !important; */
    /* Ensure it remains displayed on smaller screens */
  }
}






.bmi-container {
  /* display: flex; */
  flex-wrap: wrap;
  gap: 15px;
  padding: 15px;
  overflow: hidden;
}

.bmi-container>* {
  flex: 1 1 calc(33.33% - 15px);
  /* Each component takes 1/3rd of the container width */
  min-width: 280px;
  /* Minimum width to maintain readability on small screens */
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .bmi-container>* {
    flex: 1 1 calc(50% - 15px);
    /* 2 components per row on medium screens */
  }
}

@media (max-width: 480px) {
  .bmi-container>* {
    flex: 1 1 100%;
    /* Full width for each component on small screens */
  }
}


.calculator-toggle-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
}

.calculator-toggle-button:hover {
  background-color: #0056b3;
}

.calculator-container {
  padding: 20px;
}

.input-label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.calculate-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.calculate-button:hover {
  background-color: #218838;
}

.result-heading {
  margin-top: 10px;
  font-size: 20px;
  color: #333;
}

.interpretation {
  font-size: 18px;
  margin-top: 5px;
}

.userBmi_card {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.userBmi_card h2 {
  font-size: 24px;
  color: #333;
}

.userBmi_card h3 {
  font-size: 20px;
  margin-top: 10px;
  color: #555;
}

.userBmi_card p {
  font-size: 18px;
  color: #666;
  margin-top: 10px;
}

.update-height-weight-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.update-height-weight-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.input-label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.update-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.update-button:hover {
  background-color: #0056b3;
}

.success-message {
  color: green;
  margin-top: 10px;
}

.error-message {
  color: red;
  margin-top: 10px;
}




/* General Styles */
body {
  font-family: Arial, sans-serif;
}

.update-height-weight-container,
.userBmi_card,
.calculator-toggle-button {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.calculator-toggle-button {
  max-height: 100px;
  display: flex;
  justify-content: center;
}

.userBmi_card {
  max-height: 200px;
}

.update-height-weight-title,
.result-heading,
.interpretation,
.calculator-toggle-button {
  text-align: center;
}

.text-black {
  color: #333;
}

.input-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-field {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.update-button,
.calculate-button,
.calculator-toggle-button {
  background-color: #97d393;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  width: 100%;
  font-size: 1.2rem;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.update-button:hover,
.calculate-button:hover,
.calculator-toggle-button:hover {
  background-color: #82c17c;
}

.userBmi_card {
  background-color: #97d393;
  color: black;
  text-align: center;
  font-size: 1.2rem;
}

/* Media Queries */
@media (max-width: 768px) {

  .update-height-weight-container,
  .userBmi_card,
  .calculator-toggle-button {
    padding: 15px;
  }

  .update-button,
  .calculate-button,
  .calculator-toggle-button {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {

  .update-height-weight-container,
  .userBmi_card,
  .calculator-toggle-button {
    padding: 10px;
  }

  .update-button,
  .calculate-button,
  .calculator-toggle-button {
    font-size: 0.9rem;
    padding: 8px;
  }
}

/* filter main container */
.filter_main_container {
  width: 100%;
  background: #f6fdff;
  position: absolute;
  left: 0px;
  top: 0;

  box-shadow: 0 2px 5px rgba(40, 90, 185, .1);
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
}

.dash_filter,
.filter_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter_container {
  padding: 4px;
  position: fixed;
  width: calc(-18rem + 100%);
  background: #fff;
  z-index: 100;
}

.dash_home {
  width: 5%;
  display: flex;
  justify-content: center;
  border-right: 1px solid #eddd;
}

.home_icon {
  font-size: 16px !important;
  width: 20px;
  height: 30px;
  color: #04AA6D;
}

.dash_filter {
  padding: 0 10px 0 0;
}

.day_filter {
  color: #000;
  background-color: #fff;
  border: 1px solid #f7f7f7;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 5px;
  transition: all 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.15) 1.90px 1.90px 2.4px;

}

.day_filter.active {
  color: #fff;
  background-color: #04AA6D;
  border-color: #04AA6D;
}

.year_filter {
  position: relative;
  width: 80px;
}

.year_filter select {
  width: 80px;
  height: 35px;
  padding: 1px 10px;
  border-radius: 5px;
  font-size: 14px;
  background-color: #ffffff;
  color: #333;
  transition: all 0.3s ease;
  overflow-y: auto;
}

.year_filter select:focus {
  border-color: #04AA6D;
  outline: none;
  background-color: #e9f7ff;
}

.year_filter select option {
  padding: 10px;
}

/* dashboard */

.home_dashboard {
  width: 100%;
  border: 1px solid #efefef;
  height: fit-content;
  height: 240px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 5px;
  background: #116AEF;
  padding: 10px 20px;
  position: relative;
  overflow: hidden;

}

/* dash header */
.users_action {
  width: 100%;
  margin: 0 auto;
}

.design_1 {
  position: absolute;
  width: 150px;
  height: 150px;
  background: #5aa6f7;
  border-radius: 50%;
  top: -70px;
  left: -75px;
}

.design_2 {
  position: absolute;
  width: 180px;
  height: 180px;
  background: #5aa6f7;
  border-radius: 50%;
  top: -100px;
  left: 32%;
}

.design_21 {
  position: absolute;
  width: 100%;
  height: 150px;
  max-width: 150px;
  background: #5aa6f7;
  border-radius: 50%;
  bottom: -100px;
  left: 18%;
}

.design_3 {
  position: absolute;
  width: 90px;
  height: 90px;
  background: #5aa6f7;
  border-radius: 50%;
  right: 100px;
  top: 130px;
}

.design_4 {
  position: absolute;
  width: 60px;
  height: 60px;
  background: #5aa6f7;
  border-radius: 50%;
  right: 16%;
  top: -12%;
}

.design_5 {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #5aa6f7;
  border-radius: 50%;
  right: 32%;
  top: 42%;
}

.design_6 {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #5aa6f7;
  border-radius: 50%;
  right: -1%;
  top: 12%;
}

.dash_header {
  height: 40vh;
  display: flex;
  align-items: start;
  flex-direction: column;
  text-align: start;
  color: #fff;
  position: relative;
  z-index: 1;
}

.dash_header .greeting {
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 55px;

}

.dash_header .user_name {
  font-size: 40px;
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 25px;

}

.dash_header .dashboard_message {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}

.users_action {
  display: flex;
  justify-content: space-between;

}

.userAct_container {
  display: flex;
  flex-direction: column;
}

.userAct_container h5 {
  font-size: 14px;
  font-weight: 600;
  color: #060b52;
  text-transform: capitalize;
}

.userAct {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(235, 235, 235);
  background: #fff;
  padding: 8px 12px;
  border-radius: 5px;
  width: 49%;
  box-shadow: rgba(0, 0, 0, 0.15) 1.90px 1.90px 2.6px;
  transition: all .4s ease;
}

.userAct:hover {
  transform: scale(1.02);
}

.useraction_wrapper {
  display: flex;
  justify-content: space-between;
  width: 33.1%;
}

.Appoinment .userAct_inner_circle {
  background: #d8ecf9;

}

.blood_donation .userAct_inner_circle {
  background: #fddada;

}

.mood_monitor .userAct_inner_circle {
  background: #fffbc0;

}

.Document .userAct_inner_circle {
  background: #ddeffb;

}

.blood_sugar .userAct_inner_circle {
  background: #e6d8f9;

}

.bloodpresure .userAct_inner_circle {
  background: #f7d9f0;

}

.users_icon_size .Appoinment {
  color: #003cff;
}

.users_icon_size .blooddonation {
  color: red;
}

.users_icon_size .mood_monitor {
  color: #ff9500;
}

.users_icon_size .Document {
  color: #0584d8;
}

.users_icon_size .blood_sugar {
  color: #5d05d8;
}

.users_icon_size .bloodpresure {
  color: #d805a3;
}

.icon_text {
  display: flex;
  align-items: center;
}

.usernumber {
  display: flex;
  align-items: center;
}

.blood_donation {
  padding: 4px 6px;
}

.userAct_inner_circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}


.usersAct_numbers {
  font-size: 13px;
  margin-left: 8px;
  font-weight: 600;
  color: #2c2c2c;
}

/* all users */

.alluser {
  width: 100%;
  height: fit-content;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.devices {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2px auto;
  border-radius: 8px;
  flex-wrap: wrap;
}

.wrap_container {
  width: 49%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2px auto;
  border-radius: 8px;
}



.total,
.website,
.Android,
.iphone {
  width: 49%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px 18px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.icon {
  font-size: 1.5rem;

}


.iphone .icon {
  color: #007AFF;

}

.Android .icon {
  color: #00cc66;
  /* Green for Android */
}

.website .icon {
  color: #ee713b;
  /* Orange for Website */
}

.total .icon {
  color: #d8a723;
  /* Purple for Total */
}

.icon_circle {
  margin: 4px;
  width: 3rem;
  height: 3rem;
}

.total:hover,
.website:hover,
.Android:hover,
.iphone:hover {
  transform: scale(1.01);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}



.total span,
.website span,
.Android span,
.iphone span {
  margin-top: 8px;
  font-size: 0.9rem;
  color: #333;
  font-weight: bold;
}

.icon_inner_circle {
  width: 50px;
  /* Adjust circle size */
  height: 50px;
  border: 0px solid green;
  /* Outer circle border */
  border-radius: 50%;
  /* Make it a perfect circle */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e9ffed;
  /* Light green background */
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.icon_out_circle {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.iphone .icon_inner_circle {
  background: #ccf2ff;
  /* Light green background */

}

.Android .icon_inner_circle {
  background: #e9ffed;
  /* Light green background */

}

.website .icon_inner_circle {
  background: #ffe0cc;
  /* Light green background */

}

.total .icon_inner_circle {
  background: #fff5cc;
  /* Light green background */

}

.iphone .icon_out_circle {
  border: 1px solid #0066ff;
  /* Light green background */

}

.Android .icon_out_circle {
  border: 1px solid #00cc66;
  /* Light green background */

}

.website .icon_out_circle {
  border: 1px solid #ee713b;
  /* Light green background */

}

.total .icon_out_circle {
  border: 1px solid #d8a723;
  /* Light green background */

}

.usage {
  width: 140px;
  margin-left: 10px;
  padding: 1px 5px;
}

.usage_numbers {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.usage_text {
  font-size: 16px;

}

.iphone .usage .usage_text {
  color: #0056b3;
}

.Android .usage .usage_text {
  color: #00cc66;
}

.website .usage .usage_text {
  color: #ee713b;
}

.total .usage .usage_text {
  color: #d8a723;
}


/* user charts and location */
.user_charts_locations {
  width: 100%;
  margin: 0 auto;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user_charts_container {
  border: 1px solid rgb(237, 237, 237);
  width: 100%;
  max-width: 680px;
  height: auto;
  border-radius: 10px;
  background: #fff;
 
 
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.user_charts {
  width: 100%;
  margin-bottom: 4px;
}

.top_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top_header h3 {
  padding: 2px 10px;
  font-size: 20px;
  font-weight: 600;
}

.user_charts {
  height: 230px;
  border: 1px solid#eee;
}

.changeday_content {
  width: 100%;
  border: 1px solid rgb(231, 231, 231);
  display: flex;
  justify-content: space-between;
  padding: 4px;
}

.changeday_content button {
  width: 75px;
  border-radius: 8px;
  padding: 4px 10px;
  border: 1px solid #ddd;

}

/* location */
.user_location_container {
  border-radius: 10px;
  width: 34.5%;
  height: 320px;
  background: #c0efde;
  padding: 15px;
  border: 1px solid #57e7b2;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.user_location_container h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #04774d;
  text-transform: capitalize;
  font-family: "Ppneuemontreal", sans-serif;
}

.state_con {
  width: 75%;
  border: 1px solid rgb(245, 243, 243);
  padding: 15px 20px;
  background: #fff;
  border-radius: 10px;
  margin: 0 auto;
  max-height: 230px;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

}

.state {
  display: flex;
  justify-content: space-between;
}

.state h4 {
  padding: 4px 10px;
  width: 90%;
  margin: 4px auto;
  text-align: center;
  font-size: 15px;
  display: flex;
  align-items: center;
  background: #e1e9f5;
  color: #150662;
  border-radius: 4px;
}

.state h4 div {
  border: 1px solid #dbd9d9;
  border-radius: 4px;
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  padding: 0 10px;
  background: #fff;
  color: #075c0a;
  text-transform: capitalize;
}

@media screen and (max-width:1600px) {}

@media screen and (max-width:1400px) {
  .filter_container {
    width: calc(-17rem + 100%);
  }
}

@media screen and (max-width:1299px) {
  .filter_container {
    width: calc(-5rem + 100%);
  }

  .devices {
    flex-wrap: wrap;
  }

  .total,
  .website,
  .Android,
  .iphone {
    width: 75%;

  }

  .wrap_container {
    width: 100%;
    row-gap: 1%;
    column-gap: 1%;
  }

  .users_action {
    flex-direction: column;
  }

  .useraction_wrapper {
    width: 100%;
    margin-bottom: 5px;
  }
}

@media screen and (max-width:1150px) {
  .user_charts_locations {
    flex-direction: column;
  }

  .user_charts_container {
    width: 100%;
    margin-bottom: 10px;
  }

  .user_location_container {
    width: 40%;
  }
}

@media screen and (max-width:1025px) {
  .user_location_container {
    width: 52%;
  }
}

@media screen and (max-width:767px) {
  .filter_container {
    width: calc(0rem + 100%);
  }

  .user_location_container {
    width: 58%;
  }
}

@media screen and (max-width:580px) {
  .wrap_container {
    flex-direction: column;
    row-gap: 1%;
    column-gap: 1%;
  }

  .total,
  .website,
  .Android,
  .iphone {
    width: 100%;

  }

  .user_location_container {
    width: 68%;
  }
}

@media screen and (max-width:480px) {
  .user_location_container {
    width: 78%;
  }
}

/* App Updtae CSS */

.app-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  color: #333;
  margin-bottom: 20px;
}

.card-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.app-card {
  background-color: #ffffff;
  border: 1px solid #f0e9e9;
  border-radius: 8px;
  width: 680px;
  padding: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

.card p {
  margin: 8px 0;
  color: #555;
}

.div-btn {
  display: flex;
  justify-content: flex-end;
}

.btn-edit {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-edit:hover {
  background-color: #0056b3;
}

.btn-cancel {
  background-color: #e62253;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-cancel:hover {
  background-color: #e62253;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  min-width: 300px;
}

.notification_container {
  margin-top: -30px;
}

.notification_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.noti_title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.noti_title .icon {
  margin-left: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #288154;
}

.compose-btn {
  background: #059862;
  color: #fff;
  border: none;
  padding: 4px 16px;
  border-radius: 4px;
  transition: all .2s ease-in-out;
}

.compose-btn:hover {
  background: #0ebf7e;

}


.tab-button {
  padding: 7px 20px;
  font-size: 16px;
  border: none;
  background-color: #f0f0f0;
  /* Light grey background */
  color: #333;
  /* Dark text */
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  margin-right: 10px;
}

.tab-button:hover {
  background-color: #ddd;
}

.tab-button.active {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}


.noti-card {
  width: 100%;
  background: #fff;
  padding: 15px 25px;
  margin: 20px 0;
}

.noti-card h3 {
  font-size: 22px;
  color: #007BFF;
  font-weight: 600;
  margin-bottom: 2px;
}

.card-content label {
  font-size: 18px;
  font-weight: 500;
  color: #282828;
}

.card-content input::placeholder {
  color: #414040;

}

.noti-groups {
  display: flex;
  flex-direction: column;
}

.checkbox_lable {
  display: flex;
  align-items: center;
}

.custom-checkbox {
  width: 12px;
  height: 12px;
  transform: scale(1.5);
  cursor: pointer;
  margin-right: 4px;

}

.select-inputs label {
  font-size: 18px;
  color: #282828;
}

.select-inputs {
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 290px;
  flex-wrap: wrap;
}

.row-select-inputs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 1px;
  gap: 10px;


}

.select-inputs select {
  padding: 8px 6px;
  width: 100%;
}


.send-notification-btn {

  margin-top: 10px;
  padding: 7px 12px;
  font-size: 14px;
  border: none;
  background-color: #059862;
  /* Light grey background */
  color: #ffffff;
  /* Dark text */
  cursor: pointer;
  font-weight: bold;

  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  margin-right: 10px;
}


.send-notification-btn:hover {

  background-color: #059862;
  color: white;
  font-weight: bold;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.notification-input {
  width: 100%;
  
  padding: 6px 12px !important;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px !important;
  outline: none;
  transition: all 0.3s ease-in-out;
  margin-bottom: 15px;
}

.notification-input::placeholder {
  color: #999;
}

/* Focus Effect */
.notification-input:focus {
  border-color: #c2f2b8;
  /* Blue border on focus */
  box-shadow: 0px 0px 5px rgba(244, 247, 250, 0.5);
}

.noti-card-content{
  border: 1px solid rgb(228, 226, 226);
  border-radius: 5px;
  padding: 10px 20px;
}


.checkbox-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  border: 1px solid #eee;
  padding: 8px;
  border-radius: 5px;
  background-color: #f9f9f9;
  cursor: pointer;
}

.checkbox-container input {
  margin-right: 6px;
  cursor: pointer;
}

.checkbox-container label {
  font-size: 16px;
  margin-bottom: 0;
  color: #333;
}

.checkbox-container input:disabled + label {
  color: #aaa;
  cursor: not-allowed;
}
