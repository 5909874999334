 // Variable.scss

.nestable-cart {
    overflow: hidden;
}

.dd-handle {
    color: $white;
    background: $primary;
    border-radius: 5px;
	padding: 8px 15px;
	height:auto;
    border: 1px solid $border;
	cursor:all-scroll;
}
.dd-handle:hover {
    color: $white;
    background: $primary;
}

.dd3-content:hover {
    color: $white;
    background: $primary;
}
.dd3-content {
    color: $white;
}
.dd-item>button{
	line-height:28px;
	color:$white;
}
.nestable{
	.dd-list{
		margin-top:0;
	}
}