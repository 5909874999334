.plan-list{
	.list-row {
		border-radius: 1.25rem;
		@include transitionMedium;
		.list-icon {
			height: 76px;
			min-width:76px;
			width: 76px;
			font-weight: 600;
			@include transitionMedium;
			line-height: 20px;
			text-align: center;
			border-radius: $radius;
			@include respond ('phone-land'){
				height:60px;
				width:60px;
				min-width:60px;
				line-height:18px;
				p{
					font-size:18px!important;
				}
				span{
					font-size:12px!important;
				}
			}
		}
		.play-button{
			color:$primary;
			@include transitionMedium;
			background:$light;
			margin-left:auto;
			@include respond ('mid-desktop'){
				margin-left:0;
				margin-right:auto!important;
			}
			@include respond ('tab-land'){
				margin-left:auto;
				margin-right:1rem!important;
			}
			
		}
		.more-dropdown{	
			margin-left:auto;
			@include respond ('tab-land'){
				margin-left:auto;
			}
			@include respond ('phone'){
				margin-left:auto;
			}
		}
		.more-button{
			height:56px;
			width:56px;
			display: block;
			border-radius:$radius;
			line-height:56px;
			@include transitionMedium;
			text-align:center;
			background:$light;
			@include respond ('laptop'){
				height:40px;
				width:40px;
				line-height:40px;
				svg{
					width: 4px;
				}
			}
		}
		.activities{
			svg path,span{
				@include transitionMedium;
			}
		}
		.info {
			h4 a,span{
				@include transitionMedium;
			}
			@include respond ('phone-land'){
				h4{
					font-size:15px!important;
				}
				span{
					font-size:13px!important;
				}
			}
		}
		&:hover{
			background:$primary;
			.info{
				h4 a,span{
					color:$white!important;
				}
			}
			.play-button{
				color:$white;
				background:$warning;
			}
			.more-button{
				background:rgba($white,0.3);
				svg path{
					fill:$white;
				}
			}
			.activities{
				svg path{
					fill:$white;
				}
				span{
					color:$white!important;
				}
			}
		}
	}
}


.event-calender{
	.bootstrap-datetimepicker-widget table th.picker-switch {
		width: 145px;
		font-size: 20px;
		font-weight: 500;
		color: $black;
	}
	.bootstrap-datetimepicker-widget table th {
		height: 20px;
		line-height: 20px;
		width: 20px;
		font-size: 1rem;
		font-weight:500;
		color:$black;
	}
	.bootstrap-datetimepicker-widget table td.day {
		height: 46px;
		line-height: 30px;
		width: 46px;
		color: $black;
		font-size:15px;
	}
	.bootstrap-datetimepicker-widget .picker-switch td i {
		line-height: 2.5;
		height: 2.5em;
		width: 100%;
		display: none;
	}
	.datepicker table tr td.today{
		background:#EEEEEE!important;
		box-shadow:none;
	}
	
	.datepicker table tr td.active.active{
		background:#EEEEEE!important;
		box-shadow:none;
		color:$primary!important;
	}
	.bootstrap-datetimepicker-widget table td.day:hover {
		box-shadow: none;
		border: 0;
		background: $warning!important;
		color:$white!important;
	}
	.bootstrap-datetimepicker-widget table td.today:before {
		content: '';
		display: inline-block;
		position: absolute;
		top: -5px;
		border:3px solid $white;
		right: -5px;
		height: 17px;
		width: 17px;
		background: $primary;
		border-radius: $radius;
	}
	.bootstrap-datetimepicker-widget table td, .bootstrap-datetimepicker-widget table th{
		border-radius:$radius;
	}
	
	.bootstrap-datetimepicker-widget table thead tr:first-child th {
		cursor: pointer;
		padding: 30px 10px;
		font-size:1rem;
		&:hover{
			background-color:transparent;
		}
	}
	.bootstrap-datetimepicker-widget table td span.active{
		color:$primary;
	}
	.bootstrap-datetimepicker-widget .datepicker-decades .decade {
		width: 50%;
		float: left;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.date-icon{
	height:60px;
	width:60px;
	font-size:24px;
	line-height:60px;
	text-align:center;
	color:$primary;
	background:rgba($primary,0.2);
	border-radius:$radius;
}