/*
    Table of Contents

    Name                 : Gymove
    Author               : DexignZone
    Author Portfolio     : https://themeforest.net/user/dexignzone/portfolio
    


    Table of Sass Contents

    1. Abstracts
        _bs-custom
        _inheritance
        _maps
        _mixin
        _variable

    2. bootstrap 
        -Here goes all official bootstrap scss files

    3. Global/Base
        _color
        _custom-grid
        _fonts
        _helper
        _reset

    4. layout
        +footer
        +header
        +rtl
        +sidebar
        +tables
        +theme
        +version-dark

    5. Conponents
        +app
        +charts
        +forms
        +map
        +uc
        +ui
        +widget

    6. Page
        _index-1
        _page-error
        _page-register
        _page-signin
        _page-user-lock
        _page-user-lock

*/
@import url("./../icons/fontawesome/css/all.min.css");
@import url("./../icons/material-design-iconic-font/css/materialdesignicons.min.css");
@import url("./../icons/themify-icons/css/themify-icons.css");
@import url("./../icons/line-awesome/css/line-awesome.min.css");
@import url("./../icons/flaticon/flaticon.css");
@import url(./../vendor/animate/animate.min.css);
@import url(./../vendor/metismenu/css/metisMenu.min.css);
/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@tailwind base;
@tailwind components;
@tailwind utilities;

.gradient_one {
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
}

.gradient-1 {
  background: #f0a907;
  background: -moz-linear-gradient(top, #f0a907 0%, #f53c79 100%);
  background: -webkit-linear-gradient(top, #f0a907 0%, #f53c79 100%);
  background: linear-gradient(to bottom, #f0a907 0%, #f53c79 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0a907', endColorstr='#f53c79', GradientType=0);
}

.gradient-2 {
  background: #4dedf5;
  background: -moz-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
  background: -webkit-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
  background: linear-gradient(to bottom, #4dedf5 0%, #480ceb 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4dedf5', endColorstr='#480ceb', GradientType=0);
}

.gradient-3 {
  background: #51f5ae;
  background: -moz-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
  background: -webkit-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
  background: linear-gradient(to bottom, #51f5ae 0%, #3fbcda 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#51f5ae', endColorstr='#3fbcda', GradientType=0);
}

.gradient-4 {
  background: #f25521;
  background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: linear-gradient(to right, #f25521 0%, #f9c70a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f25521', endColorstr='#f9c70a', GradientType=1);
}

.gradient-5 {
  background: #f53c79;
  background: -moz-linear-gradient(left, #f53c79 0%, #f0a907 100%);
  background: -webkit-linear-gradient(left, #f53c79 0%, #f0a907 100%);
  background: linear-gradient(to right, #f53c79 0%, #f0a907 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f53c79', endColorstr='#f0a907', GradientType=1);
}

.gradient-6 {
  background: #36b9d8;
  background: -moz-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
  background: -webkit-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
  background: linear-gradient(to right, #36b9d8 0%, #4bffa2 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#36b9d8', endColorstr='#4bffa2', GradientType=1);
}

.gradient-7 {
  background: #4400eb;
  background: -moz-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
  background: -webkit-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
  background: linear-gradient(to right, #4400eb 0%, #44e7f5 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4400eb', endColorstr='#44e7f5', GradientType=1);
}

.gradient-8 {
  background: #F7B00F;
  background: -moz-linear-gradient(top, #F7B00F 0%, #F25521 100%);
  background: -webkit-linear-gradient(top, #F7B00F 0%, #F25521 100%);
  background: linear-gradient(to bottom, #F7B00F 0%, #F25521 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F7B00F', endColorstr='#F25521', GradientType=1);
}

.gradient-9,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background: #f31e7a !important;
  background: -moz-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
  background: -webkit-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
  background: linear-gradient(to right, #f31e7a 0%, #fd712c 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f31e7a', endColorstr='#fd712c', GradientType=1);
}

.gradient-10 {
  background: #f25521 !important;
  background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: linear-gradient(to top, #f25521 0%, #f9c70a 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f25521', endColorstr='#f9c70a', GradientType=1);
}

.gradient-11 {
  background: #3398fb;
  background: -moz-linear-gradient(left, #3398fb 0%, #8553ee 100%);
  background: -webkit-linear-gradient(left, #3398fb 0%, #8553ee 100%);
  background: linear-gradient(to right, #3398fb 0%, #8553ee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3398fb', endColorstr='#8553ee', GradientType=1);
}

.gradient-12 {
  background: #36e1b4;
  background: -moz-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
  background: -webkit-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
  background: linear-gradient(to right, #36e1b4 0%, #11cae7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#36e1b4', endColorstr='#11cae7', GradientType=1);
}

.gradient-13 {
  background: #ffbf31;
  background: -moz-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
  background: -webkit-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
  background: linear-gradient(to right, #ffbf31 0%, #ff890e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffbf31', endColorstr='#ff890e', GradientType=1);
}

.gradient-14 {
  background: #23bdb8;
  background: -moz-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: -webkit-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#23bdb8', endColorstr='#43e794', GradientType=1);
}

.gradient-15 {
  background: #9a56ff;
  background: -moz-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: -webkit-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: linear-gradient(135deg, #9a56ff 0%, #e36cd9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9a56ff', endColorstr='#e36cd9', GradientType=1);
}

.gradient-16 {
  background: #f48665;
  background: -moz-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: -webkit-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: linear-gradient(135deg, #f48665 0%, #fda23f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f48665', endColorstr='#fda23f', GradientType=1);
}

.gradient-17 {
  background: #e36cd9;
  background: -moz-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
  background: -webkit-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
  background: linear-gradient(135deg, #e36cd9 0%, #fe60ae 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e36cd9', endColorstr='#fe60ae', GradientType=1);
}

.gradient-18 {
  background: #a15cff;
  background: -moz-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
  background: -webkit-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
  background: linear-gradient(to right, #a15cff 0%, #ce82fd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a15cff', endColorstr='#ce82fd', GradientType=1);
}



.tab-container {
  margin-bottom: 15px;
  display: flex;
}

.tab {
  margin-bottom: 5px;
  min-width: 40%;
  cursor: pointer;
  background-color: #f0f0f0;
  border: 0px solid #ccc;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}

.tab.active {
  background-color: #8ef37c;
}

.meal-data {
  display: none;
}

.meal-data.active {
  display: block;
}


.avatar-circle {
  width: 165px;
  height: 165px;
  background-color: #000000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-circleheader {
  width: 42px;
  height: 43px;
  background-color: #000000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-letter {
  font-size: 80px;
  font-weight: bold;
  color: #ffffff;
}

.avatar-letter1 {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}


/* profile */
.profile-card {
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 5px rgb(144 144 144 / 56%);
  padding: 25px;
  border-radius: 11px;
  margin-left: 2.6%;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.profile-content {
  display: flex;
  flex-direction: column;
  gap: 14px;
  /* Adjust the gap between items */
}

.profile-item {
  display: flex;
  align-items: center;
}

.profile-item i {
  margin-right: 20px;
  font-size: 20px;
}

.profile-label {
  font-weight: bold;
  min-width: 130px;
  /* Adjust as needed */
}

.profile-value {
  flex-grow: 1;
}



.profilepop {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #d1dae3;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.headerselect {
  color: #014c09 !important;
  text-align: center;
}

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
/*!
   * Bootstrap  v5.3.1 (https://getbootstrap.com/)
   * Copyright 2011-2023 The Bootstrap Authors
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
:root,
[data-bs-theme="light"] {
  --bs-blue: #5e72e4;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #e83e8c;
  --bs-red: #EE3232;
  --bs-orange: #ff9900;
  --bs-yellow: #FFFA6F;
  --bs-green: #297F00;
  --bs-teal: #20c997;
  --bs-cyan: #3065D0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #99c990;
  --bs-secondary: #A02CFA;
  --bs-success: #2BC155;
  --bs-info: #1EA7C5;
  --bs-warning: #FFBC11;
  --bs-danger: #F94687;
  --bs-light: #F4F5F9;
  --bs-dark: #B1B1B1;
  --bs-primary-rgb: 30, 63, 180;
  --bs-secondary-rgb: 160, 44, 250;
  --bs-success-rgb: 43, 193, 85;
  --bs-info-rgb: 30, 167, 197;
  --bs-warning-rgb: 255, 188, 17;
  --bs-danger-rgb: 249, 70, 135;
  --bs-light-rgb: 244, 245, 249;
  --bs-dark-rgb: 177, 177, 177;
  --bs-primary-text-emphasis: #0c1948;
  --bs-secondary-text-emphasis: #401264;
  --bs-success-text-emphasis: #114d22;
  --bs-info-text-emphasis: #0c434f;
  --bs-warning-text-emphasis: #664b07;
  --bs-danger-text-emphasis: #641c36;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #d2d9f0;
  --bs-secondary-bg-subtle: #ecd5fe;
  --bs-success-bg-subtle: #d5f3dd;
  --bs-info-bg-subtle: #d2edf3;
  --bs-warning-bg-subtle: #fff2cf;
  --bs-danger-bg-subtle: #fedae7;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #a5b2e1;
  --bs-secondary-border-subtle: #d9abfd;
  --bs-success-border-subtle: #aae6bb;
  --bs-info-border-subtle: #a5dce8;
  --bs-warning-border-subtle: #ffe4a0;
  --bs-danger-border-subtle: #fdb5cf;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: "Helvetica", sans-serif;
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #7e7e7e;
  --bs-body-color-rgb: 126, 126, 126;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(126, 126, 126, 0.75);
  --bs-secondary-color-rgb: 126, 126, 126;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(126, 126, 126, 0.5);
  --bs-tertiary-color-rgb: 126, 126, 126;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: #3d4465;
  --bs-link-color: #99c990;
  --bs-link-color-rgb: 30, 63, 180;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #183290;
  --bs-link-hover-color-rgb: 24, 50, 144;
  --bs-code-color: #e83e8c;
  --bs-highlight-bg: #fffee2;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #EEEEEE;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.75rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(30, 63, 180, 0.25);
  --bs-form-valid-color: #2BC155;
  --bs-form-valid-border-color: #2BC155;
  --bs-form-invalid-color: #F94687;
  --bs-form-invalid-border-color: #F94687;
}

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #788cd2;
  --bs-secondary-text-emphasis: #c680fc;
  --bs-success-text-emphasis: #80da99;
  --bs-info-text-emphasis: #78cadc;
  --bs-warning-text-emphasis: #ffd770;
  --bs-danger-text-emphasis: #fb90b7;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #060d24;
  --bs-secondary-bg-subtle: #200932;
  --bs-success-bg-subtle: #092711;
  --bs-info-bg-subtle: #062127;
  --bs-warning-bg-subtle: #332603;
  --bs-danger-bg-subtle: #320e1b;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #12266c;
  --bs-secondary-border-subtle: #601a96;
  --bs-success-border-subtle: #1a7433;
  --bs-info-border-subtle: #126476;
  --bs-warning-border-subtle: #99710a;
  --bs-danger-border-subtle: #952a51;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #788cd2;
  --bs-link-hover-color: #93a3db;
  --bs-link-color-rgb: 120, 140, 210;
  --bs-link-hover-color-rgb: 147, 163, 219;
  --bs-code-color: #f18bba;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #7fb266;
  --bs-form-valid-border-color: #7fb266;
  --bs-form-invalid-color: #f58484;
  --bs-form-invalid-border-color: #f58484;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1,
.h1 {
  font-size: calc(1.35rem + 1.2vw);
}

@media (min-width: 1200px) {

  h1,
  .h1 {
    font-size: 2.25rem;
  }
}

h2,
.h2 {
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {

  h2,
  .h2 {
    font-size: 1.875rem;
  }
}

h3,
.h3 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {

  h3,
  .h3 {
    font-size: 1.5rem;
  }
}

h4,
.h4 {
  font-size: 1.125rem;
}

h5,
.h5 {
  font-size: 1rem;
}

h6,
.h6 {
  font-size: 0.938rem;
}

p {
  margin-top: 0;
  /* margin-bottom: 1rem;  */
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 15px;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}


/* //First Aid */
.image-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-data {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  width: 80%;
  max-width: 600px;
}

.title {
  font-size: 24px;
  font-weight: 600;
}

.image {
  width: 50%;
  border-radius: 8px;
}

.description {
  font-size: 30px;
  font-weight: 500;
  margin-top: 10px;
}




textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend+* {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote> :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1440) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1440;
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto>* {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1>* {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2>* {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3>* {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4>* {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5>* {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6>* {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1440) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-xxl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}

.table> :not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  /* color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color))); */
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

.table>tbody {
  vertical-align: inherit;
}

.table>thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm> :not(caption)>*>* {
  padding: 0.25rem 0.25rem;
}

.table-bordered> :not(caption)>* {
  border-width: var(--bs-border-width) 0;
}

.table-bordered> :not(caption)>*>* {
  border-width: 0 var(--bs-border-width);
}

.table-borderless> :not(caption)>*>* {
  border-bottom-width: 0;
}

.table-borderless> :not(:first-child) {
  border-top-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns> :not(caption)>tr> :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover>tbody>tr:hover>* {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}


/* Table Design start */
.table {
  width: 100%;
  border-collapse: collapse;
  border: 2px solid #0b0c0c;
  margin-bottom: 20px;
  border-radius: 10px;
  /* Adjust the value for roundness */
  overflow: hidden;
}

.table th,
.table td {
  border: 1px solid #0d0e0e;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #99c990;
}

/* Table Design End */


.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #d2d9f0;
  --bs-table-border-color: #bdc3d8;
  --bs-table-striped-bg: #c8cee4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bdc3d8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c2c9de;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #ecd5fe;
  --bs-table-border-color: #d4c0e5;
  --bs-table-striped-bg: #e0caf1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d4c0e5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #dac5eb;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d5f3dd;
  --bs-table-border-color: #c0dbc7;
  --bs-table-striped-bg: #cae7d2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c0dbc7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c5e1cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #d2edf3;
  --bs-table-border-color: #bdd5db;
  --bs-table-striped-bg: #c8e1e7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bdd5db;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c2dbe1;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff2cf;
  --bs-table-border-color: #e6daba;
  --bs-table-striped-bg: #f2e6c5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6daba;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece0bf;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #fedae7;
  --bs-table-border-color: #e5c4d0;
  --bs-table-striped-bg: #f1cfdb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e5c4d0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ebcad6;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #F4F5F9;
  --bs-table-border-color: #dcdde0;
  --bs-table-striped-bg: #e8e9ed;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dcdde0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e2e3e6;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #000;
  --bs-table-bg: #B1B1B1;
  --bs-table-border-color: #9f9f9f;
  --bs-table-striped-bg: #a8a8a8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #9f9f9f;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #a4a4a4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1439.98) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.09375rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.76563rem;
}

@media (min-width: 1200px) {
  .col-form-label-sm {
    font-size: 0.76562rem;
  }
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid #E6E6E6;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #8f9fda;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(30, 63, 180, 0.25);
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}

.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: var(--bs-border-radius-sm);
}

@media (min-width: 1200px) {
  .form-control-sm {
    font-size: 0.76562rem;
  }
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: var(--bs-border-radius-lg);
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}

.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}

.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid #141414;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #8f9fda;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(30, 63, 180, 0.25);
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.76563rem;
  border-radius: var(--bs-border-radius-sm);
}

@media (min-width: 1200px) {
  .form-select-sm {
    font-size: 0.76562rem;
  }
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5em !important;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #8f9fda;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(30, 63, 180, 0.25);
}

.form-check-input:checked {
  background-color: #99c990;
  border-color: #99c990;
}

.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #99c990;
  border-color: #99c990;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238f9fda'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}

.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled]+.btn,
.btn-check:disabled+.btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(30, 63, 180, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(30, 63, 180, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #99c990;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #bcc5e9;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #99c990;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #bcc5e9;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}

.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating>label {
    transition: none;
  }
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext {
  padding: 1rem 0.75rem;
}

.form-floating>.form-control::placeholder,
.form-floating>.form-control-plaintext::placeholder {
  color: transparent;
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown),
.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:-webkit-autofill,
.form-floating>.form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-control-plaintext~label,
.form-floating>.form-select~label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-select~label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}

.form-floating>.form-control:-webkit-autofill~label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control-plaintext~label {
  border-width: var(--bs-border-width) 0;
}

.form-floating> :disabled~label,
.form-floating>.form-control:disabled~label {
  color: #6c757d;
}

.form-floating> :disabled~label::after,
.form-floating>.form-control:disabled~label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control,
.input-group>.form-select,
.input-group>.form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group>.form-control:focus,
.input-group>.form-select:focus,
.input-group>.form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid #080808;
  border-radius: var(--bs-border-radius);
}

.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text,
.input-group-lg>.btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text,
.input-group-sm>.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: var(--bs-border-radius-sm);
}

@media (min-width: 1200px) {

  .input-group-sm>.form-control,
  .input-group-sm>.form-select,
  .input-group-sm>.input-group-text,
  .input-group-sm>.btn {
    font-size: 0.76562rem;
  }
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation>.dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation>.form-floating:nth-last-child(n + 3)>.form-control,
.input-group.has-validation>.form-floating:nth-last-child(n + 3)>.form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group>.form-floating:not(:first-child)>.form-control,
.input-group>.form-floating:not(:first-child)>.form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

@media (min-width: 1200px) {
  .valid-tooltip {
    font-size: 0.76562rem;
  }
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232BC155' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232BC155' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid,
.form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input~.valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group>.form-control:not(:focus):valid,
.input-group>.form-control:not(:focus).is-valid,
.was-validated .input-group>.form-select:not(:focus):valid,
.input-group>.form-select:not(:focus).is-valid,
.was-validated .input-group>.form-floating:not(:focus-within):valid,
.input-group>.form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

@media (min-width: 1200px) {
  .invalid-tooltip {
    font-size: 0.76562rem;
  }
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F94687'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F94687' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F94687'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F94687' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid,
.form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input~.invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group>.form-control:not(:focus):invalid,
.input-group>.form-control:not(:focus).is-invalid,
.was-validated .input-group>.form-select:not(:focus):invalid,
.input-group>.form-select:not(:focus).is-invalid,
.was-validated .input-group>.form-floating:not(:focus-within):invalid,
.input-group>.form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check+.btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:focus-visible+.btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked+.btn:focus-visible,
:not(.btn-check)+.btn:active:focus-visible,
.btn:first-child:active:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #141414;
  --bs-btn-bg: #99c990;
  --bs-btn-border-color: #99c990;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #99c990;
  --bs-btn-hover-border-color: #99c990;
  --bs-btn-focus-shadow-rgb: 64, 92, 191;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #99c990;
  --bs-btn-active-border-color: #99c990;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #99c990;
  --bs-btn-disabled-border-color: #99c990;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #A02CFA;
  --bs-btn-border-color: #A02CFA;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #8825d5;
  --bs-btn-hover-border-color: #8023c8;
  --bs-btn-focus-shadow-rgb: 174, 76, 251;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #8023c8;
  --bs-btn-active-border-color: #7821bc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #A02CFA;
  --bs-btn-disabled-border-color: #A02CFA;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #2BC155;
  --bs-btn-border-color: #2BC155;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #4bca6f;
  --bs-btn-hover-border-color: #40c766;
  --bs-btn-focus-shadow-rgb: 37, 164, 72;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #55cd77;
  --bs-btn-active-border-color: #40c766;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #2BC155;
  --bs-btn-disabled-border-color: #2BC155;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #1EA7C5;
  --bs-btn-border-color: #1EA7C5;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #40b4ce;
  --bs-btn-hover-border-color: #35b0cb;
  --bs-btn-focus-shadow-rgb: 26, 142, 167;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #4bb9d1;
  --bs-btn-active-border-color: #35b0cb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #1EA7C5;
  --bs-btn-disabled-border-color: #1EA7C5;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #FFBC11;
  --bs-btn-border-color: #FFBC11;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc635;
  --bs-btn-hover-border-color: #ffc329;
  --bs-btn-focus-shadow-rgb: 217, 160, 14;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc941;
  --bs-btn-active-border-color: #ffc329;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FFBC11;
  --bs-btn-disabled-border-color: #FFBC11;
}

.btn-danger {
  --bs-btn-color: #000;
  --bs-btn-bg: #F94687;
  --bs-btn-border-color: #F94687;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fa6299;
  --bs-btn-hover-border-color: #fa5993;
  --bs-btn-focus-shadow-rgb: 212, 60, 115;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fa6b9f;
  --bs-btn-active-border-color: #fa5993;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #F94687;
  --bs-btn-disabled-border-color: #F94687;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #F4F5F9;
  --bs-btn-border-color: #F4F5F9;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #cfd0d4;
  --bs-btn-hover-border-color: #c3c4c7;
  --bs-btn-focus-shadow-rgb: 207, 208, 212;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c3c4c7;
  --bs-btn-active-border-color: #b7b8bb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #F4F5F9;
  --bs-btn-disabled-border-color: #F4F5F9;
}

.btn-dark {
  --bs-btn-color: #000;
  --bs-btn-bg: #B1B1B1;
  --bs-btn-border-color: #B1B1B1;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #bdbdbd;
  --bs-btn-hover-border-color: #b9b9b9;
  --bs-btn-focus-shadow-rgb: 150, 150, 150;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c1c1c1;
  --bs-btn-active-border-color: #b9b9b9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #B1B1B1;
  --bs-btn-disabled-border-color: #B1B1B1;
}

.btn-outline-primary {
  --bs-btn-color: #99c990;
  --bs-btn-border-color: #99c990;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #99c990;
  --bs-btn-hover-border-color: #99c990;
  --bs-btn-focus-shadow-rgb: 30, 63, 180;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #99c990;
  --bs-btn-active-border-color: #99c990;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #99c990;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #99c990;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #A02CFA;
  --bs-btn-border-color: #A02CFA;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #A02CFA;
  --bs-btn-hover-border-color: #A02CFA;
  --bs-btn-focus-shadow-rgb: 160, 44, 250;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #A02CFA;
  --bs-btn-active-border-color: #A02CFA;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #A02CFA;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #A02CFA;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #2BC155;
  --bs-btn-border-color: #2BC155;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #2BC155;
  --bs-btn-hover-border-color: #2BC155;
  --bs-btn-focus-shadow-rgb: 43, 193, 85;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #2BC155;
  --bs-btn-active-border-color: #2BC155;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2BC155;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2BC155;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #1EA7C5;
  --bs-btn-border-color: #1EA7C5;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #1EA7C5;
  --bs-btn-hover-border-color: #1EA7C5;
  --bs-btn-focus-shadow-rgb: 30, 167, 197;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #1EA7C5;
  --bs-btn-active-border-color: #1EA7C5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1EA7C5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1EA7C5;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #FFBC11;
  --bs-btn-border-color: #FFBC11;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FFBC11;
  --bs-btn-hover-border-color: #FFBC11;
  --bs-btn-focus-shadow-rgb: 255, 188, 17;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FFBC11;
  --bs-btn-active-border-color: #FFBC11;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFBC11;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFBC11;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #F94687;
  --bs-btn-border-color: #F94687;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #F94687;
  --bs-btn-hover-border-color: #F94687;
  --bs-btn-focus-shadow-rgb: 249, 70, 135;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #F94687;
  --bs-btn-active-border-color: #F94687;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F94687;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F94687;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #F4F5F9;
  --bs-btn-border-color: #F4F5F9;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #F4F5F9;
  --bs-btn-hover-border-color: #F4F5F9;
  --bs-btn-focus-shadow-rgb: 244, 245, 249;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #F4F5F9;
  --bs-btn-active-border-color: #F4F5F9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F4F5F9;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F4F5F9;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #B1B1B1;
  --bs-btn-border-color: #B1B1B1;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #B1B1B1;
  --bs-btn-hover-border-color: #B1B1B1;
  --bs-btn-focus-shadow-rgb: 177, 177, 177;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #B1B1B1;
  --bs-btn-active-border-color: #B1B1B1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #B1B1B1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #B1B1B1;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 64, 92, 191;
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg,
.btn-group-lg>.btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.09375rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm,
.btn-group-sm>.btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.76563rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

@media (min-width: 1200px) {

  .btn-sm,
  .btn-group-sm>.btn {
    --bs-btn-font-size: 0.76562rem;
  }
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.875rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #99c990;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1440) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.76563rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

@media (min-width: 1200px) {
  .dropdown-header {
    font-size: 0.76562rem;
  }
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #99c990;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn:hover,
.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn:hover,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}

.btn-group> :not(.btn-check:first-child)+.btn,
.btn-group>.btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn.dropdown-toggle-split:first-child,
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:nth-child(n + 3),
.btn-group> :not(.btn-check)+.btn,
.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn~.btn,
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover,
.nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(30, 63, 180, 0.25);
}

.nav-link.disabled,
.nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #99c990;
}

.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}

.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}

.nav-underline .nav-link:hover,
.nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}

.nav-underline .nav-link.active,
.nav-underline .show>.nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified>.nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.33594rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.09375rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.09375rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28126, 126, 126, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-sm,
.navbar>.container-md,
.navbar>.container-lg,
.navbar>.container-xl,
.navbar>.container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}

.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1440) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
  inset: 37px -69px auto auto !important
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme="dark"] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme="dark"] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link+.card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group>.card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237e7e7e'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c1948'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #8f9fda;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(30, 63, 180, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 0.875rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme="dark"] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23788cd2'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23788cd2'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/")
    /* rtl: var(--bs-breadcrumb-divider, "/") */
  ;
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(30, 63, 180, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #99c990;
  --bs-pagination-active-border-color: #99c990;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}

.page-link.active,
.active>.page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled,
.disabled>.page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.09375rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.76563rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

@media (min-width: 1200px) {
  .pagination-sm {
    --bs-pagination-font-size: 0.76562rem;
  }
}

.badge {
  --bs-background-color: #99c990;
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.65625rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #99c990;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked>.progress {
  overflow: visible;
}

.progress-stacked>.progress>.progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #99c990;
  --bs-list-group-active-border-color: #99c990;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered>.list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item+.list-group-item {
  border-top-width: 0;
}

.list-group-item+.list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal>.list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1440) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush>.list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(30, 63, 180, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.75rem;
  opacity: var(--bs-btn-close-opacity);
}

.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}

.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}

.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 30px;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container> :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}

.toast-header .btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.modal-footer>* {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1439.98) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.76563rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}

@media (min-width: 1200px) {
  .tooltip {
    --bs-tooltip-font-size: 0.76562rem;
  }
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.76563rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 0.875rem;
  --bs-popover-header-color: #3d4465;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}

@media (min-width: 1200px) {
  .popover {
    --bs-popover-font-size: 0.76562rem;
  }
}

.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top>.popover-arrow,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}

.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end>.popover-arrow,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before,
.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}

.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom>.popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}

.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start>.popover-arrow,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before,
.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}

.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
[data-bs-theme="dark"] .carousel .carousel-control-next-icon,
[data-bs-theme="dark"].carousel .carousel-control-prev-icon,
[data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
[data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-caption,
[data-bs-theme="dark"].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg)
      /* rtl:ignore */
    ;
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {

  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas-sm,
.offcanvas-md,
.offcanvas-lg,
.offcanvas-xl,
.offcanvas-xxl,
.offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-sm.showing,
  .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing,
  .offcanvas-sm.hiding,
  .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-md.showing,
  .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing,
  .offcanvas-md.hiding,
  .offcanvas-md.show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-lg.showing,
  .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing,
  .offcanvas-lg.hiding,
  .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-xl.showing,
  .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing,
  .offcanvas-xl.hiding,
  .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1439.98) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 1439.98) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1439.98) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing,
  .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing,
  .offcanvas-xxl.hiding,
  .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (min-width: 1440) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}

.offcanvas.showing,
.offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing,
.offcanvas.hiding,
.offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}

.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #000 !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary:hover,
.link-primary:focus {
  color: RGBA(24, 50, 144, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(24, 50, 144, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary:hover,
.link-secondary:focus {
  color: RGBA(128, 35, 200, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(128, 35, 200, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-success:hover,
.link-success:focus {
  color: RGBA(85, 205, 119, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(85, 205, 119, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-info:hover,
.link-info:focus {
  color: RGBA(75, 185, 209, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(75, 185, 209, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning:hover,
.link-warning:focus {
  color: RGBA(255, 201, 65, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 201, 65, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger:hover,
.link-danger:focus {
  color: RGBA(250, 107, 159, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(250, 107, 159, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-light:hover,
.link-light:focus {
  color: RGBA(246, 247, 250, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(246, 247, 250, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark:hover,
.link-dark:focus {
  color: RGBA(193, 193, 193, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(193, 193, 193, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis:hover,
.link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}

.icon-link>.bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}

@media (prefers-reduced-motion: reduce) {
  .icon-link>.bi {
    transition: none;
  }
}

.icon-link-hover:hover>.bi,
.icon-link-hover:focus-visible>.bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1440) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  width: 100%;
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-4 {
  font-size: 1.125rem !important;
}

.fs-5 {
  font-size: 1rem !important;
}

.fs-6 {
  font-size: 0.938rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: #99c990;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }

  .column-gap-sm-0 {
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }

  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 1rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-5 {
    row-gap: 3rem !important;
  }

  .column-gap-md-0 {
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-md-3 {
    column-gap: 1rem !important;
  }

  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-md-5 {
    column-gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }

  .column-gap-lg-0 {
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }

  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1440) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important;
  }

  .fs-2 {
    font-size: 1.875rem !important;
  }

  .fs-3 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

.c-pointer {
  cursor: pointer;
}

* {
  outline: none;
  padding: 0;
}

*::after {
  margin: 0;
  padding: 0;
}

*::before {
  margin: 0;
  padding: 0;
}

::selection {
  color: #fff;
  background: #99c990;
}

body {
  overflow-x: hidden;
  height: 100%;
  position: relative;
  max-width: 100%;
  font-size: 1rem;
  background-color: #F9F9F9;
}

@media only screen and (max-width: 991px) {
  body {
    font-size: 0.875rem;
  }
}

@media only screen and (max-width: 1400px) {

  h2,
  .h2 {
    font-size: 1.5rem;
  }
}

p {
  line-height: 1.8;
}

.box-shadow-none {
  box-shadow: none !important;
}

#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative;
}

#main-wrapper.show {
  opacity: 1;
}

.rounded-lg {
  border-radius: 0.625rem;
}

.rounded-xl {
  border-radius: 12px !important;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  color: #7e7e7e;
}

a:hover,
a:focus,
a.active {
  text-decoration: none;
}

.btn-link:hover,
.btn-link:focus,
.btn-link.active {
  text-decoration: none;
}

.w-space-no {
  white-space: nowrap;
}

.content-body .container {
  margin-top: 40px;
}

.content-body .container-fluid,
.content-body .container-sm,
.content-body .container-md,
.content-body .container-lg,
.content-body .container-xl,
.content-body .container-xxl {
  padding-top: 40px;
  padding-right: 40px;
  padding-left: 40px;
  position: relative;
}

@media only screen and (max-width: 1600px) {

  .content-body .container-fluid,
  .content-body .container-sm,
  .content-body .container-md,
  .content-body .container-lg,
  .content-body .container-xl,
  .content-body .container-xxl {
    padding-top: 60px;
    padding-right: 20px;
    padding-left: 30px;
  }
}

@media only screen and (max-width: 1024px) {

  .content-body .container-fluid,
  .content-body .container-sm,
  .content-body .container-md,
  .content-body .container-lg,
  .content-body .container-xl,
  .content-body .container-xxl {
    padding-top: 55px;
    padding-right: 10px;
    padding-left: 10px;
  }
}
@media only screen and (max-width: 767px) {

  .content-body .container-fluid,
  .content-body .container-sm,
  .content-body .container-md,
  .content-body .container-lg,
  .content-body .container-xl,
  .content-body .container-xxl {
    padding-top: 55px;
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media only screen and (max-width: 575px) {

  .content-body .container-fluid,
  .content-body .container-sm,
  .content-body .container-md,
  .content-body .container-lg,
  .content-body .container-xl,
  .content-body .container-xxl {
    padding-top: 55px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

/* sp */
.row.sp80,
.sp80 {
  margin-left: -40px;
  margin-right: -40px;
}

.row.sp80 [class*="col-"],
.sp80 [class*="col-"] {
  padding-left: 40px;
  padding-right: 40px;
}

.row.sp60,
.sp60 {
  margin-left: -30px;
  margin-right: -30px;
}

.row.sp60 [class*="col-"],
.sp60 [class*="col-"] {
  padding-left: 30px;
  padding-right: 30px;
}

.row.sp40,
.sp40 {
  margin-left: -20px;
  margin-right: -20px;
}

.row.sp40 [class*="col-"],
.sp40 [class*="col-"] {
  padding-left: 20px;
  padding-right: 20px;
}

.row.sp20,
.sp20 {
  margin-left: -10px;
  margin-right: -10px;
}

.row.sp20 [class*="col-"],
.sp20 [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.row.sp16,
.sp16 {
  margin-left: -8px;
  margin-right: -8px;
}

.row.sp16 [class*="col-"],
.sp16 [class*="col-"] {
  padding-left: 8px;
  padding-right: 8px;
}

.row.sp10,
.sp10 {
  margin-left: -5px;
  margin-right: -5px;
}

.row.sp10 [class*="col-"],
.sp10 [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.row.sp4,
.sp4 {
  margin-left: -2px;
  margin-right: -2px;
}

.row.sp4 [class*="col-"],
.sp4 [class*="col-"] {
  padding-left: 2px;
  padding-right: 2px;
}

.row.spno,
.spno {
  margin-left: 0;
  margin-right: 0;
}

.row.spno [class*="col-"],
.spno [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.content-heading {
  font-size: 16px;
  margin-bottom: 1.875rem;
  margin-top: 3.125rem;
  border-bottom: 1px solid #E6E6E6;
  padding-bottom: 10px;
}

[direction="rtl"] .content-heading {
  text-align: right;
}

.text-ov {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.op1 {
  opacity: 0.1;
}

.op2 {
  opacity: 0.2;
}

.op3 {
  opacity: 0.3;
}

.op4 {
  opacity: 0.4;
}

.op5 {
  opacity: 0.5;
}

.op6 {
  opacity: 0.6;
}

.op7 {
  opacity: 0.7;
}

.op8 {
  opacity: 0.8;
}

.op9 {
  opacity: 0.9;
}

.wspace-no {
  white-space: nowrap;
}

.support-ticket {
  position: fixed;
  bottom: 30px;
  right: 15px;
  z-index: 999999;
}

.support-ticket-btn {
  width: 100px;
  background: #7CB442;
  animation: 0.7s ease-in-out 0s infinite alternate none running crescendo;
  border-radius: 50px;
  color: #fff;
  font-size: 8px;
  font-size: 16px;
  padding: 5px 10px 7px;
  text-align: center;
  display: inline-block;
  box-shadow: rgba(124, 180, 66, 0.7) 0px 8px 35px 0px;
}

.support-ticket-btn:hover,
.support-ticket-btn:focus {
  color: #fff;
}

.text-blue {
  color: #5e72e4;
}

.text-indigo {
  color: #6610f2;
}

.text-purple {
  color: #6f42c1;
}

.text-pink {
  color: #e83e8c;
}

.text-red {
  color: #EE3232;
}

.text-orange {
  color: #ff9900;
}

.text-yellow {
  color: #FFFA6F;
}

.text-green {
  color: #297F00;
}

.text-teal {
  color: #20c997;
}

.text-cyan {
  color: #3065D0;
}

.bg-gray-dark {
  background: #143b64 !important;
}

.bg-purpal {
  background: #4527a0 !important;
}

.bg-red {
  background: #c62828 !important;
}

.bg-blue-dark {
  background: #283593 !important;
}

.bg-blue {
  background: #7356f1 !important;
}

.bg-blue-light {
  background: #3695eb !important;
}

.bg-green-light {
  background: #00838f !important;
}

.bg-green {
  background: #ff8f16 !important;
}

.bg-black {
  background: #000;
}

.text-black {
  color: #000 !important;
}

.dz-scroll {
  position: relative;
}

.h-auto {
  height: auto !important;
}

.list-inline li {
  display: inline-block;
}

.fs-12 {
  font-size: 12px !important;
  line-height: 1.3;
}

.fs-13 {
  font-size: 13px !important;
  line-height: 1.4;
}

.fs-14 {
  font-size: 14px !important;
  line-height: 1.5;
}

.fs-15 {
  font-size: 14px !important;
  line-height: 1.5;
}

.fs-16 {
  font-size: 16px !important;
  line-height: 1.5;
}

.fs-18 {
  font-size: 18px !important;
  line-height: 1.5;
}

.fs-20 {
  font-size: 20px !important;
  line-height: 1.5;
}

.fs-22 {
  font-size: 22px !important;
  line-height: 1.5;
}

.fs-24 {
  font-size: 24px !important;
  line-height: 1.4;
}

.fs-26 {
  font-size: 26px !important;
  line-height: 1.4;
}

.fs-28 {
  font-size: 28px !important;
  line-height: 1.4;
}

.fs-30 {
  font-size: 30px !important;
  line-height: 1.4;
}

.fs-32 {
  font-size: 32px !important;
  line-height: 1.25;
}

.fs-34 {
  font-size: 34px !important;
  line-height: 1.25;
}

.fs-35 {
  font-size: 35px !important;
  line-height: 1.25;
}

.fs-36 {
  font-size: 36px !important;
  line-height: 1.25;
}

.fs-38 {
  font-size: 38px !important;
  line-height: 1.25;
}

.fs-46 {
  font-size: 46px !important;
  line-height: 1.25;
}

.fs-48 {
  font-size: 48px !important;
  line-height: 1.25;
}

.font-w100 {
  font-weight: 100;
}

.font-w200 {
  font-weight: 200;
}

.font-w300 {
  font-weight: 300;
}

.font-w400 {
  font-weight: 400;
}

.font-w500 {
  font-weight: 500;
}

.font-w600 {
  font-weight: 600;
}

.font-w700 {
  font-weight: 700;
}

.font-w800 {
  font-weight: 800;
}

.font-w900 {
  font-weight: 900;
}

.scale1 {
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  display: inline-block;
}

.scale2 {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  display: inline-block;
}

.scale3 {
  transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  display: inline-block;
}

.scale4 {
  transform: scale(1.4);
  -moz-transform: scale(1.4);
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
  display: inline-block;
}

.scale5 {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  display: inline-block;
}

.scale-2 {
  transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  display: inline-block;
}

.height10 {
  height: 10px;
}

.height20 {
  height: 20px;
}

.height30 {
  height: 30px;
}

.height40 {
  height: 40px;
}

.height50 {
  height: 50px;
}

.height60 {
  height: 60px;
}

.height70 {
  height: 70px;
}

.height80 {
  height: 80px;
}

.height90 {
  height: 90px;
}

.height100 {
  height: 100px;
}

.height110 {
  height: 110px;
}

.height120 {
  height: 120px;
}

.height130 {
  height: 130px;
}

.height140 {
  height: 140px;
}

.height150 {
  height: 150px;
}

.height160 {
  height: 160px;
}

.height170 {
  height: 170px;
}

.height180 {
  height: 180px;
}

.height190 {
  height: 190px;
}

.height200 {
  height: 200px;
}

.height210 {
  height: 210px;
}

.height220 {
  height: 220px;
}

.height230 {
  height: 230px;
}

.height240 {
  height: 240px;
}

.height250 {
  height: 250px;
}

.height260 {
  height: 260px;
}

.height270 {
  height: 270px;
}

.height280 {
  height: 280px;
}

.height290 {
  height: 290px;
}

.height300 {
  height: 300px;
}

.height310 {
  height: 310px;
}

.height320 {
  height: 320px;
}

.height330 {
  height: 330px;
}

.height340 {
  height: 340px;
}

.height350 {
  height: 350px;
}

.height360 {
  height: 360px;
}

.height370 {
  height: 370px;
}

.height380 {
  height: 380px;
}

.height390 {
  height: 390px;
}

.height400 {
  height: 400px;
}

.height415 {
  height: 415px;
}

.height720 {
  height: 720px;
}

.height750 {
  height: 750px;
}

.height800 {
  height: 800px;
}

.width10 {
  width: 10px;
}

.width20 {
  width: 20px;
}

.width30 {
  width: 30px;
}

.width40 {
  width: 40px;
}

.width50 {
  width: 50px;
}

.width60 {
  width: 60px;
}

.width70 {
  width: 70px;
}

.width80 {
  width: 80px;
}

.width90 {
  width: 90px;
}

.width100 {
  width: 100px;
}

.width110 {
  width: 110px;
}

.width120 {
  width: 120px;
}

.width130 {
  width: 130px;
}

.width140 {
  width: 140px;
}

.width150 {
  width: 150px;
}

.width160 {
  width: 160px;
}

.width170 {
  width: 170px;
}

.width180 {
  width: 180px;
}

.width190 {
  width: 190px;
}

.width200 {
  width: 200px;
}

.width210 {
  width: 210px;
}

.width220 {
  width: 220px;
}

.width230 {
  width: 230px;
}

.width240 {
  width: 240px;
}

.width250 {
  width: 250px;
}

.width260 {
  width: 260px;
}

.width270 {
  width: 270px;
}

.width280 {
  width: 280px;
}

.width290 {
  width: 290px;
}

.width300 {
  width: 300px;
}

.width310 {
  width: 310px;
}

.width320 {
  width: 320px;
}

.width330 {
  width: 330px;
}

.width340 {
  width: 340px;
}

.width350 {
  width: 350px;
}

.width360 {
  width: 360px;
}

.width370 {
  width: 370px;
}

.width380 {
  width: 380px;
}

.width390 {
  width: 390px;
}

.width400 {
  width: 400px;
}

@-webkit-keyframes crescendo {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

@keyframes gXGDoR {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

@keyframes crescendo {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

@keyframes gXGDoR {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

a,
.btn-link {
  text-decoration: none;
}

textarea.form-control {
  min-height: auto !important;
  height: auto;
  padding: 15px;
}

.form-group {
  margin-bottom: 1rem;
}

.required {
  color: #F94687;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.btn-block {
  display: block;
  width: 100%;
}

.email-list .message-single i.yellow {
  color: #ffa755 !important;
}

.dz-scroll {
  position: relative;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

div,
span,
ol,
ul {
  scrollbar-width: thin;
}

.border-s-1 {
  border-radius: 0 0.625rem 0.625rem 0 !important;
}

.dz-scroll {
  overflow-y: scroll;
}

* {
  scrollbar-width: thin;
}

* ::-webkit-scrollbar-thumb {
  background: #99c990;
}

body {
  scrollbar-width: thin;
  scrollbar-color: rgba(216, 214, 249, 0.5) #fff0;
}

body ::-webkit-scrollbar-thumb {
  background: rgba(216, 214, 249, 0.5);
}

::-webkit-scrollbar {
  width: 5px;
  opacity: 0;
}

.deznav-scroll::-webkit-scrollbar {
  width: 0px;
  opacity: 0;
}

.deznav-scroll {
  scrollbar-width: none;
}

.clockpicker-popover.popover {
  position: absolute;
  border-color: transparent;
}

.clockpicker-popover.popover .popover-title {
  padding: 10px 0;
}

.pull-right {
  float: right;
}

.form-file-input.form-control {
  margin: 0 !important;
  height: auto !important;
  border-radius: 0 0.625rem 0.625rem 0;
}

.card-body.product-grid-card {
  padding: 15px;
}

.border-2 {
  border-width: 2px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
}

/* Preloder */
#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
}

.sk-three-bounce {
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: white;
}

.sk-three-bounce .sk-child {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: #99c990;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
  animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
}

.sk-three-bounce .sk-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.sk-three-bounce .sk-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-three-bounce {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-three-bounce {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.footer {
  padding-left: 18rem;
  background-color: transparent;
}

@media only screen and (max-width: 1400px) {
  .footer {
    padding-left: 17rem;
  }
}

.footer .copyright {
  padding: 0.9375rem;
}

.footer .copyright p {
  text-align: center;
  margin: 0;
}

.footer .copyright a {
  color: #99c990;
}

@media only screen and (min-width: 991px) {
  .footer .copyright {
    border-radius: 0 0 0 50px;
  }
}

[data-sidebar-style="mini"] .nav-control,
[data-layout="horizontal"] .nav-control {
  display: none;
}

@media only screen and (max-width: 767px) {
  [data-sidebar-style="overlay"] .nav-header .logo-abbr {
    display: block;
  }
}

[data-header-position="fixed"] .nav-header {
  position: fixed;
  left: auto;
}

.nav-header {
  height: 4.5rem;
  width: 18rem;
  display: inline-block;
  text-align: left;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  transition: all .2s ease;
  z-index: 5;
}

.nav-header .logo-abbr {
  max-width: 100px;
}

@media only screen and (max-width: 575px) {
  .nav-header .logo-abbr {
    max-width: 40px;
  }
}

.nav-header .logo-compact {
  display: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .nav-header {
    width: 17rem;
  }
}

.nav-header .brand-logo {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.125rem;
  color: #fff;
  text-decoration: none;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: 700;
}

@media only screen and (max-width: 1400px) {
  .nav-header .brand-logo {
    padding-left: 25px;
    padding-right: 25px;
  }
}

[data-sidebar-style="compact"] .nav-header .brand-logo,
[data-sidebar-style="mini"] .nav-header .brand-logo {
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .nav-header .brand-logo {
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
  }
}

.nav-header .brand-title {
  margin-left: 10px;
  max-width: 120px;
  margin-top: 0px;
}

[data-theme-version="dark"] .nav-header .brand-title {
  background-position: 0 120%;
}

@media only screen and (max-width: 1199px) {
  .nav-header {
    width: 5rem;
    height: 5rem;
  }

  .nav-header .brand-title {
    display: none;
  }
}

.nav-control {
  cursor: pointer;
  position: absolute;
  right: -5.0625rem;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
  font-size: 1.4rem;
  padding: 2px 0.5rem 0;
  border-radius: 2px;
}

@media only screen and (max-width: 767px) {
  .nav-control {
    right: -2.75rem;
  }
}

@media only screen and (max-width: 575px) {
  .nav-control {
    right: -2.75rem;
  }
}

.hamburger {
  display: inline-block;
  left: 0px;
  position: relative;
  top: 3px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 26px;
  z-index: 999;
}

.hamburger .line {
  background: #99c990;
  display: block;
  height: 3px;
  border-radius: 3px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger .line:nth-child(1) {
  width: 20px;
}

.hamburger .line:nth-child(2) {
  width: 26px;
}

.hamburger .line:nth-child(3) {
  width: 22px;
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger:hover .line {
  width: 26px;
}

.hamburger.is-active .line:nth-child(1),
.hamburger.is-active .line:nth-child(3) {
  width: 10px;
  height: 2px;
}

.hamburger.is-active .line:nth-child(2) {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  width: 22px;
  height: 2px;
}

.hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(4px) rotate(45deg);
  transform: translateY(4px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-4px) rotate(-45deg);
  transform: translateY(-4px) rotate(-45deg);
}

@media (min-width: 767px) {
  [data-sidebar-style="compact"] .nav-control {
    display: none;
  }

  [data-sidebar-style="compact"] .nav-header {
    width: 15rem;
  }
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .brand-title {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .logo-abbr {
  display: block;
  margin-left: 30px;
}

[data-sidebar-style="full"][data-layout="horizontal"] .logo-compact {
  display: none;
}

[data-sidebar-style="mini"] .nav-header .logo-abbr {
  display: block;
}

[data-sidebar-style="compact"][data-layout="vertical"] .nav-header .logo-compact {
  max-width: 75px;
}

[data-sidebar-style="compact"][data-layout="horizontal"] .nav-header .brand-logo {
  padding-left: 30px;
  padding-right: 30px;
  justify-content: start;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header {
  width: 9.375rem;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header .brand-title {
  display: none;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header .logo-compact {
  display: none;
}

.header {
  height: 4.5rem;
  z-index: 1;
  position: relative;
  padding: 0rem;
  background-color: #fff;
  z-index: 4;
  padding-left: 21.563rem;
  transition: all .2s ease;
}

@media only screen and (max-width: 1400px) {
  .header {
    padding-left: 17rem;
  }
}

.header .header-content {
  height: 100%;
  padding-left: 5.3125rem;
  padding-right: 2.4rem;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .header .header-content {
    padding-left: 3.75rem;
    padding-right: 0.938rem;
    border-radius: 0;
  }
}

@media only screen and (max-width: 575px) {
  .header .header-content {
    padding-left: 2.75rem;
  }
}

.header .navbar {
  padding: 0;
  height: 100%;
  width: 100%;
}

.header .navbar .navbar-collapse {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .header {
    height: 5rem;
  }
}

/* pulse in SVG */
svg.pulse-svg {
  overflow: visible;
}

svg.pulse-svg .first-circle,
svg.pulse-svg .second-circle,
svg.pulse-svg .third-circle {
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation: pulse-me 3s linear infinite;
  animation: pulse-me 3s linear infinite;
  fill: #99c990;
}

svg.pulse-svg .second-circle {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

svg.pulse-svg .third-circle {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

/* pulse in CSS */
.pulse-css {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  border-radius: 3.5rem;
  height: 13px;
  position: absolute;
  background: #FFBC11;
  right: -4px;
  top: -3px;
  width: 13px;
}

.pulse-css:after,
.pulse-css:before {
  content: '';
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -.2rem;
  background-color: #FFBC11;
  margin: auto;
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation: pulse-me 3s linear infinite;
  animation: pulse-me 3s linear infinite;
}

[direction="rtl"] .pulse-css:after,
[direction="rtl"] .pulse-css:before {
  left: auto;
  right: -.2rem;
}

@-webkit-keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0;
  }

  50% {
    opacity: 0.1;
  }

  70% {
    opacity: 0.09;
  }

  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0;
  }

  50% {
    opacity: 0.1;
  }

  70% {
    opacity: 0.09;
  }

  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

[data-sidebar-style="full"] .header,
[data-sidebar-style="overlay"] .header {
  width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1350px) {

  [data-sidebar-style="full"] .header,
  [data-sidebar-style="overlay"] .header {
    width: 100%;
    padding-left: 17rem;
  }
}

@media only screen and (max-width: 1199px) {

  [data-sidebar-style="full"] .header,
  [data-sidebar-style="overlay"] .header {
    width: 100%;
    padding-left: 5rem;
  }
}

[data-sidebar-style="mini"] .header {
  width: 100%;
  padding-left: 5rem;
}

[data-sidebar-style="compact"] .header {
  width: 100%;
  padding-left: 15rem;
}

[data-sidebar-style="compact"] .header .header-content {
  padding-left: 2.4rem;
}

[data-header-position="fixed"] .header {
  position: fixed;
  top: 0;
  width: 100%;
}

[data-header-position="fixed"] .content-body {
  padding-top: 4.5rem;
}

@media only screen and (max-width: 1199px) {
  [data-header-position="fixed"] .content-body {
    padding-top: 5rem;
  }
}

[data-header-position="fixed"] .deznav {
  margin-top: 0;
}

[data-sidebar-style="compact"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] .header {
  width: 1199px;
}

[data-sidebar-style="modern"] .header {
  width: 100%;
  padding-left: 9.375rem;
}

[data-sidebar-style="modern"][data-layout="horizontal"] .nav-header .brand-logo {
  justify-content: start;
}

[data-sidebar-style="modern"][data-layout="horizontal"] .header .header-content {
  padding-left: 30px;
}

.header-left {
  height: 100%;
  display: flex;
  align-items: center;
}

.header-left .dashboard_bar {
  font-size: 28px;
  font-weight: 600;
  color: #000;
}

@media only screen and (max-width: 1400px) {
  .header-left .dashboard_bar {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .header-left .dashboard_bar {
    display: none;
  }
}

.header-left input {
  background: #fff !important;
  min-width: 170px;
  min-height: 40px;
  color: #B1B1B1 !important;
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;
  padding: 10px 20px;
  font-size: 16px;
  border-right-width: 0 !important;
  border: 1px solid #ebebeb;
  border-width: 1px;
  font-weight: 300;
}

[direction="rtl"] .header-left input {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.header-left input:focus,
.header-left input:active,
.header-left input.active {
  border-color: #ebebeb;
  box-shadow: none;
}

.header-left input::-webkit-input-placeholder {
  /* Edge */
  color: #B1B1B1;
}

.header-left input:-ms-input-placeholder {
  /* Internet Explorer */
  color: #B1B1B1;
}

.header-left input::placeholder {
  color: #B1B1B1;
}

.header-left .search_bar {
  display: flex;
  align-items: center;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .header-left .search_bar {
    display: none;
  }
}

.header-left .search_bar .dropdown-menu {
  box-shadow: none;
}

.header-left .search_bar .search_icon {
  background: #fff !important;
  height: 56px;
  line-height: 22px;
  padding: 15px 20px 15px 0 !important;
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border: 1px solid #ebebeb;
  border-width: 1px;
  border-left-width: 0;
}

.header-left .search_bar .search_icon i {
  font-size: 24px;
  color: #B1B1B1;
  line-height: 1;
}

@media only screen and (max-width: 1400px) {
  .header-left .search_bar .search_icon {
    height: 41px;
    padding: 8px 15px 8px 0 !important;
  }

  .header-left .search_bar .search_icon svg {
    width: 18px;
    height: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .header-left .search_bar {
    position: static;
  }

  .header-left .search_bar .dropdown-menu {
    width: 300px;
    left: -15vw;
    box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px !important;
  }
}

@media only screen and (max-width: 767px) and (max-width: 575px) {
  .header-left .search_bar .dropdown-menu {
    width: 250px;
    left: -25vw;
  }
}

@media only screen and (max-width: 767px) {
  .header-left .search_bar .dropdown-menu .form-control {
    border-radius: 4px !important;
    width: 100%;
  }

  [direction="rtl"] .header-left .search_bar .dropdown-menu {
    right: -98px;
  }
}

.header-left .search_bar .dropdown-menu,
.header-left .search_bar .dropdown-menu.show {
  border: 0px;
  background-color: transparent;
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

@media only screen and (min-width: 768px) {

  .header-left .search_bar .dropdown-menu,
  .header-left .search_bar .dropdown-menu.show {
    left: 0;
    top: 0;
    transform: none;
    display: block;
    position: unset;
  }
}

[data-sidebar-style="compact"] .header-left {
  margin-left: 0;
}

.header-right {
  height: 100%;
}

.header-right .nav-item {
  height: 100%;
  display: flex;
  align-items: center;
}

.header-right .nav-item .nav-link {
  color: #464a53;
  font-size: 18px;
}

.header-right .right-sidebar {
  margin-right: -30px;
}

.header-right .right-sidebar a {
  height: 80px;
  width: 80px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  border-left: 1px solid #F4F5F9;
}

.header-right>li:not(:first-child) {
  padding-left: 1rem;
}

@media only screen and (max-width: 1600px) {
  .header-right>li:not(:first-child) {
    padding-left: 1.3rem;
  }
}

@media only screen and (max-width: 1400px) {
  .header-right>li:not(:first-child) {
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 575px) {
  .header-right>li:not(:first-child) {
    padding-left: 0.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .header-right .notification_dropdown {
    position: static;
  }
}

.header-right .notification_dropdown .nav-link {
  position: relative;
  color: #99c990;
  background: rgba(30, 63, 180, 0.1);
  border-radius: 0.625rem;
  padding: 12px;
  line-height: 1;
}

@media only screen and (max-width: 1400px) {
  .header-right .notification_dropdown .nav-link {
    padding: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .header-right .notification_dropdown .nav-link {
    padding: 8px;
  }
}

.header-right .notification_dropdown .nav-link.secondary {
  background: #f1dffe;
  color: #A02CFA;
}

.header-right .notification_dropdown .nav-link.secondary [fill] {
  fill: #A02CFA;
}

.header-right .notification_dropdown .nav-link.primary {
  background: #d8e0f9;
  color: #99c990;
}

.header-right .notification_dropdown .nav-link.primary [fill] {
  fill: #99c990;
}

.header-right .notification_dropdown .nav-link.warning {
  background: #fff5dd;
  color: #FFBC11;
}

.header-right .notification_dropdown .nav-link.warning [fill] {
  fill: #FFBC11;
}

.header-right .notification_dropdown .nav-link.success {
  background: #e3f9e9;
  color: #2BC155;
}

.header-right .notification_dropdown .nav-link.success [fill] {
  fill: #2BC155;
}

.header-right .notification_dropdown .nav-link i {
  font-size: 22px;
}

@media only screen and (max-width: 575px) {
  .header-right .notification_dropdown .nav-link i {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1400px) {
  .header-right .notification_dropdown .nav-link svg {
    width: 24px;
    height: 24px;
  }
}

@media only screen and (max-width: 575px) {
  .header-right .notification_dropdown .nav-link svg {
    width: 20px;
    height: 20px;
  }
}

.header-right .notification_dropdown .nav-link .badge {
  position: absolute;
  font-size: 14px;
  border-radius: 40px;
  right: -1px;
  top: 3px;
  box-shadow: 0px 0px 10px 3px rgba(30, 63, 180, 0.2);
  font-weight: normal;
  height: 26px;
  width: 26px;
  line-height: 26px;
  text-align: center;
  padding: 0px;
}

@media only screen and (max-width: 1400px) {
  .header-right .notification_dropdown .nav-link .badge {
    right: 3px;
    top: 3px;
    font-size: 12px;
    height: 18px;
    width: 18px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .header-right .notification_dropdown .nav-link .badge {
    right: -1px;
    height: 15px;
    width: 15px;
    font-size: 10px;
    line-height: 15px;
  }
}

.header-right .notification_dropdown .dropdown-item:focus a,
.header-right .notification_dropdown .dropdown-item:active a {
  color: #fff;
}

.header-right .notification_dropdown .dropdown-item a {
  color: #B1B1B1;
}

.header-right .notification_dropdown .dropdown-item a:hover {
  text-decoration: none;
}

.header-right .dropdown-menu {
  border-width: 0;
  box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
}

[data-theme-version="dark"] .header-right .dropdown-menu {
  box-shadow: none;
}

.header-right .header-profile>a.nav-link {
  padding: 0 0 0 0;
  display: flex;
  align-items: center;
  border-radius: 0.625rem 28px 28px 0.625rem;
}

@media only screen and (max-width: 575px) {
  .header-right .header-profile>a.nav-link {
    background: transparent;
    padding: 0;
  }
}

.header-right .header-profile>a.nav-link i {
  font-weight: 700;
}

.header-right .header-profile>a.nav-link .header-info {
  text-align: left;
  padding-left: 15px;
}

@media only screen and (max-width: 1199px) {
  .header-right .header-profile>a.nav-link .header-info {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .header-right .header-profile>a.nav-link .header-info {
    display: none;
  }
}

.header-right .header-profile>a.nav-link .header-info span {
  font-size: 16px;
  display: block;
  font-weight: 400;
}

.header-right .header-profile>a.nav-link .header-info strong {
  font-weight: 600;
}

.header-right .header-profile>a.nav-link .header-info small,
.header-right .header-profile>a.nav-link .header-info .small {
  display: block;
  font-size: 13px;
  color: #89879f;
  font-weight: 400;
  line-height: 1.2;
}

.header-right .header-profile .dropdown-menu {
  padding: 15px 0;
  min-width: 12.5rem;
  width: 12.5rem;
}

.header-right .header-profile .dropdown-menu a:hover,
.header-right .header-profile .dropdown-menu a:focus,
.header-right .header-profile .dropdown-menu a.active {
  color: #99c990;
}

.header-right .header-profile img {
  width: 49px;
  height: 49px;
  border-radius: 50%;
}

@media only screen and (max-width: 1400px) {
  .header-right .header-profile img {
    width: 39px;
    height: 39px;
  }
}

@media only screen and (max-width: 575px) {
  .header-right .header-profile img {
    width: 36px;
    height: 36px;
  }
}

.header-right .header-profile .dropdown-toggle i {
  font-size: 1.25rem;
}

@media only screen and (max-width: 575px) {
  .header-right .header-profile .dropdown-toggle span {
    display: none;
  }
}

.header-right .header-profile .profile_title {
  background: #99c990;
  color: #fff;
  padding: 10px 20px;
}

.header-right .header-profile .profile_title h5,
.header-right .header-profile .profile_title .h5 {
  color: #fff;
  margin-bottom: 3px;
}

.header-right .header-profile .dropdown-item {
  padding: 8px 24px;
}

.dz-theme-mode #icon-light {
  display: none;
}

.dz-theme-mode.active #icon-dark {
  display: none;
}

.dz-theme-mode.active #icon-light {
  display: block;
}

.notification_dropdown .dropdown-menu-end,
.header-profile .dropdown-menu-end {
  min-width: 19.375rem;
  width: 19.375rem;
  padding: 0rem 0 1rem;
  top: 100%;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 8px 24px;
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  -webkit-transform: translateY(120px) !important;
  transform: translateY(120px) !important;
  transition: all .2s;
}

@media only screen and (max-width: 100rem) {

  .notification_dropdown .dropdown-menu-end,
  .header-profile .dropdown-menu-end {
    -webkit-transform: translateY(120px) !important;
    transform: translateY(120px) !important;
  }
}

@media only screen and (max-width: 1400px) {

  .notification_dropdown .dropdown-menu-end,
  .header-profile .dropdown-menu-end {
    transform: translateY(120px) !important;
    -webkit-transform: translateY(120px) !important;
  }
}

@media only screen and (max-width: 1199px) {

  .notification_dropdown .dropdown-menu-end,
  .header-profile .dropdown-menu-end {
    transform: translateY(80px) !important;
    -webkit-transform: translateY(80px) !important;
  }
}

.notification_dropdown .dropdown-menu-end .notification_title,
.header-profile .dropdown-menu-end .notification_title {
  background: #99c990;
  color: #fff;
  padding: 10px 20px;
}

.notification_dropdown .dropdown-menu-end .notification_title h5,
.notification_dropdown .dropdown-menu-end .notification_title .h5,
.header-profile .dropdown-menu-end .notification_title h5,
.header-profile .dropdown-menu-end .notification_title .h5 {
  color: #fff;
  margin-bottom: 3px;
}

.notification_dropdown .dropdown-menu-end .media,
.header-profile .dropdown-menu-end .media {
  width: 45px !important;
  height: 45px !important;
  font-size: 18px !important;
}

[data-theme-version="dark"] .notification_dropdown .dropdown-menu-end .media,
[data-theme-version="dark"] .header-profile .dropdown-menu-end .media {
  border-color: #333a54;
}

.notification_dropdown .dropdown-menu-end .media>span,
.header-profile .dropdown-menu-end .media>span {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  display: inline-block;
  padding: 7px 9px;
  margin-right: 10px;
}

[direction="rtl"].notification_dropdown .dropdown-menu-end .media>span,
.header-profile .dropdown-menu-end .media>span {
  margin-right: 0;
  margin-left: 10px;
}

.notification_dropdown .dropdown-menu-end .media>span.success,
.header-profile .dropdown-menu-end .media>span.success {
  background: #e3f9e9;
  color: #2BC155;
}

.notification_dropdown .dropdown-menu-end .media>span.primary,
.header-profile .dropdown-menu-end .media>span.primary {
  background: #d8e0f9;
  color: #99c990;
}

.notification_dropdown .dropdown-menu-end .media>span.danger,
.header-profile .dropdown-menu-end .media>span.danger {
  background: #fff3f7;
  color: #F94687;
}

.notification_dropdown .dropdown-menu-end .media .notify-time,
.header-profile .dropdown-menu-end .media .notify-time {
  width: 100% !important;
  margin-right: 0 !important;
  color: #828690;
}

.notification_dropdown .dropdown-menu-end .media p,
.header-profile .dropdown-menu-end .media p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
  margin-bottom: 0;
  margin-top: 5px;
}

@media only screen and (max-width: 575px) {

  .notification_dropdown .dropdown-menu-end .media p,
  .header-profile .dropdown-menu-end .media p {
    max-width: 100px;
  }
}

.notification_dropdown .dropdown-menu-end .all-notification,
.header-profile .dropdown-menu-end .all-notification {
  display: block;
  padding: 15px 30px 0;
  color: #99c990;
  text-align: center;
  border-top: 1px solid #F4F5F9;
}

.notification_dropdown .dropdown-menu-end .all-notification i,
.header-profile .dropdown-menu-end .all-notification i {
  margin-left: 10px;
}

.notification_dropdown .dropdown-menu-end.show,
.header-profile .dropdown-menu-end.show {
  will-change: transform;
  animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
}

@keyframes menu-sub-dropdown-animation-fade-in {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 1;
  }
}

@keyframes menu-sub-dropdown-animation-move-up {
  0% {
    margin-top: 0.75rem;
  }

  100% {
    margin-top: 0;
  }
}

.nav-label {
  margin: 10px 30px 0;
  padding: 1.5625rem 0 10px;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.05rem;
  border-top: 1px solid #eaeaea;
  color: #999999;
}

[data-theme-version="dark"] .nav-label {
  border-color: #333a54;
}

.nav-label.first {
  border: 0px;
  margin-top: 0px;
}

.nav-badge {
  position: absolute;
  right: 2.8125rem;
  top: 0.625rem;
}

.content-body {
  margin-left: 18rem;
  z-index: 0;
  transition: all .2s ease;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .content-body {
    margin-left: 17rem;
  }
}

.bell img {
  -webkit-animation: ring 8s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 8s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 8s .7s ease-in-out infinite;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

.deznav {
  width: 18.625rem;
  padding-bottom: 0;
  height: 100%;
  position: absolute;
  top: 0;
  padding-top: 120px;
  z-index: 4;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(120, 130, 140, 0.13);
  transition: all .2s ease;
}

@media only screen and (max-width: 1199px) {
  .deznav {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .deznav {
    width: 18rem;
  }
}

.deznav .deznav-scroll {
  position: relative;
  height: 100%;
  overflow-y: scroll;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .deznav {
    width: 17rem;
  }
}

.deznav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.deznav .metismenu {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}

@media only screen and (max-width: 767px) {
  .deznav .metismenu {
    padding-top: 0;
  }
}

.deznav .metismenu.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
}

.deznav .metismenu>li {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}

.deznav .metismenu>li a>i {
  font-size: 1.4rem;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 0;
  height: auto;
  width: auto;
  border-radius: 0.625rem;
  line-height: 1;
  text-align: center;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  margin-right: 15px;
}

[data-sidebar-style="compact"] .deznav .metismenu>li a>i {
  display: block;
  padding: 0;
}

@media only screen and (max-width: 1300px) {
  .deznav .metismenu>li a>i {
    height: auto;
    width: auto;
    margin-right: 0;
    line-height: 1;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .deznav .metismenu>li a>i {
    font-size: 1.25rem;
    padding: 0 .75rem 0 0;
    margin-right: 0px;
  }
}

.deznav .metismenu>li>a {
  font-weight: 500;
  display: inline-block;
  font-size: 15px;
}

.deznav .metismenu>li>a svg {
  max-width: 24px;
  max-height: 24px;
  height: 100%;
  margin-right: 5px;
  margin-top: -3px;
  color: #99c990;
}

.deznav .metismenu>li>a g [fill] {
  fill: #99c990;
}

.deznav .metismenu>li:hover>a,
.deznav .metismenu>li:focus>a {
  color: #99c990;
}

.deznav .metismenu>li:hover>a g [fill],
.deznav .metismenu>li:focus>a g [fill] {
  fill: #99c990;
}

.deznav .metismenu>li.mm-active>a {
  color: #99c990;
}

.deznav .metismenu>li.mm-active>a g [fill] {
  fill: #99c990;
}

.deznav .metismenu li {
  position: relative;
}

.deznav .metismenu ul {
  transition: all .2s ease-in-out;
  position: relative;
  z-index: 1;
  padding: 5px 0;
}

.deznav .metismenu ul a {
  padding-top: .5rem;
  padding-bottom: .5rem;
  position: relative;
  font-size: 16px;
  padding-left: 3.9rem;
}

@media only screen and (max-width: 1400px) {
  .deznav .metismenu ul a {
    padding-left: 3.9rem;
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .deznav .metismenu ul a {
    padding-left: 3.5rem;
  }
}

.deznav .metismenu ul a:hover,
.deznav .metismenu ul a:focus,
.deznav .metismenu ul a.mm-active {
  text-decoration: none;
  color: #99c990;
}

.deznav .metismenu a {
  position: relative;
  display: block;
  padding: 0.625rem 1.875rem;
  outline-width: 0;
  color: #000000;
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .deznav .metismenu a {
    padding: 0.625rem 1.25rem;
  }
}

.deznav .metismenu .has-arrow:after {
  width: .5rem;
  height: .5rem;
  right: 1.875rem;
  top: 48%;
  border-color: inherit;
  -webkit-transform: rotate(-225deg) translateY(-50%);
  transform: rotate(-225deg) translateY(-50%);
}

.deznav .metismenu .has-arrow[aria-expanded=true]:after,
.deznav .metismenu .mm-active>.has-arrow:after {
  -webkit-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg) translateY(-50%);
}

@media only screen and (max-width: 1199px) {
  .nav-header {
    width: 5rem;
  }
}

@media (max-width: 767px) {
  .brand-title {
    display: none;
  }

  .footer {
    padding-left: 0;
  }

  .deznav {
    left: 0;
    top: 5rem;
    padding-top: 1rem;
  }
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu>ul.collapse:not(.in),
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu>ul.collapse:not(.in) {
  height: 252px !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu:hover>a {
  width: calc(70vw + 3.75rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 13.75rem;
  width: 70vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-xl:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-xl:hover>a {
  width: calc(70vw + 3rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-xl:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 200px;
  width: 70vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-xl:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-xl:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-lg:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-lg:hover>a {
  width: calc(55vw + 3rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-lg:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 200px;
  width: 55vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-lg:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-lg:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-md:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-md:hover>a {
  width: calc(45vw + 3);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-md:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 18.75rem;
  width: 45vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-md:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-md:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-sm:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-sm:hover>a {
  width: calc(30vw + 3);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-sm:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 18.125rem;
  width: 30vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-sm:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-sm:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu:hover>a {
  width: calc(60vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 25rem;
  width: 60vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-xl:hover>a {
  width: calc(60vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 25.625rem;
  width: 60vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-lg:hover>a {
  width: calc(50vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 16.25rem;
  width: 50vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-md:hover>a {
  width: calc(40vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 18.75rem;
  width: 40vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-sm:hover>a {
  width: calc(22vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 18.125rem;
  width: 22vw;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu:not(:last-child) {
  position: static;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul {
  left: 0;
  right: 0;
}

[data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #333a54;
}

[data-sibebarbg="color_2"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #03090f;
}

[data-sibebarbg="color_3"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #0f205d;
}

[data-sibebarbg="color_4"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #22134e;
}

[data-sibebarbg="color_5"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #711717;
}

[data-sibebarbg="color_6"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #121843;
}

[data-sibebarbg="color_7"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #3511d0;
}

[data-sibebarbg="color_8"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #1161aa;
}

[data-sibebarbg="color_9"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #002629;
}

[data-sibebarbg="color_10"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #af5b00;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li a {
  transition: all .4s ease-in-out;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li a:hover {
  border-radius: 0.25rem;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 13.75rem;
  width: 70vw;
  z-index: 99;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  content: "";
  height: 100%;
  width: 1px;
  position: absolute;
  background-color: #fff;
  right: 2.8125rem;
  top: 0;
}

[data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #2a2b30;
}

[data-sibebarbg="color_2"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #0c223a;
}

[data-sibebarbg="color_3"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #173088;
}

[data-sibebarbg="color_4"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #331d77;
}

[data-sibebarbg="color_5"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #9c1f1f;
}

[data-sibebarbg="color_6"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #1d276b;
}

[data-sibebarbg="color_7"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #4c27ed;
}

[data-sibebarbg="color_8"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #167cd8;
}

[data-sibebarbg="color_9"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #00545c;
}

[data-sibebarbg="color_10"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #e27500;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul ul a {
  width: 101%;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 210px;
  width: 70vw;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 210px;
  width: 700px;
  height: 210px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  [data-layout="horizontal"] .deznav .metismenu>li.mega-menu-lg:hover>ul {
    width: 700px;
  }
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 20rem;
  width: 54vw;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  [data-layout="horizontal"] .deznav .metismenu>li.mega-menu-md:hover>ul {
    width: 60vw;
  }
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 20rem;
  width: 25vw;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  [data-layout="horizontal"] .deznav .metismenu>li.mega-menu-sm:hover>ul {
    width: 35vw;
  }
}

[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 21.875rem;
  width: 100%;
}

[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 21.875rem;
  width: 55vw;
}

[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 18.75rem;
  width: 45vw;
}

[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 18.125rem;
  width: 50vw;
}

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li {
  padding: 0 0 0 40px;
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li>a {
  font-size: 16px;
  padding: 20px 20px;
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  position: relative;
}

@media only screen and (max-width: 1400px) {
  [data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li>a {
    padding: 20px 20px;
    font-size: 16px;
  }
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li>a:before {
  height: 100%;
  position: absolute;
  border-radius: 4px;
  width: 6px;
  background: #99c990;
  top: 0;
  right: 0;
  opacity: 0;
  content: "";
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a {
  color: #1db700;
  font-weight: 600;
  background: rgba(30, 63, 180, 0.1);
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #007909;
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a:before {
  opacity: 1;
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li .has-arrow:after {
  right: 2.5rem;
}

@media only screen and (max-width: 1400px) {
  [data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li .has-arrow:after {
    right: 1.5rem;
  }
}

@media only screen and (max-width: 1400px) {
  [data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li {
    padding: 0 0 0 20px;
  }
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header {
  width: 5rem;
  z-index: 999;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .brand-logo {
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #99c990 !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .copyright,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .add-menu-sidebar {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header {
  padding-left: 6rem;
  width: 100%;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header {
  padding: 0 0.9375rem;
  padding-right: 5rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav {
  width: 5rem;
  overflow: visible;
  position: absolute;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .nav-text {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .slimScrollDiv,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .deznav-scroll {
  overflow: visible !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li {
  position: relative;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li a {
  padding: 13px;
  border-radius: 0.625rem;
  margin: 2px 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li a svg {
  max-width: 24px;
  max-height: 24px;
  margin-right: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li a:before {
  content: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul {
  position: absolute;
  left: 5rem;
  top: 0;
  width: 13rem;
  z-index: 1001;
  display: none;
  padding-left: 1px;
  height: auto !important;
  box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
  border-radius: 6px;
  margin-left: 0;
  border: 0;
  background: #fff;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul:after {
  content: "";
  position: absolute;
  background: inherit;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  left: -5px;
  top: 20px;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul {
  left: auto;
  right: 3rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul li:hover ul {
  left: 11.8125rem;
  top: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul li:hover ul:after {
  content: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li:hover>ul {
  display: block;
  height: auto;
  overflow: visible;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li {
  transition: all 0.4s ease-in-out;
  padding: 0 13px;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li>a {
  text-align: center;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li>a.has-arrow:after {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li>a i {
  padding: 0;
  width: auto;
  height: auto;
  background: transparent;
  margin: 0;
  line-height: 1;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a {
  background: #99c990;
  border-radius: 0.625rem;
  color: #fff;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a i {
  color: #fff;
  padding: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover:nth-last-child(-n + 1)>ul {
  bottom: 0;
  top: auto;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a {
  border-radius: 0.625rem;
  background: #99c990;
  color: #fff;
}

[data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a {
  background: #111217;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a i {
  color: #fff;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>ul {
  height: auto !important;
  padding: 10px 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>ul a {
  padding: 6px 20px 6px 20px;
  margin-left: -.1rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>ul ul {
  padding: 10px 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>ul ul a {
  padding: 6px 20px 6px 20px;
  margin-left: -.1rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu .nav-label,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu .nav-badge {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .content-body {
  margin-left: 5rem;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .content-body {
  margin-right: 5rem;
  margin-left: auto;
  border: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle+.footer {
  padding-left: 5rem;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle+.footer {
  padding-left: 0;
  padding-right: 5rem;
}

[data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu {
  padding: 0 30px;
}

[data-sidebar-style="full"][data-layout="horizontal"] .header .header-content {
  padding-left: 1.875rem;
}

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
@media only screen and (min-width: 768px) {
  [data-sidebar-style="mini"] .nav-header {
    width: 5rem;
  }

  [data-sidebar-style="mini"] .nav-header .nav-control {
    z-index: -1;
  }

  [data-sidebar-style="mini"] .nav-header .nav-control .hamburger {
    left: 5rem !important;
  }

  [data-sidebar-style="mini"] .nav-header .nav-control .hamburger .line {
    background-color: #B1B1B1 !important;
  }

  [data-sidebar-style="mini"] .nav-header .brand-title {
    display: none;
  }

  [data-sidebar-style="mini"] .nav-header .hamburger {
    display: none;
  }

  [data-sidebar-style="mini"] .header .header-content {
    padding-left: 1.875rem;
  }

  [direction="rtl"][data-sidebar-style="mini"] .header .header-content {
    padding-right: 1.875rem;
  }

  [data-sidebar-style="mini"] .deznav {
    width: 5rem;
    overflow: visible;
    position: absolute !important;
  }

  [data-sidebar-style="mini"] .deznav .copyright,
  [data-sidebar-style="mini"] .deznav .add-menu-sidebar {
    display: none;
  }

  [data-sidebar-style="mini"] .deznav .nav-text {
    display: none;
  }

  [data-sidebar-style="mini"] .deznav .slimScrollDiv,
  [data-sidebar-style="mini"] .deznav .deznav-scroll {
    overflow: visible !important;
  }

  [data-sidebar-style="mini"] .deznav .nav-user {
    padding: 11px;
  }

  [data-sidebar-style="mini"] .deznav .nav-user .media-body {
    display: none;
  }

  [data-sidebar-style="mini"] .deznav .metismenu li a {
    padding: 0.813rem 0.875rem;
  }

  [data-sidebar-style="mini"] .deznav .metismenu li a svg {
    margin-right: 0;
  }

  [data-sidebar-style="mini"] .deznav .metismenu li>ul {
    position: absolute;
    left: 5rem;
    top: 2.9375rem;
    width: 11.875rem;
    z-index: 1001;
    display: none;
    padding-left: 1px;
    box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
    height: auto !important;
    border-radius: 6px;
    background: #fff;
  }

  [direction="rtl"]:not([data-layout="horizontal"])[data-sidebar-style="mini"] .deznav .metismenu li>ul {
    left: auto;
    right: 5rem;
    box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.15);
  }

  [data-sidebar-style="mini"] .deznav .metismenu>li {
    padding: 2px 15px;
  }

  [data-sidebar-style="mini"] .deznav .metismenu>li>a.has-arrow:after {
    display: none;
  }

  [data-sidebar-style="mini"] .deznav .metismenu .nav-label,
  [data-sidebar-style="mini"] .deznav .metismenu .nav-badge {
    display: none;
  }

  [data-sidebar-style="mini"] .content-body {
    margin-left: 5rem;
  }

  [data-sidebar-style="mini"] .footer {
    padding-left: 5rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li:hover>ul {
    display: block;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:nth-last-child(-n + 1)>ul {
    bottom: 0;
    top: auto !important;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:nth-last-child(-n + 1)>ul:after {
    top: auto;
    bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:nth-last-child(-n + 1)>ul {
    bottom: 0;
    top: auto !important;
  }
}

@media only screen and (min-width: 768px) {
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul {
    overflow: visible;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul:after {
    content: none;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul li:hover ul {
    padding: 10px 0;
    width: 13rem;
    left: 13rem;
    top: -10px;
    border: 0;
    margin: 0;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul li:hover ul:after {
    content: none;
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul li:hover ul {
    left: auto;
    right: 13rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a {
    background: #99c990;
    color: #fff;
    border-radius: 0.625rem;
  }

  [data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a {
    background: #111217;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
    background: #99c990;
    color: #fff;
    border-radius: 0.625rem;
    position: unset;
  }

  [data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
    background: #111217;
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a .nav-text {
    padding-left: auto;
    padding-right: 1.6875rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul {
    height: auto !important;
    overflow: visible;
    border: 0;
    margin-left: 0;
    left: 5rem;
    width: 13rem;
    border-radius: 12px;
    border: 0;
    padding: 10px 0;
    top: 0;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul:after {
    content: "";
    position: absolute;
    background: inherit;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    left: -5px;
    top: 20px;
  }

  [data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul {
    box-shadow: 0px 9px 10px 0px rgba(0, 0, 0, 0.1);
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul {
    left: auto;
    right: 5rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul a {
    padding: 6px 20px 6px 20px;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul a:before {
    content: none;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul a.has-arrow:after {
    right: 1.25rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul ul a {
    padding: 6px 20px 6px 20px;
    margin-left: -1.6px;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul ul a:before {
    content: none;
  }

  [data-sidebar-style="mini"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] .header {
    width: 1199px;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu {
    padding: 0 30px;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu>li>a {
    padding: 15px 20px;
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu>li>a {
    padding: 15px 20px;
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu>li>a svg {
    margin-left: 0;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu>li>a svg {
    margin-right: 0;
    margin-top: 0;
  }
}

@media only screen and (max-width: 1199px) {
  [data-sidebar-style="mini"] .deznav li.mm-active ul {
    height: auto !important;
  }

  [data-sidebar-style="mini"] .deznav li a.has-arrow::after {
    transform: rotate(-45deg) translateY(-50%);
  }
}

@media (min-width: 1199px) {
  [data-layout="horizontal"] .nav-header {
    width: 16rem;
  }

  [data-layout="horizontal"] .nav-header .nav-control {
    display: none;
  }

  [data-layout="horizontal"] .header {
    width: 100%;
    padding-left: 16rem;
  }

  [data-layout="horizontal"] .deznav {
    width: 100%;
    position: relative;
    height: auto;
    padding-bottom: 0;
    top: 0;
    z-index: 2;
  }

  [data-layout="horizontal"] .deznav .slimScrollDiv {
    overflow: visible !important;
  }

  [data-layout="horizontal"] .deznav .slimScrollDiv .deznav-scroll {
    overflow: visible !important;
  }

  [data-layout="horizontal"] .deznav .deznav-scroll {
    overflow: visible !important;
  }

  [data-layout="horizontal"] .deznav .slimScrollBar {
    display: none !important;
  }

  [data-layout="horizontal"] .deznav .nav-user,
  [data-layout="horizontal"] .deznav .nav-label {
    display: none;
  }

  [data-layout="horizontal"] .deznav .metismenu {
    flex-direction: row;
  }

  [data-layout="horizontal"] .deznav .metismenu .collapse.in {
    display: none;
  }

  [data-layout="horizontal"] .deznav .metismenu ul {
    border-left: 0;
  }

  [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu ul {
    box-shadow: 0px 10px 13px 0px rgba(0, 0, 0, 0.1);
  }

  [data-layout="horizontal"] .deznav .metismenu li {
    flex-direction: column;
    position: relative;
  }

  [data-layout="horizontal"] .deznav .metismenu li:hover>ul {
    display: block;
  }

  [data-layout="horizontal"] .deznav .metismenu li>ul {
    position: absolute;
    height: auto !important;
    top: 3.25rem;
    width: 100%;
    min-width: 13.75rem;
    z-index: 999;
    left: auto;
    right: auto;
    padding: 0.5rem 0;
    display: none;
    box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
    margin: 0;
    background: #fff;
  }

  [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li>ul {
    box-shadow: 0px 10px 13px 0px rgba(0, 0, 0, 0.1);
  }

  [data-layout="horizontal"] .deznav .metismenu li>ul li {
    padding: 0;
  }

  [data-layout="horizontal"] .deznav .metismenu li>ul li a {
    transition: all .4s ease-in-out;
    padding: 8px 20px 8px 45px;
    margin-left: -.1rem;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu li>ul li a {
    padding: 8px 45px 8px 20px;
    text-align: right;
  }

  [data-layout="horizontal"] .deznav .metismenu li>ul li a:hover {
    border-radius: .4rem;
  }

  [data-layout="horizontal"] .deznav .metismenu li>ul li a:before {
    left: 22px;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu li>ul li a:before {
    left: auto;
    right: 21px;
  }

  [data-layout="horizontal"] .deznav .metismenu li>ul ul {
    left: 100%;
    top: 0;
    box-shadow: 0px 10px 13px 0px rgba(82, 63, 105, 0.05);
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu li>ul ul {
    left: auto;
    right: 100%;
  }

  [data-layout="horizontal"] .deznav .metismenu>li {
    flex: 0 0 auto;
    position: relative;
  }

  [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li {
    border-color: rgba(255, 255, 255, 0.07);
  }

  [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li.mm-active {
    border-color: transparent;
  }

  [data-layout="horizontal"] .deznav .metismenu>li.mm-active {
    padding: 0;
  }

  [data-layout="horizontal"] .deznav .metismenu>li.mm-active>a {
    background: #fff;
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li:first-child {
    border-right: 0;
  }

  [data-theme-version="dark"][direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li {
    border-color: #333a54;
  }

  [data-layout="horizontal"] .deznav .metismenu>li>a {
    padding: 15px 40px 15px 15px;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li>a {
    padding: 15px 15px 15px 40px;
  }

  [data-layout="horizontal"] .deznav .metismenu>li>a i {
    padding: 0 0.4375rem 0 0;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li>a i {
    padding: 0 0 0 0.4375rem;
  }

  [data-layout="horizontal"] .deznav .metismenu>li>a .nav-badge {
    display: none;
  }

  [data-layout="horizontal"] .deznav .metismenu>li>a:after {
    right: 20px;
    transform: rotate(-135deg) translateY(-50%);
  }

  [data-layout="horizontal"] .deznav .metismenu>li:hover {
    border-color: transparent;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:hover>ul {
    display: flex !important;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto !important;
    box-shadow: 0px 10px 13px 0px rgba(82, 63, 105, 0.05);
  }

  [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li:hover>ul {
    box-shadow: 0px 0 13px 0px rgba(0, 0, 0, 0.1);
  }

  [data-layout="horizontal"] .deznav .metismenu>li>ul>li:hover ul.collapse {
    display: block !important;
    position: absolute;
    left: auto !important;
    right: -100% !important;
    top: 0 !important;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:nth-last-child(-n + 5)>ul {
    left: auto;
    right: 0;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:nth-last-child(-n + 5)>ul>li:hover ul.collapse {
    right: auto !important;
    left: -100% !important;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:last-child>ul ul {
    left: -100%;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li:nth-last-child(-n + 3)>ul {
    left: 0;
    right: auto;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li:nth-last-child(-n + 3)>ul>li:hover ul.collapse {
    right: -100% !important;
    left: auto !important;
  }

  [data-layout="horizontal"] .content-body {
    margin-left: 0;
  }

  [data-layout="horizontal"] .content-body .page-titles {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1.875rem;
  }

  [data-layout="horizontal"] .footer {
    margin-left: 0;
    margin: 0 auto;
    padding-left: 0;
  }

  [data-layout="horizontal"] .sidebar-right {
    z-index: 1;
  }

  [data-header-position="fixed"][data-layout="horizontal"] .deznav {
    top: 5rem;
  }

  [data-header-position="fixed"][data-sidebar-position="fixed"] .deznav {
    position: fixed;
  }

  [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"] .content-body {
    padding-top: 8.3rem;
  }

  [data-layout="horizontal"][data-container="boxed"] .footer {
    margin-left: 0;
    max-width: 1199px;
    margin: 0 auto;
  }

  [data-layout="horizontal"][data-container="wide"] .page-titles {
    margin-left: -30px;
    margin-right: -30px;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .page-titles {
    margin-top: 0;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu>li>ul {
    top: 4.315rem;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu>li>a {
    padding: 0.8125rem 2.1rem;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu>li>a::after {
    display: none;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu>li li {
    text-align: left;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .nav-header {
    width: 6rem;
    padding-left: 30px;
    padding-right: 30px;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .nav-header .brand-logo {
    justify-content: start;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .header {
    width: 100%;
    padding-left: 6rem;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .metismenu>li:hover a {
    width: auto;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .metismenu>li:hover a .nav-text {
    display: none;
  }

  [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="compact"] .content-body {
    padding-top: 8.75rem;
  }

  [data-sidebar-position="fixed"][data-layout="horizontal"] .deznav.fixed {
    position: fixed;
    left: 0;
    top: 0;
  }
}

@media (min-width: 767px) {
  [data-sidebar-style="compact"] .deznav .nav-user {
    display: none;
  }

  [data-sidebar-style="compact"] .deznav .metismenu>li>a {
    padding: 1.5rem 1.625rem 1.5rem 1.5rem;
  }

  [data-sidebar-style="compact"] .deznav .metismenu>li ul {
    padding: 1rem 0;
  }

  [data-sidebar-style="compact"] .deznav .metismenu>li ul li>a {
    padding: 0.5rem 1rem;
  }

  [data-sidebar-style="compact"] .deznav .metismenu>li ul li>a.has-arrow:after {
    content: "";
  }

  [data-sidebar-style="compact"] .deznav .metismenu li {
    text-align: center;
  }

  [data-sidebar-style="compact"] .deznav .metismenu li a svg {
    max-width: 21px;
    max-height: 21px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  [data-sidebar-style="compact"] .deznav .metismenu li a i {
    font-size: 2.2rem;
  }

  [data-sidebar-style="compact"] .deznav .metismenu li a:after {
    content: none;
  }

  [data-sidebar-style="compact"] .deznav .metismenu li a:before {
    content: "";
    height: 75px;
    width: 0;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translatey(-50%);
    background: #99c990;
    border-radius: 0 0.625rem 0.625rem 0;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
  }

  [data-sidebar-style="compact"] .deznav .metismenu li ul:after {
    content: none;
  }

  [data-sidebar-style="compact"] .deznav .metismenu li.mm-active>a,
  [data-sidebar-style="compact"] .deznav .metismenu li:hover>a {
    background: -moz-linear-gradient(left, #e0e0e4 0%, rgba(125, 185, 232, 0) 100%);
    background: -webkit-linear-gradient(left, #e0e0e4 0%, rgba(125, 185, 232, 0) 100%);
    background: linear-gradient(to right, #e0e0e4 0%, rgba(125, 185, 232, 0) 100%);
  }

  [data-sidebar-style="compact"] .deznav .metismenu li.mm-active>a:before,
  [data-sidebar-style="compact"] .deznav .metismenu li:hover>a:before {
    width: 10px;
  }

  [data-sidebar-style="compact"] .nav-text {
    display: inline-block;
    margin-top: 0.7125rem;
  }

  [data-sidebar-style="compact"] .nav-label.first {
    display: none;
  }

  [data-sidebar-style="compact"] .nav-badge {
    display: none;
  }

  [data-sidebar-style="compact"] .footer {
    padding-left: 9.375rem;
  }

  [data-sidebar-style="compact"] .content-body {
    margin-left: 15rem;
  }
}

[data-layout="horizontal"][data-sidebar-style="compact"] .footer {
  padding-left: 0;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .content-body {
  margin-left: 0;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu {
  padding: 0 30px;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav {
  width: 15rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul {
  background: rgba(0, 0, 0, 0.02);
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul {
  background: 255, 255, 255, 0.05;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul a:before {
  content: none;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul ul a {
  padding: 0.625rem 0.9375rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li>a.has-arrow::after {
  top: 1px;
  display: inline-block;
  right: auto;
  margin-left: 5px;
  position: relative;
  width: 7px;
  height: 7px;
  border-width: 2px 0px 0px 2px;
}

[direction="rtl"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li>a.has-arrow::after {
  left: auto;
  margin-left: 0;
  margin-right: 5px;
}

@media (min-width: 767px) {
  [data-sidebar-style="icon-hover"][data-layout="horizontal"] .metismenu {
    padding: 0 30px;
  }

  [data-sidebar-style="icon-hover"][data-layout="horizontal"] .header .header-content {
    padding-left: 1.875rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header {
    width: 5.063rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo {
    padding-left: 20px;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo .logo-abbr {
    display: block;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo .brand-title {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .nav-control {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .header {
    padding-left: 5.063rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .header {
    padding-right: 4.38rem;
    padding-left: 0.9375rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .header .header-content {
    padding-left: 1.375rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .header .header-content {
    padding-right: 1.375rem;
    padding-left: 0;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav {
    overflow: visible;
    position: absolute;
    left: -12.125rem;
  }
}

@media only screen and (min-width: 767px) and (min-width: 1200px) and (max-width: 1350px) {
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav {
    left: -10rem;
  }
}

@media (min-width: 767px) {
  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav {
    left: auto;
    right: -12.125rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .nav-label {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li>a {
    display: flex;
    justify-content: space-between;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li>a>svg,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li>a>i {
    order: 1;
    margin-right: 0;
    margin-top: 0;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
    border-left: 0;
    padding-left: 0;
    padding-right: 28px;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
    padding-right: 0;
    padding-left: 28px;
  }

  [data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
    border-color: #333a54;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul:after {
    left: auto;
    right: 28px;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul:after {
    left: 28px;
    right: auto;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul a {
    position: relative;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul a::before {
    left: auto;
    right: -5px;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul a::before {
    right: auto;
    left: -5px;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu .has-arrow::after {
    right: 5rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu .has-arrow::after {
    right: auto;
    left: 5rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .nav-header {
    width: 17.1875rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .nav-header .brand-logo {
    padding-left: 1.6rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .nav-header .brand-logo .brand-title {
    display: block;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .header {
    padding-left: 4.38rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .header {
    padding-right: 4.38rem;
    padding-left: 0.9375rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .deznav {
    left: 0;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .deznav {
    left: auto;
    right: 0;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .content-body {
    margin-left: 5rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .content-body {
    margin-left: 0;
    margin-right: 5rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .footer {
    padding-left: 4.375rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .footer {
    margin-left: 0;
    margin-right: 4.375rem;
  }
}

@media (min-width: 767px) {
  [data-sidebar-style="modern"] .nav-header {
    width: 9.375rem;
  }

  [data-sidebar-style="modern"] .nav-header .brand-logo {
    justify-content: center;
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li {
    text-align: center;
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li>a {
    padding: 20px 15px 20px 15px !important;
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li>a::after {
    display: none;
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li>a:hover>a,
  [data-sidebar-style="modern"] .deznav .metismenu>li>a:focus>a,
  [data-sidebar-style="modern"] .deznav .metismenu>li>a:active>a,
  [data-sidebar-style="modern"] .deznav .metismenu>li>a.mm-active>a {
    background-color: #d8e0f9;
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li.mm-active {
    padding: 0;
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li li {
    text-align: left;
  }

  [direction="rtl"][data-sidebar-style="modern"] .deznav .metismenu>li li {
    text-align: right;
  }

  [data-sidebar-style="modern"] .deznav .metismenu li a {
    padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
  }

  [data-sidebar-style="modern"] .deznav .metismenu li ul:after {
    content: none;
  }

  [data-sidebar-style="modern"] .deznav .metismenu li>ul {
    height: auto !important;
  }

  [data-sidebar-style="modern"] .deznav .metismenu .nav-label {
    display: none;
  }

  [data-sidebar-style="modern"] .deznav .nav-label {
    display: none;
  }

  [data-sidebar-style="modern"] .deznav .nav-text {
    display: block;
    margin-top: 0.3125rem;
  }

  [data-sidebar-style="modern"] .footer {
    padding-left: 9.375rem;
  }

  [data-sidebar-style="modern"] .content-body {
    margin-left: 9.375rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav {
    width: 9.375rem;
    left: 0;
  }

  [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .deznav {
    left: auto;
    right: 0;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .slimScrollDiv,
  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .deznav-scroll {
    overflow: visible !important;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu>li>a i {
    font-size: 20px;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu>li>ul {
    display: none;
    padding: 1.875rem 0.9375rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li {
    position: relative;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li a {
    padding: 0.625rem 1.5rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li a:before {
    content: none;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li ul {
    position: absolute;
    left: 105%;
    top: 0;
    bottom: auto;
    background-color: #fff;
    border: 1px solid #E6E6E6;
    width: 200px;
  }

  [data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li ul {
    background: #111217;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  }

  [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li ul {
    left: auto;
    right: 105%;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li:hover>ul {
    display: block;
    left: 100%;
    padding: 1rem 0;
    margin-left: -10px;
    border: 0;
    box-shadow: 5px 0px 13px 0px rgba(82, 63, 105, 0.05);
  }

  [data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li:hover>ul {
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  }

  [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li:hover>ul {
    left: auto;
    right: 100%;
    box-shadow: -5px 0px 13px 0px rgba(82, 63, 105, 0.05);
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .nav-label {
    display: none;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .nav-text {
    display: block;
    margin-top: 0;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .deznav {
    left: -9.375rem;
  }

  [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .deznav {
    left: auto;
    right: -9.375rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .content-body {
    margin-left: 0;
  }

  [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .content-body {
    margin-right: 0;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .footer,
  [data-sidebar-style="modern"][data-layout="horizontal"] .content-body {
    margin-left: 0;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .deznav .metismenu {
    padding: 0 30px;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .deznav .metismenu>li>a {
    padding: 0.8125rem 2.25rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .deznav .metismenu>li>ul {
    top: 4.5625rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"][data-container="boxed"] .deznav .metismenu>li>a {
    padding: 0.8125rem 1.25rem;
  }
}

[data-sidebar-style="overlay"] .deznav {
  left: -100%;
}

[direction="rtl"][data-sidebar-style="overlay"] .deznav {
  left: auto;
  right: -100%;
}

[data-sidebar-style="overlay"] .content-body {
  margin-left: 0;
}

[data-sidebar-style="overlay"] .nav-header {
  position: absolute;
}

[data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
  left: 0;
}

[data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #99c990;
}

[data-sidebar-style="overlay"] .menu-toggle .nav-header {
  position: absolute;
  left: auto;
}

[data-sidebar-style="overlay"] .menu-toggle .deznav {
  left: 0;
}

[direction="rtl"][data-sidebar-style="overlay"] .menu-toggle .deznav {
  left: auto;
  right: 0;
}

[data-sidebar-style="overlay"] .footer {
  padding-left: 0;
}

[data-sidebar-style="overlay"][data-header-position="fixed"] .nav-header {
  position: fixed;
}

[data-sidebar-position="fixed"][data-header-position="fixed"] .nav-header {
  position: fixed;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .nav-header {
  position: fixed;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .deznav {
  position: fixed;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .deznav .deznav-scroll {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .menu-toggle .deznav {
  position: fixed;
}

[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="overlay"][data-layout="vertical"][data-container="boxed"] .deznav {
  position: absolute;
}

.sidebar-right {
  right: -15.625rem;
  position: fixed;
  top: 0;
  width: 15.625rem;
  background-color: #fff;
  height: calc(100% - 7.0625rem);
  margin-top: 5rem;
  transition: all .5s ease-in-out;
  padding-bottom: 1.875rem;
  box-shadow: -2px 3px 10px 0px rgba(119, 119, 119, 0.1);
}

[direction="rtl"] .sidebar-right .slimScrollDiv {
  overflow: visible !important;
}

.sidebar-right .sidebar-right-trigger {
  position: absolute;
  z-index: 9;
  top: 4.75rem;
  right: 100%;
  background-color: #fff;
  color: #99c990;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  text-align: center;
  font-size: 1.75rem;
  line-height: 3rem;
  border-radius: 5px 0 0 5px;
  box-shadow: -5px 3px 5px 0px rgba(119, 119, 119, 0.15);
}

[data-theme-version="dark"] .sidebar-right .sidebar-right-trigger {
  background-color: #31343b;
  color: #fff;
}

.sidebar-right .sidebar-right-trigger:hover {
  color: #99c990;
}

.sidebar-right.show {
  right: 0;
  z-index: 999;
}

.sidebar-right .nav-tabs {
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
}

[data-theme-version="dark"] .sidebar-right .nav-tabs {
  background-color: #222327;
}

.sidebar-right .nav-tabs .nav-item {
  margin-bottom: 0;
  flex: 1;
}

.sidebar-right .nav-tabs .nav-item .nav-link {
  border: 0;
  font-size: 1.125rem;
  position: relative;
  text-align: center;
  background-color: #fff;
}

.sidebar-right .nav-tabs .nav-item .nav-link::after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  content: "";
  background: transparent;
}

[data-theme-version="dark"] .sidebar-right .nav-tabs .nav-item .nav-link {
  background-color: #222327;
}

[data-theme-version="dark"] .sidebar-right .nav-tabs .nav-item .nav-link.active {
  border-right: none;
  border-left: none;
  border-top: none;
}

.sidebar-right .tab-content {
  padding: 1.25rem;
}

.sidebar-right .tab-content .tab-pane .admin-settings>div {
  margin-bottom: 10px;
}

.sidebar-right .tab-content .tab-pane .admin-settings p {
  margin-bottom: 0.125rem;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"] {
  display: none;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]+label {
  display: inline-block;
  width: 25px;
  height: 25px;
  cursor: pointer;
  transition: all .1s ease;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 3px;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]:checked+label {
  position: relative;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]:checked+label::after {
  height: 33px;
  width: 33px;
  left: -4px;
  top: -4px;
  content: "";
  position: absolute;
  background-color: inherit;
  border-radius: 6px;
  opacity: 0.4;
}

.sidebar-right #nav_header_color_1+label,
.sidebar-right #header_color_1+label,
.sidebar-right #sidebar_color_1+label,
.sidebar-right #primary_color_1+label {
  background-color: #fff;
}

.sidebar-right #nav_header_color_2+label,
.sidebar-right #header_color_2+label,
.sidebar-right #sidebar_color_2+label,
.sidebar-right #primary_color_2+label {
  background-color: #143b64;
}

.sidebar-right #nav_header_color_3+label,
.sidebar-right #header_color_3+label,
.sidebar-right #sidebar_color_3+label,
.sidebar-right #primary_color_3+label {
  background-color: #99c990;
}

.sidebar-right #nav_header_color_4+label,
.sidebar-right #header_color_4+label,
.sidebar-right #sidebar_color_4+label,
.sidebar-right #primary_color_4+label {
  background-color: #4527a0;
}

.sidebar-right #nav_header_color_5+label,
.sidebar-right #header_color_5+label,
.sidebar-right #sidebar_color_5+label,
.sidebar-right #primary_color_5+label {
  background-color: #c62828;
}

.sidebar-right #nav_header_color_6+label,
.sidebar-right #header_color_6+label,
.sidebar-right #sidebar_color_6+label,
.sidebar-right #primary_color_6+label {
  background-color: #283593;
}

.sidebar-right #nav_header_color_7+label,
.sidebar-right #header_color_7+label,
.sidebar-right #sidebar_color_7+label,
.sidebar-right #primary_color_7+label {
  background-color: #7356f1;
}

.sidebar-right #nav_header_color_8+label,
.sidebar-right #header_color_8+label,
.sidebar-right #sidebar_color_8+label,
.sidebar-right #primary_color_8+label {
  background-color: #3695eb;
}

.sidebar-right #nav_header_color_9+label,
.sidebar-right #header_color_9+label,
.sidebar-right #sidebar_color_9+label,
.sidebar-right #primary_color_9+label {
  background-color: #00838f;
}

.sidebar-right #nav_header_color_10+label,
.sidebar-right #header_color_10+label,
.sidebar-right #sidebar_color_10+label,
.sidebar-right #primary_color_10+label {
  background-color: #ff8f16;
}

.sidebar-right #nav_header_color_11+label,
.sidebar-right #header_color_11+label,
.sidebar-right #sidebar_color_11+label,
.sidebar-right #primary_color_11+label {
  background-color: #6673fd;
}

.sidebar-right #nav_header_color_12+label,
.sidebar-right #header_color_12+label,
.sidebar-right #sidebar_color_12+label,
.sidebar-right #primary_color_12+label {
  background-color: #558b2f;
}

.sidebar-right #nav_header_color_13+label,
.sidebar-right #header_color_13+label,
.sidebar-right #sidebar_color_13+label,
.sidebar-right #primary_color_13+label {
  background-color: #2a2a2a;
}

.sidebar-right #nav_header_color_14+label,
.sidebar-right #header_color_14+label,
.sidebar-right #sidebar_color_14+label,
.sidebar-right #primary_color_14+label {
  background-color: #1367c8;
}

.sidebar-right #nav_header_color_15+label,
.sidebar-right #header_color_15+label,
.sidebar-right #sidebar_color_15+label,
.sidebar-right #primary_color_15+label {
  background-color: #ed0b4c;
}

.sidebar-right #nav_header_color_1+label,
.sidebar-right #header_color_1+label,
.sidebar-right #sidebar_color_1+label,
.sidebar-right #primary_color_1+label {
  border: 1px solid #89879f;
}

@keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }

  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%);
  }

  100% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }
}

@-webkit-keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }

  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%);
  }

  100% {
    transform: translateY(-8%);
    -webkit-transform: translateY(-8%);
  }
}

.nav-user {
  background: #99c990;
  margin-bottom: 10px;
  padding: 20px 25px 15px;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .nav-user {
    padding: 20px 15px 15px;
  }
}

.nav-user img {
  width: 35px;
  height: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .nav-user img {
    width: 35px;
    height: 35px;
    margin-bottom: 10px;
  }
}

.nav-user h5,
.nav-user .h5 {
  margin-left: 10px;
  margin-bottom: 3px;
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {

  .nav-user h5,
  .nav-user .h5 {
    display: none;
  }
}

[data-sibebarbg="color_2"] .nav-user h5,
[data-sibebarbg="color_2"] .nav-user .h5 {
  color: #fff;
}

.nav-user p {
  margin-left: 10px;
  margin-bottom: 8px;
  color: #afcff7;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .nav-user p {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .nav-user i {
    margin-top: 15px;
    display: block;
  }
}

.menu-toggle .nav-user {
  padding: 20px 15px 15px;
}

.menu-toggle .nav-user img {
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
}

.menu-toggle .nav-user h5,
.menu-toggle .nav-user .h5,
.menu-toggle .nav-user p {
  display: none;
}

.menu-toggle .nav-user i {
  margin-top: 15px;
  display: block;
}

.menu-toggle .nav-user .dropdown-menu {
  left: 45px !important;
  top: 22px !important;
}

.chatbox {
  width: 340px;
  height: 100vh;
  position: fixed;
  right: -500px;
  top: 0;
  z-index: 999;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.15);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

[data-theme-version="dark"] .chatbox {
  background: #111217;
}

.chatbox .chatbox-close {
  position: absolute;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  width: 0;
  height: 100%;
  right: 340px;
  background: #000;
  z-index: 1;
  opacity: 0.1;
  cursor: pointer;
}

.chatbox .card-fotter {
  padding: 0.75rem 1rem;
}

.chatbox .card-body {
  padding: 1rem;
}

.chatbox.active {
  right: 0;
}

.chatbox.active .chatbox-close {
  width: 100vw;
}

.chatbox .type_msg {
  padding-top: 10px;
}

.chatbox .nav {
  padding: 1rem 1rem 0 1rem;
  background: #99c990;
  border: 0;
  justify-content: space-between;
}

.chatbox .nav .nav-link {
  color: #fff;
  opacity: 0.7;
  text-transform: uppercase;
}

.chatbox .nav .nav-link:hover,
.chatbox .nav .nav-link.active {
  background: transparent;
  color: #fff;
  opacity: 1;
  border-color: #fff;
}

.chatbox .img_cont {
  width: 40px;
  border-radius: 40px;
  margin-right: 10px;
  position: relative;
  height: 40px;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  min-width: 40px;
  min-height: 40px;
}

.chatbox .img_cont .icon {
  color: #fff;
  display: none;
}

.chatbox .img_cont.primary {
  background: #d8e0f9;
  color: #99c990;
}

[data-theme-version="dark"] .chatbox .img_cont.primary {
  background: rgba(30, 63, 180, 0.2);
}

.chatbox .img_cont.warning {
  background: #fff5dd;
  color: #FFBC11;
}

[data-theme-version="dark"] .chatbox .img_cont.warning {
  background: rgba(255, 188, 17, 0.1);
}

.chatbox .img_cont.success {
  background: #e3f9e9;
  color: #2BC155;
}

[data-theme-version="dark"] .chatbox .img_cont.success {
  background: rgba(43, 193, 85, 0.1);
}

.chatbox .img_cont.info {
  background: #a4e3f1;
  color: #1EA7C5;
}

[data-theme-version="dark"] .chatbox .img_cont.info {
  background: rgba(30, 167, 197, 0.1);
}

.chatbox .img_cont img {
  width: 100%;
}

.chatbox .img_cont .online_icon {
  background: #2BC155;
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 15px;
  right: -1px;
  bottom: 0px;
  border: 2px solid #fff;
}

.chatbox .img_cont .online_icon.offline {
  background: #F94687;
}

.chatbox .card {
  box-shadow: none;
}

.chatbox .search {
  height: 40px;
}

.chatbox .user_info span {
  font-size: 15px;
  color: #000;
  display: block;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  max-width: 170px;
}

[data-theme-version="dark"] .chatbox .user_info span {
  color: #fff;
}

.chatbox .user_info p {
  font-size: 13px;
  margin-bottom: 0;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  max-width: 170px;
}

.chatbox .contacts li {
  padding: 7px 1rem;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

[data-theme-version="dark"] .chatbox .contacts li {
  border-color: #333a54;
}

.chatbox .contacts li>div {
  display: flex;
  align-items: center;
}

.chatbox .contacts li:hover {
  background: #f4f7ff;
}

[data-theme-version="dark"] .chatbox .contacts li:hover {
  background-color: #222327;
}

.chatbox .contacts .name-first-letter {
  background: #fff;
  padding: 4px 1rem;
  font-weight: 700;
  color: #000;
  position: sticky;
  top: 0;
  z-index: 1;
}

[data-theme-version="dark"] .chatbox .contacts .name-first-letter {
  color: #fff;
  background: #222327;
}

.chatbox .msg_card_body {
  height: calc(100vh - 195px);
}

.chatbox .contacts_body {
  height: calc(100vh - 120px);
}

.chatbox .card-header {
  background: #f4f7ff;
  padding: 15px 20px;
  justify-content: center;
}

.chatbox .card-header h6,
.chatbox .card-header .h6 {
  font-size: 15px;
}

.chatbox .card-header p {
  line-height: 1.2;
  font-size: 12px;
  color: #7e7e7e;
}

.chatbox .chat-list-header {
  justify-content: space-between;
  background: #fff;
}

[data-theme-version="dark"] .chatbox .chat-list-header {
  background: #111217;
}

.chatbox .chat-list-header a {
  text-align: center;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 6px;
  line-height: 30px;
  display: block;
}

[data-theme-version="dark"] .chatbox .chat-list-header a {
  background: rgba(30, 63, 180, 0.2);
}

[data-theme-version="dark"] .chatbox .chat-list-header a svg g [fill] {
  fill: #fff;
}

.chatbox .img_cont_msg {
  width: 30px;
  height: 30px;
  display: block;
  max-width: 30px;
  min-width: 30px;
}

.chatbox .img_cont_msg img {
  width: 100%;
}

.chatbox .msg_cotainer {
  background: #99c990;
  margin-left: 10px;
  border-radius: 0 0.625rem 0.625rem 0.625rem;
  padding: 10px 15px;
  color: #fff;
  position: relative;
}

.chatbox .msg_cotainer .msg_time {
  display: block;
  font-size: 11px;
  color: #fff;
  margin-top: 5px;
  opacity: 0.5;
}

.chatbox .msg_cotainer:after {
  content: "";
  position: absolute;
  left: -10px;
  border-right: 10px solid #99c990;
  border-bottom: 10px solid transparent;
  border-top: 0px solid;
  top: 0;
}

.chatbox .msg_cotainer_send {
  background: #f9f9f9;
  padding: 10px 15px;
  border-radius: 6px 0px 6px 6px;
  margin-right: 10px;
  color: #222;
  position: relative;
  text-align: left;
}

[data-theme-version="dark"] .chatbox .msg_cotainer_send {
  background: #222327;
  color: #fff;
}

.chatbox .msg_cotainer_send .msg_time_send {
  display: block;
  font-size: 11px;
  text-align: right;
  margin-top: 5px;
  opacity: 0.6;
}

.chatbox .msg_cotainer_send:after {
  content: "";
  position: absolute;
  right: -10px;
  border-left: 10px solid #fff;
  border-bottom: 10px solid transparent;
  border-top: 0px solid;
  top: 0;
}

[data-theme-version="dark"] .chatbox .msg_cotainer_send:after {
  border-left: 10px solid #222327;
}

.chatbox .type_msg .form-control {
  padding: 10px 0;
  height: 50px;
  border: 0;
  resize: none;
}

.chatbox .type_msg .btn {
  font-size: 18px;
  border-radius: 38px !important;
  width: 38px;
  height: 38px;
  padding: 0;
  margin-top: 6px;
}

.chatbox .video_cam {
  margin-left: 15px;
}

.chatbox .video_cam span {
  width: 35px;
  height: 35px;
  background: #10ca93;
  text-align: center;
  line-height: 35px;
  border-radius: 35px;
  color: #fff;
  margin-right: 5px;
  align-self: center;
  font-size: 16px;
  padding: 0 3px;
  display: inline-block;
}

.chatbox .note_card .contacts li {
  padding: 12px 1rem;
}

@media only screen and (max-width: 576px) {
  .chatbox {
    width: 280px;
  }

  .chatbox .chatbox-close {
    right: 280px;
  }
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_2"] .nav-header {
  background-color: #143b64;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .brand-logo,
[data-nav-headerbg="color_2"] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .brand-logo:hover,
[data-nav-headerbg="color_2"] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_2"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_2"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_3"] .nav-header {
  background-color: #99c990;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .brand-logo,
[data-nav-headerbg="color_3"] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .brand-logo:hover,
[data-nav-headerbg="color_3"] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_3"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_3"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_4"] .nav-header {
  background-color: #4527a0;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .brand-logo,
[data-nav-headerbg="color_4"] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .brand-logo:hover,
[data-nav-headerbg="color_4"] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_4"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_4"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_5"] .nav-header {
  background-color: #c62828;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .brand-logo,
[data-nav-headerbg="color_5"] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .brand-logo:hover,
[data-nav-headerbg="color_5"] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_5"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_5"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_6"] .nav-header {
  background-color: #283593;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .brand-logo,
[data-nav-headerbg="color_6"] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .brand-logo:hover,
[data-nav-headerbg="color_6"] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_6"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_6"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_7"] .nav-header {
  background-color: #7356f1;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .brand-logo,
[data-nav-headerbg="color_7"] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .brand-logo:hover,
[data-nav-headerbg="color_7"] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_7"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_7"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_8"] .nav-header {
  background-color: #3695eb;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .brand-logo,
[data-nav-headerbg="color_8"] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .brand-logo:hover,
[data-nav-headerbg="color_8"] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_8"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_8"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_9"] .nav-header {
  background-color: #00838f;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .brand-logo,
[data-nav-headerbg="color_9"] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .brand-logo:hover,
[data-nav-headerbg="color_9"] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_9"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_9"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_10"] .nav-header {
  background-color: #ff8f16;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .brand-logo,
[data-nav-headerbg="color_10"] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .brand-logo:hover,
[data-nav-headerbg="color_10"] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_10"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_10"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_11"] .nav-header {
  background-color: #6673fd;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .brand-logo,
[data-nav-headerbg="color_11"] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .brand-logo:hover,
[data-nav-headerbg="color_11"] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_11"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_11"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_12"] .nav-header {
  background-color: #558b2f;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .brand-logo,
[data-nav-headerbg="color_12"] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .brand-logo:hover,
[data-nav-headerbg="color_12"] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_12"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_12"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_13"] .nav-header {
  background-color: #2a2a2a;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .brand-logo,
[data-nav-headerbg="color_13"] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .brand-logo:hover,
[data-nav-headerbg="color_13"] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_13"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_13"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_14"] .nav-header {
  background-color: #1367c8;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .nav-header .brand-logo,
[data-nav-headerbg="color_14"] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .nav-header .brand-logo:hover,
[data-nav-headerbg="color_14"] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_14"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_14"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_15"] .nav-header {
  background-color: #ed0b4c;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .nav-header .brand-logo,
[data-nav-headerbg="color_15"] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .nav-header .brand-logo:hover,
[data-nav-headerbg="color_15"] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_15"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_15"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header,
[data-headerbg="color_2"] .header {
  background-color: #143b64;
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_2"] .header-left input {
  border-color: #1d548f;
  background-color: #1d548f !important;
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-left input::placeholder,
[data-headerbg="color_2"] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-left input:focus,
[data-headerbg="color_2"] .header-left input:focus {
  border-color: #0c223a;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_2"] .header-left .search_icon {
  background-color: #1d548f !important;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-left .search_icon i,
[data-headerbg="color_2"] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_2"] .header-left .btn {
  background-color: #0c223a;
  border-color: #0c223a;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-left .btn:hover,
[data-headerbg="color_2"][data-theme-version="dark"] .header-left .btn:focus,
[data-headerbg="color_2"][data-theme-version="dark"] .header-left .btn:active,
[data-headerbg="color_2"] .header-left .btn:hover,
[data-headerbg="color_2"] .header-left .btn:focus,
[data-headerbg="color_2"] .header-left .btn:active {
  background-color: #03090f;
  border-color: #03090f;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .dropdown>a,
[data-headerbg="color_2"] .header-right .dropdown>a {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_2"] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .pulse-css:after,
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .pulse-css:before,
[data-headerbg="color_2"] .header-right .pulse-css:after,
[data-headerbg="color_2"] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_2"] .header-right .notification_dropdown .nav-link {
  background-color: #1d548f !important;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_2"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info strong,
[data-headerbg="color_2"] .header-right .header-profile>a.nav-link .header-info strong {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_2"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_2"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_2"] .header-right .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg="color_2"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_2"] .nav-control {
  background-color: #1d548f;
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header,
[data-headerbg="color_3"] .header {
  background-color: #99c990;
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_3"] .header-left input {
  border-color: #2a51db;
  background-color: #2a51db !important;
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-left input::placeholder,
[data-headerbg="color_3"] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-left input:focus,
[data-headerbg="color_3"] .header-left input:focus {
  border-color: #173088;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_3"] .header-left .search_icon {
  background-color: #2a51db !important;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-left .search_icon i,
[data-headerbg="color_3"] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_3"] .header-left .btn {
  background-color: #173088;
  border-color: #173088;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-left .btn:hover,
[data-headerbg="color_3"][data-theme-version="dark"] .header-left .btn:focus,
[data-headerbg="color_3"][data-theme-version="dark"] .header-left .btn:active,
[data-headerbg="color_3"] .header-left .btn:hover,
[data-headerbg="color_3"] .header-left .btn:focus,
[data-headerbg="color_3"] .header-left .btn:active {
  background-color: #0f205d;
  border-color: #0f205d;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .dropdown>a,
[data-headerbg="color_3"] .header-right .dropdown>a {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_3"] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .pulse-css:after,
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .pulse-css:before,
[data-headerbg="color_3"] .header-right .pulse-css:after,
[data-headerbg="color_3"] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_3"] .header-right .notification_dropdown .nav-link {
  background-color: #2a51db !important;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_3"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info strong,
[data-headerbg="color_3"] .header-right .header-profile>a.nav-link .header-info strong {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_3"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_3"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_3"] .header-right .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg="color_3"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_3"] .nav-control {
  background-color: #2a51db;
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header,
[data-headerbg="color_4"] .header {
  background-color: #4527a0;
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_4"] .header-left input {
  border-color: #5731c9;
  background-color: #5731c9 !important;
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-left input::placeholder,
[data-headerbg="color_4"] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-left input:focus,
[data-headerbg="color_4"] .header-left input:focus {
  border-color: #331d77;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_4"] .header-left .search_icon {
  background-color: #5731c9 !important;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-left .search_icon i,
[data-headerbg="color_4"] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_4"] .header-left .btn {
  background-color: #331d77;
  border-color: #331d77;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-left .btn:hover,
[data-headerbg="color_4"][data-theme-version="dark"] .header-left .btn:focus,
[data-headerbg="color_4"][data-theme-version="dark"] .header-left .btn:active,
[data-headerbg="color_4"] .header-left .btn:hover,
[data-headerbg="color_4"] .header-left .btn:focus,
[data-headerbg="color_4"] .header-left .btn:active {
  background-color: #22134e;
  border-color: #22134e;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .dropdown>a,
[data-headerbg="color_4"] .header-right .dropdown>a {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_4"] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .pulse-css:after,
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .pulse-css:before,
[data-headerbg="color_4"] .header-right .pulse-css:after,
[data-headerbg="color_4"] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_4"] .header-right .notification_dropdown .nav-link {
  background-color: #5731c9 !important;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_4"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info strong,
[data-headerbg="color_4"] .header-right .header-profile>a.nav-link .header-info strong {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_4"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_4"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_4"] .header-right .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg="color_4"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_4"] .nav-control {
  background-color: #5731c9;
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header,
[data-headerbg="color_5"] .header {
  background-color: #c62828;
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_5"] .header-left input {
  border-color: #da4747;
  background-color: #da4747 !important;
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-left input::placeholder,
[data-headerbg="color_5"] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-left input:focus,
[data-headerbg="color_5"] .header-left input:focus {
  border-color: #9c1f1f;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_5"] .header-left .search_icon {
  background-color: #da4747 !important;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-left .search_icon i,
[data-headerbg="color_5"] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_5"] .header-left .btn {
  background-color: #9c1f1f;
  border-color: #9c1f1f;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-left .btn:hover,
[data-headerbg="color_5"][data-theme-version="dark"] .header-left .btn:focus,
[data-headerbg="color_5"][data-theme-version="dark"] .header-left .btn:active,
[data-headerbg="color_5"] .header-left .btn:hover,
[data-headerbg="color_5"] .header-left .btn:focus,
[data-headerbg="color_5"] .header-left .btn:active {
  background-color: #711717;
  border-color: #711717;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .dropdown>a,
[data-headerbg="color_5"] .header-right .dropdown>a {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_5"] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .pulse-css:after,
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .pulse-css:before,
[data-headerbg="color_5"] .header-right .pulse-css:after,
[data-headerbg="color_5"] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_5"] .header-right .notification_dropdown .nav-link {
  background-color: #da4747 !important;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_5"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info strong,
[data-headerbg="color_5"] .header-right .header-profile>a.nav-link .header-info strong {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_5"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_5"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_5"] .header-right .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg="color_5"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_5"] .nav-control {
  background-color: #da4747;
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header,
[data-headerbg="color_6"] .header {
  background-color: #283593;
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_6"] .header-left input {
  border-color: #3343bb;
  background-color: #3343bb !important;
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-left input::placeholder,
[data-headerbg="color_6"] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-left input:focus,
[data-headerbg="color_6"] .header-left input:focus {
  border-color: #1d276b;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_6"] .header-left .search_icon {
  background-color: #3343bb !important;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-left .search_icon i,
[data-headerbg="color_6"] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_6"] .header-left .btn {
  background-color: #1d276b;
  border-color: #1d276b;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-left .btn:hover,
[data-headerbg="color_6"][data-theme-version="dark"] .header-left .btn:focus,
[data-headerbg="color_6"][data-theme-version="dark"] .header-left .btn:active,
[data-headerbg="color_6"] .header-left .btn:hover,
[data-headerbg="color_6"] .header-left .btn:focus,
[data-headerbg="color_6"] .header-left .btn:active {
  background-color: #121843;
  border-color: #121843;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .dropdown>a,
[data-headerbg="color_6"] .header-right .dropdown>a {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_6"] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .pulse-css:after,
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .pulse-css:before,
[data-headerbg="color_6"] .header-right .pulse-css:after,
[data-headerbg="color_6"] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_6"] .header-right .notification_dropdown .nav-link {
  background-color: #3343bb !important;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_6"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info strong,
[data-headerbg="color_6"] .header-right .header-profile>a.nav-link .header-info strong {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_6"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_6"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_6"] .header-right .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg="color_6"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_6"] .nav-control {
  background-color: #3343bb;
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header,
[data-headerbg="color_7"] .header {
  background-color: #7356f1;
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_7"] .header-left input {
  border-color: #9a85f5;
  background-color: #9a85f5 !important;
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-left input::placeholder,
[data-headerbg="color_7"] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-left input:focus,
[data-headerbg="color_7"] .header-left input:focus {
  border-color: #4c27ed;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_7"] .header-left .search_icon {
  background-color: #9a85f5 !important;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-left .search_icon i,
[data-headerbg="color_7"] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_7"] .header-left .btn {
  background-color: #4c27ed;
  border-color: #4c27ed;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-left .btn:hover,
[data-headerbg="color_7"][data-theme-version="dark"] .header-left .btn:focus,
[data-headerbg="color_7"][data-theme-version="dark"] .header-left .btn:active,
[data-headerbg="color_7"] .header-left .btn:hover,
[data-headerbg="color_7"] .header-left .btn:focus,
[data-headerbg="color_7"] .header-left .btn:active {
  background-color: #3511d0;
  border-color: #3511d0;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .dropdown>a,
[data-headerbg="color_7"] .header-right .dropdown>a {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_7"] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .pulse-css:after,
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .pulse-css:before,
[data-headerbg="color_7"] .header-right .pulse-css:after,
[data-headerbg="color_7"] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_7"] .header-right .notification_dropdown .nav-link {
  background-color: #9a85f5 !important;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_7"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info strong,
[data-headerbg="color_7"] .header-right .header-profile>a.nav-link .header-info strong {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_7"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_7"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_7"] .header-right .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg="color_7"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_7"] .nav-control {
  background-color: #9a85f5;
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header,
[data-headerbg="color_8"] .header {
  background-color: #3695eb;
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_8"] .header-left input {
  border-color: #64adf0;
  background-color: #64adf0 !important;
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-left input::placeholder,
[data-headerbg="color_8"] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-left input:focus,
[data-headerbg="color_8"] .header-left input:focus {
  border-color: #167cd8;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_8"] .header-left .search_icon {
  background-color: #64adf0 !important;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-left .search_icon i,
[data-headerbg="color_8"] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_8"] .header-left .btn {
  background-color: #167cd8;
  border-color: #167cd8;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-left .btn:hover,
[data-headerbg="color_8"][data-theme-version="dark"] .header-left .btn:focus,
[data-headerbg="color_8"][data-theme-version="dark"] .header-left .btn:active,
[data-headerbg="color_8"] .header-left .btn:hover,
[data-headerbg="color_8"] .header-left .btn:focus,
[data-headerbg="color_8"] .header-left .btn:active {
  background-color: #1161aa;
  border-color: #1161aa;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .dropdown>a,
[data-headerbg="color_8"] .header-right .dropdown>a {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_8"] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .pulse-css:after,
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .pulse-css:before,
[data-headerbg="color_8"] .header-right .pulse-css:after,
[data-headerbg="color_8"] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_8"] .header-right .notification_dropdown .nav-link {
  background-color: #64adf0 !important;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_8"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info strong,
[data-headerbg="color_8"] .header-right .header-profile>a.nav-link .header-info strong {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_8"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_8"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_8"] .header-right .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg="color_8"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_8"] .nav-control {
  background-color: #64adf0;
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header,
[data-headerbg="color_9"] .header {
  background-color: #00838f;
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_9"] .header-left input {
  border-color: #00b2c2;
  background-color: #00b2c2 !important;
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-left input::placeholder,
[data-headerbg="color_9"] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-left input:focus,
[data-headerbg="color_9"] .header-left input:focus {
  border-color: #00545c;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_9"] .header-left .search_icon {
  background-color: #00b2c2 !important;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-left .search_icon i,
[data-headerbg="color_9"] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_9"] .header-left .btn {
  background-color: #00545c;
  border-color: #00545c;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-left .btn:hover,
[data-headerbg="color_9"][data-theme-version="dark"] .header-left .btn:focus,
[data-headerbg="color_9"][data-theme-version="dark"] .header-left .btn:active,
[data-headerbg="color_9"] .header-left .btn:hover,
[data-headerbg="color_9"] .header-left .btn:focus,
[data-headerbg="color_9"] .header-left .btn:active {
  background-color: #002629;
  border-color: #002629;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .dropdown>a,
[data-headerbg="color_9"] .header-right .dropdown>a {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_9"] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .pulse-css:after,
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .pulse-css:before,
[data-headerbg="color_9"] .header-right .pulse-css:after,
[data-headerbg="color_9"] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_9"] .header-right .notification_dropdown .nav-link {
  background-color: #00b2c2 !important;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_9"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info strong,
[data-headerbg="color_9"] .header-right .header-profile>a.nav-link .header-info strong {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_9"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_9"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_9"] .header-right .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg="color_9"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_9"] .nav-control {
  background-color: #00b2c2;
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header,
[data-headerbg="color_10"] .header {
  background-color: #ff8f16;
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_10"] .header-left input {
  border-color: #ffa849;
  background-color: #ffa849 !important;
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-left input::placeholder,
[data-headerbg="color_10"] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-left input:focus,
[data-headerbg="color_10"] .header-left input:focus {
  border-color: #e27500;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_10"] .header-left .search_icon {
  background-color: #ffa849 !important;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-left .search_icon i,
[data-headerbg="color_10"] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_10"] .header-left .btn {
  background-color: #e27500;
  border-color: #e27500;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-left .btn:hover,
[data-headerbg="color_10"][data-theme-version="dark"] .header-left .btn:focus,
[data-headerbg="color_10"][data-theme-version="dark"] .header-left .btn:active,
[data-headerbg="color_10"] .header-left .btn:hover,
[data-headerbg="color_10"] .header-left .btn:focus,
[data-headerbg="color_10"] .header-left .btn:active {
  background-color: #af5b00;
  border-color: #af5b00;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .dropdown>a,
[data-headerbg="color_10"] .header-right .dropdown>a {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_10"] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .pulse-css:after,
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .pulse-css:before,
[data-headerbg="color_10"] .header-right .pulse-css:after,
[data-headerbg="color_10"] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_10"] .header-right .notification_dropdown .nav-link {
  background-color: #ffa849 !important;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_10"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info strong,
[data-headerbg="color_10"] .header-right .header-profile>a.nav-link .header-info strong {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_10"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_10"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_10"] .header-right .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg="color_10"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_10"] .nav-control {
  background-color: #ffa849;
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header,
[data-headerbg="color_11"] .header {
  background-color: #6673fd;
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_11"] .header-left input {
  border-color: #98a1fe;
  background-color: #98a1fe !important;
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-left input::placeholder,
[data-headerbg="color_11"] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-left input:focus,
[data-headerbg="color_11"] .header-left input:focus {
  border-color: #3445fc;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_11"] .header-left .search_icon {
  background-color: #98a1fe !important;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-left .search_icon i,
[data-headerbg="color_11"] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_11"] .header-left .btn {
  background-color: #3445fc;
  border-color: #3445fc;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-left .btn:hover,
[data-headerbg="color_11"][data-theme-version="dark"] .header-left .btn:focus,
[data-headerbg="color_11"][data-theme-version="dark"] .header-left .btn:active,
[data-headerbg="color_11"] .header-left .btn:hover,
[data-headerbg="color_11"] .header-left .btn:focus,
[data-headerbg="color_11"] .header-left .btn:active {
  background-color: #0318fa;
  border-color: #0318fa;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .dropdown>a,
[data-headerbg="color_11"] .header-right .dropdown>a {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_11"] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .pulse-css:after,
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .pulse-css:before,
[data-headerbg="color_11"] .header-right .pulse-css:after,
[data-headerbg="color_11"] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_11"] .header-right .notification_dropdown .nav-link {
  background-color: #98a1fe !important;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_11"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info strong,
[data-headerbg="color_11"] .header-right .header-profile>a.nav-link .header-info strong {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_11"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_11"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_11"] .header-right .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg="color_11"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_11"] .nav-control {
  background-color: #98a1fe;
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header,
[data-headerbg="color_12"] .header {
  background-color: #558b2f;
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_12"] .header-left input {
  border-color: #6cb13c;
  background-color: #6cb13c !important;
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-left input::placeholder,
[data-headerbg="color_12"] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-left input:focus,
[data-headerbg="color_12"] .header-left input:focus {
  border-color: #3e6522;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_12"] .header-left .search_icon {
  background-color: #6cb13c !important;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-left .search_icon i,
[data-headerbg="color_12"] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_12"] .header-left .btn {
  background-color: #3e6522;
  border-color: #3e6522;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-left .btn:hover,
[data-headerbg="color_12"][data-theme-version="dark"] .header-left .btn:focus,
[data-headerbg="color_12"][data-theme-version="dark"] .header-left .btn:active,
[data-headerbg="color_12"] .header-left .btn:hover,
[data-headerbg="color_12"] .header-left .btn:focus,
[data-headerbg="color_12"] .header-left .btn:active {
  background-color: #263f15;
  border-color: #263f15;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .dropdown>a,
[data-headerbg="color_12"] .header-right .dropdown>a {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_12"] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .pulse-css:after,
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .pulse-css:before,
[data-headerbg="color_12"] .header-right .pulse-css:after,
[data-headerbg="color_12"] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_12"] .header-right .notification_dropdown .nav-link {
  background-color: #6cb13c !important;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_12"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info strong,
[data-headerbg="color_12"] .header-right .header-profile>a.nav-link .header-info strong {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_12"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_12"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_12"] .header-right .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg="color_12"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_12"] .nav-control {
  background-color: #6cb13c;
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header,
[data-headerbg="color_13"] .header {
  background-color: #2a2a2a;
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_13"] .header-left input {
  border-color: #444444;
  background-color: #444444 !important;
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-left input::placeholder,
[data-headerbg="color_13"] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-left input:focus,
[data-headerbg="color_13"] .header-left input:focus {
  border-color: #111111;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_13"] .header-left .search_icon {
  background-color: #444444 !important;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-left .search_icon i,
[data-headerbg="color_13"] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_13"] .header-left .btn {
  background-color: #111111;
  border-color: #111111;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-left .btn:hover,
[data-headerbg="color_13"][data-theme-version="dark"] .header-left .btn:focus,
[data-headerbg="color_13"][data-theme-version="dark"] .header-left .btn:active,
[data-headerbg="color_13"] .header-left .btn:hover,
[data-headerbg="color_13"] .header-left .btn:focus,
[data-headerbg="color_13"] .header-left .btn:active {
  background-color: black;
  border-color: black;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .dropdown>a,
[data-headerbg="color_13"] .header-right .dropdown>a {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_13"] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .pulse-css:after,
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .pulse-css:before,
[data-headerbg="color_13"] .header-right .pulse-css:after,
[data-headerbg="color_13"] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_13"] .header-right .notification_dropdown .nav-link {
  background-color: #444444 !important;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_13"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info strong,
[data-headerbg="color_13"] .header-right .header-profile>a.nav-link .header-info strong {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_13"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_13"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_13"] .header-right .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg="color_13"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_13"] .nav-control {
  background-color: #444444;
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header,
[data-headerbg="color_14"] .header {
  background-color: #1367c8;
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_14"] .header-left input {
  border-color: #2480ea;
  background-color: #2480ea !important;
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-left input::placeholder,
[data-headerbg="color_14"] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-left input:focus,
[data-headerbg="color_14"] .header-left input:focus {
  border-color: #0f4f99;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_14"] .header-left .search_icon {
  background-color: #2480ea !important;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-left .search_icon i,
[data-headerbg="color_14"] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_14"] .header-left .btn {
  background-color: #0f4f99;
  border-color: #0f4f99;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-left .btn:hover,
[data-headerbg="color_14"][data-theme-version="dark"] .header-left .btn:focus,
[data-headerbg="color_14"][data-theme-version="dark"] .header-left .btn:active,
[data-headerbg="color_14"] .header-left .btn:hover,
[data-headerbg="color_14"] .header-left .btn:focus,
[data-headerbg="color_14"] .header-left .btn:active {
  background-color: #0a376b;
  border-color: #0a376b;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .dropdown>a,
[data-headerbg="color_14"] .header-right .dropdown>a {
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_14"] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .pulse-css:after,
[data-headerbg="color_14"][data-theme-version="dark"] .header-right .pulse-css:before,
[data-headerbg="color_14"] .header-right .pulse-css:after,
[data-headerbg="color_14"] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_14"] .header-right .notification_dropdown .nav-link {
  background-color: #2480ea !important;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_14"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info strong,
[data-headerbg="color_14"] .header-right .header-profile>a.nav-link .header-info strong {
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_14"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_14"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_14"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_14"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_14"] .header-right .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg="color_14"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_14"] .nav-control {
  background-color: #2480ea;
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header,
[data-headerbg="color_15"] .header {
  background-color: #ed0b4c;
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_15"] .header-left input {
  border-color: #f6356d;
  background-color: #f6356d !important;
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-left input::placeholder,
[data-headerbg="color_15"] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-left input:focus,
[data-headerbg="color_15"] .header-left input:focus {
  border-color: #bc093c;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_15"] .header-left .search_icon {
  background-color: #f6356d !important;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-left .search_icon i,
[data-headerbg="color_15"] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_15"] .header-left .btn {
  background-color: #bc093c;
  border-color: #bc093c;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-left .btn:hover,
[data-headerbg="color_15"][data-theme-version="dark"] .header-left .btn:focus,
[data-headerbg="color_15"][data-theme-version="dark"] .header-left .btn:active,
[data-headerbg="color_15"] .header-left .btn:hover,
[data-headerbg="color_15"] .header-left .btn:focus,
[data-headerbg="color_15"] .header-left .btn:active {
  background-color: #8c062d;
  border-color: #8c062d;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .dropdown>a,
[data-headerbg="color_15"] .header-right .dropdown>a {
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_15"] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .pulse-css:after,
[data-headerbg="color_15"][data-theme-version="dark"] .header-right .pulse-css:before,
[data-headerbg="color_15"] .header-right .pulse-css:after,
[data-headerbg="color_15"] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_15"] .header-right .notification_dropdown .nav-link {
  background-color: #f6356d !important;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_15"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info strong,
[data-headerbg="color_15"] .header-right .header-profile>a.nav-link .header-info strong {
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_15"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_15"][data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_15"] .header-right .header-profile>a.nav-link .header-info span,
[data-headerbg="color_15"] .header-right .header-profile>a.nav-link .header-info small,
[data-headerbg="color_15"] .header-right .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg="color_15"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_15"] .nav-control {
  background-color: #f6356d;
  color: #fff;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_2"] .deznav {
  background-color: #143b64;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
[data-sibebarbg="color_2"] .deznav .menu-tabs li a.active {
  color: #143b64;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu .nav-label,
[data-sibebarbg="color_2"] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_2"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li>a svg,
[data-sibebarbg="color_2"] .deznav .metismenu>li>a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li>a g [fill],
[data-sibebarbg="color_2"] .deznav .metismenu>li>a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_2"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_2"] .deznav .metismenu>li.mm-active>a {
  color: #fff;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a g [fill],
[data-sibebarbg="color_2"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_2"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_2"] .deznav .metismenu>li.mm-active>a g [fill] {
  fill: #fff;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a svg,
[data-sibebarbg="color_2"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_2"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_2"] .deznav .metismenu>li.mm-active>a svg {
  color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li,
[data-sibebarbg="color_2"] .deznav .metismenu>li {
  border-color: #1d548f;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_2"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu a:hover,
[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu a:focus,
[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_2"] .deznav .metismenu a:hover,
[data-sibebarbg="color_2"] .deznav .metismenu a:focus,
[data-sibebarbg="color_2"] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu ul,
[data-sibebarbg="color_2"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu ul:after,
[data-sibebarbg="color_2"] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul,
[data-sibebarbg="color_2"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul {
  background-color: #1d548f;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_2"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  background-color: #1d548f;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_2"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_2"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a {
  background-color: #1d548f;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_2"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li,
[data-sibebarbg="color_2"][data-layout="horizontal"] .deznav .metismenu>li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_3"] .deznav {
  background-color: #99c990;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
[data-sibebarbg="color_3"] .deznav .menu-tabs li a.active {
  color: #99c990;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu .nav-label,
[data-sibebarbg="color_3"] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_3"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li>a svg,
[data-sibebarbg="color_3"] .deznav .metismenu>li>a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li>a g [fill],
[data-sibebarbg="color_3"] .deznav .metismenu>li>a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_3"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_3"] .deznav .metismenu>li.mm-active>a {
  color: #fff;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a g [fill],
[data-sibebarbg="color_3"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_3"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_3"] .deznav .metismenu>li.mm-active>a g [fill] {
  fill: #fff;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a svg,
[data-sibebarbg="color_3"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_3"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_3"] .deznav .metismenu>li.mm-active>a svg {
  color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li,
[data-sibebarbg="color_3"] .deznav .metismenu>li {
  border-color: #2a51db;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_3"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu a:hover,
[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu a:focus,
[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_3"] .deznav .metismenu a:hover,
[data-sibebarbg="color_3"] .deznav .metismenu a:focus,
[data-sibebarbg="color_3"] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu ul,
[data-sibebarbg="color_3"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu ul:after,
[data-sibebarbg="color_3"] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul,
[data-sibebarbg="color_3"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul {
  background-color: #2a51db;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_3"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  background-color: #2a51db;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_3"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_3"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a {
  background-color: #2a51db;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_3"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li,
[data-sibebarbg="color_3"][data-layout="horizontal"] .deznav .metismenu>li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_4"] .deznav {
  background-color: #4527a0;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
[data-sibebarbg="color_4"] .deznav .menu-tabs li a.active {
  color: #4527a0;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu .nav-label,
[data-sibebarbg="color_4"] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_4"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li>a svg,
[data-sibebarbg="color_4"] .deznav .metismenu>li>a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li>a g [fill],
[data-sibebarbg="color_4"] .deznav .metismenu>li>a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_4"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_4"] .deznav .metismenu>li.mm-active>a {
  color: #fff;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a g [fill],
[data-sibebarbg="color_4"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_4"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_4"] .deznav .metismenu>li.mm-active>a g [fill] {
  fill: #fff;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a svg,
[data-sibebarbg="color_4"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_4"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_4"] .deznav .metismenu>li.mm-active>a svg {
  color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li,
[data-sibebarbg="color_4"] .deznav .metismenu>li {
  border-color: #5731c9;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_4"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu a:hover,
[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu a:focus,
[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_4"] .deznav .metismenu a:hover,
[data-sibebarbg="color_4"] .deznav .metismenu a:focus,
[data-sibebarbg="color_4"] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu ul,
[data-sibebarbg="color_4"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu ul:after,
[data-sibebarbg="color_4"] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul,
[data-sibebarbg="color_4"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul {
  background-color: #5731c9;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_4"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  background-color: #5731c9;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_4"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_4"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a {
  background-color: #5731c9;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_4"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li,
[data-sibebarbg="color_4"][data-layout="horizontal"] .deznav .metismenu>li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_5"] .deznav {
  background-color: #c62828;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
[data-sibebarbg="color_5"] .deznav .menu-tabs li a.active {
  color: #c62828;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu .nav-label,
[data-sibebarbg="color_5"] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_5"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li>a svg,
[data-sibebarbg="color_5"] .deznav .metismenu>li>a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li>a g [fill],
[data-sibebarbg="color_5"] .deznav .metismenu>li>a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_5"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_5"] .deznav .metismenu>li.mm-active>a {
  color: #fff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a g [fill],
[data-sibebarbg="color_5"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_5"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_5"] .deznav .metismenu>li.mm-active>a g [fill] {
  fill: #fff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a svg,
[data-sibebarbg="color_5"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_5"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_5"] .deznav .metismenu>li.mm-active>a svg {
  color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li,
[data-sibebarbg="color_5"] .deznav .metismenu>li {
  border-color: #da4747;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_5"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu a:hover,
[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu a:focus,
[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_5"] .deznav .metismenu a:hover,
[data-sibebarbg="color_5"] .deznav .metismenu a:focus,
[data-sibebarbg="color_5"] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu ul,
[data-sibebarbg="color_5"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu ul:after,
[data-sibebarbg="color_5"] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul,
[data-sibebarbg="color_5"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul {
  background-color: #da4747;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_5"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  background-color: #da4747;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_5"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_5"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a {
  background-color: #da4747;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_5"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li,
[data-sibebarbg="color_5"][data-layout="horizontal"] .deznav .metismenu>li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_6"] .deznav {
  background-color: #283593;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
[data-sibebarbg="color_6"] .deznav .menu-tabs li a.active {
  color: #283593;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu .nav-label,
[data-sibebarbg="color_6"] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_6"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li>a svg,
[data-sibebarbg="color_6"] .deznav .metismenu>li>a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li>a g [fill],
[data-sibebarbg="color_6"] .deznav .metismenu>li>a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_6"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_6"] .deznav .metismenu>li.mm-active>a {
  color: #fff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a g [fill],
[data-sibebarbg="color_6"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_6"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_6"] .deznav .metismenu>li.mm-active>a g [fill] {
  fill: #fff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a svg,
[data-sibebarbg="color_6"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_6"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_6"] .deznav .metismenu>li.mm-active>a svg {
  color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li,
[data-sibebarbg="color_6"] .deznav .metismenu>li {
  border-color: #3343bb;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_6"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu a:hover,
[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu a:focus,
[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_6"] .deznav .metismenu a:hover,
[data-sibebarbg="color_6"] .deznav .metismenu a:focus,
[data-sibebarbg="color_6"] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu ul,
[data-sibebarbg="color_6"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu ul:after,
[data-sibebarbg="color_6"] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul,
[data-sibebarbg="color_6"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul {
  background-color: #3343bb;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_6"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  background-color: #3343bb;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_6"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_6"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a {
  background-color: #3343bb;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_6"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li,
[data-sibebarbg="color_6"][data-layout="horizontal"] .deznav .metismenu>li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_7"] .deznav {
  background-color: #7356f1;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
[data-sibebarbg="color_7"] .deznav .menu-tabs li a.active {
  color: #7356f1;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu .nav-label,
[data-sibebarbg="color_7"] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_7"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li>a svg,
[data-sibebarbg="color_7"] .deznav .metismenu>li>a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li>a g [fill],
[data-sibebarbg="color_7"] .deznav .metismenu>li>a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_7"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_7"] .deznav .metismenu>li.mm-active>a {
  color: #fff;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a g [fill],
[data-sibebarbg="color_7"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_7"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_7"] .deznav .metismenu>li.mm-active>a g [fill] {
  fill: #fff;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a svg,
[data-sibebarbg="color_7"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_7"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_7"] .deznav .metismenu>li.mm-active>a svg {
  color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li,
[data-sibebarbg="color_7"] .deznav .metismenu>li {
  border-color: #9a85f5;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_7"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu a:hover,
[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu a:focus,
[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_7"] .deznav .metismenu a:hover,
[data-sibebarbg="color_7"] .deznav .metismenu a:focus,
[data-sibebarbg="color_7"] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu ul,
[data-sibebarbg="color_7"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu ul:after,
[data-sibebarbg="color_7"] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul,
[data-sibebarbg="color_7"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul {
  background-color: #9a85f5;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_7"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  background-color: #9a85f5;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_7"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_7"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a {
  background-color: #9a85f5;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_7"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li,
[data-sibebarbg="color_7"][data-layout="horizontal"] .deznav .metismenu>li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_8"] .deznav {
  background-color: #3695eb;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
[data-sibebarbg="color_8"] .deznav .menu-tabs li a.active {
  color: #3695eb;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu .nav-label,
[data-sibebarbg="color_8"] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li>a svg,
[data-sibebarbg="color_8"] .deznav .metismenu>li>a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li>a g [fill],
[data-sibebarbg="color_8"] .deznav .metismenu>li>a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_8"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  color: #fff;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a g [fill],
[data-sibebarbg="color_8"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_8"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a g [fill] {
  fill: #fff;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a svg,
[data-sibebarbg="color_8"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_8"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a svg {
  color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li,
[data-sibebarbg="color_8"] .deznav .metismenu>li {
  border-color: #64adf0;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu a:hover,
[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu a:focus,
[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu ul,
[data-sibebarbg="color_8"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu ul:after,
[data-sibebarbg="color_8"] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul,
[data-sibebarbg="color_8"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul {
  background-color: #64adf0;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_8"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  background-color: #64adf0;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_8"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_8"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a {
  background-color: #64adf0;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_8"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li,
[data-sibebarbg="color_8"][data-layout="horizontal"] .deznav .metismenu>li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_9"] .deznav {
  background-color: #00838f;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
[data-sibebarbg="color_9"] .deznav .menu-tabs li a.active {
  color: #00838f;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu .nav-label,
[data-sibebarbg="color_9"] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_9"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li>a svg,
[data-sibebarbg="color_9"] .deznav .metismenu>li>a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li>a g [fill],
[data-sibebarbg="color_9"] .deznav .metismenu>li>a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_9"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_9"] .deznav .metismenu>li.mm-active>a {
  color: #fff;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a g [fill],
[data-sibebarbg="color_9"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_9"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_9"] .deznav .metismenu>li.mm-active>a g [fill] {
  fill: #fff;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a svg,
[data-sibebarbg="color_9"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_9"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_9"] .deznav .metismenu>li.mm-active>a svg {
  color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li,
[data-sibebarbg="color_9"] .deznav .metismenu>li {
  border-color: #00b2c2;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_9"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu a:hover,
[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu a:focus,
[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_9"] .deznav .metismenu a:hover,
[data-sibebarbg="color_9"] .deznav .metismenu a:focus,
[data-sibebarbg="color_9"] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu ul,
[data-sibebarbg="color_9"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu ul:after,
[data-sibebarbg="color_9"] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul,
[data-sibebarbg="color_9"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul {
  background-color: #00b2c2;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_9"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  background-color: #00b2c2;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_9"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_9"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a {
  background-color: #00b2c2;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_9"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li,
[data-sibebarbg="color_9"][data-layout="horizontal"] .deznav .metismenu>li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_10"] .deznav {
  background-color: #ff8f16;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
[data-sibebarbg="color_10"] .deznav .menu-tabs li a.active {
  color: #ff8f16;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu .nav-label,
[data-sibebarbg="color_10"] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_10"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li>a svg,
[data-sibebarbg="color_10"] .deznav .metismenu>li>a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li>a g [fill],
[data-sibebarbg="color_10"] .deznav .metismenu>li>a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_10"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_10"] .deznav .metismenu>li.mm-active>a {
  color: #fff;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a g [fill],
[data-sibebarbg="color_10"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_10"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_10"] .deznav .metismenu>li.mm-active>a g [fill] {
  fill: #fff;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a svg,
[data-sibebarbg="color_10"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_10"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_10"] .deznav .metismenu>li.mm-active>a svg {
  color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li,
[data-sibebarbg="color_10"] .deznav .metismenu>li {
  border-color: #ffa849;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_10"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu a:hover,
[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu a:focus,
[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_10"] .deznav .metismenu a:hover,
[data-sibebarbg="color_10"] .deznav .metismenu a:focus,
[data-sibebarbg="color_10"] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu ul,
[data-sibebarbg="color_10"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu ul:after,
[data-sibebarbg="color_10"] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul,
[data-sibebarbg="color_10"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul {
  background-color: #ffa849;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_10"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  background-color: #ffa849;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_10"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_10"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a {
  background-color: #ffa849;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_10"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li,
[data-sibebarbg="color_10"][data-layout="horizontal"] .deznav .metismenu>li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_11"] .deznav {
  background-color: #6673fd;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
[data-sibebarbg="color_11"] .deznav .menu-tabs li a.active {
  color: #6673fd;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu .nav-label,
[data-sibebarbg="color_11"] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_11"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li>a svg,
[data-sibebarbg="color_11"] .deznav .metismenu>li>a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li>a g [fill],
[data-sibebarbg="color_11"] .deznav .metismenu>li>a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_11"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_11"] .deznav .metismenu>li.mm-active>a {
  color: #fff;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a g [fill],
[data-sibebarbg="color_11"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_11"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_11"] .deznav .metismenu>li.mm-active>a g [fill] {
  fill: #fff;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a svg,
[data-sibebarbg="color_11"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_11"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_11"] .deznav .metismenu>li.mm-active>a svg {
  color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li,
[data-sibebarbg="color_11"] .deznav .metismenu>li {
  border-color: #98a1fe;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_11"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu a:hover,
[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu a:focus,
[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_11"] .deznav .metismenu a:hover,
[data-sibebarbg="color_11"] .deznav .metismenu a:focus,
[data-sibebarbg="color_11"] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu ul,
[data-sibebarbg="color_11"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu ul:after,
[data-sibebarbg="color_11"] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul,
[data-sibebarbg="color_11"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul {
  background-color: #98a1fe;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_11"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  background-color: #98a1fe;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_11"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_11"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a {
  background-color: #98a1fe;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_11"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li,
[data-sibebarbg="color_11"][data-layout="horizontal"] .deznav .metismenu>li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_12"] .deznav {
  background-color: #558b2f;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
[data-sibebarbg="color_12"] .deznav .menu-tabs li a.active {
  color: #558b2f;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu .nav-label,
[data-sibebarbg="color_12"] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_12"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li>a svg,
[data-sibebarbg="color_12"] .deznav .metismenu>li>a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li>a g [fill],
[data-sibebarbg="color_12"] .deznav .metismenu>li>a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_12"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_12"] .deznav .metismenu>li.mm-active>a {
  color: #fff;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a g [fill],
[data-sibebarbg="color_12"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_12"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_12"] .deznav .metismenu>li.mm-active>a g [fill] {
  fill: #fff;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a svg,
[data-sibebarbg="color_12"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_12"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_12"] .deznav .metismenu>li.mm-active>a svg {
  color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li,
[data-sibebarbg="color_12"] .deznav .metismenu>li {
  border-color: #6cb13c;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_12"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu a:hover,
[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu a:focus,
[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_12"] .deznav .metismenu a:hover,
[data-sibebarbg="color_12"] .deznav .metismenu a:focus,
[data-sibebarbg="color_12"] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu ul,
[data-sibebarbg="color_12"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu ul:after,
[data-sibebarbg="color_12"] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul,
[data-sibebarbg="color_12"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul {
  background-color: #6cb13c;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_12"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  background-color: #6cb13c;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_12"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_12"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a {
  background-color: #6cb13c;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_12"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li,
[data-sibebarbg="color_12"][data-layout="horizontal"] .deznav .metismenu>li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_13"] .deznav {
  background-color: #2a2a2a;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
[data-sibebarbg="color_13"] .deznav .menu-tabs li a.active {
  color: #2a2a2a;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu .nav-label,
[data-sibebarbg="color_13"] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_13"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li>a svg,
[data-sibebarbg="color_13"] .deznav .metismenu>li>a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li>a g [fill],
[data-sibebarbg="color_13"] .deznav .metismenu>li>a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_13"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_13"] .deznav .metismenu>li.mm-active>a {
  color: #fff;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a g [fill],
[data-sibebarbg="color_13"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_13"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_13"] .deznav .metismenu>li.mm-active>a g [fill] {
  fill: #fff;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a svg,
[data-sibebarbg="color_13"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_13"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_13"] .deznav .metismenu>li.mm-active>a svg {
  color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li,
[data-sibebarbg="color_13"] .deznav .metismenu>li {
  border-color: #444444;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_13"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu a:hover,
[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu a:focus,
[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_13"] .deznav .metismenu a:hover,
[data-sibebarbg="color_13"] .deznav .metismenu a:focus,
[data-sibebarbg="color_13"] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu ul,
[data-sibebarbg="color_13"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu ul:after,
[data-sibebarbg="color_13"] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul,
[data-sibebarbg="color_13"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul {
  background-color: #444444;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_13"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  background-color: #444444;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_13"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_13"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a {
  background-color: #444444;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_13"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li,
[data-sibebarbg="color_13"][data-layout="horizontal"] .deznav .metismenu>li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_14"] .deznav {
  background-color: #1367c8;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
[data-sibebarbg="color_14"] .deznav .menu-tabs li a.active {
  color: #1367c8;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu .nav-label,
[data-sibebarbg="color_14"] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_14"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu>li>a svg,
[data-sibebarbg="color_14"] .deznav .metismenu>li>a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu>li>a g [fill],
[data-sibebarbg="color_14"] .deznav .metismenu>li>a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_14"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_14"] .deznav .metismenu>li.mm-active>a {
  color: #fff;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a g [fill],
[data-sibebarbg="color_14"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_14"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_14"] .deznav .metismenu>li.mm-active>a g [fill] {
  fill: #fff;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a svg,
[data-sibebarbg="color_14"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_14"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_14"] .deznav .metismenu>li.mm-active>a svg {
  color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu>li,
[data-sibebarbg="color_14"] .deznav .metismenu>li {
  border-color: #2480ea;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_14"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu a:hover,
[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu a:focus,
[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_14"] .deznav .metismenu a:hover,
[data-sibebarbg="color_14"] .deznav .metismenu a:focus,
[data-sibebarbg="color_14"] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu ul,
[data-sibebarbg="color_14"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu ul:after,
[data-sibebarbg="color_14"] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul,
[data-sibebarbg="color_14"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul {
  background-color: #2480ea;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_14"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  background-color: #2480ea;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_14"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_14"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a {
  background-color: #2480ea;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_14"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li,
[data-sibebarbg="color_14"][data-layout="horizontal"] .deznav .metismenu>li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_15"] .deznav {
  background-color: #ed0b4c;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
[data-sibebarbg="color_15"] .deznav .menu-tabs li a.active {
  color: #ed0b4c;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu .nav-label,
[data-sibebarbg="color_15"] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_15"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu>li>a svg,
[data-sibebarbg="color_15"] .deznav .metismenu>li>a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu>li>a g [fill],
[data-sibebarbg="color_15"] .deznav .metismenu>li>a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_15"] .deznav .metismenu>li:focus>a,
[data-sibebarbg="color_15"] .deznav .metismenu>li.mm-active>a {
  color: #fff;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a g [fill],
[data-sibebarbg="color_15"] .deznav .metismenu>li:hover>a g [fill],
[data-sibebarbg="color_15"] .deznav .metismenu>li:focus>a g [fill],
[data-sibebarbg="color_15"] .deznav .metismenu>li.mm-active>a g [fill] {
  fill: #fff;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a svg,
[data-sibebarbg="color_15"] .deznav .metismenu>li:hover>a svg,
[data-sibebarbg="color_15"] .deznav .metismenu>li:focus>a svg,
[data-sibebarbg="color_15"] .deznav .metismenu>li.mm-active>a svg {
  color: #fff;
}

[data-layout="horizontal"][data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu>li,
[data-sibebarbg="color_15"] .deznav .metismenu>li {
  border-color: #f6356d;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_15"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu a:hover,
[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu a:focus,
[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_15"] .deznav .metismenu a:hover,
[data-sibebarbg="color_15"] .deznav .metismenu a:focus,
[data-sibebarbg="color_15"] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu ul,
[data-sibebarbg="color_15"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu ul:after,
[data-sibebarbg="color_15"] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul,
[data-sibebarbg="color_15"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li>ul {
  background-color: #f6356d;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_15"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  background-color: #f6356d;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_15"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_15"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a {
  background-color: #f6356d;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_15"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li,
[data-sibebarbg="color_15"][data-layout="horizontal"] .deznav .metismenu>li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-headerbg="transparent"] .header {
  box-shadow: none;
  background: transparent;
}

[data-headerbg="color_1"] .nav-header .hamburger.is-active .line,
[data-headerbg="color_1"] .nav-header .hamburger .line {
  background: #99c990 !important;
}

@media (min-width: 992px) {
  [data-container="boxed"] #main-wrapper {
    max-width: 1199px;
    margin: 0 auto;
  }

  [direction="rtl"][data-container="boxed"] #main-wrapper {
    text-align: right;
  }
}

@media only screen and (min-width: 1350px) {
  [data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] .header {
    width: 1199px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1349px) {
  [data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] .header {
    width: 1199px;
  }
}

[data-header-position="fixed"][data-layout="horizontal"][data-container="boxed"] .deznav {
  max-width: 1199px;
}

[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"] .header {
  width: 1199px;
}

[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"][data-sidebar-style="mini"] .header {
  width: 1199px;
}

[data-container="boxed"] .metismenu.fixed {
  left: auto;
  max-width: 1199px;
}

[data-container="boxed"] .page-titles {
  margin-bottom: 3rem;
  padding: 15px 15px;
}

[data-container="boxed"] .content-body .container-fluid,
[data-container="boxed"] .content-body .container-sm,
[data-container="boxed"] .content-body .container-md,
[data-container="boxed"] .content-body .container-lg,
[data-container="boxed"] .content-body .container-xl,
[data-container="boxed"] .content-body .container-xxl {
  padding: 0.9375rem 0.9375rem 0 0.9375rem;
}

[data-container="boxed"][data-layout="vertical"] .page-titles {
  margin-left: 0;
  margin-right: 0;
}

[data-layout="vertical"][data-container="boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .nav-header {
  position: absolute;
}

[data-layout="vertical"][data-container="boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .menu-toggle .deznav {
  position: absolute;
}

[data-container="boxed"][data-sidebar-position="fixed"][data-layout="horizontal"] .deznav.fixed {
  left: auto;
  max-width: 1199px;
}

@media (min-width: 992px) {
  [data-container="wide-boxed"] #main-wrapper {
    max-width: 1480px;
    margin: 0 auto;
  }
}

@media (min-width: 992px) {
  [data-container="wide-boxed"][data-sidebar-style="full"] .header {
    width: 100%;
  }

  [data-container="wide-boxed"][data-sidebar-style="full"] .menu-toggle .header {
    width: 100%;
  }

  [data-header-position="fixed"][data-container="wide-boxed"][data-sidebar-style="full"] .header {
    max-width: 1480px;
  }

  [data-header-position="fixed"][data-container="wide-boxed"][data-sidebar-style="full"] .menu-toggle .header {
    max-width: 1480px;
  }
}

@media (min-width: 992px) {
  [data-container="wide-boxed"][data-sidebar-style="mini"] .header {
    width: 100%;
  }

  [data-header-position="fixed"][data-container="wide-boxed"][data-sidebar-style="mini"] .header {
    max-width: 1480px;
  }

  [data-header-position="fixed"][data-layout="horizontal"][data-container="wide-boxed"][data-sidebar-style="mini"] .header {
    max-width: 1480px;
  }
}

@media (min-width: 992px) {
  [data-container="wide-boxed"][data-sidebar-style="compact"] .header {
    width: 100%;
  }

  [data-header-position="fixed"][data-container="wide-boxed"][data-sidebar-style="compact"] .header {
    max-width: 1480px;
  }
}

@media (min-width: 992px) {
  [data-container="wide-boxed"][data-sidebar-style="overlay"] .header {
    width: 100%;
  }

  [data-header-position="fixed"][data-container="wide-boxed"][data-sidebar-style="overlay"] .header {
    max-width: 1480px;
  }
}

[data-layout="horizontal"][data-container="wide-boxed"] .header {
  width: 100%;
}

[data-sidebar-style="full"][data-header-position="fixed"][data-layout="horizontal"][data-container="wide-boxed"] .header {
  max-width: 1480px;
}

[data-sidebar-style="mini"][data-layout="horizontal"][data-container="wide-boxed"] .header {
  width: 100%;
}

[data-layout="horizontal"][data-container="wide-boxed"][data-sidebar-position="fixed"] .deznav.fixed {
  max-width: 1480px;
  transition: none;
}

[data-layout="horizontal"][data-container="wide-boxed"][data-sidebar-position="fixed"][direction="rtl"] .deznav.fixed {
  right: 5%;
}

[data-layout="horizontal"][data-container="wide-boxed"][data-sidebar-position="fixed"][data-header-position="fixed"] .deznav {
  max-width: 1480px;
  left: 5%;
}

[data-layout="horizontal"][data-container="wide-boxed"][data-sidebar-position="fixed"][data-header-position="fixed"][direction="rtl"] .deznav {
  right: 5%;
}

[data-primary="color_2"] a:hover,
[data-primary="color_2"] a:focus,
[data-primary="color_2"] a.active {
  color: #143b64;
}

[data-primary="color_2"] [data-class="bg-primary"]:before {
  background: #143b64;
}

[data-primary="color_2"] .email-left-box .intro-title {
  background: rgba(20, 59, 100, 0.1);
}

[data-primary="color_2"] .email-left-box .intro-title i {
  color: #143b64;
}

[data-primary="color_2"] .widget-stat .media .media-body h4,
[data-primary="color_2"] .widget-stat .media .media-body .h4 {
  color: #143b64 !important;
}

[data-primary="color_2"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(20, 59, 100, 0.1);
}

[data-primary="color_2"] .mail-list .list-group-item.active i {
  color: #143b64;
}

[data-primary="color_2"] .single-mail.active {
  background: #143b64;
}

[data-primary="color_2"] .profile-info h4.text-primary,
[data-primary="color_2"] .profile-info .text-primary.h4 {
  color: #143b64 !important;
}

[data-primary="color_2"] .profile-tab .nav-item .nav-link:hover,
[data-primary="color_2"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #143b64;
  color: #143b64;
}

[data-primary="color_2"] .amChartsInputField {
  border: 0;
  background: #143b64;
}

[data-primary="color_2"] .amcharts-period-input,
[data-primary="color_2"] .amcharts-period-input-selected {
  background: #143b64;
}

[data-primary="color_2"] .morris-hover {
  background: #143b64;
}

[data-primary="color_2"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #143b64;
}

[data-primary="color_2"] .custom-select:focus {
  border-color: #143b64;
  color: #143b64;
}

[data-primary="color_2"] .daterangepicker td.active {
  background-color: #143b64;
}

[data-primary="color_2"] .daterangepicker td.active:hover {
  background-color: #143b64;
}

[data-primary="color_2"] .daterangepicker button.applyBtn {
  background-color: #143b64;
  border-color: #143b64;
}

[data-primary="color_2"] .wizard>.steps li.current a {
  background-color: #143b64;
}

[data-primary="color_2"] .wizard .skip-email a {
  color: #143b64;
}

[data-primary="color_2"] .wizard>.actions li:not(.disabled) a {
  background-color: #143b64;
}

[data-primary="color_2"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #143b64;
}

[data-primary="color_2"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #143b64;
  border-color: #143b64;
}

[data-primary="color_2"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #143b64;
}

[data-primary="color_2"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #143b64;
}

[data-primary="color_2"] .is-invalid .input-group-prepend .input-group-text i {
  color: #2161a4;
}

[data-primary="color_2"] .datamaps-hoverover {
  color: #143b64;
  border: 1px solid rgba(20, 59, 100, 0.3);
}

[data-primary="color_2"] .jqvmap-zoomin,
[data-primary="color_2"] .jqvmap-zoomout {
  background-color: #143b64;
}

[data-primary="color_2"] .table .thead-primary th {
  background-color: #143b64;
}

[data-primary="color_2"] .table.primary-table-bg-hover thead th {
  background-color: #102e4f;
}

[data-primary="color_2"] .table.primary-table-bg-hover tbody tr {
  background-color: #143b64;
}

[data-primary="color_2"] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #184879;
}

[data-primary="color_2"] .table.primary-table-bg-hover tbody tr:not(:last-child) td,
[data-primary="color_2"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 1px solid #102e4f;
}

[data-primary="color_2"] table.dataTable tr.selected {
  color: #143b64;
}

[data-primary="color_2"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #143b64 !important;
  background: rgba(20, 59, 100, 0.1);
}

[data-primary="color_2"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #143b64 !important;
  background: rgba(20, 59, 100, 0.1);
}

[data-primary="color_2"] .clipboard-btn:hover {
  background-color: #143b64;
}

[data-primary="color_2"] .cd-h-timeline__dates::before {
  background: #143b64;
}

[data-primary="color_2"] .cd-h-timeline__dates::after {
  background: #143b64;
}

[data-primary="color_2"] .cd-h-timeline__line {
  background-color: #143b64;
}

[data-primary="color_2"] .cd-h-timeline__date:after {
  border-color: #153d68;
  background-color: #143b64;
}

[data-primary="color_2"] .cd-h-timeline__navigation {
  border-color: #153d68;
}

[data-primary="color_2"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #153d68;
}

[data-primary="color_2"] .dd-handle {
  background: #143b64;
}

[data-primary="color_2"] .dd-handle:hover {
  background: #143b64;
}

[data-primary="color_2"] .dd3-content:hover {
  background: #143b64;
}

[data-primary="color_2"] .noUi-connect {
  background-color: #143b64;
}

[data-primary="color_2"] .noUi-connect.c-3-color {
  background-color: #143b64;
}

[data-primary="color_2"] .noUi-horizontal .noUi-handle,
[data-primary="color_2"] .noUi-vertical .noUi-handle {
  background-color: #143b64;
}

[data-primary="color_2"] #slider-toggle.off .noUi-handle {
  border-color: #143b64;
}

[data-primary="color_2"] .pignose-calendar {
  border-color: #143b64;
}

[data-primary="color_2"] .pignose-calendar .pignose-calendar-top-date {
  background-color: #143b64;
}

[data-primary="color_2"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #143b64;
}

[data-primary="color_2"] .bootstrap-tagsinput .tag {
  background-color: #143b64;
}

[data-primary="color_2"] .toast-success {
  background-color: #143b64;
}

[data-primary="color_2"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #143b64;
}

[data-primary="color_2"] .accordion-header-bg .accordion__header--primary {
  background-color: #143b64;
}

[data-primary="color_2"] .alert-primary {
  background: #297ace;
  border-color: #297ace;
  color: #143b64;
}

[data-primary="color_2"] .alert-alt.alert-primary {
  border-left: 4px solid #143b64;
}

[data-primary="color_2"] .alert-alt.alert-primary.solid {
  border-left: 4px solid black !important;
}

[data-primary="color_2"] .alert.alert-primary.solid {
  background: #143b64;
  border-color: #143b64;
}

[data-primary="color_2"] .alert.alert-outline-primary {
  color: #143b64;
  border-color: #143b64;
}

[data-primary="color_2"] .badge-outline-primary {
  border: 1px solid #143b64;
  color: #143b64;
}

[data-primary="color_2"] .badge-primary {
  background-color: #143b64;
}

[data-primary="color_2"] .page-titles h4,
[data-primary="color_2"] .page-titles .h4 {
  color: #143b64;
}

[data-primary="color_2"] .card-action>a {
  background: black;
}

[data-primary="color_2"] .card-action .dropdown {
  background: black;
  color: #143b64;
}

[data-primary="color_2"] .card-action .dropdown:hover,
[data-primary="color_2"] .card-action .dropdown:focus {
  background: black;
}

[data-primary="color_2"] .card-loader i {
  background: #0e2946;
}

[data-primary="color_2"] .dropdown-outline {
  border: 0.1rem solid #143b64;
}

[data-primary="color_2"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #143b64;
}

[data-primary="color_2"] .card-action .custom-dropdown {
  background: #3a87d8;
}

[data-primary="color_2"] .card-action .custom-dropdown.show,
[data-primary="color_2"] .card-action .custom-dropdown:focus,
[data-primary="color_2"] .card-action .custom-dropdown:hover {
  background: #143b64;
}

[data-primary="color_2"] .label-primary {
  background: #143b64;
}

[data-primary="color_2"] .pagination .page-item .page-link:hover {
  background: #143b64;
  border-color: #143b64;
}

[data-primary="color_2"] .pagination .page-item.active .page-link {
  background-color: #143b64;
  border-color: #143b64;
}

[data-primary="color_2"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_2"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #143b64;
}

[data-primary="color_2"] .progress-bar {
  background-color: #143b64;
}

[data-primary="color_2"] .progress-bar-primary {
  background-color: #143b64;
}

[data-primary="color_2"] .ribbon__four {
  background-color: #143b64;
}

[data-primary="color_2"] .ribbon__four:after,
[data-primary="color_2"] .ribbon__four:before {
  background-color: #256db9;
}

[data-primary="color_2"] .ribbon__five {
  background-color: #143b64;
}

[data-primary="color_2"] .ribbon__five::before {
  border-color: transparent transparent #143b64 transparent;
}

[data-primary="color_2"] .ribbon__six {
  background-color: #143b64;
}

[data-primary="color_2"] .multi-steps>li {
  color: #143b64;
}

[data-primary="color_2"] .multi-steps>li:after {
  background-color: #143b64;
}

[data-primary="color_2"] .multi-steps>li.is-active:before {
  border-color: #143b64;
}

[data-primary="color_2"] .timeline-badge.primary {
  background-color: #143b64 !important;
}

[data-primary="color_2"] .tooltip-wrapper button:hover {
  background: #143b64;
}

[data-primary="color_2"] .chart_widget_tab_one .nav-link.active {
  background-color: #143b64;
  border: 1px solid #143b64;
}

[data-primary="color_2"] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #143b64;
}

[data-primary="color_2"] .social-icon2 a {
  border: 0.1rem solid #143b64;
}

[data-primary="color_2"] .social-icon2 i {
  color: #143b64;
}

[data-primary="color_2"] .social-icon3 ul li a:hover i {
  color: #143b64;
}

[data-primary="color_2"] .bgl-primary {
  background: #3a87d8;
  border-color: #3a87d8;
  color: #143b64;
}

[data-primary="color_2"] .tdl-holder input[type=checkbox]:checked+i {
  background: #143b64;
}

[data-primary="color_2"] .footer .copyright a {
  color: #143b64;
}

[data-primary="color_2"] .hamburger .line {
  background: #143b64;
}

[data-primary="color_2"] svg.pulse-svg .first-circle,
[data-primary="color_2"] svg.pulse-svg .second-circle,
[data-primary="color_2"] svg.pulse-svg .third-circle {
  fill: #143b64;
}

[data-primary="color_2"] .pulse-css {
  background: #143b64;
}

[data-primary="color_2"] .pulse-css:after,
[data-primary="color_2"] .pulse-css:before {
  background-color: #143b64;
}

[data-primary="color_2"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #143b64;
}

[data-primary="color_2"] .header-right .header-profile .dropdown-menu a:hover,
[data-primary="color_2"] .header-right .header-profile .dropdown-menu a:focus,
[data-primary="color_2"] .header-right .header-profile .dropdown-menu a.active {
  color: #143b64;
}

[data-primary="color_2"] .header-right .header-profile .profile_title {
  background: #143b64;
}

[data-primary="color_2"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #143b64 !important;
}

[data-primary="color_2"] .deznav .metismenu>li>a svg {
  color: #143b64;
}

[data-primary="color_2"] .deznav .metismenu>li:hover>a,
[data-primary="color_2"] .deznav .metismenu>li:focus>a {
  color: #143b64;
}

[data-primary="color_2"] .deznav .metismenu>li.mm-active>a {
  color: #143b64;
}

[data-primary="color_2"] .deznav .metismenu ul a:hover,
[data-primary="color_2"] .deznav .metismenu ul a:focus,
[data-primary="color_2"] .deznav .metismenu ul a.mm-active {
  color: #143b64;
}

@media (min-width: 767px) {

  [data-primary="color_2"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:hover>a,
  [data-primary="color_2"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:focus>a,
  [data-primary="color_2"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:active>a,
  [data-primary="color_2"] [data-sidebar-style="modern"] .deznav .metismenu>li>a.mm-active>a {
    background-color: #8fbae9;
  }
}

[data-primary="color_2"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #143b64;
}

[data-primary="color_2"] .nav-user {
  background: #143b64;
}

[data-primary="color_2"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #143b64;
}

[data-primary="color_2"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
  color: #143b64;
}

[data-primary="color_2"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #143b64;
  border-color: #143b64;
}

[data-primary="color_2"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #143b64;
  border-color: #143b64;
}

[data-primary="color_2"][data-theme-version="dark"] .header-left input:focus {
  border-color: #143b64;
}

[data-primary="color_2"][data-theme-version="dark"] .loader__bar {
  background: #143b64;
}

[data-primary="color_2"][data-theme-version="dark"] .loader__ball {
  background: #143b64;
}

[data-primary="color_2"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #143b64;
}

[data-primary="color_2"] .new-arrival-content .price {
  color: #143b64;
}

[data-primary="color_2"] .chart-link a i.text-primary {
  color: #143b64;
}

[data-primary="color_2"] #user-activity .nav-tabs .nav-link.active {
  background: #143b64;
  border-color: #143b64;
}

[data-primary="color_2"] span#counter {
  color: #143b64;
}

[data-primary="color_2"] .welcome-content:after {
  background: #143b64;
}

[data-primary="color_2"] .timeline-badge {
  background-color: #143b64;
}

[data-primary="color_2"] .page-timeline .timeline-workplan[data-primary="color_2"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(20, 59, 100, 0.4);
}

[data-primary="color_2"] .sk-three-bounce .sk-child {
  background-color: #143b64;
}

[data-primary="color_2"] .dropdown-item.active,
[data-primary="color_2"] .dropdown-item:active {
  color: #fff;
  background-color: #143b64;
}

[data-primary="color_2"] .overlay-box:after {
  background: #143b64;
}

[data-primary="color_2"] .btn-primary {
  background-color: #143b64;
  border-color: #143b64;
}

[data-primary="color_2"] .bg-primary {
  background-color: #143b64 !important;
}

[data-primary="color_2"] .text-primary {
  color: #143b64 !important;
}

[data-primary="color_2"] .btn-primary:hover {
  background-color: #071524;
  border-color: #071524;
}

[data-primary="color_2"] .btn-outline-primary {
  color: #143b64;
  border-color: #143b64;
}

[data-primary="color_2"] .btn-outline-primary:hover {
  background-color: #143b64;
  border-color: #143b64;
}

[data-primary="color_2"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_2"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_2"] .show>.btn-primary.dropdown-toggle,
[data-primary="color_2"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_2"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_2"] .show>.btn-outline-primary.dropdown-toggle {
  background-color: #071524;
  border-color: #071524;
}

[data-primary="color_3"] a:hover,
[data-primary="color_3"] a:focus,
[data-primary="color_3"] a.active {
  color: #99c990;
}

[data-primary="color_3"] [data-class="bg-primary"]:before {
  background: #99c990;
}

[data-primary="color_3"] .email-left-box .intro-title {
  background: rgba(30, 63, 180, 0.1);
}

[data-primary="color_3"] .email-left-box .intro-title i {
  color: #99c990;
}

[data-primary="color_3"] .widget-stat .media .media-body h4,
[data-primary="color_3"] .widget-stat .media .media-body .h4 {
  color: #99c990 !important;
}

[data-primary="color_3"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(30, 63, 180, 0.1);
}

[data-primary="color_3"] .mail-list .list-group-item.active i {
  color: #99c990;
}

[data-primary="color_3"] .single-mail.active {
  background: #99c990;
}

[data-primary="color_3"] .profile-info h4.text-primary,
[data-primary="color_3"] .profile-info .text-primary.h4 {
  color: #99c990 !important;
}

[data-primary="color_3"] .profile-tab .nav-item .nav-link:hover,
[data-primary="color_3"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #99c990;
  color: #99c990;
}

[data-primary="color_3"] .amChartsInputField {
  border: 0;
  background: #99c990;
}

[data-primary="color_3"] .amcharts-period-input,
[data-primary="color_3"] .amcharts-period-input-selected {
  background: #99c990;
}

[data-primary="color_3"] .morris-hover {
  background: #99c990;
}

[data-primary="color_3"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #99c990;
}

[data-primary="color_3"] .custom-select:focus {
  border-color: #99c990;
  color: #99c990;
}

[data-primary="color_3"] .daterangepicker td.active {
  background-color: #99c990;
}

[data-primary="color_3"] .daterangepicker td.active:hover {
  background-color: #99c990;
}

[data-primary="color_3"] .daterangepicker button.applyBtn {
  background-color: #99c990;
  border-color: #99c990;
}

[data-primary="color_3"] .wizard>.steps li.current a {
  background-color: #99c990;
}

[data-primary="color_3"] .wizard .skip-email a {
  color: #99c990;
}

[data-primary="color_3"] .wizard>.actions li:not(.disabled) a {
  background-color: #99c990;
}

[data-primary="color_3"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #99c990;
}

[data-primary="color_3"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #99c990;
  border-color: #99c990;
}

[data-primary="color_3"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #99c990;
}

[data-primary="color_3"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #99c990;
}

[data-primary="color_3"] .is-invalid .input-group-prepend .input-group-text i {
  color: #3f63df;
}

[data-primary="color_3"] .datamaps-hoverover {
  color: #99c990;
  border: 1px solid rgba(30, 63, 180, 0.3);
}

[data-primary="color_3"] .jqvmap-zoomin,
[data-primary="color_3"] .jqvmap-zoomout {
  background-color: #99c990;
}

[data-primary="color_3"] .table .thead-primary th {
  background-color: #99c990;
}

[data-primary="color_3"] .table.primary-table-bg-hover thead th {
  background-color: #1a379e;
}

[data-primary="color_3"] .table.primary-table-bg-hover tbody tr {
  background-color: #99c990;
}

[data-primary="color_3"] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #2247ca;
}

[data-primary="color_3"] .table.primary-table-bg-hover tbody tr:not(:last-child) td,
[data-primary="color_3"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 1px solid #1a379e;
}

[data-primary="color_3"] table.dataTable tr.selected {
  color: #99c990;
}

[data-primary="color_3"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #99c990 !important;
  background: rgba(30, 63, 180, 0.1);
}

[data-primary="color_3"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #99c990 !important;
  background: rgba(30, 63, 180, 0.1);
}

[data-primary="color_3"] .clipboard-btn:hover {
  background-color: #99c990;
}

[data-primary="color_3"] .cd-h-timeline__dates::before {
  background: #99c990;
}

[data-primary="color_3"] .cd-h-timeline__dates::after {
  background: #99c990;
}

[data-primary="color_3"] .cd-h-timeline__line {
  background-color: #99c990;
}

[data-primary="color_3"] .cd-h-timeline__date:after {
  border-color: #1f40b8;
  background-color: #99c990;
}

[data-primary="color_3"] .cd-h-timeline__navigation {
  border-color: #1f40b8;
}

[data-primary="color_3"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #1f40b8;
}

[data-primary="color_3"] .dd-handle {
  background: #99c990;
}

[data-primary="color_3"] .dd-handle:hover {
  background: #99c990;
}

[data-primary="color_3"] .dd3-content:hover {
  background: #99c990;
}

[data-primary="color_3"] .noUi-connect {
  background-color: #99c990;
}

[data-primary="color_3"] .noUi-connect.c-3-color {
  background-color: #99c990;
}

[data-primary="color_3"] .noUi-horizontal .noUi-handle,
[data-primary="color_3"] .noUi-vertical .noUi-handle {
  background-color: #99c990;
}

[data-primary="color_3"] #slider-toggle.off .noUi-handle {
  border-color: #99c990;
}

[data-primary="color_3"] .pignose-calendar {
  border-color: #99c990;
}

[data-primary="color_3"] .pignose-calendar .pignose-calendar-top-date {
  background-color: #99c990;
}

[data-primary="color_3"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #99c990;
}

[data-primary="color_3"] .bootstrap-tagsinput .tag {
  background-color: #99c990;
}

[data-primary="color_3"] .toast-success {
  background-color: #99c990;
}

[data-primary="color_3"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #99c990;
}

[data-primary="color_3"] .accordion-header-bg .accordion__header--primary {
  background-color: #99c990;
}

[data-primary="color_3"] .alert-primary {
  background: #6b86e6;
  border-color: #6b86e6;
  color: #99c990;
}

[data-primary="color_3"] .alert-alt.alert-primary {
  border-left: 4px solid #99c990;
}

[data-primary="color_3"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #0c1947 !important;
}

[data-primary="color_3"] .alert.alert-primary.solid {
  background: #99c990;
  border-color: #99c990;
}

[data-primary="color_3"] .alert.alert-outline-primary {
  color: #99c990;
  border-color: #99c990;
}

[data-primary="color_3"] .badge-outline-primary {
  border: 1px solid #99c990;
  color: #99c990;
}

[data-primary="color_3"] .badge-primary {
  background-color: #99c990;
}

[data-primary="color_3"] .page-titles h4,
[data-primary="color_3"] .page-titles .h4 {
  color: #99c990;
}

[data-primary="color_3"] .card-action>a {
  background: black;
}

[data-primary="color_3"] .card-action .dropdown {
  background: black;
  color: #99c990;
}

[data-primary="color_3"] .card-action .dropdown:hover,
[data-primary="color_3"] .card-action .dropdown:focus {
  background: black;
}

[data-primary="color_3"] .card-loader i {
  background: #193495;
}

[data-primary="color_3"] .dropdown-outline {
  border: 0.1rem solid #99c990;
}

[data-primary="color_3"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #99c990;
}

[data-primary="color_3"] .card-action .custom-dropdown {
  background: #8198ea;
}

[data-primary="color_3"] .card-action .custom-dropdown.show,
[data-primary="color_3"] .card-action .custom-dropdown:focus,
[data-primary="color_3"] .card-action .custom-dropdown:hover {
  background: #99c990;
}

[data-primary="color_3"] .label-primary {
  background: #99c990;
}

[data-primary="color_3"] .pagination .page-item .page-link:hover {
  background: #99c990;
  border-color: #99c990;
}

[data-primary="color_3"] .pagination .page-item.active .page-link {
  background-color: #99c990;
  border-color: #99c990;
}

[data-primary="color_3"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_3"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #99c990;
}

[data-primary="color_3"] .progress-bar {
  background-color: #99c990;
}

[data-primary="color_3"] .progress-bar-primary {
  background-color: #99c990;
}

[data-primary="color_3"] .ribbon__four {
  background-color: #99c990;
}

[data-primary="color_3"] .ribbon__four:after,
[data-primary="color_3"] .ribbon__four:before {
  background-color: #5574e3;
}

[data-primary="color_3"] .ribbon__five {
  background-color: #99c990;
}

[data-primary="color_3"] .ribbon__five::before {
  border-color: transparent transparent #99c990 transparent;
}

[data-primary="color_3"] .ribbon__six {
  background-color: #99c990;
}

[data-primary="color_3"] .multi-steps>li {
  color: #99c990;
}

[data-primary="color_3"] .multi-steps>li:after {
  background-color: #99c990;
}

[data-primary="color_3"] .multi-steps>li.is-active:before {
  border-color: #99c990;
}

[data-primary="color_3"] .timeline-badge.primary {
  background-color: #99c990 !important;
}

[data-primary="color_3"] .tooltip-wrapper button:hover {
  background: #99c990;
}

[data-primary="color_3"] .chart_widget_tab_one .nav-link.active {
  background-color: #99c990;
  border: 1px solid #99c990;
}

[data-primary="color_3"] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #99c990;
}

[data-primary="color_3"] .social-icon2 a {
  border: 0.1rem solid #99c990;
}

[data-primary="color_3"] .social-icon2 i {
  color: #99c990;
}

[data-primary="color_3"] .social-icon3 ul li a:hover i {
  color: #99c990;
}

[data-primary="color_3"] .bgl-primary {
  background: #8198ea;
  border-color: #8198ea;
  color: #99c990;
}

[data-primary="color_3"] .tdl-holder input[type=checkbox]:checked+i {
  background: #99c990;
}

[data-primary="color_3"] .footer .copyright a {
  color: #99c990;
}

[data-primary="color_3"] .hamburger .line {
  background: #99c990;
}

[data-primary="color_3"] svg.pulse-svg .first-circle,
[data-primary="color_3"] svg.pulse-svg .second-circle,
[data-primary="color_3"] svg.pulse-svg .third-circle {
  fill: #99c990;
}

[data-primary="color_3"] .pulse-css {
  background: #99c990;
}

[data-primary="color_3"] .pulse-css:after,
[data-primary="color_3"] .pulse-css:before {
  background-color: #99c990;
}

[data-primary="color_3"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #99c990;
}

[data-primary="color_3"] .header-right .header-profile .dropdown-menu a:hover,
[data-primary="color_3"] .header-right .header-profile .dropdown-menu a:focus,
[data-primary="color_3"] .header-right .header-profile .dropdown-menu a.active {
  color: #99c990;
}

[data-primary="color_3"] .header-right .header-profile .profile_title {
  background: #99c990;
}

[data-primary="color_3"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #99c990 !important;
}

[data-primary="color_3"] .deznav .metismenu>li>a svg {
  color: #99c990;
}

[data-primary="color_3"] .deznav .metismenu>li:hover>a,
[data-primary="color_3"] .deznav .metismenu>li:focus>a {
  color: #99c990;
}

[data-primary="color_3"] .deznav .metismenu>li.mm-active>a {
  color: #99c990;
}

[data-primary="color_3"] .deznav .metismenu ul a:hover,
[data-primary="color_3"] .deznav .metismenu ul a:focus,
[data-primary="color_3"] .deznav .metismenu ul a.mm-active {
  color: #99c990;
}

@media (min-width: 767px) {

  [data-primary="color_3"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:hover>a,
  [data-primary="color_3"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:focus>a,
  [data-primary="color_3"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:active>a,
  [data-primary="color_3"] [data-sidebar-style="modern"] .deznav .metismenu>li>a.mm-active>a {
    background-color: #d8e0f9;
  }
}

[data-primary="color_3"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #99c990;
}

[data-primary="color_3"] .nav-user {
  background: #99c990;
}

[data-primary="color_3"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #99c990;
}

[data-primary="color_3"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
  color: #99c990;
}

[data-primary="color_3"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #99c990;
  border-color: #99c990;
}

[data-primary="color_3"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #99c990;
  border-color: #99c990;
}

[data-primary="color_3"][data-theme-version="dark"] .header-left input:focus {
  border-color: #99c990;
}

[data-primary="color_3"][data-theme-version="dark"] .loader__bar {
  background: #99c990;
}

[data-primary="color_3"][data-theme-version="dark"] .loader__ball {
  background: #99c990;
}

[data-primary="color_3"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #99c990;
}

[data-primary="color_3"] .new-arrival-content .price {
  color: #99c990;
}

[data-primary="color_3"] .chart-link a i.text-primary {
  color: #99c990;
}

[data-primary="color_3"] #user-activity .nav-tabs .nav-link.active {
  background: #99c990;
  border-color: #99c990;
}

[data-primary="color_3"] span#counter {
  color: #99c990;
}

[data-primary="color_3"] .welcome-content:after {
  background: #99c990;
}

[data-primary="color_3"] .timeline-badge {
  background-color: #99c990;
}

[data-primary="color_3"] .page-timeline .timeline-workplan[data-primary="color_3"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(30, 63, 180, 0.4);
}

[data-primary="color_3"] .sk-three-bounce .sk-child {
  background-color: #99c990;
}

[data-primary="color_3"] .dropdown-item.active,
[data-primary="color_3"] .dropdown-item:active {
  color: #fff;
  background-color: #99c990;
}

[data-primary="color_3"] .overlay-box:after {
  background: #99c990;
}

[data-primary="color_3"] .btn-primary {
  background-color: #99c990;
  border-color: #99c990;
}

[data-primary="color_3"] .bg-primary {
  background-color: #99c990 !important;
}

[data-primary="color_3"] .text-primary {
  color: #99c990 !important;
}

[data-primary="color_3"] .btn-primary:hover {
  background-color: #132872;
  border-color: #132872;
}

[data-primary="color_3"] .btn-outline-primary {
  color: #99c990;
  border-color: #99c990;
}

[data-primary="color_3"] .btn-outline-primary:hover {
  background-color: #99c990;
  border-color: #99c990;
}

[data-primary="color_3"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_3"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_3"] .show>.btn-primary.dropdown-toggle,
[data-primary="color_3"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_3"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_3"] .show>.btn-outline-primary.dropdown-toggle {
  background-color: #132872;
  border-color: #132872;
}

[data-primary="color_4"] a:hover,
[data-primary="color_4"] a:focus,
[data-primary="color_4"] a.active {
  color: #4527a0;
}

[data-primary="color_4"] [data-class="bg-primary"]:before {
  background: #4527a0;
}

[data-primary="color_4"] .email-left-box .intro-title {
  background: rgba(69, 39, 160, 0.1);
}

[data-primary="color_4"] .email-left-box .intro-title i {
  color: #4527a0;
}

[data-primary="color_4"] .widget-stat .media .media-body h4,
[data-primary="color_4"] .widget-stat .media .media-body .h4 {
  color: #4527a0 !important;
}

[data-primary="color_4"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(69, 39, 160, 0.1);
}

[data-primary="color_4"] .mail-list .list-group-item.active i {
  color: #4527a0;
}

[data-primary="color_4"] .single-mail.active {
  background: #4527a0;
}

[data-primary="color_4"] .profile-info h4.text-primary,
[data-primary="color_4"] .profile-info .text-primary.h4 {
  color: #4527a0 !important;
}

[data-primary="color_4"] .profile-tab .nav-item .nav-link:hover,
[data-primary="color_4"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #4527a0;
  color: #4527a0;
}

[data-primary="color_4"] .amChartsInputField {
  border: 0;
  background: #4527a0;
}

[data-primary="color_4"] .amcharts-period-input,
[data-primary="color_4"] .amcharts-period-input-selected {
  background: #4527a0;
}

[data-primary="color_4"] .morris-hover {
  background: #4527a0;
}

[data-primary="color_4"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #4527a0;
}

[data-primary="color_4"] .custom-select:focus {
  border-color: #4527a0;
  color: #4527a0;
}

[data-primary="color_4"] .daterangepicker td.active {
  background-color: #4527a0;
}

[data-primary="color_4"] .daterangepicker td.active:hover {
  background-color: #4527a0;
}

[data-primary="color_4"] .daterangepicker button.applyBtn {
  background-color: #4527a0;
  border-color: #4527a0;
}

[data-primary="color_4"] .wizard>.steps li.current a {
  background-color: #4527a0;
}

[data-primary="color_4"] .wizard .skip-email a {
  color: #4527a0;
}

[data-primary="color_4"] .wizard>.actions li:not(.disabled) a {
  background-color: #4527a0;
}

[data-primary="color_4"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #4527a0;
}

[data-primary="color_4"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #4527a0;
  border-color: #4527a0;
}

[data-primary="color_4"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #4527a0;
}

[data-primary="color_4"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #4527a0;
}

[data-primary="color_4"] .is-invalid .input-group-prepend .input-group-text i {
  color: #6642d1;
}

[data-primary="color_4"] .datamaps-hoverover {
  color: #4527a0;
  border: 1px solid rgba(69, 39, 160, 0.3);
}

[data-primary="color_4"] .jqvmap-zoomin,
[data-primary="color_4"] .jqvmap-zoomout {
  background-color: #4527a0;
}

[data-primary="color_4"] .table .thead-primary th {
  background-color: #4527a0;
}

[data-primary="color_4"] .table.primary-table-bg-hover thead th {
  background-color: #3c228b;
}

[data-primary="color_4"] .table.primary-table-bg-hover tbody tr {
  background-color: #4527a0;
}

[data-primary="color_4"] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #4e2cb5;
}

[data-primary="color_4"] .table.primary-table-bg-hover tbody tr:not(:last-child) td,
[data-primary="color_4"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 1px solid #3c228b;
}

[data-primary="color_4"] table.dataTable tr.selected {
  color: #4527a0;
}

[data-primary="color_4"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #4527a0 !important;
  background: rgba(69, 39, 160, 0.1);
}

[data-primary="color_4"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #4527a0 !important;
  background: rgba(69, 39, 160, 0.1);
}

[data-primary="color_4"] .clipboard-btn:hover {
  background-color: #4527a0;
}

[data-primary="color_4"] .cd-h-timeline__dates::before {
  background: #4527a0;
}

[data-primary="color_4"] .cd-h-timeline__dates::after {
  background: #4527a0;
}

[data-primary="color_4"] .cd-h-timeline__line {
  background-color: #4527a0;
}

[data-primary="color_4"] .cd-h-timeline__date:after {
  border-color: #4728a4;
  background-color: #4527a0;
}

[data-primary="color_4"] .cd-h-timeline__navigation {
  border-color: #4728a4;
}

[data-primary="color_4"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #4728a4;
}

[data-primary="color_4"] .dd-handle {
  background: #4527a0;
}

[data-primary="color_4"] .dd-handle:hover {
  background: #4527a0;
}

[data-primary="color_4"] .dd3-content:hover {
  background: #4527a0;
}

[data-primary="color_4"] .noUi-connect {
  background-color: #4527a0;
}

[data-primary="color_4"] .noUi-connect.c-3-color {
  background-color: #4527a0;
}

[data-primary="color_4"] .noUi-horizontal .noUi-handle,
[data-primary="color_4"] .noUi-vertical .noUi-handle {
  background-color: #4527a0;
}

[data-primary="color_4"] #slider-toggle.off .noUi-handle {
  border-color: #4527a0;
}

[data-primary="color_4"] .pignose-calendar {
  border-color: #4527a0;
}

[data-primary="color_4"] .pignose-calendar .pignose-calendar-top-date {
  background-color: #4527a0;
}

[data-primary="color_4"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #4527a0;
}

[data-primary="color_4"] .bootstrap-tagsinput .tag {
  background-color: #4527a0;
}

[data-primary="color_4"] .toast-success {
  background-color: #4527a0;
}

[data-primary="color_4"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #4527a0;
}

[data-primary="color_4"] .accordion-header-bg .accordion__header--primary {
  background-color: #4527a0;
}

[data-primary="color_4"] .alert-primary {
  background: #876bdb;
  border-color: #876bdb;
  color: #4527a0;
}

[data-primary="color_4"] .alert-alt.alert-primary {
  border-left: 4px solid #4527a0;
}

[data-primary="color_4"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #190e39 !important;
}

[data-primary="color_4"] .alert.alert-primary.solid {
  background: #4527a0;
  border-color: #4527a0;
}

[data-primary="color_4"] .alert.alert-outline-primary {
  color: #4527a0;
  border-color: #4527a0;
}

[data-primary="color_4"] .badge-outline-primary {
  border: 1px solid #4527a0;
  color: #4527a0;
}

[data-primary="color_4"] .badge-primary {
  background-color: #4527a0;
}

[data-primary="color_4"] .page-titles h4,
[data-primary="color_4"] .page-titles .h4 {
  color: #4527a0;
}

[data-primary="color_4"] .card-action>a {
  background: black;
}

[data-primary="color_4"] .card-action .dropdown {
  background: black;
  color: #4527a0;
}

[data-primary="color_4"] .card-action .dropdown:hover,
[data-primary="color_4"] .card-action .dropdown:focus {
  background: black;
}

[data-primary="color_4"] .card-loader i {
  background: #392083;
}

[data-primary="color_4"] .dropdown-outline {
  border: 0.1rem solid #4527a0;
}

[data-primary="color_4"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #4527a0;
}

[data-primary="color_4"] .card-action .custom-dropdown {
  background: #9880e0;
}

[data-primary="color_4"] .card-action .custom-dropdown.show,
[data-primary="color_4"] .card-action .custom-dropdown:focus,
[data-primary="color_4"] .card-action .custom-dropdown:hover {
  background: #4527a0;
}

[data-primary="color_4"] .label-primary {
  background: #4527a0;
}

[data-primary="color_4"] .pagination .page-item .page-link:hover {
  background: #4527a0;
  border-color: #4527a0;
}

[data-primary="color_4"] .pagination .page-item.active .page-link {
  background-color: #4527a0;
  border-color: #4527a0;
}

[data-primary="color_4"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_4"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #4527a0;
}

[data-primary="color_4"] .progress-bar {
  background-color: #4527a0;
}

[data-primary="color_4"] .progress-bar-primary {
  background-color: #4527a0;
}

[data-primary="color_4"] .ribbon__four {
  background-color: #4527a0;
}

[data-primary="color_4"] .ribbon__four:after,
[data-primary="color_4"] .ribbon__four:before {
  background-color: #7657d6;
}

[data-primary="color_4"] .ribbon__five {
  background-color: #4527a0;
}

[data-primary="color_4"] .ribbon__five::before {
  border-color: transparent transparent #4527a0 transparent;
}

[data-primary="color_4"] .ribbon__six {
  background-color: #4527a0;
}

[data-primary="color_4"] .multi-steps>li {
  color: #4527a0;
}

[data-primary="color_4"] .multi-steps>li:after {
  background-color: #4527a0;
}

[data-primary="color_4"] .multi-steps>li.is-active:before {
  border-color: #4527a0;
}

[data-primary="color_4"] .timeline-badge.primary {
  background-color: #4527a0 !important;
}

[data-primary="color_4"] .tooltip-wrapper button:hover {
  background: #4527a0;
}

[data-primary="color_4"] .chart_widget_tab_one .nav-link.active {
  background-color: #4527a0;
  border: 1px solid #4527a0;
}

[data-primary="color_4"] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #4527a0;
}

[data-primary="color_4"] .social-icon2 a {
  border: 0.1rem solid #4527a0;
}

[data-primary="color_4"] .social-icon2 i {
  color: #4527a0;
}

[data-primary="color_4"] .social-icon3 ul li a:hover i {
  color: #4527a0;
}

[data-primary="color_4"] .bgl-primary {
  background: #9880e0;
  border-color: #9880e0;
  color: #4527a0;
}

[data-primary="color_4"] .tdl-holder input[type=checkbox]:checked+i {
  background: #4527a0;
}

[data-primary="color_4"] .footer .copyright a {
  color: #4527a0;
}

[data-primary="color_4"] .hamburger .line {
  background: #4527a0;
}

[data-primary="color_4"] svg.pulse-svg .first-circle,
[data-primary="color_4"] svg.pulse-svg .second-circle,
[data-primary="color_4"] svg.pulse-svg .third-circle {
  fill: #4527a0;
}

[data-primary="color_4"] .pulse-css {
  background: #4527a0;
}

[data-primary="color_4"] .pulse-css:after,
[data-primary="color_4"] .pulse-css:before {
  background-color: #4527a0;
}

[data-primary="color_4"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #4527a0;
}

[data-primary="color_4"] .header-right .header-profile .dropdown-menu a:hover,
[data-primary="color_4"] .header-right .header-profile .dropdown-menu a:focus,
[data-primary="color_4"] .header-right .header-profile .dropdown-menu a.active {
  color: #4527a0;
}

[data-primary="color_4"] .header-right .header-profile .profile_title {
  background: #4527a0;
}

[data-primary="color_4"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #4527a0 !important;
}

[data-primary="color_4"] .deznav .metismenu>li>a svg {
  color: #4527a0;
}

[data-primary="color_4"] .deznav .metismenu>li:hover>a,
[data-primary="color_4"] .deznav .metismenu>li:focus>a {
  color: #4527a0;
}

[data-primary="color_4"] .deznav .metismenu>li.mm-active>a {
  color: #4527a0;
}

[data-primary="color_4"] .deznav .metismenu ul a:hover,
[data-primary="color_4"] .deznav .metismenu ul a:focus,
[data-primary="color_4"] .deznav .metismenu ul a.mm-active {
  color: #4527a0;
}

@media (min-width: 767px) {

  [data-primary="color_4"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:hover>a,
  [data-primary="color_4"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:focus>a,
  [data-primary="color_4"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:active>a,
  [data-primary="color_4"] [data-sidebar-style="modern"] .deznav .metismenu>li>a.mm-active>a {
    background-color: #dad2f4;
  }
}

[data-primary="color_4"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #4527a0;
}

[data-primary="color_4"] .nav-user {
  background: #4527a0;
}

[data-primary="color_4"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #4527a0;
}

[data-primary="color_4"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
  color: #4527a0;
}

[data-primary="color_4"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #4527a0;
  border-color: #4527a0;
}

[data-primary="color_4"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #4527a0;
  border-color: #4527a0;
}

[data-primary="color_4"][data-theme-version="dark"] .header-left input:focus {
  border-color: #4527a0;
}

[data-primary="color_4"][data-theme-version="dark"] .loader__bar {
  background: #4527a0;
}

[data-primary="color_4"][data-theme-version="dark"] .loader__ball {
  background: #4527a0;
}

[data-primary="color_4"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #4527a0;
}

[data-primary="color_4"] .new-arrival-content .price {
  color: #4527a0;
}

[data-primary="color_4"] .chart-link a i.text-primary {
  color: #4527a0;
}

[data-primary="color_4"] #user-activity .nav-tabs .nav-link.active {
  background: #4527a0;
  border-color: #4527a0;
}

[data-primary="color_4"] span#counter {
  color: #4527a0;
}

[data-primary="color_4"] .welcome-content:after {
  background: #4527a0;
}

[data-primary="color_4"] .timeline-badge {
  background-color: #4527a0;
}

[data-primary="color_4"] .page-timeline .timeline-workplan[data-primary="color_4"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(69, 39, 160, 0.4);
}

[data-primary="color_4"] .sk-three-bounce .sk-child {
  background-color: #4527a0;
}

[data-primary="color_4"] .dropdown-item.active,
[data-primary="color_4"] .dropdown-item:active {
  color: #fff;
  background-color: #4527a0;
}

[data-primary="color_4"] .overlay-box:after {
  background: #4527a0;
}

[data-primary="color_4"] .btn-primary {
  background-color: #4527a0;
  border-color: #4527a0;
}

[data-primary="color_4"] .bg-primary {
  background-color: #4527a0 !important;
}

[data-primary="color_4"] .text-primary {
  color: #4527a0 !important;
}

[data-primary="color_4"] .btn-primary:hover {
  background-color: #2a1862;
  border-color: #2a1862;
}

[data-primary="color_4"] .btn-outline-primary {
  color: #4527a0;
  border-color: #4527a0;
}

[data-primary="color_4"] .btn-outline-primary:hover {
  background-color: #4527a0;
  border-color: #4527a0;
}

[data-primary="color_4"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_4"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_4"] .show>.btn-primary.dropdown-toggle,
[data-primary="color_4"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_4"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_4"] .show>.btn-outline-primary.dropdown-toggle {
  background-color: #2a1862;
  border-color: #2a1862;
}

[data-primary="color_5"] a:hover,
[data-primary="color_5"] a:focus,
[data-primary="color_5"] a.active {
  color: #c62828;
}

[data-primary="color_5"] [data-class="bg-primary"]:before {
  background: #c62828;
}

[data-primary="color_5"] .email-left-box .intro-title {
  background: rgba(198, 40, 40, 0.1);
}

[data-primary="color_5"] .email-left-box .intro-title i {
  color: #c62828;
}

[data-primary="color_5"] .widget-stat .media .media-body h4,
[data-primary="color_5"] .widget-stat .media .media-body .h4 {
  color: #c62828 !important;
}

[data-primary="color_5"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(198, 40, 40, 0.1);
}

[data-primary="color_5"] .mail-list .list-group-item.active i {
  color: #c62828;
}

[data-primary="color_5"] .single-mail.active {
  background: #c62828;
}

[data-primary="color_5"] .profile-info h4.text-primary,
[data-primary="color_5"] .profile-info .text-primary.h4 {
  color: #c62828 !important;
}

[data-primary="color_5"] .profile-tab .nav-item .nav-link:hover,
[data-primary="color_5"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #c62828;
  color: #c62828;
}

[data-primary="color_5"] .amChartsInputField {
  border: 0;
  background: #c62828;
}

[data-primary="color_5"] .amcharts-period-input,
[data-primary="color_5"] .amcharts-period-input-selected {
  background: #c62828;
}

[data-primary="color_5"] .morris-hover {
  background: #c62828;
}

[data-primary="color_5"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #c62828;
}

[data-primary="color_5"] .custom-select:focus {
  border-color: #c62828;
  color: #c62828;
}

[data-primary="color_5"] .daterangepicker td.active {
  background-color: #c62828;
}

[data-primary="color_5"] .daterangepicker td.active:hover {
  background-color: #c62828;
}

[data-primary="color_5"] .daterangepicker button.applyBtn {
  background-color: #c62828;
  border-color: #c62828;
}

[data-primary="color_5"] .wizard>.steps li.current a {
  background-color: #c62828;
}

[data-primary="color_5"] .wizard .skip-email a {
  color: #c62828;
}

[data-primary="color_5"] .wizard>.actions li:not(.disabled) a {
  background-color: #c62828;
}

[data-primary="color_5"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #c62828;
}

[data-primary="color_5"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #c62828;
  border-color: #c62828;
}

[data-primary="color_5"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #c62828;
}

[data-primary="color_5"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #c62828;
}

[data-primary="color_5"] .is-invalid .input-group-prepend .input-group-text i {
  color: #de5c5c;
}

[data-primary="color_5"] .datamaps-hoverover {
  color: #c62828;
  border: 1px solid rgba(198, 40, 40, 0.3);
}

[data-primary="color_5"] .jqvmap-zoomin,
[data-primary="color_5"] .jqvmap-zoomout {
  background-color: #c62828;
}

[data-primary="color_5"] .table .thead-primary th {
  background-color: #c62828;
}

[data-primary="color_5"] .table.primary-table-bg-hover thead th {
  background-color: #b12424;
}

[data-primary="color_5"] .table.primary-table-bg-hover tbody tr {
  background-color: #c62828;
}

[data-primary="color_5"] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #d63232;
}

[data-primary="color_5"] .table.primary-table-bg-hover tbody tr:not(:last-child) td,
[data-primary="color_5"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 1px solid #b12424;
}

[data-primary="color_5"] table.dataTable tr.selected {
  color: #c62828;
}

[data-primary="color_5"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #c62828 !important;
  background: rgba(198, 40, 40, 0.1);
}

[data-primary="color_5"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #c62828 !important;
  background: rgba(198, 40, 40, 0.1);
}

[data-primary="color_5"] .clipboard-btn:hover {
  background-color: #c62828;
}

[data-primary="color_5"] .cd-h-timeline__dates::before {
  background: #c62828;
}

[data-primary="color_5"] .cd-h-timeline__dates::after {
  background: #c62828;
}

[data-primary="color_5"] .cd-h-timeline__line {
  background-color: #c62828;
}

[data-primary="color_5"] .cd-h-timeline__date:after {
  border-color: #ca2929;
  background-color: #c62828;
}

[data-primary="color_5"] .cd-h-timeline__navigation {
  border-color: #ca2929;
}

[data-primary="color_5"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #ca2929;
}

[data-primary="color_5"] .dd-handle {
  background: #c62828;
}

[data-primary="color_5"] .dd-handle:hover {
  background: #c62828;
}

[data-primary="color_5"] .dd3-content:hover {
  background: #c62828;
}

[data-primary="color_5"] .noUi-connect {
  background-color: #c62828;
}

[data-primary="color_5"] .noUi-connect.c-3-color {
  background-color: #c62828;
}

[data-primary="color_5"] .noUi-horizontal .noUi-handle,
[data-primary="color_5"] .noUi-vertical .noUi-handle {
  background-color: #c62828;
}

[data-primary="color_5"] #slider-toggle.off .noUi-handle {
  border-color: #c62828;
}

[data-primary="color_5"] .pignose-calendar {
  border-color: #c62828;
}

[data-primary="color_5"] .pignose-calendar .pignose-calendar-top-date {
  background-color: #c62828;
}

[data-primary="color_5"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #c62828;
}

[data-primary="color_5"] .bootstrap-tagsinput .tag {
  background-color: #c62828;
}

[data-primary="color_5"] .toast-success {
  background-color: #c62828;
}

[data-primary="color_5"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #c62828;
}

[data-primary="color_5"] .accordion-header-bg .accordion__header--primary {
  background-color: #c62828;
}

[data-primary="color_5"] .alert-primary {
  background: #e78787;
  border-color: #e78787;
  color: #c62828;
}

[data-primary="color_5"] .alert-alt.alert-primary {
  border-left: 4px solid #c62828;
}

[data-primary="color_5"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #5c1313 !important;
}

[data-primary="color_5"] .alert.alert-primary.solid {
  background: #c62828;
  border-color: #c62828;
}

[data-primary="color_5"] .alert.alert-outline-primary {
  color: #c62828;
  border-color: #c62828;
}

[data-primary="color_5"] .badge-outline-primary {
  border: 1px solid #c62828;
  color: #c62828;
}

[data-primary="color_5"] .badge-primary {
  background-color: #c62828;
}

[data-primary="color_5"] .page-titles h4,
[data-primary="color_5"] .page-titles .h4 {
  color: #c62828;
}

[data-primary="color_5"] .card-action>a {
  background: black;
}

[data-primary="color_5"] .card-action .dropdown {
  background: black;
  color: #c62828;
}

[data-primary="color_5"] .card-action .dropdown:hover,
[data-primary="color_5"] .card-action .dropdown:focus {
  background: black;
}

[data-primary="color_5"] .card-loader i {
  background: #a82222;
}

[data-primary="color_5"] .dropdown-outline {
  border: 0.1rem solid #c62828;
}

[data-primary="color_5"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #c62828;
}

[data-primary="color_5"] .card-action .custom-dropdown {
  background: #eb9c9c;
}

[data-primary="color_5"] .card-action .custom-dropdown.show,
[data-primary="color_5"] .card-action .custom-dropdown:focus,
[data-primary="color_5"] .card-action .custom-dropdown:hover {
  background: #c62828;
}

[data-primary="color_5"] .label-primary {
  background: #c62828;
}

[data-primary="color_5"] .pagination .page-item .page-link:hover {
  background: #c62828;
  border-color: #c62828;
}

[data-primary="color_5"] .pagination .page-item.active .page-link {
  background-color: #c62828;
  border-color: #c62828;
}

[data-primary="color_5"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_5"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #c62828;
}

[data-primary="color_5"] .progress-bar {
  background-color: #c62828;
}

[data-primary="color_5"] .progress-bar-primary {
  background-color: #c62828;
}

[data-primary="color_5"] .ribbon__four {
  background-color: #c62828;
}

[data-primary="color_5"] .ribbon__four:after,
[data-primary="color_5"] .ribbon__four:before {
  background-color: #e27272;
}

[data-primary="color_5"] .ribbon__five {
  background-color: #c62828;
}

[data-primary="color_5"] .ribbon__five::before {
  border-color: transparent transparent #c62828 transparent;
}

[data-primary="color_5"] .ribbon__six {
  background-color: #c62828;
}

[data-primary="color_5"] .multi-steps>li {
  color: #c62828;
}

[data-primary="color_5"] .multi-steps>li:after {
  background-color: #c62828;
}

[data-primary="color_5"] .multi-steps>li.is-active:before {
  border-color: #c62828;
}

[data-primary="color_5"] .timeline-badge.primary {
  background-color: #c62828 !important;
}

[data-primary="color_5"] .tooltip-wrapper button:hover {
  background: #c62828;
}

[data-primary="color_5"] .chart_widget_tab_one .nav-link.active {
  background-color: #c62828;
  border: 1px solid #c62828;
}

[data-primary="color_5"] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #c62828;
}

[data-primary="color_5"] .social-icon2 a {
  border: 0.1rem solid #c62828;
}

[data-primary="color_5"] .social-icon2 i {
  color: #c62828;
}

[data-primary="color_5"] .social-icon3 ul li a:hover i {
  color: #c62828;
}

[data-primary="color_5"] .bgl-primary {
  background: #eb9c9c;
  border-color: #eb9c9c;
  color: #c62828;
}

[data-primary="color_5"] .tdl-holder input[type=checkbox]:checked+i {
  background: #c62828;
}

[data-primary="color_5"] .footer .copyright a {
  color: #c62828;
}

[data-primary="color_5"] .hamburger .line {
  background: #c62828;
}

[data-primary="color_5"] svg.pulse-svg .first-circle,
[data-primary="color_5"] svg.pulse-svg .second-circle,
[data-primary="color_5"] svg.pulse-svg .third-circle {
  fill: #c62828;
}

[data-primary="color_5"] .pulse-css {
  background: #c62828;
}

[data-primary="color_5"] .pulse-css:after,
[data-primary="color_5"] .pulse-css:before {
  background-color: #c62828;
}

[data-primary="color_5"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #c62828;
}

[data-primary="color_5"] .header-right .header-profile .dropdown-menu a:hover,
[data-primary="color_5"] .header-right .header-profile .dropdown-menu a:focus,
[data-primary="color_5"] .header-right .header-profile .dropdown-menu a.active {
  color: #c62828;
}

[data-primary="color_5"] .header-right .header-profile .profile_title {
  background: #c62828;
}

[data-primary="color_5"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #c62828 !important;
}

[data-primary="color_5"] .deznav .metismenu>li>a svg {
  color: #c62828;
}

[data-primary="color_5"] .deznav .metismenu>li:hover>a,
[data-primary="color_5"] .deznav .metismenu>li:focus>a {
  color: #c62828;
}

[data-primary="color_5"] .deznav .metismenu>li.mm-active>a {
  color: #c62828;
}

[data-primary="color_5"] .deznav .metismenu ul a:hover,
[data-primary="color_5"] .deznav .metismenu ul a:focus,
[data-primary="color_5"] .deznav .metismenu ul a.mm-active {
  color: #c62828;
}

@media (min-width: 767px) {

  [data-primary="color_5"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:hover>a,
  [data-primary="color_5"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:focus>a,
  [data-primary="color_5"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:active>a,
  [data-primary="color_5"] [data-sidebar-style="modern"] .deznav .metismenu>li>a.mm-active>a {
    background-color: #fcf1f1;
  }
}

[data-primary="color_5"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #c62828;
}

[data-primary="color_5"] .nav-user {
  background: #c62828;
}

[data-primary="color_5"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #c62828;
}

[data-primary="color_5"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
  color: #c62828;
}

[data-primary="color_5"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #c62828;
  border-color: #c62828;
}

[data-primary="color_5"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #c62828;
  border-color: #c62828;
}

[data-primary="color_5"][data-theme-version="dark"] .header-left input:focus {
  border-color: #c62828;
}

[data-primary="color_5"][data-theme-version="dark"] .loader__bar {
  background: #c62828;
}

[data-primary="color_5"][data-theme-version="dark"] .loader__ball {
  background: #c62828;
}

[data-primary="color_5"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #c62828;
}

[data-primary="color_5"] .new-arrival-content .price {
  color: #c62828;
}

[data-primary="color_5"] .chart-link a i.text-primary {
  color: #c62828;
}

[data-primary="color_5"] #user-activity .nav-tabs .nav-link.active {
  background: #c62828;
  border-color: #c62828;
}

[data-primary="color_5"] span#counter {
  color: #c62828;
}

[data-primary="color_5"] .welcome-content:after {
  background: #c62828;
}

[data-primary="color_5"] .timeline-badge {
  background-color: #c62828;
}

[data-primary="color_5"] .page-timeline .timeline-workplan[data-primary="color_5"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(198, 40, 40, 0.4);
}

[data-primary="color_5"] .sk-three-bounce .sk-child {
  background-color: #c62828;
}

[data-primary="color_5"] .dropdown-item.active,
[data-primary="color_5"] .dropdown-item:active {
  color: #fff;
  background-color: #c62828;
}

[data-primary="color_5"] .overlay-box:after {
  background: #c62828;
}

[data-primary="color_5"] .btn-primary {
  background-color: #c62828;
  border-color: #c62828;
}

[data-primary="color_5"] .bg-primary {
  background-color: #c62828 !important;
}

[data-primary="color_5"] .text-primary {
  color: #c62828 !important;
}

[data-primary="color_5"] .btn-primary:hover {
  background-color: #861b1b;
  border-color: #861b1b;
}

[data-primary="color_5"] .btn-outline-primary {
  color: #c62828;
  border-color: #c62828;
}

[data-primary="color_5"] .btn-outline-primary:hover {
  background-color: #c62828;
  border-color: #c62828;
}

[data-primary="color_5"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_5"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_5"] .show>.btn-primary.dropdown-toggle,
[data-primary="color_5"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_5"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_5"] .show>.btn-outline-primary.dropdown-toggle {
  background-color: #861b1b;
  border-color: #861b1b;
}

[data-primary="color_6"] a:hover,
[data-primary="color_6"] a:focus,
[data-primary="color_6"] a.active {
  color: #283593;
}

[data-primary="color_6"] [data-class="bg-primary"]:before {
  background: #283593;
}

[data-primary="color_6"] .email-left-box .intro-title {
  background: rgba(40, 53, 147, 0.1);
}

[data-primary="color_6"] .email-left-box .intro-title i {
  color: #283593;
}

[data-primary="color_6"] .widget-stat .media .media-body h4,
[data-primary="color_6"] .widget-stat .media .media-body .h4 {
  color: #283593 !important;
}

[data-primary="color_6"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(40, 53, 147, 0.1);
}

[data-primary="color_6"] .mail-list .list-group-item.active i {
  color: #283593;
}

[data-primary="color_6"] .single-mail.active {
  background: #283593;
}

[data-primary="color_6"] .profile-info h4.text-primary,
[data-primary="color_6"] .profile-info .text-primary.h4 {
  color: #283593 !important;
}

[data-primary="color_6"] .profile-tab .nav-item .nav-link:hover,
[data-primary="color_6"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #283593;
  color: #283593;
}

[data-primary="color_6"] .amChartsInputField {
  border: 0;
  background: #283593;
}

[data-primary="color_6"] .amcharts-period-input,
[data-primary="color_6"] .amcharts-period-input-selected {
  background: #283593;
}

[data-primary="color_6"] .morris-hover {
  background: #283593;
}

[data-primary="color_6"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #283593;
}

[data-primary="color_6"] .custom-select:focus {
  border-color: #283593;
  color: #283593;
}

[data-primary="color_6"] .daterangepicker td.active {
  background-color: #283593;
}

[data-primary="color_6"] .daterangepicker td.active:hover {
  background-color: #283593;
}

[data-primary="color_6"] .daterangepicker button.applyBtn {
  background-color: #283593;
  border-color: #283593;
}

[data-primary="color_6"] .wizard>.steps li.current a {
  background-color: #283593;
}

[data-primary="color_6"] .wizard .skip-email a {
  color: #283593;
}

[data-primary="color_6"] .wizard>.actions li:not(.disabled) a {
  background-color: #283593;
}

[data-primary="color_6"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #283593;
}

[data-primary="color_6"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #283593;
  border-color: #283593;
}

[data-primary="color_6"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #283593;
}

[data-primary="color_6"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #283593;
}

[data-primary="color_6"] .is-invalid .input-group-prepend .input-group-text i {
  color: #3d4eca;
}

[data-primary="color_6"] .datamaps-hoverover {
  color: #283593;
  border: 1px solid rgba(40, 53, 147, 0.3);
}

[data-primary="color_6"] .jqvmap-zoomin,
[data-primary="color_6"] .jqvmap-zoomout {
  background-color: #283593;
}

[data-primary="color_6"] .table .thead-primary th {
  background-color: #283593;
}

[data-primary="color_6"] .table.primary-table-bg-hover thead th {
  background-color: #232e7f;
}

[data-primary="color_6"] .table.primary-table-bg-hover tbody tr {
  background-color: #283593;
}

[data-primary="color_6"] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #2d3ca7;
}

[data-primary="color_6"] .table.primary-table-bg-hover tbody tr:not(:last-child) td,
[data-primary="color_6"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 1px solid #232e7f;
}

[data-primary="color_6"] table.dataTable tr.selected {
  color: #283593;
}

[data-primary="color_6"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #283593 !important;
  background: rgba(40, 53, 147, 0.1);
}

[data-primary="color_6"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #283593 !important;
  background: rgba(40, 53, 147, 0.1);
}

[data-primary="color_6"] .clipboard-btn:hover {
  background-color: #283593;
}

[data-primary="color_6"] .cd-h-timeline__dates::before {
  background: #283593;
}

[data-primary="color_6"] .cd-h-timeline__dates::after {
  background: #283593;
}

[data-primary="color_6"] .cd-h-timeline__line {
  background-color: #283593;
}

[data-primary="color_6"] .cd-h-timeline__date:after {
  border-color: #293697;
  background-color: #283593;
}

[data-primary="color_6"] .cd-h-timeline__navigation {
  border-color: #293697;
}

[data-primary="color_6"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #293697;
}

[data-primary="color_6"] .dd-handle {
  background: #283593;
}

[data-primary="color_6"] .dd-handle:hover {
  background: #283593;
}

[data-primary="color_6"] .dd3-content:hover {
  background: #283593;
}

[data-primary="color_6"] .noUi-connect {
  background-color: #283593;
}

[data-primary="color_6"] .noUi-connect.c-3-color {
  background-color: #283593;
}

[data-primary="color_6"] .noUi-horizontal .noUi-handle,
[data-primary="color_6"] .noUi-vertical .noUi-handle {
  background-color: #283593;
}

[data-primary="color_6"] #slider-toggle.off .noUi-handle {
  border-color: #283593;
}

[data-primary="color_6"] .pignose-calendar {
  border-color: #283593;
}

[data-primary="color_6"] .pignose-calendar .pignose-calendar-top-date {
  background-color: #283593;
}

[data-primary="color_6"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #283593;
}

[data-primary="color_6"] .bootstrap-tagsinput .tag {
  background-color: #283593;
}

[data-primary="color_6"] .toast-success {
  background-color: #283593;
}

[data-primary="color_6"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #283593;
}

[data-primary="color_6"] .accordion-header-bg .accordion__header--primary {
  background-color: #283593;
}

[data-primary="color_6"] .alert-primary {
  background: #6573d5;
  border-color: #6573d5;
  color: #283593;
}

[data-primary="color_6"] .alert-alt.alert-primary {
  border-left: 4px solid #283593;
}

[data-primary="color_6"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #0d112f !important;
}

[data-primary="color_6"] .alert.alert-primary.solid {
  background: #283593;
  border-color: #283593;
}

[data-primary="color_6"] .alert.alert-outline-primary {
  color: #283593;
  border-color: #283593;
}

[data-primary="color_6"] .badge-outline-primary {
  border: 1px solid #283593;
  color: #283593;
}

[data-primary="color_6"] .badge-primary {
  background-color: #283593;
}

[data-primary="color_6"] .page-titles h4,
[data-primary="color_6"] .page-titles .h4 {
  color: #283593;
}

[data-primary="color_6"] .card-action>a {
  background: black;
}

[data-primary="color_6"] .card-action .dropdown {
  background: black;
  color: #283593;
}

[data-primary="color_6"] .card-action .dropdown:hover,
[data-primary="color_6"] .card-action .dropdown:focus {
  background: black;
}

[data-primary="color_6"] .card-loader i {
  background: #202b77;
}

[data-primary="color_6"] .dropdown-outline {
  border: 0.1rem solid #283593;
}

[data-primary="color_6"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #283593;
}

[data-primary="color_6"] .card-action .custom-dropdown {
  background: #7985db;
}

[data-primary="color_6"] .card-action .custom-dropdown.show,
[data-primary="color_6"] .card-action .custom-dropdown:focus,
[data-primary="color_6"] .card-action .custom-dropdown:hover {
  background: #283593;
}

[data-primary="color_6"] .label-primary {
  background: #283593;
}

[data-primary="color_6"] .pagination .page-item .page-link:hover {
  background: #283593;
  border-color: #283593;
}

[data-primary="color_6"] .pagination .page-item.active .page-link {
  background-color: #283593;
  border-color: #283593;
}

[data-primary="color_6"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_6"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #283593;
}

[data-primary="color_6"] .progress-bar {
  background-color: #283593;
}

[data-primary="color_6"] .progress-bar-primary {
  background-color: #283593;
}

[data-primary="color_6"] .ribbon__four {
  background-color: #283593;
}

[data-primary="color_6"] .ribbon__four:after,
[data-primary="color_6"] .ribbon__four:before {
  background-color: #5161d0;
}

[data-primary="color_6"] .ribbon__five {
  background-color: #283593;
}

[data-primary="color_6"] .ribbon__five::before {
  border-color: transparent transparent #283593 transparent;
}

[data-primary="color_6"] .ribbon__six {
  background-color: #283593;
}

[data-primary="color_6"] .multi-steps>li {
  color: #283593;
}

[data-primary="color_6"] .multi-steps>li:after {
  background-color: #283593;
}

[data-primary="color_6"] .multi-steps>li.is-active:before {
  border-color: #283593;
}

[data-primary="color_6"] .timeline-badge.primary {
  background-color: #283593 !important;
}

[data-primary="color_6"] .tooltip-wrapper button:hover {
  background: #283593;
}

[data-primary="color_6"] .chart_widget_tab_one .nav-link.active {
  background-color: #283593;
  border: 1px solid #283593;
}

[data-primary="color_6"] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #283593;
}

[data-primary="color_6"] .social-icon2 a {
  border: 0.1rem solid #283593;
}

[data-primary="color_6"] .social-icon2 i {
  color: #283593;
}

[data-primary="color_6"] .social-icon3 ul li a:hover i {
  color: #283593;
}

[data-primary="color_6"] .bgl-primary {
  background: #7985db;
  border-color: #7985db;
  color: #283593;
}

[data-primary="color_6"] .tdl-holder input[type=checkbox]:checked+i {
  background: #283593;
}

[data-primary="color_6"] .footer .copyright a {
  color: #283593;
}

[data-primary="color_6"] .hamburger .line {
  background: #283593;
}

[data-primary="color_6"] svg.pulse-svg .first-circle,
[data-primary="color_6"] svg.pulse-svg .second-circle,
[data-primary="color_6"] svg.pulse-svg .third-circle {
  fill: #283593;
}

[data-primary="color_6"] .pulse-css {
  background: #283593;
}

[data-primary="color_6"] .pulse-css:after,
[data-primary="color_6"] .pulse-css:before {
  background-color: #283593;
}

[data-primary="color_6"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #283593;
}

[data-primary="color_6"] .header-right .header-profile .dropdown-menu a:hover,
[data-primary="color_6"] .header-right .header-profile .dropdown-menu a:focus,
[data-primary="color_6"] .header-right .header-profile .dropdown-menu a.active {
  color: #283593;
}

[data-primary="color_6"] .header-right .header-profile .profile_title {
  background: #283593;
}

[data-primary="color_6"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #283593 !important;
}

[data-primary="color_6"] .deznav .metismenu>li>a svg {
  color: #283593;
}

[data-primary="color_6"] .deznav .metismenu>li:hover>a,
[data-primary="color_6"] .deznav .metismenu>li:focus>a {
  color: #283593;
}

[data-primary="color_6"] .deznav .metismenu>li.mm-active>a {
  color: #283593;
}

[data-primary="color_6"] .deznav .metismenu ul a:hover,
[data-primary="color_6"] .deznav .metismenu ul a:focus,
[data-primary="color_6"] .deznav .metismenu ul a.mm-active {
  color: #283593;
}

@media (min-width: 767px) {

  [data-primary="color_6"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:hover>a,
  [data-primary="color_6"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:focus>a,
  [data-primary="color_6"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:active>a,
  [data-primary="color_6"] [data-sidebar-style="modern"] .deznav .metismenu>li>a.mm-active>a {
    background-color: #cacef0;
  }
}

[data-primary="color_6"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #283593;
}

[data-primary="color_6"] .nav-user {
  background: #283593;
}

[data-primary="color_6"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #283593;
}

[data-primary="color_6"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
  color: #283593;
}

[data-primary="color_6"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #283593;
  border-color: #283593;
}

[data-primary="color_6"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #283593;
  border-color: #283593;
}

[data-primary="color_6"][data-theme-version="dark"] .header-left input:focus {
  border-color: #283593;
}

[data-primary="color_6"][data-theme-version="dark"] .loader__bar {
  background: #283593;
}

[data-primary="color_6"][data-theme-version="dark"] .loader__ball {
  background: #283593;
}

[data-primary="color_6"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #283593;
}

[data-primary="color_6"] .new-arrival-content .price {
  color: #283593;
}

[data-primary="color_6"] .chart-link a i.text-primary {
  color: #283593;
}

[data-primary="color_6"] #user-activity .nav-tabs .nav-link.active {
  background: #283593;
  border-color: #283593;
}

[data-primary="color_6"] span#counter {
  color: #283593;
}

[data-primary="color_6"] .welcome-content:after {
  background: #283593;
}

[data-primary="color_6"] .timeline-badge {
  background-color: #283593;
}

[data-primary="color_6"] .page-timeline .timeline-workplan[data-primary="color_6"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(40, 53, 147, 0.4);
}

[data-primary="color_6"] .sk-three-bounce .sk-child {
  background-color: #283593;
}

[data-primary="color_6"] .dropdown-item.active,
[data-primary="color_6"] .dropdown-item:active {
  color: #fff;
  background-color: #283593;
}

[data-primary="color_6"] .overlay-box:after {
  background: #283593;
}

[data-primary="color_6"] .btn-primary {
  background-color: #283593;
  border-color: #283593;
}

[data-primary="color_6"] .bg-primary {
  background-color: #283593 !important;
}

[data-primary="color_6"] .text-primary {
  color: #283593 !important;
}

[data-primary="color_6"] .btn-primary:hover {
  background-color: #181f57;
  border-color: #181f57;
}

[data-primary="color_6"] .btn-outline-primary {
  color: #283593;
  border-color: #283593;
}

[data-primary="color_6"] .btn-outline-primary:hover {
  background-color: #283593;
  border-color: #283593;
}

[data-primary="color_6"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_6"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_6"] .show>.btn-primary.dropdown-toggle,
[data-primary="color_6"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_6"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_6"] .show>.btn-outline-primary.dropdown-toggle {
  background-color: #181f57;
  border-color: #181f57;
}

[data-primary="color_7"] a:hover,
[data-primary="color_7"] a:focus,
[data-primary="color_7"] a.active {
  color: #7356f1;
}

[data-primary="color_7"] [data-class="bg-primary"]:before {
  background: #7356f1;
}

[data-primary="color_7"] .email-left-box .intro-title {
  background: rgba(115, 86, 241, 0.1);
}

[data-primary="color_7"] .email-left-box .intro-title i {
  color: #7356f1;
}

[data-primary="color_7"] .widget-stat .media .media-body h4,
[data-primary="color_7"] .widget-stat .media .media-body .h4 {
  color: #7356f1 !important;
}

[data-primary="color_7"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(115, 86, 241, 0.1);
}

[data-primary="color_7"] .mail-list .list-group-item.active i {
  color: #7356f1;
}

[data-primary="color_7"] .single-mail.active {
  background: #7356f1;
}

[data-primary="color_7"] .profile-info h4.text-primary,
[data-primary="color_7"] .profile-info .text-primary.h4 {
  color: #7356f1 !important;
}

[data-primary="color_7"] .profile-tab .nav-item .nav-link:hover,
[data-primary="color_7"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #7356f1;
  color: #7356f1;
}

[data-primary="color_7"] .amChartsInputField {
  border: 0;
  background: #7356f1;
}

[data-primary="color_7"] .amcharts-period-input,
[data-primary="color_7"] .amcharts-period-input-selected {
  background: #7356f1;
}

[data-primary="color_7"] .morris-hover {
  background: #7356f1;
}

[data-primary="color_7"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #7356f1;
}

[data-primary="color_7"] .custom-select:focus {
  border-color: #7356f1;
  color: #7356f1;
}

[data-primary="color_7"] .daterangepicker td.active {
  background-color: #7356f1;
}

[data-primary="color_7"] .daterangepicker td.active:hover {
  background-color: #7356f1;
}

[data-primary="color_7"] .daterangepicker button.applyBtn {
  background-color: #7356f1;
  border-color: #7356f1;
}

[data-primary="color_7"] .wizard>.steps li.current a {
  background-color: #7356f1;
}

[data-primary="color_7"] .wizard .skip-email a {
  color: #7356f1;
}

[data-primary="color_7"] .wizard>.actions li:not(.disabled) a {
  background-color: #7356f1;
}

[data-primary="color_7"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #7356f1;
}

[data-primary="color_7"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #7356f1;
  border-color: #7356f1;
}

[data-primary="color_7"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #7356f1;
}

[data-primary="color_7"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #7356f1;
}

[data-primary="color_7"] .is-invalid .input-group-prepend .input-group-text i {
  color: #ae9df7;
}

[data-primary="color_7"] .datamaps-hoverover {
  color: #7356f1;
  border: 1px solid rgba(115, 86, 241, 0.3);
}

[data-primary="color_7"] .jqvmap-zoomin,
[data-primary="color_7"] .jqvmap-zoomout {
  background-color: #7356f1;
}

[data-primary="color_7"] .table .thead-primary th {
  background-color: #7356f1;
}

[data-primary="color_7"] .table.primary-table-bg-hover thead th {
  background-color: #5f3eef;
}

[data-primary="color_7"] .table.primary-table-bg-hover tbody tr {
  background-color: #7356f1;
}

[data-primary="color_7"] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #876ef3;
}

[data-primary="color_7"] .table.primary-table-bg-hover tbody tr:not(:last-child) td,
[data-primary="color_7"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 1px solid #5f3eef;
}

[data-primary="color_7"] table.dataTable tr.selected {
  color: #7356f1;
}

[data-primary="color_7"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #7356f1 !important;
  background: rgba(115, 86, 241, 0.1);
}

[data-primary="color_7"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #7356f1 !important;
  background: rgba(115, 86, 241, 0.1);
}

[data-primary="color_7"] .clipboard-btn:hover {
  background-color: #7356f1;
}

[data-primary="color_7"] .cd-h-timeline__dates::before {
  background: #7356f1;
}

[data-primary="color_7"] .cd-h-timeline__dates::after {
  background: #7356f1;
}

[data-primary="color_7"] .cd-h-timeline__line {
  background-color: #7356f1;
}

[data-primary="color_7"] .cd-h-timeline__date:after {
  border-color: #775af1;
  background-color: #7356f1;
}

[data-primary="color_7"] .cd-h-timeline__navigation {
  border-color: #775af1;
}

[data-primary="color_7"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #775af1;
}

[data-primary="color_7"] .dd-handle {
  background: #7356f1;
}

[data-primary="color_7"] .dd-handle:hover {
  background: #7356f1;
}

[data-primary="color_7"] .dd3-content:hover {
  background: #7356f1;
}

[data-primary="color_7"] .noUi-connect {
  background-color: #7356f1;
}

[data-primary="color_7"] .noUi-connect.c-3-color {
  background-color: #7356f1;
}

[data-primary="color_7"] .noUi-horizontal .noUi-handle,
[data-primary="color_7"] .noUi-vertical .noUi-handle {
  background-color: #7356f1;
}

[data-primary="color_7"] #slider-toggle.off .noUi-handle {
  border-color: #7356f1;
}

[data-primary="color_7"] .pignose-calendar {
  border-color: #7356f1;
}

[data-primary="color_7"] .pignose-calendar .pignose-calendar-top-date {
  background-color: #7356f1;
}

[data-primary="color_7"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #7356f1;
}

[data-primary="color_7"] .bootstrap-tagsinput .tag {
  background-color: #7356f1;
}

[data-primary="color_7"] .toast-success {
  background-color: #7356f1;
}

[data-primary="color_7"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #7356f1;
}

[data-primary="color_7"] .accordion-header-bg .accordion__header--primary {
  background-color: #7356f1;
}

[data-primary="color_7"] .alert-primary {
  background: #d5ccfb;
  border-color: #d5ccfb;
  color: #7356f1;
}

[data-primary="color_7"] .alert-alt.alert-primary {
  border-left: 4px solid #7356f1;
}

[data-primary="color_7"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #2f0fb8 !important;
}

[data-primary="color_7"] .alert.alert-primary.solid {
  background: #7356f1;
  border-color: #7356f1;
}

[data-primary="color_7"] .alert.alert-outline-primary {
  color: #7356f1;
  border-color: #7356f1;
}

[data-primary="color_7"] .badge-outline-primary {
  border: 1px solid #7356f1;
  color: #7356f1;
}

[data-primary="color_7"] .badge-primary {
  background-color: #7356f1;
}

[data-primary="color_7"] .page-titles h4,
[data-primary="color_7"] .page-titles .h4 {
  color: #7356f1;
}

[data-primary="color_7"] .card-action>a {
  background: #090322;
}

[data-primary="color_7"] .card-action .dropdown {
  background: #090322;
  color: #7356f1;
}

[data-primary="color_7"] .card-action .dropdown:hover,
[data-primary="color_7"] .card-action .dropdown:focus {
  background: #090322;
}

[data-primary="color_7"] .card-loader i {
  background: #5835ee;
}

[data-primary="color_7"] .dropdown-outline {
  border: 0.1rem solid #7356f1;
}

[data-primary="color_7"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #7356f1;
}

[data-primary="color_7"] .card-action .custom-dropdown {
  background: #e8e3fd;
}

[data-primary="color_7"] .card-action .custom-dropdown.show,
[data-primary="color_7"] .card-action .custom-dropdown:focus,
[data-primary="color_7"] .card-action .custom-dropdown:hover {
  background: #7356f1;
}

[data-primary="color_7"] .label-primary {
  background: #7356f1;
}

[data-primary="color_7"] .pagination .page-item .page-link:hover {
  background: #7356f1;
  border-color: #7356f1;
}

[data-primary="color_7"] .pagination .page-item.active .page-link {
  background-color: #7356f1;
  border-color: #7356f1;
}

[data-primary="color_7"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_7"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #7356f1;
}

[data-primary="color_7"] .progress-bar {
  background-color: #7356f1;
}

[data-primary="color_7"] .progress-bar-primary {
  background-color: #7356f1;
}

[data-primary="color_7"] .ribbon__four {
  background-color: #7356f1;
}

[data-primary="color_7"] .ribbon__four:after,
[data-primary="color_7"] .ribbon__four:before {
  background-color: #c1b4f9;
}

[data-primary="color_7"] .ribbon__five {
  background-color: #7356f1;
}

[data-primary="color_7"] .ribbon__five::before {
  border-color: transparent transparent #7356f1 transparent;
}

[data-primary="color_7"] .ribbon__six {
  background-color: #7356f1;
}

[data-primary="color_7"] .multi-steps>li {
  color: #7356f1;
}

[data-primary="color_7"] .multi-steps>li:after {
  background-color: #7356f1;
}

[data-primary="color_7"] .multi-steps>li.is-active:before {
  border-color: #7356f1;
}

[data-primary="color_7"] .timeline-badge.primary {
  background-color: #7356f1 !important;
}

[data-primary="color_7"] .tooltip-wrapper button:hover {
  background: #7356f1;
}

[data-primary="color_7"] .chart_widget_tab_one .nav-link.active {
  background-color: #7356f1;
  border: 1px solid #7356f1;
}

[data-primary="color_7"] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #7356f1;
}

[data-primary="color_7"] .social-icon2 a {
  border: 0.1rem solid #7356f1;
}

[data-primary="color_7"] .social-icon2 i {
  color: #7356f1;
}

[data-primary="color_7"] .social-icon3 ul li a:hover i {
  color: #7356f1;
}

[data-primary="color_7"] .bgl-primary {
  background: #e8e3fd;
  border-color: #e8e3fd;
  color: #7356f1;
}

[data-primary="color_7"] .tdl-holder input[type=checkbox]:checked+i {
  background: #7356f1;
}

[data-primary="color_7"] .footer .copyright a {
  color: #7356f1;
}

[data-primary="color_7"] .hamburger .line {
  background: #7356f1;
}

[data-primary="color_7"] svg.pulse-svg .first-circle,
[data-primary="color_7"] svg.pulse-svg .second-circle,
[data-primary="color_7"] svg.pulse-svg .third-circle {
  fill: #7356f1;
}

[data-primary="color_7"] .pulse-css {
  background: #7356f1;
}

[data-primary="color_7"] .pulse-css:after,
[data-primary="color_7"] .pulse-css:before {
  background-color: #7356f1;
}

[data-primary="color_7"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #7356f1;
}

[data-primary="color_7"] .header-right .header-profile .dropdown-menu a:hover,
[data-primary="color_7"] .header-right .header-profile .dropdown-menu a:focus,
[data-primary="color_7"] .header-right .header-profile .dropdown-menu a.active {
  color: #7356f1;
}

[data-primary="color_7"] .header-right .header-profile .profile_title {
  background: #7356f1;
}

[data-primary="color_7"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #7356f1 !important;
}

[data-primary="color_7"] .deznav .metismenu>li>a svg {
  color: #7356f1;
}

[data-primary="color_7"] .deznav .metismenu>li:hover>a,
[data-primary="color_7"] .deznav .metismenu>li:focus>a {
  color: #7356f1;
}

[data-primary="color_7"] .deznav .metismenu>li.mm-active>a {
  color: #7356f1;
}

[data-primary="color_7"] .deznav .metismenu ul a:hover,
[data-primary="color_7"] .deznav .metismenu ul a:focus,
[data-primary="color_7"] .deznav .metismenu ul a.mm-active {
  color: #7356f1;
}

@media (min-width: 767px) {

  [data-primary="color_7"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:hover>a,
  [data-primary="color_7"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:focus>a,
  [data-primary="color_7"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:active>a,
  [data-primary="color_7"] [data-sidebar-style="modern"] .deznav .metismenu>li>a.mm-active>a {
    background-color: white;
  }
}

[data-primary="color_7"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #7356f1;
}

[data-primary="color_7"] .nav-user {
  background: #7356f1;
}

[data-primary="color_7"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #7356f1;
}

[data-primary="color_7"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
  color: #7356f1;
}

[data-primary="color_7"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #7356f1;
  border-color: #7356f1;
}

[data-primary="color_7"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #7356f1;
  border-color: #7356f1;
}

[data-primary="color_7"][data-theme-version="dark"] .header-left input:focus {
  border-color: #7356f1;
}

[data-primary="color_7"][data-theme-version="dark"] .loader__bar {
  background: #7356f1;
}

[data-primary="color_7"][data-theme-version="dark"] .loader__ball {
  background: #7356f1;
}

[data-primary="color_7"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #7356f1;
}

[data-primary="color_7"] .new-arrival-content .price {
  color: #7356f1;
}

[data-primary="color_7"] .chart-link a i.text-primary {
  color: #7356f1;
}

[data-primary="color_7"] #user-activity .nav-tabs .nav-link.active {
  background: #7356f1;
  border-color: #7356f1;
}

[data-primary="color_7"] span#counter {
  color: #7356f1;
}

[data-primary="color_7"] .welcome-content:after {
  background: #7356f1;
}

[data-primary="color_7"] .timeline-badge {
  background-color: #7356f1;
}

[data-primary="color_7"] .page-timeline .timeline-workplan[data-primary="color_7"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(115, 86, 241, 0.4);
}

[data-primary="color_7"] .sk-three-bounce .sk-child {
  background-color: #7356f1;
}

[data-primary="color_7"] .dropdown-item.active,
[data-primary="color_7"] .dropdown-item:active {
  color: #fff;
  background-color: #7356f1;
}

[data-primary="color_7"] .overlay-box:after {
  background: #7356f1;
}

[data-primary="color_7"] .btn-primary {
  background-color: #7356f1;
  border-color: #7356f1;
}

[data-primary="color_7"] .bg-primary {
  background-color: #7356f1 !important;
}

[data-primary="color_7"] .text-primary {
  color: #7356f1 !important;
}

[data-primary="color_7"] .btn-primary:hover {
  background-color: #3b13e7;
  border-color: #3b13e7;
}

[data-primary="color_7"] .btn-outline-primary {
  color: #7356f1;
  border-color: #7356f1;
}

[data-primary="color_7"] .btn-outline-primary:hover {
  background-color: #7356f1;
  border-color: #7356f1;
}

[data-primary="color_7"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_7"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_7"] .show>.btn-primary.dropdown-toggle,
[data-primary="color_7"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_7"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_7"] .show>.btn-outline-primary.dropdown-toggle {
  background-color: #3b13e7;
  border-color: #3b13e7;
}

[data-primary="color_8"] a:hover,
[data-primary="color_8"] a:focus,
[data-primary="color_8"] a.active {
  color: #3695eb;
}

[data-primary="color_8"] [data-class="bg-primary"]:before {
  background: #3695eb;
}

[data-primary="color_8"] .email-left-box .intro-title {
  background: rgba(54, 149, 235, 0.1);
}

[data-primary="color_8"] .email-left-box .intro-title i {
  color: #3695eb;
}

[data-primary="color_8"] .widget-stat .media .media-body h4,
[data-primary="color_8"] .widget-stat .media .media-body .h4 {
  color: #3695eb !important;
}

[data-primary="color_8"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(54, 149, 235, 0.1);
}

[data-primary="color_8"] .mail-list .list-group-item.active i {
  color: #3695eb;
}

[data-primary="color_8"] .single-mail.active {
  background: #3695eb;
}

[data-primary="color_8"] .profile-info h4.text-primary,
[data-primary="color_8"] .profile-info .text-primary.h4 {
  color: #3695eb !important;
}

[data-primary="color_8"] .profile-tab .nav-item .nav-link:hover,
[data-primary="color_8"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #3695eb;
  color: #3695eb;
}

[data-primary="color_8"] .amChartsInputField {
  border: 0;
  background: #3695eb;
}

[data-primary="color_8"] .amcharts-period-input,
[data-primary="color_8"] .amcharts-period-input-selected {
  background: #3695eb;
}

[data-primary="color_8"] .morris-hover {
  background: #3695eb;
}

[data-primary="color_8"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #3695eb;
}

[data-primary="color_8"] .custom-select:focus {
  border-color: #3695eb;
  color: #3695eb;
}

[data-primary="color_8"] .daterangepicker td.active {
  background-color: #3695eb;
}

[data-primary="color_8"] .daterangepicker td.active:hover {
  background-color: #3695eb;
}

[data-primary="color_8"] .daterangepicker button.applyBtn {
  background-color: #3695eb;
  border-color: #3695eb;
}

[data-primary="color_8"] .wizard>.steps li.current a {
  background-color: #3695eb;
}

[data-primary="color_8"] .wizard .skip-email a {
  color: #3695eb;
}

[data-primary="color_8"] .wizard>.actions li:not(.disabled) a {
  background-color: #3695eb;
}

[data-primary="color_8"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #3695eb;
}

[data-primary="color_8"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #3695eb;
  border-color: #3695eb;
}

[data-primary="color_8"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #3695eb;
}

[data-primary="color_8"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #3695eb;
}

[data-primary="color_8"] .is-invalid .input-group-prepend .input-group-text i {
  color: #7cbaf2;
}

[data-primary="color_8"] .datamaps-hoverover {
  color: #3695eb;
  border: 1px solid rgba(54, 149, 235, 0.3);
}

[data-primary="color_8"] .jqvmap-zoomin,
[data-primary="color_8"] .jqvmap-zoomout {
  background-color: #3695eb;
}

[data-primary="color_8"] .table .thead-primary th {
  background-color: #3695eb;
}

[data-primary="color_8"] .table.primary-table-bg-hover thead th {
  background-color: #1f89e9;
}

[data-primary="color_8"] .table.primary-table-bg-hover tbody tr {
  background-color: #3695eb;
}

[data-primary="color_8"] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #4da1ed;
}

[data-primary="color_8"] .table.primary-table-bg-hover tbody tr:not(:last-child) td,
[data-primary="color_8"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 1px solid #1f89e9;
}

[data-primary="color_8"] table.dataTable tr.selected {
  color: #3695eb;
}

[data-primary="color_8"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #3695eb !important;
  background: rgba(54, 149, 235, 0.1);
}

[data-primary="color_8"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #3695eb !important;
  background: rgba(54, 149, 235, 0.1);
}

[data-primary="color_8"] .clipboard-btn:hover {
  background-color: #3695eb;
}

[data-primary="color_8"] .cd-h-timeline__dates::before {
  background: #3695eb;
}

[data-primary="color_8"] .cd-h-timeline__dates::after {
  background: #3695eb;
}

[data-primary="color_8"] .cd-h-timeline__line {
  background-color: #3695eb;
}

[data-primary="color_8"] .cd-h-timeline__date:after {
  border-color: #3a97eb;
  background-color: #3695eb;
}

[data-primary="color_8"] .cd-h-timeline__navigation {
  border-color: #3a97eb;
}

[data-primary="color_8"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #3a97eb;
}

[data-primary="color_8"] .dd-handle {
  background: #3695eb;
}

[data-primary="color_8"] .dd-handle:hover {
  background: #3695eb;
}

[data-primary="color_8"] .dd3-content:hover {
  background: #3695eb;
}

[data-primary="color_8"] .noUi-connect {
  background-color: #3695eb;
}

[data-primary="color_8"] .noUi-connect.c-3-color {
  background-color: #3695eb;
}

[data-primary="color_8"] .noUi-horizontal .noUi-handle,
[data-primary="color_8"] .noUi-vertical .noUi-handle {
  background-color: #3695eb;
}

[data-primary="color_8"] #slider-toggle.off .noUi-handle {
  border-color: #3695eb;
}

[data-primary="color_8"] .pignose-calendar {
  border-color: #3695eb;
}

[data-primary="color_8"] .pignose-calendar .pignose-calendar-top-date {
  background-color: #3695eb;
}

[data-primary="color_8"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #3695eb;
}

[data-primary="color_8"] .bootstrap-tagsinput .tag {
  background-color: #3695eb;
}

[data-primary="color_8"] .toast-success {
  background-color: #3695eb;
}

[data-primary="color_8"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #3695eb;
}

[data-primary="color_8"] .accordion-header-bg .accordion__header--primary {
  background-color: #3695eb;
}

[data-primary="color_8"] .alert-primary {
  background: #aad2f7;
  border-color: #aad2f7;
  color: #3695eb;
}

[data-primary="color_8"] .alert-alt.alert-primary {
  border-left: 4px solid #3695eb;
}

[data-primary="color_8"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #0f5493 !important;
}

[data-primary="color_8"] .alert.alert-primary.solid {
  background: #3695eb;
  border-color: #3695eb;
}

[data-primary="color_8"] .alert.alert-outline-primary {
  color: #3695eb;
  border-color: #3695eb;
}

[data-primary="color_8"] .badge-outline-primary {
  border: 1px solid #3695eb;
  color: #3695eb;
}

[data-primary="color_8"] .badge-primary {
  background-color: #3695eb;
}

[data-primary="color_8"] .page-titles h4,
[data-primary="color_8"] .page-titles .h4 {
  color: #3695eb;
}

[data-primary="color_8"] .card-action>a {
  background: black;
}

[data-primary="color_8"] .card-action .dropdown {
  background: black;
  color: #3695eb;
}

[data-primary="color_8"] .card-action .dropdown:hover,
[data-primary="color_8"] .card-action .dropdown:focus {
  background: black;
}

[data-primary="color_8"] .card-loader i {
  background: #1784e6;
}

[data-primary="color_8"] .dropdown-outline {
  border: 0.1rem solid #3695eb;
}

[data-primary="color_8"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #3695eb;
}

[data-primary="color_8"] .card-action .custom-dropdown {
  background: #c1def9;
}

[data-primary="color_8"] .card-action .custom-dropdown.show,
[data-primary="color_8"] .card-action .custom-dropdown:focus,
[data-primary="color_8"] .card-action .custom-dropdown:hover {
  background: #3695eb;
}

[data-primary="color_8"] .label-primary {
  background: #3695eb;
}

[data-primary="color_8"] .pagination .page-item .page-link:hover {
  background: #3695eb;
  border-color: #3695eb;
}

[data-primary="color_8"] .pagination .page-item.active .page-link {
  background-color: #3695eb;
  border-color: #3695eb;
}

[data-primary="color_8"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_8"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #3695eb;
}

[data-primary="color_8"] .progress-bar {
  background-color: #3695eb;
}

[data-primary="color_8"] .progress-bar-primary {
  background-color: #3695eb;
}

[data-primary="color_8"] .ribbon__four {
  background-color: #3695eb;
}

[data-primary="color_8"] .ribbon__four:after,
[data-primary="color_8"] .ribbon__four:before {
  background-color: #93c6f4;
}

[data-primary="color_8"] .ribbon__five {
  background-color: #3695eb;
}

[data-primary="color_8"] .ribbon__five::before {
  border-color: transparent transparent #3695eb transparent;
}

[data-primary="color_8"] .ribbon__six {
  background-color: #3695eb;
}

[data-primary="color_8"] .multi-steps>li {
  color: #3695eb;
}

[data-primary="color_8"] .multi-steps>li:after {
  background-color: #3695eb;
}

[data-primary="color_8"] .multi-steps>li.is-active:before {
  border-color: #3695eb;
}

[data-primary="color_8"] .timeline-badge.primary {
  background-color: #3695eb !important;
}

[data-primary="color_8"] .tooltip-wrapper button:hover {
  background: #3695eb;
}

[data-primary="color_8"] .chart_widget_tab_one .nav-link.active {
  background-color: #3695eb;
  border: 1px solid #3695eb;
}

[data-primary="color_8"] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #3695eb;
}

[data-primary="color_8"] .social-icon2 a {
  border: 0.1rem solid #3695eb;
}

[data-primary="color_8"] .social-icon2 i {
  color: #3695eb;
}

[data-primary="color_8"] .social-icon3 ul li a:hover i {
  color: #3695eb;
}

[data-primary="color_8"] .bgl-primary {
  background: #c1def9;
  border-color: #c1def9;
  color: #3695eb;
}

[data-primary="color_8"] .tdl-holder input[type=checkbox]:checked+i {
  background: #3695eb;
}

[data-primary="color_8"] .footer .copyright a {
  color: #3695eb;
}

[data-primary="color_8"] .hamburger .line {
  background: #3695eb;
}

[data-primary="color_8"] svg.pulse-svg .first-circle,
[data-primary="color_8"] svg.pulse-svg .second-circle,
[data-primary="color_8"] svg.pulse-svg .third-circle {
  fill: #3695eb;
}

[data-primary="color_8"] .pulse-css {
  background: #3695eb;
}

[data-primary="color_8"] .pulse-css:after,
[data-primary="color_8"] .pulse-css:before {
  background-color: #3695eb;
}

[data-primary="color_8"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #3695eb;
}

[data-primary="color_8"] .header-right .header-profile .dropdown-menu a:hover,
[data-primary="color_8"] .header-right .header-profile .dropdown-menu a:focus,
[data-primary="color_8"] .header-right .header-profile .dropdown-menu a.active {
  color: #3695eb;
}

[data-primary="color_8"] .header-right .header-profile .profile_title {
  background: #3695eb;
}

[data-primary="color_8"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #3695eb !important;
}

[data-primary="color_8"] .deznav .metismenu>li>a svg {
  color: #3695eb;
}

[data-primary="color_8"] .deznav .metismenu>li:hover>a,
[data-primary="color_8"] .deznav .metismenu>li:focus>a {
  color: #3695eb;
}

[data-primary="color_8"] .deznav .metismenu>li.mm-active>a {
  color: #3695eb;
}

[data-primary="color_8"] .deznav .metismenu ul a:hover,
[data-primary="color_8"] .deznav .metismenu ul a:focus,
[data-primary="color_8"] .deznav .metismenu ul a.mm-active {
  color: #3695eb;
}

@media (min-width: 767px) {

  [data-primary="color_8"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:hover>a,
  [data-primary="color_8"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:focus>a,
  [data-primary="color_8"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:active>a,
  [data-primary="color_8"] [data-sidebar-style="modern"] .deznav .metismenu>li>a.mm-active>a {
    background-color: white;
  }
}

[data-primary="color_8"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #3695eb;
}

[data-primary="color_8"] .nav-user {
  background: #3695eb;
}

[data-primary="color_8"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #3695eb;
}

[data-primary="color_8"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
  color: #3695eb;
}

[data-primary="color_8"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #3695eb;
  border-color: #3695eb;
}

[data-primary="color_8"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #3695eb;
  border-color: #3695eb;
}

[data-primary="color_8"][data-theme-version="dark"] .header-left input:focus {
  border-color: #3695eb;
}

[data-primary="color_8"][data-theme-version="dark"] .loader__bar {
  background: #3695eb;
}

[data-primary="color_8"][data-theme-version="dark"] .loader__ball {
  background: #3695eb;
}

[data-primary="color_8"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #3695eb;
}

[data-primary="color_8"] .new-arrival-content .price {
  color: #3695eb;
}

[data-primary="color_8"] .chart-link a i.text-primary {
  color: #3695eb;
}

[data-primary="color_8"] #user-activity .nav-tabs .nav-link.active {
  background: #3695eb;
  border-color: #3695eb;
}

[data-primary="color_8"] span#counter {
  color: #3695eb;
}

[data-primary="color_8"] .welcome-content:after {
  background: #3695eb;
}

[data-primary="color_8"] .timeline-badge {
  background-color: #3695eb;
}

[data-primary="color_8"] .page-timeline .timeline-workplan[data-primary="color_8"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(54, 149, 235, 0.4);
}

[data-primary="color_8"] .sk-three-bounce .sk-child {
  background-color: #3695eb;
}

[data-primary="color_8"] .dropdown-item.active,
[data-primary="color_8"] .dropdown-item:active {
  color: #fff;
  background-color: #3695eb;
}

[data-primary="color_8"] .overlay-box:after {
  background: #3695eb;
}

[data-primary="color_8"] .btn-primary {
  background-color: #3695eb;
  border-color: #3695eb;
}

[data-primary="color_8"] .bg-primary {
  background-color: #3695eb !important;
}

[data-primary="color_8"] .text-primary {
  color: #3695eb !important;
}

[data-primary="color_8"] .btn-primary:hover {
  background-color: #136fc1;
  border-color: #136fc1;
}

[data-primary="color_8"] .btn-outline-primary {
  color: #3695eb;
  border-color: #3695eb;
}

[data-primary="color_8"] .btn-outline-primary:hover {
  background-color: #3695eb;
  border-color: #3695eb;
}

[data-primary="color_8"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_8"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_8"] .show>.btn-primary.dropdown-toggle,
[data-primary="color_8"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_8"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_8"] .show>.btn-outline-primary.dropdown-toggle {
  background-color: #136fc1;
  border-color: #136fc1;
}

[data-primary="color_9"] a:hover,
[data-primary="color_9"] a:focus,
[data-primary="color_9"] a.active {
  color: #00838f;
}

[data-primary="color_9"] [data-class="bg-primary"]:before {
  background: #00838f;
}

[data-primary="color_9"] .email-left-box .intro-title {
  background: rgba(0, 131, 143, 0.1);
}

[data-primary="color_9"] .email-left-box .intro-title i {
  color: #00838f;
}

[data-primary="color_9"] .widget-stat .media .media-body h4,
[data-primary="color_9"] .widget-stat .media .media-body .h4 {
  color: #00838f !important;
}

[data-primary="color_9"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(0, 131, 143, 0.1);
}

[data-primary="color_9"] .mail-list .list-group-item.active i {
  color: #00838f;
}

[data-primary="color_9"] .single-mail.active {
  background: #00838f;
}

[data-primary="color_9"] .profile-info h4.text-primary,
[data-primary="color_9"] .profile-info .text-primary.h4 {
  color: #00838f !important;
}

[data-primary="color_9"] .profile-tab .nav-item .nav-link:hover,
[data-primary="color_9"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #00838f;
  color: #00838f;
}

[data-primary="color_9"] .amChartsInputField {
  border: 0;
  background: #00838f;
}

[data-primary="color_9"] .amcharts-period-input,
[data-primary="color_9"] .amcharts-period-input-selected {
  background: #00838f;
}

[data-primary="color_9"] .morris-hover {
  background: #00838f;
}

[data-primary="color_9"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #00838f;
}

[data-primary="color_9"] .custom-select:focus {
  border-color: #00838f;
  color: #00838f;
}

[data-primary="color_9"] .daterangepicker td.active {
  background-color: #00838f;
}

[data-primary="color_9"] .daterangepicker td.active:hover {
  background-color: #00838f;
}

[data-primary="color_9"] .daterangepicker button.applyBtn {
  background-color: #00838f;
  border-color: #00838f;
}

[data-primary="color_9"] .wizard>.steps li.current a {
  background-color: #00838f;
}

[data-primary="color_9"] .wizard .skip-email a {
  color: #00838f;
}

[data-primary="color_9"] .wizard>.actions li:not(.disabled) a {
  background-color: #00838f;
}

[data-primary="color_9"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #00838f;
}

[data-primary="color_9"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #00838f;
  border-color: #00838f;
}

[data-primary="color_9"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #00838f;
}

[data-primary="color_9"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #00838f;
}

[data-primary="color_9"] .is-invalid .input-group-prepend .input-group-text i {
  color: #00c9dc;
}

[data-primary="color_9"] .datamaps-hoverover {
  color: #00838f;
  border: 1px solid rgba(0, 131, 143, 0.3);
}

[data-primary="color_9"] .jqvmap-zoomin,
[data-primary="color_9"] .jqvmap-zoomout {
  background-color: #00838f;
}

[data-primary="color_9"] .table .thead-primary th {
  background-color: #00838f;
}

[data-primary="color_9"] .table.primary-table-bg-hover thead th {
  background-color: #006c76;
}

[data-primary="color_9"] .table.primary-table-bg-hover tbody tr {
  background-color: #00838f;
}

[data-primary="color_9"] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #009aa9;
}

[data-primary="color_9"] .table.primary-table-bg-hover tbody tr:not(:last-child) td,
[data-primary="color_9"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 1px solid #006c76;
}

[data-primary="color_9"] table.dataTable tr.selected {
  color: #00838f;
}

[data-primary="color_9"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #00838f !important;
  background: rgba(0, 131, 143, 0.1);
}

[data-primary="color_9"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #00838f !important;
  background: rgba(0, 131, 143, 0.1);
}

[data-primary="color_9"] .clipboard-btn:hover {
  background-color: #00838f;
}

[data-primary="color_9"] .cd-h-timeline__dates::before {
  background: #00838f;
}

[data-primary="color_9"] .cd-h-timeline__dates::after {
  background: #00838f;
}

[data-primary="color_9"] .cd-h-timeline__line {
  background-color: #00838f;
}

[data-primary="color_9"] .cd-h-timeline__date:after {
  border-color: #008794;
  background-color: #00838f;
}

[data-primary="color_9"] .cd-h-timeline__navigation {
  border-color: #008794;
}

[data-primary="color_9"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #008794;
}

[data-primary="color_9"] .dd-handle {
  background: #00838f;
}

[data-primary="color_9"] .dd-handle:hover {
  background: #00838f;
}

[data-primary="color_9"] .dd3-content:hover {
  background: #00838f;
}

[data-primary="color_9"] .noUi-connect {
  background-color: #00838f;
}

[data-primary="color_9"] .noUi-connect.c-3-color {
  background-color: #00838f;
}

[data-primary="color_9"] .noUi-horizontal .noUi-handle,
[data-primary="color_9"] .noUi-vertical .noUi-handle {
  background-color: #00838f;
}

[data-primary="color_9"] #slider-toggle.off .noUi-handle {
  border-color: #00838f;
}

[data-primary="color_9"] .pignose-calendar {
  border-color: #00838f;
}

[data-primary="color_9"] .pignose-calendar .pignose-calendar-top-date {
  background-color: #00838f;
}

[data-primary="color_9"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #00838f;
}

[data-primary="color_9"] .bootstrap-tagsinput .tag {
  background-color: #00838f;
}

[data-primary="color_9"] .toast-success {
  background-color: #00838f;
}

[data-primary="color_9"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #00838f;
}

[data-primary="color_9"] .accordion-header-bg .accordion__header--primary {
  background-color: #00838f;
}

[data-primary="color_9"] .alert-primary {
  background: #10ebff;
  border-color: #10ebff;
  color: #00838f;
}

[data-primary="color_9"] .alert-alt.alert-primary {
  border-left: 4px solid #00838f;
}

[data-primary="color_9"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #000e10 !important;
}

[data-primary="color_9"] .alert.alert-primary.solid {
  background: #00838f;
  border-color: #00838f;
}

[data-primary="color_9"] .alert.alert-outline-primary {
  color: #00838f;
  border-color: #00838f;
}

[data-primary="color_9"] .badge-outline-primary {
  border: 1px solid #00838f;
  color: #00838f;
}

[data-primary="color_9"] .badge-primary {
  background-color: #00838f;
}

[data-primary="color_9"] .page-titles h4,
[data-primary="color_9"] .page-titles .h4 {
  color: #00838f;
}

[data-primary="color_9"] .card-action>a {
  background: black;
}

[data-primary="color_9"] .card-action .dropdown {
  background: black;
  color: #00838f;
}

[data-primary="color_9"] .card-action .dropdown:hover,
[data-primary="color_9"] .card-action .dropdown:focus {
  background: black;
}

[data-primary="color_9"] .card-loader i {
  background: #00626b;
}

[data-primary="color_9"] .dropdown-outline {
  border: 0.1rem solid #00838f;
}

[data-primary="color_9"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #00838f;
}

[data-primary="color_9"] .card-action .custom-dropdown {
  background: #29edff;
}

[data-primary="color_9"] .card-action .custom-dropdown.show,
[data-primary="color_9"] .card-action .custom-dropdown:focus,
[data-primary="color_9"] .card-action .custom-dropdown:hover {
  background: #00838f;
}

[data-primary="color_9"] .label-primary {
  background: #00838f;
}

[data-primary="color_9"] .pagination .page-item .page-link:hover {
  background: #00838f;
  border-color: #00838f;
}

[data-primary="color_9"] .pagination .page-item.active .page-link {
  background-color: #00838f;
  border-color: #00838f;
}

[data-primary="color_9"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_9"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #00838f;
}

[data-primary="color_9"] .progress-bar {
  background-color: #00838f;
}

[data-primary="color_9"] .progress-bar-primary {
  background-color: #00838f;
}

[data-primary="color_9"] .ribbon__four {
  background-color: #00838f;
}

[data-primary="color_9"] .ribbon__four:after,
[data-primary="color_9"] .ribbon__four:before {
  background-color: #00e0f5;
}

[data-primary="color_9"] .ribbon__five {
  background-color: #00838f;
}

[data-primary="color_9"] .ribbon__five::before {
  border-color: transparent transparent #00838f transparent;
}

[data-primary="color_9"] .ribbon__six {
  background-color: #00838f;
}

[data-primary="color_9"] .multi-steps>li {
  color: #00838f;
}

[data-primary="color_9"] .multi-steps>li:after {
  background-color: #00838f;
}

[data-primary="color_9"] .multi-steps>li.is-active:before {
  border-color: #00838f;
}

[data-primary="color_9"] .timeline-badge.primary {
  background-color: #00838f !important;
}

[data-primary="color_9"] .tooltip-wrapper button:hover {
  background: #00838f;
}

[data-primary="color_9"] .chart_widget_tab_one .nav-link.active {
  background-color: #00838f;
  border: 1px solid #00838f;
}

[data-primary="color_9"] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #00838f;
}

[data-primary="color_9"] .social-icon2 a {
  border: 0.1rem solid #00838f;
}

[data-primary="color_9"] .social-icon2 i {
  color: #00838f;
}

[data-primary="color_9"] .social-icon3 ul li a:hover i {
  color: #00838f;
}

[data-primary="color_9"] .bgl-primary {
  background: #29edff;
  border-color: #29edff;
  color: #00838f;
}

[data-primary="color_9"] .tdl-holder input[type=checkbox]:checked+i {
  background: #00838f;
}

[data-primary="color_9"] .footer .copyright a {
  color: #00838f;
}

[data-primary="color_9"] .hamburger .line {
  background: #00838f;
}

[data-primary="color_9"] svg.pulse-svg .first-circle,
[data-primary="color_9"] svg.pulse-svg .second-circle,
[data-primary="color_9"] svg.pulse-svg .third-circle {
  fill: #00838f;
}

[data-primary="color_9"] .pulse-css {
  background: #00838f;
}

[data-primary="color_9"] .pulse-css:after,
[data-primary="color_9"] .pulse-css:before {
  background-color: #00838f;
}

[data-primary="color_9"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #00838f;
}

[data-primary="color_9"] .header-right .header-profile .dropdown-menu a:hover,
[data-primary="color_9"] .header-right .header-profile .dropdown-menu a:focus,
[data-primary="color_9"] .header-right .header-profile .dropdown-menu a.active {
  color: #00838f;
}

[data-primary="color_9"] .header-right .header-profile .profile_title {
  background: #00838f;
}

[data-primary="color_9"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #00838f !important;
}

[data-primary="color_9"] .deznav .metismenu>li>a svg {
  color: #00838f;
}

[data-primary="color_9"] .deznav .metismenu>li:hover>a,
[data-primary="color_9"] .deznav .metismenu>li:focus>a {
  color: #00838f;
}

[data-primary="color_9"] .deznav .metismenu>li.mm-active>a {
  color: #00838f;
}

[data-primary="color_9"] .deznav .metismenu ul a:hover,
[data-primary="color_9"] .deznav .metismenu ul a:focus,
[data-primary="color_9"] .deznav .metismenu ul a.mm-active {
  color: #00838f;
}

@media (min-width: 767px) {

  [data-primary="color_9"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:hover>a,
  [data-primary="color_9"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:focus>a,
  [data-primary="color_9"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:active>a,
  [data-primary="color_9"] [data-sidebar-style="modern"] .deznav .metismenu>li>a.mm-active>a {
    background-color: #8ff6ff;
  }
}

[data-primary="color_9"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #00838f;
}

[data-primary="color_9"] .nav-user {
  background: #00838f;
}

[data-primary="color_9"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #00838f;
}

[data-primary="color_9"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
  color: #00838f;
}

[data-primary="color_9"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #00838f;
  border-color: #00838f;
}

[data-primary="color_9"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #00838f;
  border-color: #00838f;
}

[data-primary="color_9"][data-theme-version="dark"] .header-left input:focus {
  border-color: #00838f;
}

[data-primary="color_9"][data-theme-version="dark"] .loader__bar {
  background: #00838f;
}

[data-primary="color_9"][data-theme-version="dark"] .loader__ball {
  background: #00838f;
}

[data-primary="color_9"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #00838f;
}

[data-primary="color_9"] .new-arrival-content .price {
  color: #00838f;
}

[data-primary="color_9"] .chart-link a i.text-primary {
  color: #00838f;
}

[data-primary="color_9"] #user-activity .nav-tabs .nav-link.active {
  background: #00838f;
  border-color: #00838f;
}

[data-primary="color_9"] span#counter {
  color: #00838f;
}

[data-primary="color_9"] .welcome-content:after {
  background: #00838f;
}

[data-primary="color_9"] .timeline-badge {
  background-color: #00838f;
}

[data-primary="color_9"] .page-timeline .timeline-workplan[data-primary="color_9"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(0, 131, 143, 0.4);
}

[data-primary="color_9"] .sk-three-bounce .sk-child {
  background-color: #00838f;
}

[data-primary="color_9"] .dropdown-item.active,
[data-primary="color_9"] .dropdown-item:active {
  color: #fff;
  background-color: #00838f;
}

[data-primary="color_9"] .overlay-box:after {
  background: #00838f;
}

[data-primary="color_9"] .btn-primary {
  background-color: #00838f;
  border-color: #00838f;
}

[data-primary="color_9"] .bg-primary {
  background-color: #00838f !important;
}

[data-primary="color_9"] .text-primary {
  color: #00838f !important;
}

[data-primary="color_9"] .btn-primary:hover {
  background-color: #003d43;
  border-color: #003d43;
}

[data-primary="color_9"] .btn-outline-primary {
  color: #00838f;
  border-color: #00838f;
}

[data-primary="color_9"] .btn-outline-primary:hover {
  background-color: #00838f;
  border-color: #00838f;
}

[data-primary="color_9"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_9"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_9"] .show>.btn-primary.dropdown-toggle,
[data-primary="color_9"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_9"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_9"] .show>.btn-outline-primary.dropdown-toggle {
  background-color: #003d43;
  border-color: #003d43;
}

[data-primary="color_10"] a:hover,
[data-primary="color_10"] a:focus,
[data-primary="color_10"] a.active {
  color: #ff8f16;
}

[data-primary="color_10"] [data-class="bg-primary"]:before {
  background: #ff8f16;
}

[data-primary="color_10"] .email-left-box .intro-title {
  background: rgba(255, 143, 22, 0.1);
}

[data-primary="color_10"] .email-left-box .intro-title i {
  color: #ff8f16;
}

[data-primary="color_10"] .widget-stat .media .media-body h4,
[data-primary="color_10"] .widget-stat .media .media-body .h4 {
  color: #ff8f16 !important;
}

[data-primary="color_10"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(255, 143, 22, 0.1);
}

[data-primary="color_10"] .mail-list .list-group-item.active i {
  color: #ff8f16;
}

[data-primary="color_10"] .single-mail.active {
  background: #ff8f16;
}

[data-primary="color_10"] .profile-info h4.text-primary,
[data-primary="color_10"] .profile-info .text-primary.h4 {
  color: #ff8f16 !important;
}

[data-primary="color_10"] .profile-tab .nav-item .nav-link:hover,
[data-primary="color_10"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #ff8f16;
  color: #ff8f16;
}

[data-primary="color_10"] .amChartsInputField {
  border: 0;
  background: #ff8f16;
}

[data-primary="color_10"] .amcharts-period-input,
[data-primary="color_10"] .amcharts-period-input-selected {
  background: #ff8f16;
}

[data-primary="color_10"] .morris-hover {
  background: #ff8f16;
}

[data-primary="color_10"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #ff8f16;
}

[data-primary="color_10"] .custom-select:focus {
  border-color: #ff8f16;
  color: #ff8f16;
}

[data-primary="color_10"] .daterangepicker td.active {
  background-color: #ff8f16;
}

[data-primary="color_10"] .daterangepicker td.active:hover {
  background-color: #ff8f16;
}

[data-primary="color_10"] .daterangepicker button.applyBtn {
  background-color: #ff8f16;
  border-color: #ff8f16;
}

[data-primary="color_10"] .wizard>.steps li.current a {
  background-color: #ff8f16;
}

[data-primary="color_10"] .wizard .skip-email a {
  color: #ff8f16;
}

[data-primary="color_10"] .wizard>.actions li:not(.disabled) a {
  background-color: #ff8f16;
}

[data-primary="color_10"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #ff8f16;
}

[data-primary="color_10"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #ff8f16;
  border-color: #ff8f16;
}

[data-primary="color_10"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #ff8f16;
}

[data-primary="color_10"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #ff8f16;
}

[data-primary="color_10"] .is-invalid .input-group-prepend .input-group-text i {
  color: #ffb463;
}

[data-primary="color_10"] .datamaps-hoverover {
  color: #ff8f16;
  border: 1px solid rgba(255, 143, 22, 0.3);
}

[data-primary="color_10"] .jqvmap-zoomin,
[data-primary="color_10"] .jqvmap-zoomout {
  background-color: #ff8f16;
}

[data-primary="color_10"] .table .thead-primary th {
  background-color: #ff8f16;
}

[data-primary="color_10"] .table.primary-table-bg-hover thead th {
  background-color: #fc8300;
}

[data-primary="color_10"] .table.primary-table-bg-hover tbody tr {
  background-color: #ff8f16;
}

[data-primary="color_10"] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #ff9b30;
}

[data-primary="color_10"] .table.primary-table-bg-hover tbody tr:not(:last-child) td,
[data-primary="color_10"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 1px solid #fc8300;
}

[data-primary="color_10"] table.dataTable tr.selected {
  color: #ff8f16;
}

[data-primary="color_10"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #ff8f16 !important;
  background: rgba(255, 143, 22, 0.1);
}

[data-primary="color_10"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #ff8f16 !important;
  background: rgba(255, 143, 22, 0.1);
}

[data-primary="color_10"] .clipboard-btn:hover {
  background-color: #ff8f16;
}

[data-primary="color_10"] .cd-h-timeline__dates::before {
  background: #ff8f16;
}

[data-primary="color_10"] .cd-h-timeline__dates::after {
  background: #ff8f16;
}

[data-primary="color_10"] .cd-h-timeline__line {
  background-color: #ff8f16;
}

[data-primary="color_10"] .cd-h-timeline__date:after {
  border-color: #ff911b;
  background-color: #ff8f16;
}

[data-primary="color_10"] .cd-h-timeline__navigation {
  border-color: #ff911b;
}

[data-primary="color_10"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #ff911b;
}

[data-primary="color_10"] .dd-handle {
  background: #ff8f16;
}

[data-primary="color_10"] .dd-handle:hover {
  background: #ff8f16;
}

[data-primary="color_10"] .dd3-content:hover {
  background: #ff8f16;
}

[data-primary="color_10"] .noUi-connect {
  background-color: #ff8f16;
}

[data-primary="color_10"] .noUi-connect.c-3-color {
  background-color: #ff8f16;
}

[data-primary="color_10"] .noUi-horizontal .noUi-handle,
[data-primary="color_10"] .noUi-vertical .noUi-handle {
  background-color: #ff8f16;
}

[data-primary="color_10"] #slider-toggle.off .noUi-handle {
  border-color: #ff8f16;
}

[data-primary="color_10"] .pignose-calendar {
  border-color: #ff8f16;
}

[data-primary="color_10"] .pignose-calendar .pignose-calendar-top-date {
  background-color: #ff8f16;
}

[data-primary="color_10"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #ff8f16;
}

[data-primary="color_10"] .bootstrap-tagsinput .tag {
  background-color: #ff8f16;
}

[data-primary="color_10"] .toast-success {
  background-color: #ff8f16;
}

[data-primary="color_10"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #ff8f16;
}

[data-primary="color_10"] .accordion-header-bg .accordion__header--primary {
  background-color: #ff8f16;
}

[data-primary="color_10"] .alert-primary {
  background: #ffcc96;
  border-color: #ffcc96;
  color: #ff8f16;
}

[data-primary="color_10"] .alert-alt.alert-primary {
  border-left: 4px solid #ff8f16;
}

[data-primary="color_10"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #964e00 !important;
}

[data-primary="color_10"] .alert.alert-primary.solid {
  background: #ff8f16;
  border-color: #ff8f16;
}

[data-primary="color_10"] .alert.alert-outline-primary {
  color: #ff8f16;
  border-color: #ff8f16;
}

[data-primary="color_10"] .badge-outline-primary {
  border: 1px solid #ff8f16;
  color: #ff8f16;
}

[data-primary="color_10"] .badge-primary {
  background-color: #ff8f16;
}

[data-primary="color_10"] .page-titles h4,
[data-primary="color_10"] .page-titles .h4 {
  color: #ff8f16;
}

[data-primary="color_10"] .card-action>a {
  background: black;
}

[data-primary="color_10"] .card-action .dropdown {
  background: black;
  color: #ff8f16;
}

[data-primary="color_10"] .card-action .dropdown:hover,
[data-primary="color_10"] .card-action .dropdown:focus {
  background: black;
}

[data-primary="color_10"] .card-loader i {
  background: #f17d00;
}

[data-primary="color_10"] .dropdown-outline {
  border: 0.1rem solid #ff8f16;
}

[data-primary="color_10"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #ff8f16;
}

[data-primary="color_10"] .card-action .custom-dropdown {
  background: #ffd9af;
}

[data-primary="color_10"] .card-action .custom-dropdown.show,
[data-primary="color_10"] .card-action .custom-dropdown:focus,
[data-primary="color_10"] .card-action .custom-dropdown:hover {
  background: #ff8f16;
}

[data-primary="color_10"] .label-primary {
  background: #ff8f16;
}

[data-primary="color_10"] .pagination .page-item .page-link:hover {
  background: #ff8f16;
  border-color: #ff8f16;
}

[data-primary="color_10"] .pagination .page-item.active .page-link {
  background-color: #ff8f16;
  border-color: #ff8f16;
}

[data-primary="color_10"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_10"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #ff8f16;
}

[data-primary="color_10"] .progress-bar {
  background-color: #ff8f16;
}

[data-primary="color_10"] .progress-bar-primary {
  background-color: #ff8f16;
}

[data-primary="color_10"] .ribbon__four {
  background-color: #ff8f16;
}

[data-primary="color_10"] .ribbon__four:after,
[data-primary="color_10"] .ribbon__four:before {
  background-color: #ffc07c;
}

[data-primary="color_10"] .ribbon__five {
  background-color: #ff8f16;
}

[data-primary="color_10"] .ribbon__five::before {
  border-color: transparent transparent #ff8f16 transparent;
}

[data-primary="color_10"] .ribbon__six {
  background-color: #ff8f16;
}

[data-primary="color_10"] .multi-steps>li {
  color: #ff8f16;
}

[data-primary="color_10"] .multi-steps>li:after {
  background-color: #ff8f16;
}

[data-primary="color_10"] .multi-steps>li.is-active:before {
  border-color: #ff8f16;
}

[data-primary="color_10"] .timeline-badge.primary {
  background-color: #ff8f16 !important;
}

[data-primary="color_10"] .tooltip-wrapper button:hover {
  background: #ff8f16;
}

[data-primary="color_10"] .chart_widget_tab_one .nav-link.active {
  background-color: #ff8f16;
  border: 1px solid #ff8f16;
}

[data-primary="color_10"] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #ff8f16;
}

[data-primary="color_10"] .social-icon2 a {
  border: 0.1rem solid #ff8f16;
}

[data-primary="color_10"] .social-icon2 i {
  color: #ff8f16;
}

[data-primary="color_10"] .social-icon3 ul li a:hover i {
  color: #ff8f16;
}

[data-primary="color_10"] .bgl-primary {
  background: #ffd9af;
  border-color: #ffd9af;
  color: #ff8f16;
}

[data-primary="color_10"] .tdl-holder input[type=checkbox]:checked+i {
  background: #ff8f16;
}

[data-primary="color_10"] .footer .copyright a {
  color: #ff8f16;
}

[data-primary="color_10"] .hamburger .line {
  background: #ff8f16;
}

[data-primary="color_10"] svg.pulse-svg .first-circle,
[data-primary="color_10"] svg.pulse-svg .second-circle,
[data-primary="color_10"] svg.pulse-svg .third-circle {
  fill: #ff8f16;
}

[data-primary="color_10"] .pulse-css {
  background: #ff8f16;
}

[data-primary="color_10"] .pulse-css:after,
[data-primary="color_10"] .pulse-css:before {
  background-color: #ff8f16;
}

[data-primary="color_10"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #ff8f16;
}

[data-primary="color_10"] .header-right .header-profile .dropdown-menu a:hover,
[data-primary="color_10"] .header-right .header-profile .dropdown-menu a:focus,
[data-primary="color_10"] .header-right .header-profile .dropdown-menu a.active {
  color: #ff8f16;
}

[data-primary="color_10"] .header-right .header-profile .profile_title {
  background: #ff8f16;
}

[data-primary="color_10"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #ff8f16 !important;
}

[data-primary="color_10"] .deznav .metismenu>li>a svg {
  color: #ff8f16;
}

[data-primary="color_10"] .deznav .metismenu>li:hover>a,
[data-primary="color_10"] .deznav .metismenu>li:focus>a {
  color: #ff8f16;
}

[data-primary="color_10"] .deznav .metismenu>li.mm-active>a {
  color: #ff8f16;
}

[data-primary="color_10"] .deznav .metismenu ul a:hover,
[data-primary="color_10"] .deznav .metismenu ul a:focus,
[data-primary="color_10"] .deznav .metismenu ul a.mm-active {
  color: #ff8f16;
}

@media (min-width: 767px) {

  [data-primary="color_10"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:hover>a,
  [data-primary="color_10"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:focus>a,
  [data-primary="color_10"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:active>a,
  [data-primary="color_10"] [data-sidebar-style="modern"] .deznav .metismenu>li>a.mm-active>a {
    background-color: white;
  }
}

[data-primary="color_10"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #ff8f16;
}

[data-primary="color_10"] .nav-user {
  background: #ff8f16;
}

[data-primary="color_10"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #ff8f16;
}

[data-primary="color_10"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
  color: #ff8f16;
}

[data-primary="color_10"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #ff8f16;
  border-color: #ff8f16;
}

[data-primary="color_10"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #ff8f16;
  border-color: #ff8f16;
}

[data-primary="color_10"][data-theme-version="dark"] .header-left input:focus {
  border-color: #ff8f16;
}

[data-primary="color_10"][data-theme-version="dark"] .loader__bar {
  background: #ff8f16;
}

[data-primary="color_10"][data-theme-version="dark"] .loader__ball {
  background: #ff8f16;
}

[data-primary="color_10"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #ff8f16;
}

[data-primary="color_10"] .new-arrival-content .price {
  color: #ff8f16;
}

[data-primary="color_10"] .chart-link a i.text-primary {
  color: #ff8f16;
}

[data-primary="color_10"] #user-activity .nav-tabs .nav-link.active {
  background: #ff8f16;
  border-color: #ff8f16;
}

[data-primary="color_10"] span#counter {
  color: #ff8f16;
}

[data-primary="color_10"] .welcome-content:after {
  background: #ff8f16;
}

[data-primary="color_10"] .timeline-badge {
  background-color: #ff8f16;
}

[data-primary="color_10"] .page-timeline .timeline-workplan[data-primary="color_10"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(255, 143, 22, 0.4);
}

[data-primary="color_10"] .sk-three-bounce .sk-child {
  background-color: #ff8f16;
}

[data-primary="color_10"] .dropdown-item.active,
[data-primary="color_10"] .dropdown-item:active {
  color: #fff;
  background-color: #ff8f16;
}

[data-primary="color_10"] .overlay-box:after {
  background: #ff8f16;
}

[data-primary="color_10"] .btn-primary {
  background-color: #ff8f16;
  border-color: #ff8f16;
}

[data-primary="color_10"] .bg-primary {
  background-color: #ff8f16 !important;
}

[data-primary="color_10"] .text-primary {
  color: #ff8f16 !important;
}

[data-primary="color_10"] .btn-primary:hover {
  background-color: #c96800;
  border-color: #c96800;
}

[data-primary="color_10"] .btn-outline-primary {
  color: #ff8f16;
  border-color: #ff8f16;
}

[data-primary="color_10"] .btn-outline-primary:hover {
  background-color: #ff8f16;
  border-color: #ff8f16;
}

[data-primary="color_10"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_10"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_10"] .show>.btn-primary.dropdown-toggle,
[data-primary="color_10"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_10"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_10"] .show>.btn-outline-primary.dropdown-toggle {
  background-color: #c96800;
  border-color: #c96800;
}

[data-primary="color_11"] a:hover,
[data-primary="color_11"] a:focus,
[data-primary="color_11"] a.active {
  color: #6673fd;
}

[data-primary="color_11"] [data-class="bg-primary"]:before {
  background: #6673fd;
}

[data-primary="color_11"] .email-left-box .intro-title {
  background: rgba(102, 115, 253, 0.1);
}

[data-primary="color_11"] .email-left-box .intro-title i {
  color: #6673fd;
}

[data-primary="color_11"] .widget-stat .media .media-body h4,
[data-primary="color_11"] .widget-stat .media .media-body .h4 {
  color: #6673fd !important;
}

[data-primary="color_11"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(102, 115, 253, 0.1);
}

[data-primary="color_11"] .mail-list .list-group-item.active i {
  color: #6673fd;
}

[data-primary="color_11"] .single-mail.active {
  background: #6673fd;
}

[data-primary="color_11"] .profile-info h4.text-primary,
[data-primary="color_11"] .profile-info .text-primary.h4 {
  color: #6673fd !important;
}

[data-primary="color_11"] .profile-tab .nav-item .nav-link:hover,
[data-primary="color_11"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #6673fd;
  color: #6673fd;
}

[data-primary="color_11"] .amChartsInputField {
  border: 0;
  background: #6673fd;
}

[data-primary="color_11"] .amcharts-period-input,
[data-primary="color_11"] .amcharts-period-input-selected {
  background: #6673fd;
}

[data-primary="color_11"] .morris-hover {
  background: #6673fd;
}

[data-primary="color_11"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #6673fd;
}

[data-primary="color_11"] .custom-select:focus {
  border-color: #6673fd;
  color: #6673fd;
}

[data-primary="color_11"] .daterangepicker td.active {
  background-color: #6673fd;
}

[data-primary="color_11"] .daterangepicker td.active:hover {
  background-color: #6673fd;
}

[data-primary="color_11"] .daterangepicker button.applyBtn {
  background-color: #6673fd;
  border-color: #6673fd;
}

[data-primary="color_11"] .wizard>.steps li.current a {
  background-color: #6673fd;
}

[data-primary="color_11"] .wizard .skip-email a {
  color: #6673fd;
}

[data-primary="color_11"] .wizard>.actions li:not(.disabled) a {
  background-color: #6673fd;
}

[data-primary="color_11"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #6673fd;
}

[data-primary="color_11"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #6673fd;
  border-color: #6673fd;
}

[data-primary="color_11"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #6673fd;
}

[data-primary="color_11"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #6673fd;
}

[data-primary="color_11"] .is-invalid .input-group-prepend .input-group-text i {
  color: #b2b8fe;
}

[data-primary="color_11"] .datamaps-hoverover {
  color: #6673fd;
  border: 1px solid rgba(102, 115, 253, 0.3);
}

[data-primary="color_11"] .jqvmap-zoomin,
[data-primary="color_11"] .jqvmap-zoomout {
  background-color: #6673fd;
}

[data-primary="color_11"] .table .thead-primary th {
  background-color: #6673fd;
}

[data-primary="color_11"] .table.primary-table-bg-hover thead th {
  background-color: #4d5cfd;
}

[data-primary="color_11"] .table.primary-table-bg-hover tbody tr {
  background-color: #6673fd;
}

[data-primary="color_11"] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #7f8afd;
}

[data-primary="color_11"] .table.primary-table-bg-hover tbody tr:not(:last-child) td,
[data-primary="color_11"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 1px solid #4d5cfd;
}

[data-primary="color_11"] table.dataTable tr.selected {
  color: #6673fd;
}

[data-primary="color_11"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #6673fd !important;
  background: rgba(102, 115, 253, 0.1);
}

[data-primary="color_11"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #6673fd !important;
  background: rgba(102, 115, 253, 0.1);
}

[data-primary="color_11"] .clipboard-btn:hover {
  background-color: #6673fd;
}

[data-primary="color_11"] .cd-h-timeline__dates::before {
  background: #6673fd;
}

[data-primary="color_11"] .cd-h-timeline__dates::after {
  background: #6673fd;
}

[data-primary="color_11"] .cd-h-timeline__line {
  background-color: #6673fd;
}

[data-primary="color_11"] .cd-h-timeline__date:after {
  border-color: #6b77fd;
  background-color: #6673fd;
}

[data-primary="color_11"] .cd-h-timeline__navigation {
  border-color: #6b77fd;
}

[data-primary="color_11"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #6b77fd;
}

[data-primary="color_11"] .dd-handle {
  background: #6673fd;
}

[data-primary="color_11"] .dd-handle:hover {
  background: #6673fd;
}

[data-primary="color_11"] .dd3-content:hover {
  background: #6673fd;
}

[data-primary="color_11"] .noUi-connect {
  background-color: #6673fd;
}

[data-primary="color_11"] .noUi-connect.c-3-color {
  background-color: #6673fd;
}

[data-primary="color_11"] .noUi-horizontal .noUi-handle,
[data-primary="color_11"] .noUi-vertical .noUi-handle {
  background-color: #6673fd;
}

[data-primary="color_11"] #slider-toggle.off .noUi-handle {
  border-color: #6673fd;
}

[data-primary="color_11"] .pignose-calendar {
  border-color: #6673fd;
}

[data-primary="color_11"] .pignose-calendar .pignose-calendar-top-date {
  background-color: #6673fd;
}

[data-primary="color_11"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #6673fd;
}

[data-primary="color_11"] .bootstrap-tagsinput .tag {
  background-color: #6673fd;
}

[data-primary="color_11"] .toast-success {
  background-color: #6673fd;
}

[data-primary="color_11"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #6673fd;
}

[data-primary="color_11"] .accordion-header-bg .accordion__header--primary {
  background-color: #6673fd;
}

[data-primary="color_11"] .alert-primary {
  background: #e4e6ff;
  border-color: #e4e6ff;
  color: #6673fd;
}

[data-primary="color_11"] .alert-alt.alert-primary {
  border-left: 4px solid #6673fd;
}

[data-primary="color_11"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #0316e1 !important;
}

[data-primary="color_11"] .alert.alert-primary.solid {
  background: #6673fd;
  border-color: #6673fd;
}

[data-primary="color_11"] .alert.alert-outline-primary {
  color: #6673fd;
  border-color: #6673fd;
}

[data-primary="color_11"] .badge-outline-primary {
  border: 1px solid #6673fd;
  color: #6673fd;
}

[data-primary="color_11"] .badge-primary {
  background-color: #6673fd;
}

[data-primary="color_11"] .page-titles h4,
[data-primary="color_11"] .page-titles .h4 {
  color: #6673fd;
}

[data-primary="color_11"] .card-action>a {
  background: #01063f;
}

[data-primary="color_11"] .card-action .dropdown {
  background: #01063f;
  color: #6673fd;
}

[data-primary="color_11"] .card-action .dropdown:hover,
[data-primary="color_11"] .card-action .dropdown:focus {
  background: #01063f;
}

[data-primary="color_11"] .card-loader i {
  background: #4353fd;
}

[data-primary="color_11"] .dropdown-outline {
  border: 0.1rem solid #6673fd;
}

[data-primary="color_11"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #6673fd;
}

[data-primary="color_11"] .card-action .custom-dropdown {
  background: #fdfdff;
}

[data-primary="color_11"] .card-action .custom-dropdown.show,
[data-primary="color_11"] .card-action .custom-dropdown:focus,
[data-primary="color_11"] .card-action .custom-dropdown:hover {
  background: #6673fd;
}

[data-primary="color_11"] .label-primary {
  background: #6673fd;
}

[data-primary="color_11"] .pagination .page-item .page-link:hover {
  background: #6673fd;
  border-color: #6673fd;
}

[data-primary="color_11"] .pagination .page-item.active .page-link {
  background-color: #6673fd;
  border-color: #6673fd;
}

[data-primary="color_11"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_11"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #6673fd;
}

[data-primary="color_11"] .progress-bar {
  background-color: #6673fd;
}

[data-primary="color_11"] .progress-bar-primary {
  background-color: #6673fd;
}

[data-primary="color_11"] .ribbon__four {
  background-color: #6673fd;
}

[data-primary="color_11"] .ribbon__four:after,
[data-primary="color_11"] .ribbon__four:before {
  background-color: #cbcffe;
}

[data-primary="color_11"] .ribbon__five {
  background-color: #6673fd;
}

[data-primary="color_11"] .ribbon__five::before {
  border-color: transparent transparent #6673fd transparent;
}

[data-primary="color_11"] .ribbon__six {
  background-color: #6673fd;
}

[data-primary="color_11"] .multi-steps>li {
  color: #6673fd;
}

[data-primary="color_11"] .multi-steps>li:after {
  background-color: #6673fd;
}

[data-primary="color_11"] .multi-steps>li.is-active:before {
  border-color: #6673fd;
}

[data-primary="color_11"] .timeline-badge.primary {
  background-color: #6673fd !important;
}

[data-primary="color_11"] .tooltip-wrapper button:hover {
  background: #6673fd;
}

[data-primary="color_11"] .chart_widget_tab_one .nav-link.active {
  background-color: #6673fd;
  border: 1px solid #6673fd;
}

[data-primary="color_11"] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #6673fd;
}

[data-primary="color_11"] .social-icon2 a {
  border: 0.1rem solid #6673fd;
}

[data-primary="color_11"] .social-icon2 i {
  color: #6673fd;
}

[data-primary="color_11"] .social-icon3 ul li a:hover i {
  color: #6673fd;
}

[data-primary="color_11"] .bgl-primary {
  background: #fdfdff;
  border-color: #fdfdff;
  color: #6673fd;
}

[data-primary="color_11"] .tdl-holder input[type=checkbox]:checked+i {
  background: #6673fd;
}

[data-primary="color_11"] .footer .copyright a {
  color: #6673fd;
}

[data-primary="color_11"] .hamburger .line {
  background: #6673fd;
}

[data-primary="color_11"] svg.pulse-svg .first-circle,
[data-primary="color_11"] svg.pulse-svg .second-circle,
[data-primary="color_11"] svg.pulse-svg .third-circle {
  fill: #6673fd;
}

[data-primary="color_11"] .pulse-css {
  background: #6673fd;
}

[data-primary="color_11"] .pulse-css:after,
[data-primary="color_11"] .pulse-css:before {
  background-color: #6673fd;
}

[data-primary="color_11"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #6673fd;
}

[data-primary="color_11"] .header-right .header-profile .dropdown-menu a:hover,
[data-primary="color_11"] .header-right .header-profile .dropdown-menu a:focus,
[data-primary="color_11"] .header-right .header-profile .dropdown-menu a.active {
  color: #6673fd;
}

[data-primary="color_11"] .header-right .header-profile .profile_title {
  background: #6673fd;
}

[data-primary="color_11"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #6673fd !important;
}

[data-primary="color_11"] .deznav .metismenu>li>a svg {
  color: #6673fd;
}

[data-primary="color_11"] .deznav .metismenu>li:hover>a,
[data-primary="color_11"] .deznav .metismenu>li:focus>a {
  color: #6673fd;
}

[data-primary="color_11"] .deznav .metismenu>li.mm-active>a {
  color: #6673fd;
}

[data-primary="color_11"] .deznav .metismenu ul a:hover,
[data-primary="color_11"] .deznav .metismenu ul a:focus,
[data-primary="color_11"] .deznav .metismenu ul a.mm-active {
  color: #6673fd;
}

@media (min-width: 767px) {

  [data-primary="color_11"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:hover>a,
  [data-primary="color_11"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:focus>a,
  [data-primary="color_11"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:active>a,
  [data-primary="color_11"] [data-sidebar-style="modern"] .deznav .metismenu>li>a.mm-active>a {
    background-color: white;
  }
}

[data-primary="color_11"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #6673fd;
}

[data-primary="color_11"] .nav-user {
  background: #6673fd;
}

[data-primary="color_11"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #6673fd;
}

[data-primary="color_11"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
  color: #6673fd;
}

[data-primary="color_11"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #6673fd;
  border-color: #6673fd;
}

[data-primary="color_11"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #6673fd;
  border-color: #6673fd;
}

[data-primary="color_11"][data-theme-version="dark"] .header-left input:focus {
  border-color: #6673fd;
}

[data-primary="color_11"][data-theme-version="dark"] .loader__bar {
  background: #6673fd;
}

[data-primary="color_11"][data-theme-version="dark"] .loader__ball {
  background: #6673fd;
}

[data-primary="color_11"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #6673fd;
}

[data-primary="color_11"] .new-arrival-content .price {
  color: #6673fd;
}

[data-primary="color_11"] .chart-link a i.text-primary {
  color: #6673fd;
}

[data-primary="color_11"] #user-activity .nav-tabs .nav-link.active {
  background: #6673fd;
  border-color: #6673fd;
}

[data-primary="color_11"] span#counter {
  color: #6673fd;
}

[data-primary="color_11"] .welcome-content:after {
  background: #6673fd;
}

[data-primary="color_11"] .timeline-badge {
  background-color: #6673fd;
}

[data-primary="color_11"] .page-timeline .timeline-workplan[data-primary="color_11"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(102, 115, 253, 0.4);
}

[data-primary="color_11"] .sk-three-bounce .sk-child {
  background-color: #6673fd;
}

[data-primary="color_11"] .dropdown-item.active,
[data-primary="color_11"] .dropdown-item:active {
  color: #fff;
  background-color: #6673fd;
}

[data-primary="color_11"] .overlay-box:after {
  background: #6673fd;
}

[data-primary="color_11"] .btn-primary {
  background-color: #6673fd;
  border-color: #6673fd;
}

[data-primary="color_11"] .bg-primary {
  background-color: #6673fd !important;
}

[data-primary="color_11"] .text-primary {
  color: #6673fd !important;
}

[data-primary="color_11"] .btn-primary:hover {
  background-color: #1a2efc;
  border-color: #1a2efc;
}

[data-primary="color_11"] .btn-outline-primary {
  color: #6673fd;
  border-color: #6673fd;
}

[data-primary="color_11"] .btn-outline-primary:hover {
  background-color: #6673fd;
  border-color: #6673fd;
}

[data-primary="color_11"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_11"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_11"] .show>.btn-primary.dropdown-toggle,
[data-primary="color_11"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_11"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_11"] .show>.btn-outline-primary.dropdown-toggle {
  background-color: #1a2efc;
  border-color: #1a2efc;
}

[data-primary="color_12"] a:hover,
[data-primary="color_12"] a:focus,
[data-primary="color_12"] a.active {
  color: #558b2f;
}

[data-primary="color_12"] [data-class="bg-primary"]:before {
  background: #558b2f;
}

[data-primary="color_12"] .email-left-box .intro-title {
  background: rgba(85, 139, 47, 0.1);
}

[data-primary="color_12"] .email-left-box .intro-title i {
  color: #558b2f;
}

[data-primary="color_12"] .widget-stat .media .media-body h4,
[data-primary="color_12"] .widget-stat .media .media-body .h4 {
  color: #558b2f !important;
}

[data-primary="color_12"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(85, 139, 47, 0.1);
}

[data-primary="color_12"] .mail-list .list-group-item.active i {
  color: #558b2f;
}

[data-primary="color_12"] .single-mail.active {
  background: #558b2f;
}

[data-primary="color_12"] .profile-info h4.text-primary,
[data-primary="color_12"] .profile-info .text-primary.h4 {
  color: #558b2f !important;
}

[data-primary="color_12"] .profile-tab .nav-item .nav-link:hover,
[data-primary="color_12"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #558b2f;
  color: #558b2f;
}

[data-primary="color_12"] .amChartsInputField {
  border: 0;
  background: #558b2f;
}

[data-primary="color_12"] .amcharts-period-input,
[data-primary="color_12"] .amcharts-period-input-selected {
  background: #558b2f;
}

[data-primary="color_12"] .morris-hover {
  background: #558b2f;
}

[data-primary="color_12"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #558b2f;
}

[data-primary="color_12"] .custom-select:focus {
  border-color: #558b2f;
  color: #558b2f;
}

[data-primary="color_12"] .daterangepicker td.active {
  background-color: #558b2f;
}

[data-primary="color_12"] .daterangepicker td.active:hover {
  background-color: #558b2f;
}

[data-primary="color_12"] .daterangepicker button.applyBtn {
  background-color: #558b2f;
  border-color: #558b2f;
}

[data-primary="color_12"] .wizard>.steps li.current a {
  background-color: #558b2f;
}

[data-primary="color_12"] .wizard .skip-email a {
  color: #558b2f;
}

[data-primary="color_12"] .wizard>.actions li:not(.disabled) a {
  background-color: #558b2f;
}

[data-primary="color_12"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #558b2f;
}

[data-primary="color_12"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #558b2f;
  border-color: #558b2f;
}

[data-primary="color_12"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #558b2f;
}

[data-primary="color_12"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #558b2f;
}

[data-primary="color_12"] .is-invalid .input-group-prepend .input-group-text i {
  color: #79c046;
}

[data-primary="color_12"] .datamaps-hoverover {
  color: #558b2f;
  border: 1px solid rgba(85, 139, 47, 0.3);
}

[data-primary="color_12"] .jqvmap-zoomin,
[data-primary="color_12"] .jqvmap-zoomout {
  background-color: #558b2f;
}

[data-primary="color_12"] .table .thead-primary th {
  background-color: #558b2f;
}

[data-primary="color_12"] .table.primary-table-bg-hover thead th {
  background-color: #497829;
}

[data-primary="color_12"] .table.primary-table-bg-hover tbody tr {
  background-color: #558b2f;
}

[data-primary="color_12"] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #619e35;
}

[data-primary="color_12"] .table.primary-table-bg-hover tbody tr:not(:last-child) td,
[data-primary="color_12"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 1px solid #497829;
}

[data-primary="color_12"] table.dataTable tr.selected {
  color: #558b2f;
}

[data-primary="color_12"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #558b2f !important;
  background: rgba(85, 139, 47, 0.1);
}

[data-primary="color_12"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #558b2f !important;
  background: rgba(85, 139, 47, 0.1);
}

[data-primary="color_12"] .clipboard-btn:hover {
  background-color: #558b2f;
}

[data-primary="color_12"] .cd-h-timeline__dates::before {
  background: #558b2f;
}

[data-primary="color_12"] .cd-h-timeline__dates::after {
  background: #558b2f;
}

[data-primary="color_12"] .cd-h-timeline__line {
  background-color: #558b2f;
}

[data-primary="color_12"] .cd-h-timeline__date:after {
  border-color: #578e30;
  background-color: #558b2f;
}

[data-primary="color_12"] .cd-h-timeline__navigation {
  border-color: #578e30;
}

[data-primary="color_12"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #578e30;
}

[data-primary="color_12"] .dd-handle {
  background: #558b2f;
}

[data-primary="color_12"] .dd-handle:hover {
  background: #558b2f;
}

[data-primary="color_12"] .dd3-content:hover {
  background: #558b2f;
}

[data-primary="color_12"] .noUi-connect {
  background-color: #558b2f;
}

[data-primary="color_12"] .noUi-connect.c-3-color {
  background-color: #558b2f;
}

[data-primary="color_12"] .noUi-horizontal .noUi-handle,
[data-primary="color_12"] .noUi-vertical .noUi-handle {
  background-color: #558b2f;
}

[data-primary="color_12"] #slider-toggle.off .noUi-handle {
  border-color: #558b2f;
}

[data-primary="color_12"] .pignose-calendar {
  border-color: #558b2f;
}

[data-primary="color_12"] .pignose-calendar .pignose-calendar-top-date {
  background-color: #558b2f;
}

[data-primary="color_12"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #558b2f;
}

[data-primary="color_12"] .bootstrap-tagsinput .tag {
  background-color: #558b2f;
}

[data-primary="color_12"] .toast-success {
  background-color: #558b2f;
}

[data-primary="color_12"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #558b2f;
}

[data-primary="color_12"] .accordion-header-bg .accordion__header--primary {
  background-color: #558b2f;
}

[data-primary="color_12"] .alert-primary {
  background: #94cd6c;
  border-color: #94cd6c;
  color: #558b2f;
}

[data-primary="color_12"] .alert-alt.alert-primary {
  border-left: 4px solid #558b2f;
}

[data-primary="color_12"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #1b2c0f !important;
}

[data-primary="color_12"] .alert.alert-primary.solid {
  background: #558b2f;
  border-color: #558b2f;
}

[data-primary="color_12"] .alert.alert-outline-primary {
  color: #558b2f;
  border-color: #558b2f;
}

[data-primary="color_12"] .badge-outline-primary {
  border: 1px solid #558b2f;
  color: #558b2f;
}

[data-primary="color_12"] .badge-primary {
  background-color: #558b2f;
}

[data-primary="color_12"] .page-titles h4,
[data-primary="color_12"] .page-titles .h4 {
  color: #558b2f;
}

[data-primary="color_12"] .card-action>a {
  background: black;
}

[data-primary="color_12"] .card-action .dropdown {
  background: black;
  color: #558b2f;
}

[data-primary="color_12"] .card-action .dropdown:hover,
[data-primary="color_12"] .card-action .dropdown:focus {
  background: black;
}

[data-primary="color_12"] .card-loader i {
  background: #457026;
}

[data-primary="color_12"] .dropdown-outline {
  border: 0.1rem solid #558b2f;
}

[data-primary="color_12"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #558b2f;
}

[data-primary="color_12"] .card-action .custom-dropdown {
  background: #a2d47f;
}

[data-primary="color_12"] .card-action .custom-dropdown.show,
[data-primary="color_12"] .card-action .custom-dropdown:focus,
[data-primary="color_12"] .card-action .custom-dropdown:hover {
  background: #558b2f;
}

[data-primary="color_12"] .label-primary {
  background: #558b2f;
}

[data-primary="color_12"] .pagination .page-item .page-link:hover {
  background: #558b2f;
  border-color: #558b2f;
}

[data-primary="color_12"] .pagination .page-item.active .page-link {
  background-color: #558b2f;
  border-color: #558b2f;
}

[data-primary="color_12"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_12"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #558b2f;
}

[data-primary="color_12"] .progress-bar {
  background-color: #558b2f;
}

[data-primary="color_12"] .progress-bar-primary {
  background-color: #558b2f;
}

[data-primary="color_12"] .ribbon__four {
  background-color: #558b2f;
}

[data-primary="color_12"] .ribbon__four:after,
[data-primary="color_12"] .ribbon__four:before {
  background-color: #86c759;
}

[data-primary="color_12"] .ribbon__five {
  background-color: #558b2f;
}

[data-primary="color_12"] .ribbon__five::before {
  border-color: transparent transparent #558b2f transparent;
}

[data-primary="color_12"] .ribbon__six {
  background-color: #558b2f;
}

[data-primary="color_12"] .multi-steps>li {
  color: #558b2f;
}

[data-primary="color_12"] .multi-steps>li:after {
  background-color: #558b2f;
}

[data-primary="color_12"] .multi-steps>li.is-active:before {
  border-color: #558b2f;
}

[data-primary="color_12"] .timeline-badge.primary {
  background-color: #558b2f !important;
}

[data-primary="color_12"] .tooltip-wrapper button:hover {
  background: #558b2f;
}

[data-primary="color_12"] .chart_widget_tab_one .nav-link.active {
  background-color: #558b2f;
  border: 1px solid #558b2f;
}

[data-primary="color_12"] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #558b2f;
}

[data-primary="color_12"] .social-icon2 a {
  border: 0.1rem solid #558b2f;
}

[data-primary="color_12"] .social-icon2 i {
  color: #558b2f;
}

[data-primary="color_12"] .social-icon3 ul li a:hover i {
  color: #558b2f;
}

[data-primary="color_12"] .bgl-primary {
  background: #a2d47f;
  border-color: #a2d47f;
  color: #558b2f;
}

[data-primary="color_12"] .tdl-holder input[type=checkbox]:checked+i {
  background: #558b2f;
}

[data-primary="color_12"] .footer .copyright a {
  color: #558b2f;
}

[data-primary="color_12"] .hamburger .line {
  background: #558b2f;
}

[data-primary="color_12"] svg.pulse-svg .first-circle,
[data-primary="color_12"] svg.pulse-svg .second-circle,
[data-primary="color_12"] svg.pulse-svg .third-circle {
  fill: #558b2f;
}

[data-primary="color_12"] .pulse-css {
  background: #558b2f;
}

[data-primary="color_12"] .pulse-css:after,
[data-primary="color_12"] .pulse-css:before {
  background-color: #558b2f;
}

[data-primary="color_12"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #558b2f;
}

[data-primary="color_12"] .header-right .header-profile .dropdown-menu a:hover,
[data-primary="color_12"] .header-right .header-profile .dropdown-menu a:focus,
[data-primary="color_12"] .header-right .header-profile .dropdown-menu a.active {
  color: #558b2f;
}

[data-primary="color_12"] .header-right .header-profile .profile_title {
  background: #558b2f;
}

[data-primary="color_12"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #558b2f !important;
}

[data-primary="color_12"] .deznav .metismenu>li>a svg {
  color: #558b2f;
}

[data-primary="color_12"] .deznav .metismenu>li:hover>a,
[data-primary="color_12"] .deznav .metismenu>li:focus>a {
  color: #558b2f;
}

[data-primary="color_12"] .deznav .metismenu>li.mm-active>a {
  color: #558b2f;
}

[data-primary="color_12"] .deznav .metismenu ul a:hover,
[data-primary="color_12"] .deznav .metismenu ul a:focus,
[data-primary="color_12"] .deznav .metismenu ul a.mm-active {
  color: #558b2f;
}

@media (min-width: 767px) {

  [data-primary="color_12"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:hover>a,
  [data-primary="color_12"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:focus>a,
  [data-primary="color_12"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:active>a,
  [data-primary="color_12"] [data-sidebar-style="modern"] .deznav .metismenu>li>a.mm-active>a {
    background-color: #daeecb;
  }
}

[data-primary="color_12"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #558b2f;
}

[data-primary="color_12"] .nav-user {
  background: #558b2f;
}

[data-primary="color_12"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #558b2f;
}

[data-primary="color_12"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
  color: #558b2f;
}

[data-primary="color_12"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #558b2f;
  border-color: #558b2f;
}

[data-primary="color_12"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #558b2f;
  border-color: #558b2f;
}

[data-primary="color_12"][data-theme-version="dark"] .header-left input:focus {
  border-color: #558b2f;
}

[data-primary="color_12"][data-theme-version="dark"] .loader__bar {
  background: #558b2f;
}

[data-primary="color_12"][data-theme-version="dark"] .loader__ball {
  background: #558b2f;
}

[data-primary="color_12"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #558b2f;
}

[data-primary="color_12"] .new-arrival-content .price {
  color: #558b2f;
}

[data-primary="color_12"] .chart-link a i.text-primary {
  color: #558b2f;
}

[data-primary="color_12"] #user-activity .nav-tabs .nav-link.active {
  background: #558b2f;
  border-color: #558b2f;
}

[data-primary="color_12"] span#counter {
  color: #558b2f;
}

[data-primary="color_12"] .welcome-content:after {
  background: #558b2f;
}

[data-primary="color_12"] .timeline-badge {
  background-color: #558b2f;
}

[data-primary="color_12"] .page-timeline .timeline-workplan[data-primary="color_12"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(85, 139, 47, 0.4);
}

[data-primary="color_12"] .sk-three-bounce .sk-child {
  background-color: #558b2f;
}

[data-primary="color_12"] .dropdown-item.active,
[data-primary="color_12"] .dropdown-item:active {
  color: #fff;
  background-color: #558b2f;
}

[data-primary="color_12"] .overlay-box:after {
  background: #558b2f;
}

[data-primary="color_12"] .btn-primary {
  background-color: #558b2f;
  border-color: #558b2f;
}

[data-primary="color_12"] .bg-primary {
  background-color: #558b2f !important;
}

[data-primary="color_12"] .text-primary {
  color: #558b2f !important;
}

[data-primary="color_12"] .btn-primary:hover {
  background-color: #32521c;
  border-color: #32521c;
}

[data-primary="color_12"] .btn-outline-primary {
  color: #558b2f;
  border-color: #558b2f;
}

[data-primary="color_12"] .btn-outline-primary:hover {
  background-color: #558b2f;
  border-color: #558b2f;
}

[data-primary="color_12"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_12"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_12"] .show>.btn-primary.dropdown-toggle,
[data-primary="color_12"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_12"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_12"] .show>.btn-outline-primary.dropdown-toggle {
  background-color: #32521c;
  border-color: #32521c;
}

[data-primary="color_13"] a:hover,
[data-primary="color_13"] a:focus,
[data-primary="color_13"] a.active {
  color: #2a2a2a;
}

[data-primary="color_13"] [data-class="bg-primary"]:before {
  background: #2a2a2a;
}

[data-primary="color_13"] .email-left-box .intro-title {
  background: rgba(42, 42, 42, 0.1);
}

[data-primary="color_13"] .email-left-box .intro-title i {
  color: #2a2a2a;
}

[data-primary="color_13"] .widget-stat .media .media-body h4,
[data-primary="color_13"] .widget-stat .media .media-body .h4 {
  color: #2a2a2a !important;
}

[data-primary="color_13"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(42, 42, 42, 0.1);
}

[data-primary="color_13"] .mail-list .list-group-item.active i {
  color: #2a2a2a;
}

[data-primary="color_13"] .single-mail.active {
  background: #2a2a2a;
}

[data-primary="color_13"] .profile-info h4.text-primary,
[data-primary="color_13"] .profile-info .text-primary.h4 {
  color: #2a2a2a !important;
}

[data-primary="color_13"] .profile-tab .nav-item .nav-link:hover,
[data-primary="color_13"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #2a2a2a;
  color: #2a2a2a;
}

[data-primary="color_13"] .amChartsInputField {
  border: 0;
  background: #2a2a2a;
}

[data-primary="color_13"] .amcharts-period-input,
[data-primary="color_13"] .amcharts-period-input-selected {
  background: #2a2a2a;
}

[data-primary="color_13"] .morris-hover {
  background: #2a2a2a;
}

[data-primary="color_13"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .custom-select:focus {
  border-color: #2a2a2a;
  color: #2a2a2a;
}

[data-primary="color_13"] .daterangepicker td.active {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .daterangepicker td.active:hover {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .daterangepicker button.applyBtn {
  background-color: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary="color_13"] .wizard>.steps li.current a {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .wizard .skip-email a {
  color: #2a2a2a;
}

[data-primary="color_13"] .wizard>.actions li:not(.disabled) a {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #2a2a2a;
}

[data-primary="color_13"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary="color_13"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #2a2a2a;
}

[data-primary="color_13"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .is-invalid .input-group-prepend .input-group-text i {
  color: #505050;
}

[data-primary="color_13"] .datamaps-hoverover {
  color: #2a2a2a;
  border: 1px solid rgba(42, 42, 42, 0.3);
}

[data-primary="color_13"] .jqvmap-zoomin,
[data-primary="color_13"] .jqvmap-zoomout {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .table .thead-primary th {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .table.primary-table-bg-hover thead th {
  background-color: #1d1d1d;
}

[data-primary="color_13"] .table.primary-table-bg-hover tbody tr {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #373737;
}

[data-primary="color_13"] .table.primary-table-bg-hover tbody tr:not(:last-child) td,
[data-primary="color_13"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 1px solid #1d1d1d;
}

[data-primary="color_13"] table.dataTable tr.selected {
  color: #2a2a2a;
}

[data-primary="color_13"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #2a2a2a !important;
  background: rgba(42, 42, 42, 0.1);
}

[data-primary="color_13"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #2a2a2a !important;
  background: rgba(42, 42, 42, 0.1);
}

[data-primary="color_13"] .clipboard-btn:hover {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .cd-h-timeline__dates::before {
  background: #2a2a2a;
}

[data-primary="color_13"] .cd-h-timeline__dates::after {
  background: #2a2a2a;
}

[data-primary="color_13"] .cd-h-timeline__line {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .cd-h-timeline__date:after {
  border-color: #2c2c2c;
  background-color: #2a2a2a;
}

[data-primary="color_13"] .cd-h-timeline__navigation {
  border-color: #2c2c2c;
}

[data-primary="color_13"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #2c2c2c;
}

[data-primary="color_13"] .dd-handle {
  background: #2a2a2a;
}

[data-primary="color_13"] .dd-handle:hover {
  background: #2a2a2a;
}

[data-primary="color_13"] .dd3-content:hover {
  background: #2a2a2a;
}

[data-primary="color_13"] .noUi-connect {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .noUi-connect.c-3-color {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .noUi-horizontal .noUi-handle,
[data-primary="color_13"] .noUi-vertical .noUi-handle {
  background-color: #2a2a2a;
}

[data-primary="color_13"] #slider-toggle.off .noUi-handle {
  border-color: #2a2a2a;
}

[data-primary="color_13"] .pignose-calendar {
  border-color: #2a2a2a;
}

[data-primary="color_13"] .pignose-calendar .pignose-calendar-top-date {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .bootstrap-tagsinput .tag {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .toast-success {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .accordion-header-bg .accordion__header--primary {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .alert-primary {
  background: #6a6a6a;
  border-color: #6a6a6a;
  color: #2a2a2a;
}

[data-primary="color_13"] .alert-alt.alert-primary {
  border-left: 4px solid #2a2a2a;
}

[data-primary="color_13"] .alert-alt.alert-primary.solid {
  border-left: 4px solid black !important;
}

[data-primary="color_13"] .alert.alert-primary.solid {
  background: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary="color_13"] .alert.alert-outline-primary {
  color: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary="color_13"] .badge-outline-primary {
  border: 1px solid #2a2a2a;
  color: #2a2a2a;
}

[data-primary="color_13"] .badge-primary {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .page-titles h4,
[data-primary="color_13"] .page-titles .h4 {
  color: #2a2a2a;
}

[data-primary="color_13"] .card-action>a {
  background: black;
}

[data-primary="color_13"] .card-action .dropdown {
  background: black;
  color: #2a2a2a;
}

[data-primary="color_13"] .card-action .dropdown:hover,
[data-primary="color_13"] .card-action .dropdown:focus {
  background: black;
}

[data-primary="color_13"] .card-loader i {
  background: #181818;
}

[data-primary="color_13"] .dropdown-outline {
  border: 0.1rem solid #2a2a2a;
}

[data-primary="color_13"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #2a2a2a;
}

[data-primary="color_13"] .card-action .custom-dropdown {
  background: #777777;
}

[data-primary="color_13"] .card-action .custom-dropdown.show,
[data-primary="color_13"] .card-action .custom-dropdown:focus,
[data-primary="color_13"] .card-action .custom-dropdown:hover {
  background: #2a2a2a;
}

[data-primary="color_13"] .label-primary {
  background: #2a2a2a;
}

[data-primary="color_13"] .pagination .page-item .page-link:hover {
  background: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary="color_13"] .pagination .page-item.active .page-link {
  background-color: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary="color_13"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_13"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #2a2a2a;
}

[data-primary="color_13"] .progress-bar {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .progress-bar-primary {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .ribbon__four {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .ribbon__four:after,
[data-primary="color_13"] .ribbon__four:before {
  background-color: #5d5d5d;
}

[data-primary="color_13"] .ribbon__five {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .ribbon__five::before {
  border-color: transparent transparent #2a2a2a transparent;
}

[data-primary="color_13"] .ribbon__six {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .multi-steps>li {
  color: #2a2a2a;
}

[data-primary="color_13"] .multi-steps>li:after {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .multi-steps>li.is-active:before {
  border-color: #2a2a2a;
}

[data-primary="color_13"] .timeline-badge.primary {
  background-color: #2a2a2a !important;
}

[data-primary="color_13"] .tooltip-wrapper button:hover {
  background: #2a2a2a;
}

[data-primary="color_13"] .chart_widget_tab_one .nav-link.active {
  background-color: #2a2a2a;
  border: 1px solid #2a2a2a;
}

[data-primary="color_13"] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #2a2a2a;
}

[data-primary="color_13"] .social-icon2 a {
  border: 0.1rem solid #2a2a2a;
}

[data-primary="color_13"] .social-icon2 i {
  color: #2a2a2a;
}

[data-primary="color_13"] .social-icon3 ul li a:hover i {
  color: #2a2a2a;
}

[data-primary="color_13"] .bgl-primary {
  background: #777777;
  border-color: #777777;
  color: #2a2a2a;
}

[data-primary="color_13"] .tdl-holder input[type=checkbox]:checked+i {
  background: #2a2a2a;
}

[data-primary="color_13"] .footer .copyright a {
  color: #2a2a2a;
}

[data-primary="color_13"] .hamburger .line {
  background: #2a2a2a;
}

[data-primary="color_13"] svg.pulse-svg .first-circle,
[data-primary="color_13"] svg.pulse-svg .second-circle,
[data-primary="color_13"] svg.pulse-svg .third-circle {
  fill: #2a2a2a;
}

[data-primary="color_13"] .pulse-css {
  background: #2a2a2a;
}

[data-primary="color_13"] .pulse-css:after,
[data-primary="color_13"] .pulse-css:before {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #2a2a2a;
}

[data-primary="color_13"] .header-right .header-profile .dropdown-menu a:hover,
[data-primary="color_13"] .header-right .header-profile .dropdown-menu a:focus,
[data-primary="color_13"] .header-right .header-profile .dropdown-menu a.active {
  color: #2a2a2a;
}

[data-primary="color_13"] .header-right .header-profile .profile_title {
  background: #2a2a2a;
}

[data-primary="color_13"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #2a2a2a !important;
}

[data-primary="color_13"] .deznav .metismenu>li>a svg {
  color: #2a2a2a;
}

[data-primary="color_13"] .deznav .metismenu>li:hover>a,
[data-primary="color_13"] .deznav .metismenu>li:focus>a {
  color: #2a2a2a;
}

[data-primary="color_13"] .deznav .metismenu>li.mm-active>a {
  color: #2a2a2a;
}

[data-primary="color_13"] .deznav .metismenu ul a:hover,
[data-primary="color_13"] .deznav .metismenu ul a:focus,
[data-primary="color_13"] .deznav .metismenu ul a.mm-active {
  color: #2a2a2a;
}

@media (min-width: 767px) {

  [data-primary="color_13"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:hover>a,
  [data-primary="color_13"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:focus>a,
  [data-primary="color_13"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:active>a,
  [data-primary="color_13"] [data-sidebar-style="modern"] .deznav .metismenu>li>a.mm-active>a {
    background-color: #aaaaaa;
  }
}

[data-primary="color_13"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .nav-user {
  background: #2a2a2a;
}

[data-primary="color_13"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #2a2a2a;
}

[data-primary="color_13"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
  color: #2a2a2a;
}

[data-primary="color_13"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary="color_13"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary="color_13"][data-theme-version="dark"] .header-left input:focus {
  border-color: #2a2a2a;
}

[data-primary="color_13"][data-theme-version="dark"] .loader__bar {
  background: #2a2a2a;
}

[data-primary="color_13"][data-theme-version="dark"] .loader__ball {
  background: #2a2a2a;
}

[data-primary="color_13"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #2a2a2a;
}

[data-primary="color_13"] .new-arrival-content .price {
  color: #2a2a2a;
}

[data-primary="color_13"] .chart-link a i.text-primary {
  color: #2a2a2a;
}

[data-primary="color_13"] #user-activity .nav-tabs .nav-link.active {
  background: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary="color_13"] span#counter {
  color: #2a2a2a;
}

[data-primary="color_13"] .welcome-content:after {
  background: #2a2a2a;
}

[data-primary="color_13"] .timeline-badge {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .page-timeline .timeline-workplan[data-primary="color_13"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(42, 42, 42, 0.4);
}

[data-primary="color_13"] .sk-three-bounce .sk-child {
  background-color: #2a2a2a;
}

[data-primary="color_13"] .dropdown-item.active,
[data-primary="color_13"] .dropdown-item:active {
  color: #fff;
  background-color: #2a2a2a;
}

[data-primary="color_13"] .overlay-box:after {
  background: #2a2a2a;
}

[data-primary="color_13"] .btn-primary {
  background-color: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary="color_13"] .bg-primary {
  background-color: #2a2a2a !important;
}

[data-primary="color_13"] .text-primary {
  color: #2a2a2a !important;
}

[data-primary="color_13"] .btn-primary:hover {
  background-color: #040404;
  border-color: #040404;
}

[data-primary="color_13"] .btn-outline-primary {
  color: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary="color_13"] .btn-outline-primary:hover {
  background-color: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary="color_13"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_13"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_13"] .show>.btn-primary.dropdown-toggle,
[data-primary="color_13"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_13"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_13"] .show>.btn-outline-primary.dropdown-toggle {
  background-color: #040404;
  border-color: #040404;
}

[data-primary="color_14"] a:hover,
[data-primary="color_14"] a:focus,
[data-primary="color_14"] a.active {
  color: #1367c8;
}

[data-primary="color_14"] [data-class="bg-primary"]:before {
  background: #1367c8;
}

[data-primary="color_14"] .email-left-box .intro-title {
  background: rgba(19, 103, 200, 0.1);
}

[data-primary="color_14"] .email-left-box .intro-title i {
  color: #1367c8;
}

[data-primary="color_14"] .widget-stat .media .media-body h4,
[data-primary="color_14"] .widget-stat .media .media-body .h4 {
  color: #1367c8 !important;
}

[data-primary="color_14"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(19, 103, 200, 0.1);
}

[data-primary="color_14"] .mail-list .list-group-item.active i {
  color: #1367c8;
}

[data-primary="color_14"] .single-mail.active {
  background: #1367c8;
}

[data-primary="color_14"] .profile-info h4.text-primary,
[data-primary="color_14"] .profile-info .text-primary.h4 {
  color: #1367c8 !important;
}

[data-primary="color_14"] .profile-tab .nav-item .nav-link:hover,
[data-primary="color_14"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #1367c8;
  color: #1367c8;
}

[data-primary="color_14"] .amChartsInputField {
  border: 0;
  background: #1367c8;
}

[data-primary="color_14"] .amcharts-period-input,
[data-primary="color_14"] .amcharts-period-input-selected {
  background: #1367c8;
}

[data-primary="color_14"] .morris-hover {
  background: #1367c8;
}

[data-primary="color_14"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #1367c8;
}

[data-primary="color_14"] .custom-select:focus {
  border-color: #1367c8;
  color: #1367c8;
}

[data-primary="color_14"] .daterangepicker td.active {
  background-color: #1367c8;
}

[data-primary="color_14"] .daterangepicker td.active:hover {
  background-color: #1367c8;
}

[data-primary="color_14"] .daterangepicker button.applyBtn {
  background-color: #1367c8;
  border-color: #1367c8;
}

[data-primary="color_14"] .wizard>.steps li.current a {
  background-color: #1367c8;
}

[data-primary="color_14"] .wizard .skip-email a {
  color: #1367c8;
}

[data-primary="color_14"] .wizard>.actions li:not(.disabled) a {
  background-color: #1367c8;
}

[data-primary="color_14"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #1367c8;
}

[data-primary="color_14"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #1367c8;
  border-color: #1367c8;
}

[data-primary="color_14"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #1367c8;
}

[data-primary="color_14"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #1367c8;
}

[data-primary="color_14"] .is-invalid .input-group-prepend .input-group-text i {
  color: #3b8dec;
}

[data-primary="color_14"] .datamaps-hoverover {
  color: #1367c8;
  border: 1px solid rgba(19, 103, 200, 0.3);
}

[data-primary="color_14"] .jqvmap-zoomin,
[data-primary="color_14"] .jqvmap-zoomout {
  background-color: #1367c8;
}

[data-primary="color_14"] .table .thead-primary th {
  background-color: #1367c8;
}

[data-primary="color_14"] .table.primary-table-bg-hover thead th {
  background-color: #115bb1;
}

[data-primary="color_14"] .table.primary-table-bg-hover tbody tr {
  background-color: #1367c8;
}

[data-primary="color_14"] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #1573df;
}

[data-primary="color_14"] .table.primary-table-bg-hover tbody tr:not(:last-child) td,
[data-primary="color_14"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 1px solid #115bb1;
}

[data-primary="color_14"] table.dataTable tr.selected {
  color: #1367c8;
}

[data-primary="color_14"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #1367c8 !important;
  background: rgba(19, 103, 200, 0.1);
}

[data-primary="color_14"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #1367c8 !important;
  background: rgba(19, 103, 200, 0.1);
}

[data-primary="color_14"] .clipboard-btn:hover {
  background-color: #1367c8;
}

[data-primary="color_14"] .cd-h-timeline__dates::before {
  background: #1367c8;
}

[data-primary="color_14"] .cd-h-timeline__dates::after {
  background: #1367c8;
}

[data-primary="color_14"] .cd-h-timeline__line {
  background-color: #1367c8;
}

[data-primary="color_14"] .cd-h-timeline__date:after {
  border-color: #1369cc;
  background-color: #1367c8;
}

[data-primary="color_14"] .cd-h-timeline__navigation {
  border-color: #1369cc;
}

[data-primary="color_14"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #1369cc;
}

[data-primary="color_14"] .dd-handle {
  background: #1367c8;
}

[data-primary="color_14"] .dd-handle:hover {
  background: #1367c8;
}

[data-primary="color_14"] .dd3-content:hover {
  background: #1367c8;
}

[data-primary="color_14"] .noUi-connect {
  background-color: #1367c8;
}

[data-primary="color_14"] .noUi-connect.c-3-color {
  background-color: #1367c8;
}

[data-primary="color_14"] .noUi-horizontal .noUi-handle,
[data-primary="color_14"] .noUi-vertical .noUi-handle {
  background-color: #1367c8;
}

[data-primary="color_14"] #slider-toggle.off .noUi-handle {
  border-color: #1367c8;
}

[data-primary="color_14"] .pignose-calendar {
  border-color: #1367c8;
}

[data-primary="color_14"] .pignose-calendar .pignose-calendar-top-date {
  background-color: #1367c8;
}

[data-primary="color_14"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #1367c8;
}

[data-primary="color_14"] .bootstrap-tagsinput .tag {
  background-color: #1367c8;
}

[data-primary="color_14"] .toast-success {
  background-color: #1367c8;
}

[data-primary="color_14"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #1367c8;
}

[data-primary="color_14"] .accordion-header-bg .accordion__header--primary {
  background-color: #1367c8;
}

[data-primary="color_14"] .alert-primary {
  background: #6aa8f1;
  border-color: #6aa8f1;
  color: #1367c8;
}

[data-primary="color_14"] .alert-alt.alert-primary {
  border-left: 4px solid #1367c8;
}

[data-primary="color_14"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #082b54 !important;
}

[data-primary="color_14"] .alert.alert-primary.solid {
  background: #1367c8;
  border-color: #1367c8;
}

[data-primary="color_14"] .alert.alert-outline-primary {
  color: #1367c8;
  border-color: #1367c8;
}

[data-primary="color_14"] .badge-outline-primary {
  border: 1px solid #1367c8;
  color: #1367c8;
}

[data-primary="color_14"] .badge-primary {
  background-color: #1367c8;
}

[data-primary="color_14"] .page-titles h4,
[data-primary="color_14"] .page-titles .h4 {
  color: #1367c8;
}

[data-primary="color_14"] .card-action>a {
  background: black;
}

[data-primary="color_14"] .card-action .dropdown {
  background: black;
  color: #1367c8;
}

[data-primary="color_14"] .card-action .dropdown:hover,
[data-primary="color_14"] .card-action .dropdown:focus {
  background: black;
}

[data-primary="color_14"] .card-loader i {
  background: #1056a7;
}

[data-primary="color_14"] .dropdown-outline {
  border: 0.1rem solid #1367c8;
}

[data-primary="color_14"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #1367c8;
}

[data-primary="color_14"] .card-action .custom-dropdown {
  background: #81b6f3;
}

[data-primary="color_14"] .card-action .custom-dropdown.show,
[data-primary="color_14"] .card-action .custom-dropdown:focus,
[data-primary="color_14"] .card-action .custom-dropdown:hover {
  background: #1367c8;
}

[data-primary="color_14"] .label-primary {
  background: #1367c8;
}

[data-primary="color_14"] .pagination .page-item .page-link:hover {
  background: #1367c8;
  border-color: #1367c8;
}

[data-primary="color_14"] .pagination .page-item.active .page-link {
  background-color: #1367c8;
  border-color: #1367c8;
}

[data-primary="color_14"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_14"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #1367c8;
}

[data-primary="color_14"] .progress-bar {
  background-color: #1367c8;
}

[data-primary="color_14"] .progress-bar-primary {
  background-color: #1367c8;
}

[data-primary="color_14"] .ribbon__four {
  background-color: #1367c8;
}

[data-primary="color_14"] .ribbon__four:after,
[data-primary="color_14"] .ribbon__four:before {
  background-color: #529bef;
}

[data-primary="color_14"] .ribbon__five {
  background-color: #1367c8;
}

[data-primary="color_14"] .ribbon__five::before {
  border-color: transparent transparent #1367c8 transparent;
}

[data-primary="color_14"] .ribbon__six {
  background-color: #1367c8;
}

[data-primary="color_14"] .multi-steps>li {
  color: #1367c8;
}

[data-primary="color_14"] .multi-steps>li:after {
  background-color: #1367c8;
}

[data-primary="color_14"] .multi-steps>li.is-active:before {
  border-color: #1367c8;
}

[data-primary="color_14"] .timeline-badge.primary {
  background-color: #1367c8 !important;
}

[data-primary="color_14"] .tooltip-wrapper button:hover {
  background: #1367c8;
}

[data-primary="color_14"] .chart_widget_tab_one .nav-link.active {
  background-color: #1367c8;
  border: 1px solid #1367c8;
}

[data-primary="color_14"] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #1367c8;
}

[data-primary="color_14"] .social-icon2 a {
  border: 0.1rem solid #1367c8;
}

[data-primary="color_14"] .social-icon2 i {
  color: #1367c8;
}

[data-primary="color_14"] .social-icon3 ul li a:hover i {
  color: #1367c8;
}

[data-primary="color_14"] .bgl-primary {
  background: #81b6f3;
  border-color: #81b6f3;
  color: #1367c8;
}

[data-primary="color_14"] .tdl-holder input[type=checkbox]:checked+i {
  background: #1367c8;
}

[data-primary="color_14"] .footer .copyright a {
  color: #1367c8;
}

[data-primary="color_14"] .hamburger .line {
  background: #1367c8;
}

[data-primary="color_14"] svg.pulse-svg .first-circle,
[data-primary="color_14"] svg.pulse-svg .second-circle,
[data-primary="color_14"] svg.pulse-svg .third-circle {
  fill: #1367c8;
}

[data-primary="color_14"] .pulse-css {
  background: #1367c8;
}

[data-primary="color_14"] .pulse-css:after,
[data-primary="color_14"] .pulse-css:before {
  background-color: #1367c8;
}

[data-primary="color_14"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #1367c8;
}

[data-primary="color_14"] .header-right .header-profile .dropdown-menu a:hover,
[data-primary="color_14"] .header-right .header-profile .dropdown-menu a:focus,
[data-primary="color_14"] .header-right .header-profile .dropdown-menu a.active {
  color: #1367c8;
}

[data-primary="color_14"] .header-right .header-profile .profile_title {
  background: #1367c8;
}

[data-primary="color_14"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #1367c8 !important;
}

[data-primary="color_14"] .deznav .metismenu>li>a svg {
  color: #1367c8;
}

[data-primary="color_14"] .deznav .metismenu>li:hover>a,
[data-primary="color_14"] .deznav .metismenu>li:focus>a {
  color: #1367c8;
}

[data-primary="color_14"] .deznav .metismenu>li.mm-active>a {
  color: #1367c8;
}

[data-primary="color_14"] .deznav .metismenu ul a:hover,
[data-primary="color_14"] .deznav .metismenu ul a:focus,
[data-primary="color_14"] .deznav .metismenu ul a.mm-active {
  color: #1367c8;
}

@media (min-width: 767px) {

  [data-primary="color_14"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:hover>a,
  [data-primary="color_14"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:focus>a,
  [data-primary="color_14"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:active>a,
  [data-primary="color_14"] [data-sidebar-style="modern"] .deznav .metismenu>li>a.mm-active>a {
    background-color: #deecfc;
  }
}

[data-primary="color_14"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #1367c8;
}

[data-primary="color_14"] .nav-user {
  background: #1367c8;
}

[data-primary="color_14"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #1367c8;
}

[data-primary="color_14"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
  color: #1367c8;
}

[data-primary="color_14"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #1367c8;
  border-color: #1367c8;
}

[data-primary="color_14"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #1367c8;
  border-color: #1367c8;
}

[data-primary="color_14"][data-theme-version="dark"] .header-left input:focus {
  border-color: #1367c8;
}

[data-primary="color_14"][data-theme-version="dark"] .loader__bar {
  background: #1367c8;
}

[data-primary="color_14"][data-theme-version="dark"] .loader__ball {
  background: #1367c8;
}

[data-primary="color_14"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #1367c8;
}

[data-primary="color_14"] .new-arrival-content .price {
  color: #1367c8;
}

[data-primary="color_14"] .chart-link a i.text-primary {
  color: #1367c8;
}

[data-primary="color_14"] #user-activity .nav-tabs .nav-link.active {
  background: #1367c8;
  border-color: #1367c8;
}

[data-primary="color_14"] span#counter {
  color: #1367c8;
}

[data-primary="color_14"] .welcome-content:after {
  background: #1367c8;
}

[data-primary="color_14"] .timeline-badge {
  background-color: #1367c8;
}

[data-primary="color_14"] .page-timeline .timeline-workplan[data-primary="color_14"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(19, 103, 200, 0.4);
}

[data-primary="color_14"] .sk-three-bounce .sk-child {
  background-color: #1367c8;
}

[data-primary="color_14"] .dropdown-item.active,
[data-primary="color_14"] .dropdown-item:active {
  color: #fff;
  background-color: #1367c8;
}

[data-primary="color_14"] .overlay-box:after {
  background: #1367c8;
}

[data-primary="color_14"] .btn-primary {
  background-color: #1367c8;
  border-color: #1367c8;
}

[data-primary="color_14"] .bg-primary {
  background-color: #1367c8 !important;
}

[data-primary="color_14"] .text-primary {
  color: #1367c8 !important;
}

[data-primary="color_14"] .btn-primary:hover {
  background-color: #0c4382;
  border-color: #0c4382;
}

[data-primary="color_14"] .btn-outline-primary {
  color: #1367c8;
  border-color: #1367c8;
}

[data-primary="color_14"] .btn-outline-primary:hover {
  background-color: #1367c8;
  border-color: #1367c8;
}

[data-primary="color_14"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_14"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_14"] .show>.btn-primary.dropdown-toggle,
[data-primary="color_14"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_14"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_14"] .show>.btn-outline-primary.dropdown-toggle {
  background-color: #0c4382;
  border-color: #0c4382;
}

[data-primary="color_15"] a:hover,
[data-primary="color_15"] a:focus,
[data-primary="color_15"] a.active {
  color: #ed0b4c;
}

[data-primary="color_15"] [data-class="bg-primary"]:before {
  background: #ed0b4c;
}

[data-primary="color_15"] .email-left-box .intro-title {
  background: rgba(237, 11, 76, 0.1);
}

[data-primary="color_15"] .email-left-box .intro-title i {
  color: #ed0b4c;
}

[data-primary="color_15"] .widget-stat .media .media-body h4,
[data-primary="color_15"] .widget-stat .media .media-body .h4 {
  color: #ed0b4c !important;
}

[data-primary="color_15"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(237, 11, 76, 0.1);
}

[data-primary="color_15"] .mail-list .list-group-item.active i {
  color: #ed0b4c;
}

[data-primary="color_15"] .single-mail.active {
  background: #ed0b4c;
}

[data-primary="color_15"] .profile-info h4.text-primary,
[data-primary="color_15"] .profile-info .text-primary.h4 {
  color: #ed0b4c !important;
}

[data-primary="color_15"] .profile-tab .nav-item .nav-link:hover,
[data-primary="color_15"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #ed0b4c;
  color: #ed0b4c;
}

[data-primary="color_15"] .amChartsInputField {
  border: 0;
  background: #ed0b4c;
}

[data-primary="color_15"] .amcharts-period-input,
[data-primary="color_15"] .amcharts-period-input-selected {
  background: #ed0b4c;
}

[data-primary="color_15"] .morris-hover {
  background: #ed0b4c;
}

[data-primary="color_15"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .custom-select:focus {
  border-color: #ed0b4c;
  color: #ed0b4c;
}

[data-primary="color_15"] .daterangepicker td.active {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .daterangepicker td.active:hover {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .daterangepicker button.applyBtn {
  background-color: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary="color_15"] .wizard>.steps li.current a {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .wizard .skip-email a {
  color: #ed0b4c;
}

[data-primary="color_15"] .wizard>.actions li:not(.disabled) a {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #ed0b4c;
}

[data-primary="color_15"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary="color_15"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #ed0b4c;
}

[data-primary="color_15"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .is-invalid .input-group-prepend .input-group-text i {
  color: #f74e7e;
}

[data-primary="color_15"] .datamaps-hoverover {
  color: #ed0b4c;
  border: 1px solid rgba(237, 11, 76, 0.3);
}

[data-primary="color_15"] .jqvmap-zoomin,
[data-primary="color_15"] .jqvmap-zoomout {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .table .thead-primary th {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .table.primary-table-bg-hover thead th {
  background-color: #d50a44;
}

[data-primary="color_15"] .table.primary-table-bg-hover tbody tr {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #f51d5b;
}

[data-primary="color_15"] .table.primary-table-bg-hover tbody tr:not(:last-child) td,
[data-primary="color_15"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 1px solid #d50a44;
}

[data-primary="color_15"] table.dataTable tr.selected {
  color: #ed0b4c;
}

[data-primary="color_15"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #ed0b4c !important;
  background: rgba(237, 11, 76, 0.1);
}

[data-primary="color_15"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #ed0b4c !important;
  background: rgba(237, 11, 76, 0.1);
}

[data-primary="color_15"] .clipboard-btn:hover {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .cd-h-timeline__dates::before {
  background: #ed0b4c;
}

[data-primary="color_15"] .cd-h-timeline__dates::after {
  background: #ed0b4c;
}

[data-primary="color_15"] .cd-h-timeline__line {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .cd-h-timeline__date:after {
  border-color: #f10b4d;
  background-color: #ed0b4c;
}

[data-primary="color_15"] .cd-h-timeline__navigation {
  border-color: #f10b4d;
}

[data-primary="color_15"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #f10b4d;
}

[data-primary="color_15"] .dd-handle {
  background: #ed0b4c;
}

[data-primary="color_15"] .dd-handle:hover {
  background: #ed0b4c;
}

[data-primary="color_15"] .dd3-content:hover {
  background: #ed0b4c;
}

[data-primary="color_15"] .noUi-connect {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .noUi-connect.c-3-color {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .noUi-horizontal .noUi-handle,
[data-primary="color_15"] .noUi-vertical .noUi-handle {
  background-color: #ed0b4c;
}

[data-primary="color_15"] #slider-toggle.off .noUi-handle {
  border-color: #ed0b4c;
}

[data-primary="color_15"] .pignose-calendar {
  border-color: #ed0b4c;
}

[data-primary="color_15"] .pignose-calendar .pignose-calendar-top-date {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .bootstrap-tagsinput .tag {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .toast-success {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .accordion-header-bg .accordion__header--primary {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .alert-primary {
  background: #f97ea2;
  border-color: #f97ea2;
  color: #ed0b4c;
}

[data-primary="color_15"] .alert-alt.alert-primary {
  border-left: 4px solid #ed0b4c;
}

[data-primary="color_15"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #730525 !important;
}

[data-primary="color_15"] .alert.alert-primary.solid {
  background: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary="color_15"] .alert.alert-outline-primary {
  color: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary="color_15"] .badge-outline-primary {
  border: 1px solid #ed0b4c;
  color: #ed0b4c;
}

[data-primary="color_15"] .badge-primary {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .page-titles h4,
[data-primary="color_15"] .page-titles .h4 {
  color: #ed0b4c;
}

[data-primary="color_15"] .card-action>a {
  background: black;
}

[data-primary="color_15"] .card-action .dropdown {
  background: black;
  color: #ed0b4c;
}

[data-primary="color_15"] .card-action .dropdown:hover,
[data-primary="color_15"] .card-action .dropdown:focus {
  background: black;
}

[data-primary="color_15"] .card-loader i {
  background: #cb0941;
}

[data-primary="color_15"] .dropdown-outline {
  border: 0.1rem solid #ed0b4c;
}

[data-primary="color_15"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #ed0b4c;
}

[data-primary="color_15"] .card-action .custom-dropdown {
  background: #fa97b3;
}

[data-primary="color_15"] .card-action .custom-dropdown.show,
[data-primary="color_15"] .card-action .custom-dropdown:focus,
[data-primary="color_15"] .card-action .custom-dropdown:hover {
  background: #ed0b4c;
}

[data-primary="color_15"] .label-primary {
  background: #ed0b4c;
}

[data-primary="color_15"] .pagination .page-item .page-link:hover {
  background: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary="color_15"] .pagination .page-item.active .page-link {
  background-color: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary="color_15"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_15"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #ed0b4c;
}

[data-primary="color_15"] .progress-bar {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .progress-bar-primary {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .ribbon__four {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .ribbon__four:after,
[data-primary="color_15"] .ribbon__four:before {
  background-color: #f86690;
}

[data-primary="color_15"] .ribbon__five {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .ribbon__five::before {
  border-color: transparent transparent #ed0b4c transparent;
}

[data-primary="color_15"] .ribbon__six {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .multi-steps>li {
  color: #ed0b4c;
}

[data-primary="color_15"] .multi-steps>li:after {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .multi-steps>li.is-active:before {
  border-color: #ed0b4c;
}

[data-primary="color_15"] .timeline-badge.primary {
  background-color: #ed0b4c !important;
}

[data-primary="color_15"] .tooltip-wrapper button:hover {
  background: #ed0b4c;
}

[data-primary="color_15"] .chart_widget_tab_one .nav-link.active {
  background-color: #ed0b4c;
  border: 1px solid #ed0b4c;
}

[data-primary="color_15"] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #ed0b4c;
}

[data-primary="color_15"] .social-icon2 a {
  border: 0.1rem solid #ed0b4c;
}

[data-primary="color_15"] .social-icon2 i {
  color: #ed0b4c;
}

[data-primary="color_15"] .social-icon3 ul li a:hover i {
  color: #ed0b4c;
}

[data-primary="color_15"] .bgl-primary {
  background: #fa97b3;
  border-color: #fa97b3;
  color: #ed0b4c;
}

[data-primary="color_15"] .tdl-holder input[type=checkbox]:checked+i {
  background: #ed0b4c;
}

[data-primary="color_15"] .footer .copyright a {
  color: #ed0b4c;
}

[data-primary="color_15"] .hamburger .line {
  background: #ed0b4c;
}

[data-primary="color_15"] svg.pulse-svg .first-circle,
[data-primary="color_15"] svg.pulse-svg .second-circle,
[data-primary="color_15"] svg.pulse-svg .third-circle {
  fill: #ed0b4c;
}

[data-primary="color_15"] .pulse-css {
  background: #ed0b4c;
}

[data-primary="color_15"] .pulse-css:after,
[data-primary="color_15"] .pulse-css:before {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #ed0b4c;
}

[data-primary="color_15"] .header-right .header-profile .dropdown-menu a:hover,
[data-primary="color_15"] .header-right .header-profile .dropdown-menu a:focus,
[data-primary="color_15"] .header-right .header-profile .dropdown-menu a.active {
  color: #ed0b4c;
}

[data-primary="color_15"] .header-right .header-profile .profile_title {
  background: #ed0b4c;
}

[data-primary="color_15"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #ed0b4c !important;
}

[data-primary="color_15"] .deznav .metismenu>li>a svg {
  color: #ed0b4c;
}

[data-primary="color_15"] .deznav .metismenu>li:hover>a,
[data-primary="color_15"] .deznav .metismenu>li:focus>a {
  color: #ed0b4c;
}

[data-primary="color_15"] .deznav .metismenu>li.mm-active>a {
  color: #ed0b4c;
}

[data-primary="color_15"] .deznav .metismenu ul a:hover,
[data-primary="color_15"] .deznav .metismenu ul a:focus,
[data-primary="color_15"] .deznav .metismenu ul a.mm-active {
  color: #ed0b4c;
}

@media (min-width: 767px) {

  [data-primary="color_15"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:hover>a,
  [data-primary="color_15"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:focus>a,
  [data-primary="color_15"] [data-sidebar-style="modern"] .deznav .metismenu>li>a:active>a,
  [data-primary="color_15"] [data-sidebar-style="modern"] .deznav .metismenu>li>a.mm-active>a {
    background-color: #fff8fa;
  }
}

[data-primary="color_15"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .nav-user {
  background: #ed0b4c;
}

[data-primary="color_15"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #ed0b4c;
}

[data-primary="color_15"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
  color: #ed0b4c;
}

[data-primary="color_15"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary="color_15"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary="color_15"][data-theme-version="dark"] .header-left input:focus {
  border-color: #ed0b4c;
}

[data-primary="color_15"][data-theme-version="dark"] .loader__bar {
  background: #ed0b4c;
}

[data-primary="color_15"][data-theme-version="dark"] .loader__ball {
  background: #ed0b4c;
}

[data-primary="color_15"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #ed0b4c;
}

[data-primary="color_15"] .new-arrival-content .price {
  color: #ed0b4c;
}

[data-primary="color_15"] .chart-link a i.text-primary {
  color: #ed0b4c;
}

[data-primary="color_15"] #user-activity .nav-tabs .nav-link.active {
  background: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary="color_15"] span#counter {
  color: #ed0b4c;
}

[data-primary="color_15"] .welcome-content:after {
  background: #ed0b4c;
}

[data-primary="color_15"] .timeline-badge {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .page-timeline .timeline-workplan[data-primary="color_15"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(237, 11, 76, 0.4);
}

[data-primary="color_15"] .sk-three-bounce .sk-child {
  background-color: #ed0b4c;
}

[data-primary="color_15"] .dropdown-item.active,
[data-primary="color_15"] .dropdown-item:active {
  color: #fff;
  background-color: #ed0b4c;
}

[data-primary="color_15"] .overlay-box:after {
  background: #ed0b4c;
}

[data-primary="color_15"] .btn-primary {
  background-color: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary="color_15"] .bg-primary {
  background-color: #ed0b4c !important;
}

[data-primary="color_15"] .text-primary {
  color: #ed0b4c !important;
}

[data-primary="color_15"] .btn-primary:hover {
  background-color: #a40835;
  border-color: #a40835;
}

[data-primary="color_15"] .btn-outline-primary {
  color: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary="color_15"] .btn-outline-primary:hover {
  background-color: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary="color_15"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_15"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_15"] .show>.btn-primary.dropdown-toggle,
[data-primary="color_15"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_15"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_15"] .show>.btn-outline-primary.dropdown-toggle {
  background-color: #a40835;
  border-color: #a40835;
}

.bg-primary-alt {
  background-color: rgba(30, 63, 180, 0.1);
}

.bg-secondary-alt {
  background-color: rgba(160, 44, 250, 0.1);
}

.bg-success-alt {
  background-color: rgba(43, 193, 85, 0.1);
}

.bg-info-alt {
  background-color: rgba(30, 167, 197, 0.1);
}

.bg-warning-alt {
  background-color: rgba(255, 188, 17, 0.1);
}

.bg-danger-alt {
  background-color: rgba(249, 70, 135, 0.1);
}

.bg-light-alt {
  background-color: rgba(244, 245, 249, 0.1);
}

.bg-dark-alt {
  background-color: rgba(177, 177, 177, 0.1);
}

[data-typography="poppins"] {
  font-family: 'poppins', sans-serif;
}

[data-typography="opensans"] {
  font-family: 'Open Sans', sans-serif;
}

[data-typography="roboto"] {
  font-family: 'Roboto', sans-serif;
}

[data-typography="helvetica"] {
  font-family: 'HelveticaNeue';
}

[data-theme-version="dark"] {
  background: #222327;
  color: #828690;
  --bs-body-bg: #111217;
}

[data-theme-version="dark"] h1,
[data-theme-version="dark"] .h1,
[data-theme-version="dark"] h2,
[data-theme-version="dark"] .h2,
[data-theme-version="dark"] h3,
[data-theme-version="dark"] .h3,
[data-theme-version="dark"] h4,
[data-theme-version="dark"] .h4,
[data-theme-version="dark"] h5,
[data-theme-version="dark"] .h5,
[data-theme-version="dark"] h6,
[data-theme-version="dark"] .h6 {
  color: #fff;
}

[data-theme-version="dark"] a.link {
  color: #ddd;
}

[data-theme-version="dark"] a.link:focus,
[data-theme-version="dark"] a.link:hover {
  color: #1EA7C5;
}

[data-theme-version="dark"] a:hover {
  color: #fff;
}

[data-theme-version="dark"] .border-right {
  border-right: 1px solid #333a54 !important;
}

[data-theme-version="dark"] .border-left {
  border-left: 1px solid #333a54 !important;
}

[data-theme-version="dark"] .border-top {
  border-top: 1px solid #333a54 !important;
}

[data-theme-version="dark"] .border-bottom {
  border-bottom: 1px solid #333a54 !important;
}

[data-theme-version="dark"] .border {
  border: 1px solid #333a54 !important;
}

[data-theme-version="dark"] {
  /* cms-dark */
}

[data-theme-version="dark"] .dropdown-menu {
  background-color: #222327;
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.1);
}

[data-theme-version="dark"] .dropdown-menu .dropdown-item.selected,
[data-theme-version="dark"] .dropdown-menu .dropdown-item.selected.active,
[data-theme-version="dark"] .dropdown-menu .dropdown-item.active,
[data-theme-version="dark"] .dropdown-menu .dropdown-item:hover,
[data-theme-version="dark"] .dropdown-menu .dropdown-item:focus {
  background-color: #111217;
  color: #7e7e7e;
}

[data-theme-version="dark"] .form-control {
  background-color: #111217 !important;
  border-color: #333a54;
  color: #fff;
}

[data-theme-version="dark"] .search-area .form-control {
  background-color: #222327 !important;
}

[data-theme-version="dark"] .search-area .input-group-text {
  background-color: #222327;
}

[data-theme-version="dark"] .search-area i {
  color: #fff;
}

[data-theme-version="dark"] #chartBar line,
[data-theme-version="dark"] #chartTimeline line,
[data-theme-version="dark"] #chartBar2 line,
[data-theme-version="dark"] #chartBar3 line,
[data-theme-version="dark"] #chartBarCycle line,
[data-theme-version="dark"] #chartBarRunning line {
  stroke: #333a54;
}

[data-theme-version="dark"] .card {
  background-color: #111217;
  box-shadow: none;
}

[data-theme-version="dark"] .btn-outline-dark:hover {
  background-color: #222327;
  border-color: #222327;
}

[data-theme-version="dark"] .tdl-holder input[type=text],
[data-theme-version="dark"] .tdl-holder li {
  background-color: #111217;
  border-color: #333a54;
}

[data-theme-version="dark"] .tdl-holder input[type=text]::placeholder {
  color: #828690;
}

[data-theme-version="dark"] .tdl-holder label:hover {
  background-color: #333a54;
  color: #fff;
}

[data-theme-version="dark"] .text-muted {
  color: #828690 !important;
}

[data-theme-version="dark"] .modal-content {
  background: #111217;
}

[data-theme-version="dark"] .modal-footer,
[data-theme-version="dark"] .modal-header {
  border-color: #333a54;
}

[data-theme-version="dark"] .close {
  font-weight: 400;
  color: #fff;
  text-shadow: none;
}

[data-theme-version="dark"] .close:hover {
  color: #fff;
}

[data-theme-version="dark"] .table strong {
  color: #fff;
}

[data-theme-version="dark"] .text-dark {
  color: #828690 !important;
}

[data-theme-version="dark"] .star-rating .product-review,
[data-theme-version="dark"] .new-arrival-content .item {
  color: #fff;
}

[data-theme-version="dark"] .custom-dropdown .dropdown-menu {
  border-color: #333a54;
}

[data-theme-version="dark"] .widget-stat .media>span {
  background: #333a54;
  border-color: #333a54;
  color: #fff;
}

[data-theme-version="dark"] .plus-minus-input .custom-btn {
  background: #222327;
  border-color: #333a54;
}

[data-theme-version="dark"] .dropdown-divider,
[data-theme-version="dark"] .size-filter ul li {
  border-color: #333a54;
}

[data-theme-version="dark"] .custom-select {
  border-color: #333a54;
  color: #828690;
  background: #222327;
}

[data-theme-version="dark"] .nav-tabs {
  border-color: #333a54;
}

[data-theme-version="dark"] .mail-list .list-group-item.active i {
  color: #fff;
}

[data-theme-version="dark"] hr {
  border-color: #333a54;
}

[data-theme-version="dark"] .grid-col {
  background: #222327;
}

[data-theme-version="dark"] .noUi-target {
  border-color: #333a54;
  border-radius: 8px;
  box-shadow: none;
}

[data-theme-version="dark"] .noUi-target .noUi-connects {
  background: #333a54;
}

[data-theme-version="dark"] .noUi-marker-large,
[data-theme-version="dark"] .noUi-marker {
  background: #333a54;
}

[data-theme-version="dark"] .input-group-text {
  background: #222327;
  color: #7e7e7e;
}

[data-theme-version="dark"] .border-end {
  border-color: #333a54 !important;
}

[data-theme-version="dark"] .note-editor.note-frame {
  border-color: #333a54;
}

[data-theme-version="dark"] .note-editor.note-frame .btn {
  color: #fff;
}

[data-theme-version="dark"] .note-editor.note-frame .note-editing-area .note-editable {
  color: #fff;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel {
  border-color: #333a54;
}

[data-theme-version="dark"] .notification_dropdown .dropdown-menu-right .all-notification {
  border-color: #333a54;
}

[data-theme-version="dark"] #user-activity .nav-tabs .nav-link {
  border-color: #333a54;
}

[data-theme-version="dark"] .list-group-item-action {
  color: #7e7e7e;
}

[data-theme-version="dark"] .list-group-item-action:focus,
[data-theme-version="dark"] .list-group-item-action:hover,
[data-theme-version="dark"] .list-group-item-action:focus {
  background-color: #222327;
  border-color: #222327;
}

[data-theme-version="dark"] .list-group-item.active {
  color: #fff;
  border-color: #99c990;
}

[data-theme-version="dark"] .list-group-item.active:focus,
[data-theme-version="dark"] .list-group-item.active:hover,
[data-theme-version="dark"] .list-group-item.active:focus {
  background-color: #99c990;
  border-color: #99c990;
  color: #fff;
}

[data-theme-version="dark"] .swal2-popup {
  background: #111217;
}

[data-theme-version="dark"] .dashboard_bar {
  color: #fff;
}

[data-theme-version="dark"] .avtivity-card .text-black {
  color: #fff !important;
}

[data-theme-version="dark"] .text-black {
  color: #fff !important;
}

[data-theme-version="dark"] .card-footer.style-1 a {
  background-color: #222327;
  color: #fff !important;
}

[data-theme-version="dark"] #SvgjsText1300 {
  fill: #fff;
}

[data-theme-version="dark"] .card-action.card-tabs .nav-tabs {
  background-color: #222327;
  color: #fff;
}

[data-theme-version="dark"] .card-action.card-tabs .nav-tabs .nav-link {
  color: #fff;
}

[data-theme-version="dark"] .card-action.card-tabs .nav-tabs .nav-link.active {
  background-color: #111217;
  color: #fff;
}

[data-theme-version="dark"] .card-tabs.style-1 .nav-tabs {
  background-color: #111217;
}

[data-theme-version="dark"] .plan-list .list-row .play-button,
[data-theme-version="dark"] .plan-list .list-row .more-button {
  background-color: #222327;
}

[data-theme-version="dark"] .event-calender .bootstrap-datetimepicker-widget table td.day {
  color: #fff;
}

[data-theme-version="dark"] .event-calender .datepicker table tr td.active.active {
  background-color: #222327 !important;
}

[data-theme-version="dark"] .event-calender .bootstrap-datetimepicker-widget table th.picker-switch {
  color: #fff;
}

[data-theme-version="dark"] .bg-light .text-black {
  color: #000 !important;
}

[data-theme-version="dark"] .bg-white {
  background-color: #111217 !important;
}

[data-theme-version="dark"] table.dataTable tbody td {
  background-color: #111217 !important;
  color: #fff;
}

[data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate .paginate_button.previous,
[data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate .paginate_button.next {
  background-color: #222327 !important;
  color: #fff !important;
}

[data-theme-version="dark"] .btn-link g [fill] {
  fill: #fff;
}

[data-theme-version="dark"] .page-titles {
  background-color: #222327;
}

[data-theme-version="dark"] .table thead th {
  color: #fff;
  border-color: #333a54;
}

[data-theme-version="dark"] .fc-theme-standard td,
[data-theme-version="dark"] .fc-theme-standard th,
[data-theme-version="dark"] .fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid,
[data-theme-version="dark"] .fc-scrollgrid,
[data-theme-version="dark"] table,
[data-theme-version="dark"] .fc .fc-button-group>.fc-button,
[data-theme-version="dark"] .form-check-input[type="checkbox"],
[data-theme-version="dark"] .form-check-input[type="radio"] {
  border-color: #333a54 !important;
}

[data-theme-version="dark"] table.dataTable thead th {
  border-color: #333a54;
}

[data-theme-version="dark"] .daterangepicker {
  background: #222327;
  border-color: #99c990;
}

[data-theme-version="dark"] .daterangepicker .calendar-table {
  border-color: #99c990;
  background: #222327;
}

[data-theme-version="dark"] .daterangepicker .calendar-table .table-condensed td:hover {
  background-color: #99c990;
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker:after {
  border-bottom: 6px solid #222327;
}

[data-theme-version="dark"] .daterangepicker select.hourselect,
[data-theme-version="dark"] .daterangepicker select.minuteselect,
[data-theme-version="dark"] .daterangepicker select.secondselect,
[data-theme-version="dark"] .daterangepicker select.ampmselect {
  background: #222327;
  border: 1px solid #333a54;
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker td.off,
[data-theme-version="dark"] .daterangepicker td.off.in-range,
[data-theme-version="dark"] .daterangepicker td.off.start-date,
[data-theme-version="dark"] .daterangepicker td.off.end-date {
  background-color: transparent;
}

[data-theme-version="dark"] .daterangepicker td.off:hover,
[data-theme-version="dark"] .daterangepicker td.off.in-range:hover,
[data-theme-version="dark"] .daterangepicker td.off.start-date:hover,
[data-theme-version="dark"] .daterangepicker td.off.end-date:hover {
  background-color: #99c990;
  color: #fff;
}

[data-theme-version="dark"] button.cancelBtn.btn.btn-sm.btn-inverse,
[data-theme-version="dark"] .btn-group-sm>button.cancelBtn.btn.btn-inverse {
  background-color: #111217;
}

[data-theme-version="dark"] .clockpicker-plate {
  background-color: #222327;
  border-color: #333a54;
}

[data-theme-version="dark"] .dtp>.dtp-content {
  background-color: #222327;
}

[data-theme-version="dark"] .dtp table.dtp-picker-days tr>td>a,
[data-theme-version="dark"] .dtp .dtp-picker-time>a {
  color: #7e7e7e;
}

[data-theme-version="dark"] .daterangepicker .drp-buttons,
[data-theme-version="dark"] .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
  border-color: #333a54;
}

[data-theme-version="dark"] .clockpicker-popover .popover-content {
  background-color: #111217;
}

[data-theme-version="dark"] .popover-title {
  background-color: #222327;
}

[data-theme-version="dark"] .note-editor.note-frame .note-statusbar,
[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link span,
[data-theme-version="dark"] .svg-icons-ov.style-1 .svg-icons-prev,
[data-theme-version="dark"] .svg-icons-ov .svg-icon-popup .btn {
  background-color: #222327;
}

[data-theme-version="dark"] .dd-handle {
  border-color: #333a54;
}

[data-theme-version="dark"] .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill='white' d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}

[data-theme-version="dark"] .svg-area .card-header,
[data-theme-version="dark"] .new-arrival-content h4 a,
[data-theme-version="dark"] .new-arrival-content .h4 a,
[data-theme-version="dark"] .new-arrival-content .h4 a {
  color: #fff;
}

[data-theme-version="dark"] .svg-icons-ov,
[data-theme-version="dark"] .cm-content-box .cm-content-body,
[data-theme-version="dark"] .menu-tabs,
[data-theme-version="dark"] .cm-content-box.style-1 .content-title,
[data-theme-version="dark"] .dd .accordion-body,
[data-theme-version="dark"] .dz-terms,
[data-theme-version="dark"] .compose-content .dropzone {
  border-color: #333a54;
}

[data-theme-version="dark"] .new-scroll,
[data-theme-version="dark"] .compose-content .dropzone {
  background-color: #222327 !important;
}

[data-theme-version="dark"] .cm-content-box,
[data-theme-version="dark"] .dd-handle.move-media+.accordion-button {
  background-color: #111217;
}

[data-theme-version="dark"] .btn-icon-right {
  border-color: #333a54;
}

[data-theme-version="dark"] .accordion-danger-solid .accordion-button {
  border-color: #F94687;
}

[data-theme-version="dark"] .deznav .metismenu>li.mm-active>a {
  background-color: #27282D !important;
}

[data-theme-version="dark"] .testimonial-one .owl-nav .owl-prev,
[data-theme-version="dark"] .testimonial-one .owl-nav .owl-next {
  background-color: #222327 !important;
}

[data-theme-version="dark"] .datepicker.datepicker-dropdown {
  border-color: #333a54;
}

[data-theme-version="dark"] .event-calender .bootstrap-datetimepicker-widget table th {
  color: #fff;
}

[data-theme-version="dark"] .nav-tabs.diet-tabs .nav-link {
  background-color: #222327;
  color: #fff;
}

[data-theme-version="dark"] .nav-tabs.diet-tabs .nav-link.active {
  background-color: #99c990;
}

[data-theme-version="dark"] .brand-title {
  filter: brightness(35.9);
}

[data-theme-version="dark"] .tab-icon .nav-link.active {
  border-color: #333a54;
}

[data-theme-version="dark"] .tab-icon .nav-link.active i {
  color: #fff;
}

[data-theme-version="dark"] .tab-icon .nav-link:hover {
  border-color: #333a54;
}

[data-theme-version="dark"] .notification_dropdown .dropdown-menu-end .all-notification,
[data-theme-version="dark"] .header-profile .dropdown-menu-end .all-notification {
  border-color: #333a54;
}

[data-theme-version="dark"] svg text {
  fill: #fff;
}

[data-theme-version="dark"] #chart line {
  stroke: #333a54;
}

[data-theme-version="dark"] .react-calendar {
  background: transparent;
}

[data-theme-version="dark"] .react-calendar .react-calendar__navigation__label__labelText {
  color: #fff;
}

[data-theme-version="dark"] .react-calendar .react-calendar__navigation__label__labelText:hover {
  color: #000;
}

[data-theme-version="dark"] .react-calendar .react-calendar__navigation__arrow {
  color: #fff;
}

[data-theme-version="dark"] .react-calendar .react-calendar__tile {
  color: #fff;
}

[data-theme-version="dark"] .react-calendar .react-calendar__tile:hover {
  color: #000;
}

[data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul {
  background: #111217;
}

[data-theme-version="dark"] .email-left-body.active {
  background: #1b1c1f;
}

[data-theme-version="dark"] .header {
  background-color: #111217;
}

[data-theme-version="dark"] .header-left input {
  background: #222327 !important;
  border-color: transparent;
  color: #fff !important;
}

[data-theme-version="dark"] .header-left input:focus {
  box-shadow: none;
  border-color: #99c990;
}

[data-theme-version="dark"] .header-left input::placeholder {
  color: #fff;
}

[data-theme-version="dark"] .header-left .search_icon {
  background: #222327 !important;
}

[data-theme-version="dark"] .header-left .search_icon i {
  color: #fff;
}

[data-theme-version="dark"] .header-right .right-sidebar a {
  color: #99c990;
  border-color: #333a54;
}

[data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info strong {
  color: #fff;
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link {
  background: #222327;
}

[data-theme-version="dark"] .header-right .dropdown .nav-link {
  color: #fff;
}

[data-theme-version="dark"] .header-right .dropdown .nav-link:hover {
  color: #fff;
}

[data-theme-version="dark"] .header-right .notification_dropdown .dropdown-item a {
  color: #fff;
}

[data-theme-version="dark"] .nav-header,
[data-theme-version="dark"] .nav-control {
  background-color: #111217;
}

[data-theme-version="dark"] .nav-control {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="dark"] .nav-header,
[data-theme-version="dark"] .nav-control {
  background-color: #111217;
}

[data-theme-version="dark"] .nav-control {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li>ul {
  background-color: #111217;
}

[data-theme-version="dark"] .fixed-content-box {
  background-color: #111217;
}

[data-theme-version="dark"] .fixed-content-box .head-name {
  background: #111217;
  color: #fff;
  border-color: #333a54;
}

[data-theme-version="dark"] .fixed-content-box+.header+.deznav {
  background-color: #222327;
}

[data-theme-version="dark"] .deznav {
  background-color: #111217;
}

[data-theme-version="dark"] .deznav .metismenu>li>a {
  color: #b3b3b3;
}

[data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-theme-version="dark"] .deznav .metismenu>li:focus>a,
[data-theme-version="dark"] .deznav .metismenu>li.mm-active>a {
  color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu>li:hover>a::after,
[data-theme-version="dark"] .deznav .metismenu>li:focus>a::after,
[data-theme-version="dark"] .deznav .metismenu>li.mm-active>a::after {
  border-color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu>li.mm-active ul ul {
  background-color: transparent;
}

[data-theme-version="dark"] .deznav .metismenu ul:after {
  background-color: #333a54;
}

[data-theme-version="dark"] .deznav .metismenu ul a:hover,
[data-theme-version="dark"] .deznav .metismenu ul a:focus,
[data-theme-version="dark"] .deznav .metismenu ul a.mm-active {
  color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu ul a:before {
  background-color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu a {
  color: #b3b3b3;
}

[data-theme-version="dark"] .deznav .metismenu .has-arrow:after {
  border-color: #fff;
}

[data-theme-version="dark"] .sidebar-right {
  background-color: #2a2b30;
}

[data-theme-version="dark"] .sidebar-right .nav-tabs {
  background-color: #2a2b30;
  border-bottom: 1px solid #222327;
}

[data-theme-version="dark"] .sidebar-right .nav-tabs .nav-link.active {
  background-color: #222327;
}

[data-theme-version="dark"] .footer {
  background-color: transparent;
}

[data-theme-version="dark"] .footer .copyright {
  background-color: transparent;
}

[data-theme-version="dark"] .footer .copyright p {
  color: rgba(255, 255, 255, 0.7);
}

/* =============
   Calendar
============= */
.app-fullcalender button {
  border-radius: 0rem;
  color: #B1B1B1;
}

.app-fullcalender td {
  border-color: #E6E6E6;
}

.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-view {
  margin-top: 1.875rem;
}

.fc-toolbar {
  margin-bottom: 0.3125rem;
  margin-top: 0.9375rem;
}

.fc-toolbar .fc-left {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  float: none;
}

.fc-toolbar .fc-right {
  display: flex;
  justify-content: center;
  float: none;
  margin-bottom: 0.3125rem;
}

.fc-toolbar .fc-center {
  display: flex;
  justify-content: center;
}

.fc-toolbar .fc-center * {
  float: none;
}

.fc-toolbar h2,
.fc-toolbar .h2 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.875rem;
  text-transform: uppercase;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar .ui-state-hover,
.fc-toolbar button:focus,
.fc-toolbar button:hover {
  z-index: 0;
  box-shadow: none;
}

.fc-widget-header {
  border: 0.0625rem solid #E6E6E6;
  border-bottom: 0 !important;
}

.fc th.fc-widget-header {
  background: #E6E6E6 !important;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.625rem 0;
  text-transform: uppercase;
}

.fc-button {
  border: 0.0625rem solid #E6E6E6;
  text-transform: capitalize;
}

.fc-button.active {
  box-shadow: none !important;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem;
}

.fc-event,
.external-event {
  border-radius: 0.125rem;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 0.3125rem 0.4375rem;
  padding: 0.3125rem;
  text-align: center;
}

.external-event {
  cursor: move;
  margin: 0.625rem 0;
  padding: 0.125rem 0;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 0.3125rem;
}

.fc-basic-view td.fc-day-number {
  padding-right: 0.3125rem;
}

#drop-remove {
  margin-right: 0.5rem;
  top: 0.1875rem;
}

#add-category .modal-dialog,
#event-modal .modal-dialog {
  max-width: 37.5rem;
}

.fc-content {
  color: #fff;
}

.fc th.fc-widget-header {
  background: transparent !important;
}

.fc-button {
  background: #fff;
}

.fc-state-hover {
  background: #fff !important;
}

.fc-state-highlight {
  background: #f2f4fa !important;
}

[data-theme-version="dark"] .fc-state-highlight {
  color: #fff !important;
}

.fc-cell-overlay {
  background: #fff !important;
}

.fc-unthemed .fc-today {
  background: #f2f4fa !important;
}

.fc-day-top {
  color: #B1B1B1 !important;
}

[data-theme-version="dark"] .fc-day-top {
  color: #fff !important;
}

.external-event {
  color: #fff;
}

[data-theme-version="dark"] .external-event {
  color: #fff !important;
}

.fc-basic-view .fc-body .fc-row {
  min-height: 1rem;
}

.fc-scroller.fc-day-grid-container {
  height: 30.625rem !important;
}

.fc-row.fc-week.fc-widget-content.fc-rigid {
  height: 5.0625rem !important;
}

@media only screen and (max-width: 90rem) {
  .email_left_pane {
    display: none;
  }
}

.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-slot-label-cushion {
  font-size: 14px;
}

#external-events .external-event:before {
  content: "";
  display: block;
  width: 0.875rem;
  min-width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  margin-right: .9rem;
  position: relative;
  top: 0.125rem;
}

[data-theme-version="dark"] #external-events [data-class="bg-primary"] {
  color: #fff !important;
}

#external-events [data-class="bg-primary"]::before {
  background: #99c990;
}

#external-events [data-class="bg-success"]::before {
  background: #2BC155;
}

#external-events [data-class="bg-warning"]::before {
  background: #FFBC11;
}

#external-events [data-class="bg-dark"]::before {
  background: #B1B1B1;
}

#external-events [data-class="bg-danger"]::before {
  background: #F94687;
}

#external-events [data-class="bg-info"]::before {
  background: #1EA7C5;
}

#external-events [data-class="bg-pink"]::before {
  background: #e83e8c;
}

#external-events [data-class="bg-secondary"]::before {
  background: #A02CFA;
}

.fc .fc-row .fc-content-skeleton table,
.fc .fc-row .fc-content-skeleton td,
.fc .fc-row .fc-helper-skeleton td {
  border-color: #E6E6E6;
}

[data-theme-version="dark"] .fc .fc-row .fc-content-skeleton table,
[data-theme-version="dark"] .fc .fc-row .fc-content-skeleton td,
[data-theme-version="dark"] .fc .fc-row .fc-helper-skeleton td {
  border-color: #333a54;
}

[data-theme-version="dark"] .fc-unthemed .fc-content,
[data-theme-version="dark"] .fc-unthemed .fc-divider,
[data-theme-version="dark"] .fc-unthemed .fc-list-heading td,
[data-theme-version="dark"] .fc-unthemed .fc-list-view,
[data-theme-version="dark"] .fc-unthemed .fc-popover,
[data-theme-version="dark"] .fc-unthemed .fc-row,
[data-theme-version="dark"] .fc-unthemed tbody,
[data-theme-version="dark"] .fc-unthemed td,
[data-theme-version="dark"] .fc-unthemed th,
[data-theme-version="dark"] .fc-unthemed thead {
  border-color: #333a54;
}

.email-left-box {
  padding: 0 1.25rem 1.25rem 1rem;
  border-top: 0;
  border-left: 0;
  width: 100%;
  float: none;
  border: none;
  padding-bottom: 30px !important;
}

@media only screen and (max-width: 100rem) {
  .email-left-box {
    padding: 0;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .email-left-box {
    width: 100%;
    padding-bottom: 0px !important;
  }
}

.email-left-box .intro-title {
  background: rgba(30, 63, 180, 0.1);
  padding: 1rem;
  margin: 1.875rem 0 1.25rem 0;
}

.email-left-box .intro-title h5,
.email-left-box .intro-title .h5 {
  margin-bottom: 0;
  color: #6a707e;
  font-size: 14px;
}

.email-left-box .intro-title h5 i,
.email-left-box .intro-title .h5 i {
  font-size: 0.75rem;
  position: relative;
  bottom: 1px;
}

.email-left-box .intro-title i {
  color: #99c990;
}

.email-right-box {
  padding-right: 1rem;
  padding-right: 0;
}

@media only screen and (max-width: 991px) {
  .email-right-box {
    padding-right: 0;
    padding-top: 1rem;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .email-right-box {
    margin-left: 0 !important;
    clear: both;
  }
}

.email-right-box .right-box-border {
  border-right: 2px solid rgba(30, 63, 180, 0.1);
}

@media screen and (min-width: 649px) and (max-width: 1200px) {
  .email-right-box .right-box-padding {
    padding-left: 1.25rem;
  }
}

@media (min-width: 1700px) {
  .email-right-box .right-box-padding {
    padding-left: 0.9375rem;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .email-right-box .right-box-padding {
    padding-left: 0;
  }
}

.toolbar .btn-group .btn {
  border: 0;
}

.toolbar .btn-group input {
  position: relative;
  top: 2px;
}

.read-content textarea {
  height: 150px;
  padding: 15px 20px;
}

.read-content-email {
  font-size: 0.875rem;
}

.read-content h5,
.read-content .h5 {
  color: #6a707e;
}

.read-content p strong {
  color: #6a707e;
}

.read-content-body p {
  margin-bottom: 1.875rem;
}

.read-content-attachment {
  padding: 0.5rem 0;
}

.read-content-attachment h6,
.read-content-attachment .h6 {
  font-size: 1.125rem;
  color: #6a707e;
}

.read-content-attachment h6 i,
.read-content-attachment .h6 i {
  padding-right: 0.3125rem;
}

.read-content-attachment .attachment>div:not(:last-child) {
  border-right: 1px solid #DDDFE1;
}

.compose-content .wysihtml5-toolbar {
  border-color: #eaeaea;
}

.compose-content .dropzone {
  background: #f2f4fa !important;
}

.compose-content h5,
.compose-content .h5 {
  font-size: 1.0625rem;
  color: #6a707e;
}

.compose-content h5 i,
.compose-content .h5 i {
  font-size: 1.125rem;
  transform: rotate(90deg);
}

.compose-content .dropzone {
  border: 1px dashed #DDDFE1;
  min-height: 13.125rem;
  position: relative;
  border-radius: 0.625rem;
}

.compose-content .dropzone .dz-message {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.email-list {
  display: block;
  padding-left: 0;
}

.email-list .message {
  position: relative;
  display: block;
  height: 3.125rem;
  line-height: 3.125rem;
  cursor: default;
  transition-duration: 0.3s;
}

.email-list .message a {
  color: #828690;
}

.email-list .message-single .custom-checkbox {
  margin-top: 2px;
}

.email-list .message-single i {
  color: #89879f;
  font-size: 1.125rem;
  padding-left: .4rem;
}

.email-list .message:hover {
  transition-duration: 0.05s;
  background: rgba(152, 166, 173, 0.15);
}

.email-list .message .col-mail {
  float: left;
  position: relative;
}

.email-list .message .col-mail-1 {
  width: 5.625rem;
}

.email-list .message .col-mail-1 .star-toggle {
  display: block;
  float: left;
  margin-top: 1.125rem;
  font-size: 1rem;
  margin-left: 0.3125rem;
}

.email-list .message .col-mail-1 .email-checkbox {
  display: block;
  float: left;
  margin: 0.9375rem 0.625rem 0 1.25rem;
}

.email-list .message .col-mail-1 .dot {
  display: block;
  float: left;
  border: .4rem solid transparent;
  border-radius: 6.25rem;
  margin: 1.375rem 1.625rem 0;
  height: 0;
  width: 0;
  line-height: 0;
  font-size: 0;
}

.email-list .message .col-mail-2 {
  position: absolute;
  top: 0;
  left: 5.625rem;
  right: 0;
  bottom: 0;
}

.email-list .message .col-mail-2 .subject {
  position: absolute;
  top: 0;
  left: 0;
  right: 5.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.email-list .message .col-mail-2 .date {
  position: absolute;
  top: 0;
  right: 0;
}

.email-checkbox {
  cursor: pointer;
  height: 0.9375rem;
  width: 0.9375rem;
  position: relative;
  display: inline-block;
  border-radius: .1rem;
  position: relative;
  top: 0.3125rem;
  box-shadow: inset 0 0 0 0.1rem #828690;
}

.email-checkbox input {
  opacity: 0;
  cursor: pointer;
}

.email-checkbox input:checked label {
  opacity: 1;
}

.email-checkbox label {
  position: absolute;
  top: .3rem;
  left: .3rem;
  right: .3rem;
  bottom: .3rem;
  cursor: pointer;
  opacity: 0;
  margin-bottom: 0 !important;
  transition-duration: 0.05s;
}

.mail-list {
  border-radius: 0.625rem;
}

.mail-list a {
  vertical-align: middle;
  padding: 0.625rem 0.9375rem;
  display: block;
  background: transparent;
  color: #464a53;
  font-weight: 600;
}

.mail-list .list-group-item {
  color: #6a707e;
  padding: 0.75rem 1.0625rem;
}

.mail-list .list-group-item i {
  font-size: 1rem;
  padding-right: 0.625rem;
  color: #cccccc;
}

.mail-list .list-group-item.active {
  color: #fff;
  background-color: #99c990;
  border-color: #99c990;
}

.mail-list .list-group-item.active i {
  color: #fff;
}

.chat-wrap {
  padding: 1.0625rem 1.875rem;
}

.chat-wrap .media .media-body h6,
.chat-wrap .media .media-body .h6 {
  font-size: 1.0625rem;
  color: #6a707e;
}

.chat-wrap .media .media-body p {
  font-size: 0.875rem;
}

@media (min-width: 648px) {
  .email-filter {
    padding-left: 1.25rem;
  }
}

@media (min-width: 1700px) {
  .email-filter {
    padding-left: 1.875rem;
  }
}

.email-filter .input-group-prepend i {
  font-size: 0.875rem;
  color: #89879f;
}

.email-filter .input-group-prepend .input-group-text {
  border: 0;
  border-bottom: 1px solid #DDDFE1 !important;
  background: transparent;
}

.email-filter .input-group .form-control {
  padding: 0 0 0 0.3125rem;
  border: 0;
  font-size: 0.875rem;
  height: 1.875rem;
  color: #89879f;
  border-bottom: 1px solid #DDDFE1;
}

.email-filter .input-group .form-control::placeholder {
  font-size: 0.875rem;
  color: #89879f;
}

.email-filter .input-group>.form-control {
  min-height: 1.875rem;
}

.single-mail {
  display: block;
  padding: 1.5625rem 0;
}

.single-mail .media {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

@media (min-width: 1700px) {
  .single-mail .media {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}

.single-mail .media img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin-right: 0.9375rem;
}

@media (min-width: 1700px) {
  .single-mail .media img {
    margin-right: 1.875rem;
  }
}

.single-mail .media-body h6,
.single-mail .media-body .h6 {
  color: #abafb3;
}

.single-mail .media-body h4,
.single-mail .media-body .h4 {
  font-size: 1rem;
  color: #6a707e;
}

.single-mail .media-body h4 button i,
.single-mail .media-body .h4 button i {
  font-size: 1.125rem;
  color: #abafb3;
  font-weight: bold;
  transform: rotate(90deg);
}

.single-mail .media-body p {
  font-size: 0.875rem;
  color: #abafb3;
}

.single-mail.active {
  background: #99c990;
}

.single-mail.active h6,
.single-mail.active .h6,
.single-mail.active h4,
.single-mail.active .h4,
.single-mail.active p,
.single-mail.active i {
  color: #fff !important;
}

[direction="rtl"] .email-right-box {
  padding-left: 1rem;
  padding-right: 15rem;
}

@media only screen and (max-width: 991px) {
  [direction="rtl"] .email-right-box {
    padding-left: 1rem;
    padding-right: 12rem;
  }
}

@media only screen and (max-width: 575px) {
  [direction="rtl"] .email-right-box {
    padding-left: 0;
    padding-right: 0;
  }
}

[direction="rtl"] .email-left-box {
  float: right;
}

[direction="rtl"] .email-list .message .col-mail-2 {
  right: 5.625rem;
  left: 0;
  float: right;
}

[direction="rtl"] .email-list .message .col-mail-2 .date {
  right: auto;
  left: 0;
}

[direction="rtl"] .email-list .message .col-mail-2 .subject {
  right: 0;
  left: 5.5rem;
}

@media only screen and (max-width: 575px) {
  .email-left-body {
    width: 18rem;
    position: fixed;
    height: 100vh;
    z-index: 2;
    transition: all 0.5s;
    left: -22rem;
    top: 0px;
    z-index: 999;
  }

  .email-left-body.active {
    left: 0;
    background: #fff;
    transition: all 0.5s;
    overflow-y: scroll;
  }
}

.email-tools-box {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #d8e0f9;
  border-radius: 0.625rem;
  color: #99c990;
  display: none !important;
}

.email-tools-box.active {
  background-color: #99c990;
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .email-tools-box {
    display: inline-block !important;
    margin-left: auto;
  }
}

.email-tools-box.active:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vh;
  height: 100vh;
  background-color: #000;
  opacity: 0.2;
  border-radius: 0.75rem;
  z-index: 4;
}

.email-left-box {
  margin-bottom: 20px;
}

@media only screen and (max-width: 1199px) {
  .email-left-box {
    margin-right: -10px;
  }
}

@media only screen and (max-width: 991px) {
  .email-left-box {
    margin: 0;
  }
}

@media only screen and (max-width: 575px) {
  .email-left-box {
    margin-top: 20px;
  }
}

.photo-content {
  position: relative;
}

.photo-content .cover-photo {
  background: url(../images/profile/cover.jpg);
  background-size: cover;
  background-position: center;
  min-height: 250px;
  width: 100%;
  border-radius: 0.625rem;
}

.profile .profile-photo {
  max-width: 100px;
  position: relative;
  z-index: 1;
  margin-top: -40px;
  margin-right: 10px;
}

@media only screen and (max-width: 575px) {
  .profile .profile-photo {
    width: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
}

[direction="rtl"] .profile .photo-content .profile-photo {
  left: auto;
  right: 50px;
}

[direction="rtl"] .profile .profile-photo {
  left: auto;
  right: 30px;
}

@media only screen and (max-width: 1199px) {
  [direction="rtl"] .profile .profile-photo {
    right: 15px;
  }
}

@media only screen and (max-width: 575px) {
  [direction="rtl"] .profile .profile-photo {
    width: 80px;
    right: calc(50% - 40px);
    top: -100px;
  }
}

.profile-info {
  padding: 15px 20px;
}

@media only screen and (max-width: 575px) {
  .profile-info {
    padding: 0 0 20px;
    text-align: center;
  }
}

.profile-info h4,
.profile-info .h4 {
  color: #464a53 !important;
}

.profile-info h4.text-primary,
.profile-info .text-primary.h4 {
  color: #99c990 !important;
}

.profile-info p {
  color: #828690;
}

.profile-info .prf-col {
  min-width: 250px;
  padding: 10px 50px 0;
}

.profile-interest .row {
  margin: 0 -.70px;
}

.profile-interest .row .int-col {
  padding: 0 .70px;
}

.profile-interest .row .int-col .interest-cat {
  margin-bottom: 1.40px;
  position: relative;
  display: block;
}

.profile-interest .row .int-col .interest-cat:after {
  background: #000;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.profile-interest .row .int-col .interest-cat p {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  left: 0;
  margin: 0;
  z-index: 1;
  color: white;
  font-size: 1.20px;
}

.profile-tab .nav-item .nav-link {
  font-size: 16px;
  margin-right: 30px;
  transition: all 0.5s ease-in-out;
  border: none;
  border-bottom: .20px solid transparent;
  color: #828690;
}

.profile-tab .nav-item .nav-link:hover,
.profile-tab .nav-item .nav-link.active {
  border: 0;
  background: transparent;
  background: transparent;
  border-bottom: 0.2px solid #99c990;
  color: #99c990;
}

@media only screen and (max-width: 575px) {
  .profile-tab .nav-item .nav-link {
    margin-right: 0px;
  }
}

.profile-info {
  display: flex;
}

@media only screen and (max-width: 575px) {
  .profile-info {
    display: block;
  }
}

.profile-info .profile-details {
  display: flex;
  width: 100%;
}

@media only screen and (max-width: 575px) {
  .profile-info .profile-details {
    display: block;
  }

  .profile-info .profile-details .dropdown {
    position: absolute;
    top: 30px;
    right: 30px;
  }
}

.post-input {
  margin-bottom: 30px;
}

.post-input .form-control {
  height: 75px;
  font-weight: 400;
  margin: 15px 0;
}

.profile-uoloaded-post .post-title h3,
.profile-uoloaded-post .post-title .h3 {
  font-size: 24px;
}

@media only screen and (max-width: 575px) {

  .profile-uoloaded-post .post-title h3,
  .profile-uoloaded-post .post-title .h3 {
    font-size: 18px;
  }
}

.profile-uoloaded-post img {
  margin-bottom: 20px;
}

.profile-uoloaded-post a h4,
.profile-uoloaded-post a .h4 {
  margin-bottom: 10px;
  color: #464a53;
}

.post-input {
  margin-bottom: 1.875rem;
}

.post-input .form-control {
  font-weight: 400;
}

.post-input .btn-social {
  font-size: 1.25rem;
  height: 3.4375rem;
  display: inline-block;
  padding: 0;
  text-align: center;
  border-radius: 0.625rem;
  color: #fff;
  width: 3.4375rem;
  line-height: 3.375rem;
}

@media only screen and (max-width: 575px) {
  .post-input .btn-social {
    font-size: 0.95rem;
    height: 2.2375rem;
    width: 2.2375rem;
    line-height: 2.375rem;
  }
}

.post-input .btn-social.facebook {
  background-color: #3B5998;
}

.post-input .btn-social.google-plus {
  background-color: #DE4E43;
}

.post-input .btn-social.linkedin {
  background-color: #007BB6;
}

.post-input .btn-social.instagram {
  background-color: #8A5A4E;
}

.post-input .btn-social.twitter {
  background-color: #1EA1F3;
}

.post-input .btn-social.youtube {
  background-color: #CE201F;
}

.post-input .btn-social.whatsapp {
  background-color: #01C854;
}

.post-input .btn-social i {
  margin: 0 !important;
}

.profile-blog a:hover {
  color: #99c990;
}

.event-chat-ryt .chat-area .chat-reciver,
.event-chat-ryt .chat-area .chat-sender {
  margin-bottom: 1.875rem;
  padding: 0;
}

.event-chat-ryt .chat-area .chat-reciver img,
.event-chat-ryt .chat-area .chat-sender img {
  border-radius: 30px;
}

.event-chat-ryt .chat-area .media {
  position: relative;
}

.event-chat-ryt .chat-area .media-body p {
  margin: 0;
  max-width: 100%;
  display: inline-block;
  position: relative;
}

.event-chat-ryt .chat-area .media-body p span {
  padding: 1rem;
  display: inline-block;
  top: 103%;
  position: relative;
  border: 1px solid #E6E6E6;
}

.event-chat-ryt .chat-reciver {
  padding: .5rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.event-chat-ryt .chat-reciver .media {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.event-chat-ryt .chat-reciver .media .media-body {
  margin-right: 2rem;
  text-align: right;
}

[direction="rtl"] .event-chat-ryt .chat-reciver .media .media-body {
  text-align: left;
  margin-left: 2rem;
  margin-right: auto;
}

.event-chat-ryt .chat-reciver .media .media-body p {
  background: #fff;
  margin-bottom: 0;
  border-radius: 5px 5px 0 5px;
}

.event-chat-ryt .chat-reciver .media .media-body p span {
  text-align: left;
  border: 1px solid #E6E6E6;
}

.event-chat-ryt .chat-reciver .media .media-body p span::after {
  content: "";
  width: 20px;
  height: 20px;
  border-bottom: 1px solid #E6E6E6;
  border-right: 1px solid #E6E6E6;
  position: absolute;
  right: 0;
  bottom: 0;
  background: #fff;
  -webkit-transform: rotate(-45deg) translateX(15px);
  transform: rotate(-45deg) translateX(15px);
}

[direction="rtl"] .event-chat-ryt .chat-reciver .media .media-body p span::after {
  left: 0;
  right: auto;
  -webkit-transform: rotate(135deg) translateY(15px);
  transform: rotate(135deg) translateY(15px);
}

.event-chat-ryt .chat-reciver .media .media-body p .time {
  position: absolute;
  font-size: 12px;
  color: #7e7e7e;
  font-weight: 400;
  bottom: 0;
  left: -80px;
}

[direction="rtl"] .event-chat-ryt .chat-reciver .media .media-body p .time {
  right: -5rem;
  left: auto;
}

.event-chat-ryt .chat-sender {
  text-align: left;
  padding: .5rem 1rem;
}

.event-chat-ryt .chat-sender .media .media-body {
  margin-left: 2rem;
}

[direction="rtl"] .event-chat-ryt .chat-sender .media .media-body {
  text-align: right;
  margin-right: 2rem;
  margin-left: auto;
}

.event-chat-ryt .chat-sender .media .media-body p {
  background-color: #fff;
  margin-bottom: 0;
}

.event-chat-ryt .chat-sender .media .media-body p span::after {
  content: "";
  width: 20px;
  height: 20px;
  border-bottom: 1px solid #E6E6E6;
  border-left: 1px solid #E6E6E6;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  -webkit-transform: rotate(45deg) translateX(-15px);
  transform: rotate(45deg) translateX(-15px);
}

[direction="rtl"] .event-chat-ryt .chat-sender .media .media-body p span::after {
  left: auto;
  right: 0;
  -webkit-transform: rotate(-135deg) translateY(15px);
  transform: rotate(-135deg) translateY(15px);
}

.event-chat-ryt .chat-sender .media .media-body p .time {
  position: absolute;
  font-size: 10px;
  color: #7e7e7e;
  font-weight: 400;
  bottom: 0;
  right: -5rem;
}

[direction="rtl"] .event-chat-ryt .chat-sender .media .media-body p .time {
  left: -5rem;
  right: auto;
}

.char-type {
  padding-top: 30px;
  padding-bottom: 30px;
}

.char-type form .form-control {
  height: 45px;
  padding-left: 18px;
  background: #fff;
  border-right: 0;
}

.char-type form .input-group-append i {
  color: #898989;
  font-size: 18px;
}

.char-type form .input-group-append .input-group-text {
  padding-left: .7rem;
  padding-right: .7rem;
  background: #fff;
  border-color: #E6E6E6;
  border-left: 0;
}

.char-type form .input-group-append .input-group-text:last-child {
  padding-right: 1.8rem;
}

.media-avatar {
  padding: 25px 0;
  border-bottom: 1px solid #E6E6E6;
}

.media-avatar:last-child {
  border-bottom: 0px;
}

.media-avatar p {
  margin-bottom: 0;
}

.media-avatar .avatar-status {
  position: relative;
}

.media-avatar .avatar-status i {
  position: absolute;
  right: 0;
  bottom: 0;
}

.ct-golden-section:before {
  float: none;
}

.ct-chart {
  max-height: 15.7rem;
}

.ct-chart .ct-label {
  fill: #a3afb7;
  color: #a3afb7;
  font-size: 0.75rem;
  line-height: 1;
}

.ct-grid {
  stroke: rgba(49, 58, 70, 0.1);
}

.ct-chart.simple-pie-chart-chartist .ct-label {
  color: #ffffff;
  fill: #ffffff;
  font-size: 0.625rem;
}

.ct-chart .ct-series.ct-series-a .ct-bar,
.ct-chart .ct-series.ct-series-a .ct-line,
.ct-chart .ct-series.ct-series-a .ct-point,
.ct-chart .ct-series.ct-series-a .ct-slice-donut {
  stroke: #99c990;
}

.ct-chart .ct-series.ct-series-b .ct-bar,
.ct-chart .ct-series.ct-series-b .ct-line,
.ct-chart .ct-series.ct-series-b .ct-point,
.ct-chart .ct-series.ct-series-b .ct-slice-donut {
  stroke: #2BC155;
}

.ct-chart .ct-series.ct-series-c .ct-bar,
.ct-chart .ct-series.ct-series-c .ct-line,
.ct-chart .ct-series.ct-series-c .ct-point,
.ct-chart .ct-series.ct-series-c .ct-slice-donut {
  stroke: #FFBC11;
}

.ct-chart .ct-series.ct-series-d .ct-bar,
.ct-chart .ct-series.ct-series-d .ct-line,
.ct-chart .ct-series.ct-series-d .ct-point,
.ct-chart .ct-series.ct-series-d .ct-slice-donut {
  stroke: #F94687;
}

.ct-chart .ct-series.ct-series-e .ct-bar,
.ct-chart .ct-series.ct-series-e .ct-line,
.ct-chart .ct-series.ct-series-e .ct-point,
.ct-chart .ct-series.ct-series-e .ct-slice-donut {
  stroke: #1EA7C5;
}

.ct-chart .ct-series.ct-series-f .ct-bar,
.ct-chart .ct-series.ct-series-f .ct-line,
.ct-chart .ct-series.ct-series-f .ct-point,
.ct-chart .ct-series.ct-series-f .ct-slice-donut {
  stroke: #B1B1B1;
}

.ct-chart .ct-series.ct-series-g .ct-bar,
.ct-chart .ct-series.ct-series-g .ct-line,
.ct-chart .ct-series.ct-series-g .ct-point,
.ct-chart .ct-series.ct-series-g .ct-slice-donut {
  stroke: #8d6e63;
}

.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
  fill: #A02CFA;
}

.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
  fill: #00A2FF;
}

.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
  fill: #ff9800;
}

.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
  fill: #ff9800;
}

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 0.625rem;
  padding: 2px 0.625rem;
  border-radius: 3px;
  background: #313a46;
  color: #ffffff;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity .2s linear;
  -moz-transition: opacity .2s linear;
  -o-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

.chartist-tooltip.tooltip-show {
  opacity: 1;
}

#donught_graph .ct-series.ct-series-a .ct-slice-donut {
  stroke: #3FC6D4;
}

#donught_graph .ct-series.ct-series-b .ct-slice-donut {
  stroke: #333333;
}

#donught_graph .ct-series.ct-series-c .ct-slice-donut {
  stroke: #F63465;
}

@media only screen and (max-width: 767px) {
  #pie-chart .ct-label {
    color: #fff;
    fill: #fff;
  }
}

#visitor_graph {
  height: 253px !important;
}

#user_rating_graph {
  height: 280px !important;
}

#visitorOnline {
  height: 72px !important;
}

#trendMeter {
  height: 72px !important;
}

#widget-revenue1,
#widget-revenue2,
#widget-revenue3 {
  height: 117px !important;
}

#widget-profit1,
#widget-profit2,
#widget-profit3 {
  height: 160px !important;
}

#comparison-rate {
  height: 230px !important;
}

#session_day {
  height: 175px !important;
  width: auto !important;
  margin: 0 auto;
}

#walet-status {
  height: 140px !important;
}

#bar1 {
  height: 150px !important;
}

#sold-product {
  height: 230px !important;
}

#chart-venue-expenses,
#chart-online-sale,
#chart-gross-sale {
  height: 150px !important;
}

#areaChart_3 {
  height: 295px !important;
  width: 100% !important;
}

@media only screen and (max-width: 575px) {
  #areaChart_3 {
    height: 100% !important;
  }
}

.chart-point {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart-point .check-point-area {
  width: 100px;
  height: 100px;
  margin-top: -10px;
  margin-left: -10px;
}

.chart-point .chart-point-list {
  margin: 0;
  padding-left: 5px;
}

.chart-point .chart-point-list li {
  list-style: none;
  font-size: 13px;
  padding: 2px 0;
}

.chart-point .chart-point-list li i {
  margin-right: 5px;
  font-size: 11px;
  position: relative;
  top: -1px;
}

.flot-chart {
  height: 15.7rem;
}

.tooltipflot {
  background-color: transparent;
  font-size: 1.4rem;
  padding: .5rem 1rem;
  color: rgba(255, 255, 255, 0.7);
  border-radius: .2rem;
}

.legendColorBox>div {
  border: 0 !important;
  padding: 0 !important;
}

.legendLabel {
  font-size: 0.825rem;
  padding-left: .5rem;
  color: #fff;
}

.flotTip {
  background: #000;
  border: 1px solid #000;
  color: #fff;
}

.legend>div {
  background: transparent !important;
}

#balance_graph {
  height: 260px;
}

.morris-hover {
  position: absolute;
  z-index: 1;
  background: #99c990;
  color: #fff;
}

.morris-hover .morris-hover-point {
  color: #fff !important;
  margin: 3px 0;
  text-align: center;
  padding: 0 25px;
}

.morris-hover .morris-hover-row-label {
  background-color: #B1B1B1;
  text-align: center;
  padding: 5px;
  margin-bottom: 5px;
}

.morris-hover.morris-default-style {
  border-radius: 5px;
  padding: 0;
  margin: 0;
  border: none;
  overflow: hidden;
}

svg text {
  font-weight: 600 !important;
}

#morris_donught,
#morris_donught_2,
#line_chart_2,
#morris_bar,
#morris_bar_stalked,
#morris_bar_2,
#morris_area_2,
#morris_area {
  height: 240px !important;
}

#morris_line {
  height: 278px !important;
}

#crypto-btc-card,
#crypto-eth-card,
#crypto-rpl-card,
#crypto-ltc-card {
  height: 9.375rem;
}

#daily-sales,
#comparison-rate,
#usage-chart,
#walet-status {
  width: 100%;
  display: block;
}

#daily-sales canvas,
#comparison-rate canvas,
#usage-chart canvas,
#walet-status canvas {
  max-width: 100% !important;
  width: 100% !important;
}

#sparkline-composite-chart canvas,
#composite-bar canvas,
#sparkline11 canvas,
#StackedBarChart canvas,
#spark-bar canvas,
#tristate canvas {
  height: 100px !important;
}

#sparkline11 canvas {
  width: 100px !important;
}

.easy-pie-chart {
  position: relative;
  text-align: center;
}

.easy-pie-chart .inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
}

.easy-pie-chart img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

.easy-pie-chart canvas {
  display: block;
  margin: 0 auto;
}

.form-control {
  border-radius: 0.625rem;
  background: #fff;
  border: 1px solid #E6E6E6;
  color: #B1B1B1;
  height: 56px;
}

@media only screen and (max-width: 1400px) {
  .form-control {
    height: 44px;
  }
}

.form-control:hover,
.form-control:focus,
.form-control.active {
  box-shadow: none;
  background: #fff;
  color: #B1B1B1;
}

.form-control.form-control-lg {
  height: 4.5rem;
}

.form-control.form-control-lg .dropdown-toggle {
  line-height: 3.75rem;
}

.form-control.form-control-sm {
  height: 2.5rem;
}

.form-control.form-control-sm .dropdown-toggle {
  line-height: 2rem;
  padding: 0.25rem 1.563rem;
}

.input-group-sm .form-control {
  height: 2.5rem;
}

.input-group-lg .form-control {
  height: 4.5rem;
}

.form-control[type="file"] {
  line-height: 44px;
  padding-left: 12px;
}

@media only screen and (max-width: 1400px) {
  .form-control[type="file"] {
    line-height: 29px;
  }
}

.form-control-sm[type="file"] {
  line-height: 32px;
  padding: 4px;
  padding-left: 8px;
}

.form-control-lg[type="file"] {
  line-height: 53px;
  padding-left: 10px;
}

.input-rounded {
  border-radius: 100px;
}

[data-theme-version="light"] .input-primary .form-control,
[data-theme-version="dark"] .input-primary .form-control {
  border-color: #99c990;
}

[data-theme-version="light"] .input-primary .input-group-text,
[data-theme-version="dark"] .input-primary .input-group-text {
  background-color: #99c990;
  color: #fff;
}

[data-theme-version="light"] .input-danger .form-control,
[data-theme-version="dark"] .input-danger .form-control {
  border-color: #F94687;
}

[data-theme-version="light"] .input-danger .input-group-text,
[data-theme-version="dark"] .input-danger .input-group-text {
  background-color: #F94687;
  color: #fff;
}

[data-theme-version="light"] .input-info .form-control,
[data-theme-version="dark"] .input-info .form-control {
  border-color: #1EA7C5;
}

[data-theme-version="light"] .input-info .input-group-text,
[data-theme-version="dark"] .input-info .input-group-text {
  background-color: #1EA7C5;
  color: #fff;
}

[data-theme-version="light"] .input-success .form-control,
[data-theme-version="dark"] .input-success .form-control {
  border-color: #2BC155;
}

[data-theme-version="light"] .input-success .input-group-text,
[data-theme-version="dark"] .input-success .input-group-text {
  background-color: #2BC155;
  color: #fff;
}

[data-theme-version="light"] .input-warning .form-control,
[data-theme-version="dark"] .input-warning .form-control {
  border-color: #FFBC11;
}

[data-theme-version="light"] .input-warning .input-group-text,
[data-theme-version="dark"] .input-warning .input-group-text {
  background-color: #FFBC11;
  color: #fff;
}

[data-theme-version="light"] .input-primary-o .form-control,
[data-theme-version="dark"] .input-primary-o .form-control {
  border-color: #99c990;
}

[data-theme-version="light"] .input-primary-o .input-group-text,
[data-theme-version="dark"] .input-primary-o .input-group-text {
  background-color: transparent;
  border-color: #99c990;
  color: #99c990;
}

[data-theme-version="light"] .input-danger-o .form-control,
[data-theme-version="dark"] .input-danger-o .form-control {
  border-color: #F94687;
}

[data-theme-version="light"] .input-danger-o .input-group-text,
[data-theme-version="dark"] .input-danger-o .input-group-text {
  background-color: transparent;
  border-color: #F94687;
  color: #F94687;
}

[data-theme-version="light"] .input-info-o .form-control,
[data-theme-version="dark"] .input-info-o .form-control {
  border-color: #1EA7C5;
}

[data-theme-version="light"] .input-info-o .input-group-text,
[data-theme-version="dark"] .input-info-o .input-group-text {
  background-color: transparent;
  border-color: #1EA7C5;
  color: #1EA7C5;
}

[data-theme-version="light"] .input-success-o .form-control,
[data-theme-version="dark"] .input-success-o .form-control {
  border-color: #2BC155;
}

[data-theme-version="light"] .input-success-o .input-group-text,
[data-theme-version="dark"] .input-success-o .input-group-text {
  background-color: transparent;
  border-color: #2BC155;
  color: #2BC155;
}

[data-theme-version="light"] .input-warning-o .form-control,
[data-theme-version="dark"] .input-warning-o .form-control {
  border-color: #FFBC11;
}

[data-theme-version="light"] .input-warning-o .input-group-text,
[data-theme-version="dark"] .input-warning-o .input-group-text {
  background-color: transparent;
  border-color: #FFBC11;
  color: #FFBC11;
}

.input-group-text {
  background: #eee;
  border: 1px solid transparent;
  min-width: 50px;
  display: flex;
  justify-content: center;
  padding: 0.532rem 0.75rem;
  border-radius: 0.625rem;
}

.input-group-text i {
  font-size: 16px;
}

.custom-file-label {
  height: 40px;
  padding: 0.5rem 0.75rem;
  border-radius: 0;
}

.input-group-prepend .btn,
.input-group-append .btn {
  z-index: 0;
}

.custom-select {
  background: none;
  border-color: #E6E6E6;
  color: #B1B1B1;
}

.custom-select:focus {
  box-shadow: none;
  border-color: #99c990;
  color: #99c990;
}

.custom-file-label:after {
  background: #656C73;
  border: 1px solid #E6E6E6;
  color: #fff;
  border-radius: 0;
}

[data-theme-version="dark"] .custom-file-label:after {
  background: #333a54;
  border-color: #333a54;
  color: #7e7e7e;
}

.custom_file_input .custom-file-label::after {
  height: 100%;
}

.form-control:disabled,
.form-control[readonly] {
  background: #fff;
  opacity: 1;
}

.custom-file-label {
  background: #fff;
  border-color: #E6E6E6;
}

[data-theme-version="dark"] .custom-file-label {
  background: #222327;
  border-color: #333a54;
}

.right-radius {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.left-radius {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.form-check-label {
  margin-left: 0.313rem;
  margin-top: 0.113rem;
}

.form-check-inline .form-check-input {
  margin-right: 0.625rem;
}

.form-check-input {
  top: 0.125rem;
  border-width: 0.125rem;
  width: 1.75rem;
  height: 1.75rem;
  border-color: #bebebe;
  background-color: transparent;
}

.rtl .form-check-label:before,
.rtl .form-check-label:after {
  right: -1.5rem !important;
  left: inherit;
}

.form-check {
  /* line-height: normal; */
  padding: 10px;
  font-size: 30px;
}

.form-switch {
  padding-left: 3.125rem;
  line-height: 1.25;
  display: inline-block;
  color: #000;
  font-weight: 600;
}

.form-switch .form-check-input {
  border: 0;
  cursor: pointer;
  background: #d8d8d8;
  width: 2.3125rem;
  border-radius: 0.625rem;
  height: 0.875rem;
  position: relative;
  left: -0.3125rem;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  background-image: none;
}

.form-switch .form-check-input:focus {
  background-image: none !important;
}

.form-switch .form-check-input:checked {
  background: #d8e0f9;
  background-image: none !important;
  border-radius: 0.625rem;
}

.form-switch .form-check-input:checked:after {
  left: 1.25rem;
  background: #99c990;
}

.form-switch .form-check-input:focus {
  box-shadow: none;
}

.form-switch .form-check-input:after {
  width: 1.25rem;
  background: #909090;
  height: 1.25rem;
  content: "";
  position: absolute;
  border-radius: 1.5rem;
  top: -0.1875rem;
  left: 0;
  box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.form-switch.text-end {
  padding-right: 0rem;
  padding-left: 0;
}

.form-switch.text-end .form-check-input {
  left: auto;
  margin-left: 0;
  float: right;
  right: 0rem;
}

.form-switch.text-end .form-check-label {
  margin-right: 0.9375rem;
  margin-left: 0;
  margin-top: 0;
}

.form-switch .form-check-label {
  cursor: pointer;
}

.form-check-input:focus~.form-check-label::before {
  box-shadow: none !important;
}

.form-check-label::before {
  background-color: transparent;
  border-color: #F4F5F9;
  border-width: 0.125rem;
  border-radius: 0.125rem !important;
}

[data-theme-version="dark"] .form-check-label::before {
  background-color: transparent;
  border-color: #333a54;
}

.check-xs .custom-control-label:before,
.check-xs .custom-control-label:after {
  width: 18px;
  height: 18px;
}

.check-lg .custom-control-label:before,
.check-lg .custom-control-label:after {
  width: 24px;
  height: 24px;
}

.check-xl .custom-control-label:before,
.check-xl .custom-control-label:after {
  width: 28px;
  height: 28px;
}

.checkbox-info .form-check-label::before {
  background-color: #a4e3f1;
  border-color: #a4e3f1;
}

[data-theme-version="dark"] .checkbox-info .form-check-label::before {
  background-color: rgba(30, 167, 197, 0.1);
  border-color: transparent;
}

.checkbox-info .form-check-input:checked {
  background-color: #1EA7C5;
  border-color: #1EA7C5;
}

.checkbox-danger .form-check-input::before {
  background-color: #fff3f7;
  border-color: #fff3f7;
}

[data-theme-version="dark"] .checkbox-danger .form-check-input::before {
  background-color: rgba(249, 70, 135, 0.15);
  border-color: transparent;
}

.checkbox-danger .form-check-input:checked {
  background-color: #F94687;
  border-color: #F94687;
}

.checkbox-success .form-check-input::before {
  background-color: #e3f9e9;
  border-color: #e3f9e9;
}

[data-theme-version="dark"] .checkbox-success .form-check-input::before {
  background-color: rgba(43, 193, 85, 0.1);
  border-color: transparent;
}

.checkbox-success .form-check-input:checked {
  background-color: #2BC155;
  border-color: #2BC155;
}

.checkbox-warning .form-check-input::before {
  background-color: #fff5dd;
  border-color: #fff5dd;
}

[data-theme-version="dark"] .checkbox-warning .form-check-input::before {
  background-color: rgba(255, 188, 17, 0.1);
  border-color: transparent;
}

.checkbox-warning .form-check-input:checked {
  background-color: #FFBC11;
  border-color: #FFBC11;
}

.checkbox-secondary .form-check-input::before {
  background-color: #f1dffe;
  border-color: #f1dffe;
}

[data-theme-version="dark"] .checkbox-secondary .form-check-input::before {
  background-color: rgba(160, 44, 250, 0.5);
  border-color: transparent;
}

.checkbox-secondary .form-check-input:checked {
  background-color: #A02CFA;
  border-color: #A02CFA;
}

.form-switch {
  padding-left: 2.5rem;
}

.form-switch .form-check-label {
  line-height: 1.875rem;
  font-weight: 500;
}

.form-switch .form-check-label span {
  line-height: 1;
}

.form-switch .form-check-label:after,
.form-switch .form-check-label:before {
  height: 1.5rem;
  width: 1.5rem;
  left: -2rem;
  border-radius: 3rem !important;
  border-color: var(--rgba-primary-3);
}

.form-switch .form-check-input:checked~.form-check-label::before {
  background: #fff;
}

.check-switch {
  padding-left: 40px;
}

@media only screen and (max-width: 767px) {
  .check-switch {
    padding-left: 20px;
  }
}

.check-switch .custom-control-label {
  line-height: 30px;
  font-weight: 500;
}

.check-switch .custom-control-label:after,
.check-switch .custom-control-label:before {
  height: 1.5rem;
  width: 1.5rem;
  left: -2rem;
  border-radius: 3rem !important;
  border-color: rgba(30, 63, 180, 0.3);
}

.js-switch+.switchery {
  border-radius: 50px;
  margin-right: 4rem;
}

@media (max-width: 575.98px) {
  .js-switch+.switchery {
    margin-right: 1rem;
  }
}

.js-switch+.switchery>small,
.js-switch+.switchery>.small {
  top: 2px;
}

.js-switch.js-switch-lg+.switchery {
  height: 2rem;
  width: 4.5rem;
}

.js-switch.js-switch-lg+.switchery>small,
.js-switch.js-switch-lg+.switchery>.small {
  width: 1.75rem;
  height: 1.75rem;
}

.js-switch.js-switch-md+.switchery {
  height: 1.5rem;
  width: 3.5rem;
}

.js-switch.js-switch-md+.switchery>small,
.js-switch.js-switch-md+.switchery>.small {
  width: 1.25rem;
  height: 1.25rem;
}

.js-switch.js-switch-sm+.switchery {
  height: 1rem;
  width: 2.2rem;
}

.js-switch.js-switch-sm+.switchery>small,
.js-switch.js-switch-sm+.switchery>.small {
  width: 0.875rem;
  height: 0.875rem;
  top: 1px;
}

.js-switch-square+.switchery {
  border-radius: 0;
}

.js-switch-square+.switchery>small,
.js-switch-square+.switchery>.small {
  border-radius: 0;
  top: 2px;
}

.js-switch-square.js-switch-lg+.switchery {
  height: 2rem;
  width: 4.5rem;
}

.js-switch-square.js-switch-lg+.switchery>small,
.js-switch-square.js-switch-lg+.switchery>.small {
  width: 1.75rem;
  height: 1.75rem;
}

.js-switch-square.js-switch-md+.switchery {
  height: 1.5rem;
  width: 3.5rem;
}

.js-switch-square.js-switch-md+.switchery>small,
.js-switch-square.js-switch-md+.switchery>.small {
  width: 1.25rem;
  height: 1.25rem;
}

.js-switch-square.js-switch-sm+.switchery {
  height: 1rem;
  width: 2.2rem;
}

.js-switch-square.js-switch-sm+.switchery>small,
.js-switch-square.js-switch-sm+.switchery>.small {
  width: 0.875rem;
  height: 0.875rem;
  top: 1px;
}

.form-control.is-valid {
  border-color: #2BC155 !important;
  border-right: 0px !important;
}

.form-control.is-valid:focus {
  box-shadow: none;
}

.form-control.is-warning {
  border-color: #FFBC11 !important;
  border-right: 0px !important;
}

.form-control.is-warning:focus {
  box-shadow: none;
}

.form-control.is-invalid {
  border-color: #F94687 !important;
  border-right: -1px !important;
}

.form-control.is-invalid:focus {
  box-shadow: none;
}

.is-valid .input-group-prepend .input-group-text i {
  color: #2BC155;
}

.is-invalid .input-group-prepend .input-group-text i {
  color: #3f63df;
}

.show-pass {
  cursor: pointer;
}

.show-pass .fa-eye {
  display: none;
}

.show-pass.active .fa-eye-slash {
  display: none;
}

.show-pass.active .fa-eye {
  display: inline-block;
}

.asColorPicker-dropdown {
  max-width: 26rem;
}

.asColorPicker-trigger {
  border: 0 none;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 2.1875rem;
}

[direction="rtl"] .asColorPicker-trigger {
  left: 0;
  right: auto;
}

.asColorPicker-clear {
  display: none;
  position: absolute;
  right: 1rem;
  text-decoration: none;
  top: .5rem;
}

.daterangepicker td.active {
  background-color: var(--primary);
}

.daterangepicker td.active:hover {
  background-color: var(--primary);
}

.daterangepicker button.applyBtn {
  background-color: var(--primary);
  border-color: var(--primary);
}

.datepicker.datepicker-dropdown {
  background: #f2f4fa;
  border-radius: 0.0625rem;
  border: 0.0625rem solid #EEEEEE;
  padding: 1.25rem 1.875rem;
  background: #fff;
  position: absolute;
  left: auto !important;
  right: 31px;
  border-radius: 0.625rem;
}

.datepicker.datepicker-dropdown td.day,
.datepicker.datepicker-dropdown th.next,
.datepicker.datepicker-dropdown th.prev {
  height: 1.875rem;
  width: 1.875rem !important;
  padding: 1rem;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  color: #000;
}

.datepicker.datepicker-dropdown th.datepicker-switch,
.datepicker.datepicker-dropdown th.next,
.datepicker.datepicker-dropdown th.prev {
  font-weight: 600;
  color: #fff;
  text-align: center;
  background-color: #99c990;
  border-radius: unset;
  font-size: 1.2rem;
}

.datepicker.datepicker-dropdown th.dow {
  font-weight: 500;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.625rem 0rem;
}

.datepicker table tr td.selected,
.datepicker table tr td.active.active {
  /* @extend %gradient-9;
            box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243,30,122,0.3);
            border: 0; */
  border: 0;
  background: #99c990 !important;
  box-shadow: unset;
  color: #fff;
  border-radius: 0.625rem;
}

.datepicker table tr td.today {
  color: #ffffff !important;
  background: #99c990 !important;
  border-radius: 0.625rem !important;
}

.datepicker table tr td.today.disabled {
  box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
  color: #ffffff;
}

.datepicker table tr td.today.disabled:hover {
  box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
  color: #ffffff;
}

.picker__select--month,
.picker__select--year {
  height: 2.5em;
}

.picker__input {
  background-color: transparent !important;
}

[data-theme-version="dark"] .picker__input {
  background-color: transparent !important;
  border: 0.0625rem solid #333a54;
}

.asColorPicker-wrap .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.picker-data .color-time-picker .react-time-picker {
  width: 100%;
}

.picker-data .color-time-picker .react-time-picker__wrapper {
  color: #7e7e7e;
  border: 0;
}

.picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__clear-button {
  display: none;
}

.picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__inputGroup {
  border: 0.0625rem solid #E6E6E6;
  padding: 0rem 1.25rem;
  height: 3.5rem;
  border-radius: 1rem 0 0 1rem;
}

.picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__inputGroup__input {
  color: #7e7e7e;
}

.picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__inputGroup__amPm {
  color: #7e7e7e;
  appearance: none;
}

.picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__clock-button {
  background: #99c990;
  color: #b7b7b7;
  padding: .532rem .75rem;
  border-radius: 0 0.75rem 0.75rem 0;
  min-width: 50px;
  text-align: center;
  line-height: 1;
}

.picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__clock-button svg {
  stroke: #fff;
  display: inline-block;
}

.picker-data .color-time-picker .react-clock__face {
  background: #ededed;
  padding: 10px 10px;
  border: 10px solid #ededed;
}

.picker-data .color-time-picker .react-clock__hand__body {
  background-color: #99c990;
}

.picker-data .color-time-picker .react-time-picker__clock {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border: 0 !important;
}

.picker-data .color-time-picker.style-1 .react-time-picker__wrapper .react-time-picker__clock-button {
  display: none;
}

.picker-data .color-time-picker.style-1 .react-time-picker__wrapper .react-time-picker__inputGroup {
  border-radius: 1rem;
}

.color-gradian-tixia .gpw {
  padding: 0;
}

.color-gradian-tixia .gpw .trigger .inner {
  height: 1.5rem;
  width: 6.25rem;
}

#image {
  max-width: 100%;
}

.docs-options .dropdown-menu {
  padding: 1.5rem;
}

.docs-preview {
  margin-bottom: 3rem;
}

.docs-preview .img-preview {
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  overflow: hidden;
  max-width: 100% !important;
}

.docs-preview .img-preview>img {
  max-width: 100% !important;
}

.docs-preview .img-preview.preview-lg {
  width: 16rem;
  height: 9rem;
}

.docs-preview .img-preview.preview-md {
  width: 8rem;
  height: 4.5rem;
}

.docs-preview .img-preview.preview-sm {
  width: 4rem;
  height: 2.25rem;
}

.docs-preview .img-preview.preview-xs {
  width: 2rem;
  height: 1.125rem;
  margin-right: 0;
}

.select2-container--default .select2-selection--single {
  border-radius: 0.625rem;
  border: 1px solid #E6E6E6;
  height: 56px;
  background: #fff;
}

[data-theme-version="dark"] .select2-container--default .select2-selection--single {
  background: #222327;
  border-color: #333a54;
}

.select2-container--default .select2-selection--single:hover,
.select2-container--default .select2-selection--single:focus,
.select2-container--default .select2-selection--single.active {
  box-shadow: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 56px;
  color: #7e7e7e;
  padding-left: 15px;
  min-height: 56px;
}

.select2-container--default .select2-selection--multiple {
  border-color: #E6E6E6;
  border-radius: 0;
}

.select2-dropdown {
  border-radius: 0;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #99c990;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #E6E6E6;
  background: #fff;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 16px;
  right: 15px;
}

.select2-container .select2-selection--multiple {
  min-height: 56px;
  color: #7e7e7e;
  border-radius: 0.625rem;
  border: 1px solid #E6E6E6 !important;
}

[data-theme-version="dark"] .select2-container .select2-selection--multiple {
  background: #222327;
  border-color: #333a54 !important;
}

[data-theme-version="dark"] .select2-search--dropdown .select2-search__field {
  background: #111217;
  border-color: #333a54;
}

.select2-dropdown {
  border-color: #E6E6E6;
}

[data-theme-version="dark"] .select2-dropdown {
  background: #222327;
  border-color: #333a54;
}

.swal2-popup .swal2-content {
  color: #7e7e7e;
}

.select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #99c990;
}

.select2-container--classic.select2-container--open .select2-selection--multiple,
.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #99c990;
}

.select2-container--open .select2-dropdown {
  background-color: #fff;
  border-radius: 0 0 0.625rem 0.625rem;
  padding: 1rem 0;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
}

[data-theme-version="dark"] .select2-container--open .select2-dropdown {
  background-color: #111217;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border-radius: 0.625rem;
  padding: 0.5rem 1.25rem;
  margin: 0 0 0.5rem 0;
}

.select2-search--dropdown {
  padding: 0.5rem 1.25rem;
  margin: 0 0 0.5rem 0;
}

.select2-results__options li {
  padding: 0.75rem 1.25rem;
  margin: 0 0;
  background-color: #fff;
}

[data-theme-version="dark"] .select2-results__options li {
  background-color: #111217;
}

.select2-container--default .select2-results__option--selected {
  background-color: #fff;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #f5f5f5;
  color: #99c990;
}

[data-theme-version="dark"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #222327;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #d8e0f9;
  border-color: #d8e0f9;
  border-radius: 0.2rem;
  margin-bottom: 14px;
  margin-left: 13px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #F94687;
  opacity: 0.5;
  border-color: #99c990;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  display: block;
  color: #99c990;
  font-size: 13px;
  width: 100%;
}

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
.form-wizard {
  border: 0;
}

.form-wizard .nav-wizard {
  box-shadow: none !important;
  margin-bottom: 0.5rem;
  width: 50%;
  margin-left: 25%;
}

.form-wizard .nav-wizard #RFS-ConnectorContainer {
  display: none;
}

.form-wizard .nav-wizard .nav-link {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent !important;
}

.form-wizard .nav-wizard .nav-link span {
  border-radius: 3.125rem;
  width: 2rem;
  height: 2rem;
  border: 0.125rem solid #99c990;
  display: block;
  line-height: 2rem;
  color: #99c990;
  font-size: 1rem;
  margin: auto;
  background-color: #fff;
  position: relative;
  z-index: 1;
}

.form-wizard .nav-wizard .nav-link:after {
  content: "" !important;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 0.1875rem;
  transform: translateY(-50%);
  background: #f1f1f1 !important;
  z-index: 0;
  width: 100%;
}

.form-wizard .nav-wizard .nav-link.active span {
  background: #99c990;
  color: #fff;
}

.form-wizard .nav-wizard .nav-link.active:after {
  background: #99c990 !important;
}

.form-wizard .nav-wizard .nav-link.completed span {
  background-color: #99c990;
  color: #fff;
}

.form-wizard .nav-wizard .nav-link.completed:after {
  background: #99c990 !important;
}

.form-wizard .nav-wizard>div:last-child .nav-link:after {
  display: none;
}

.form-wizard .toolbar-bottom .btn {
  border: 0;
  padding: 0.75rem 1.125rem;
}

.form-wizard .tab-content .tab-pane {
  padding: 0;
}

.form-wizard .emial-setup label.mailclinet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #EEF5F9;
  text-align: center;
  margin: auto;
}

[data-theme-version="dark"] .form-wizard .emial-setup label.mailclinet {
  background-color: #222327;
}

@media only screen and (max-width: 575px) {
  .form-wizard .emial-setup label.mailclinet {
    width: 7rem;
    height: 7rem;
  }
}

.form-wizard .emial-setup label.mailclinet .mail-icon {
  font-size: 3rem;
  display: inline-block;
  line-height: 1;
  margin-top: -1rem;
}

@media only screen and (max-width: 575px) {
  .form-wizard .emial-setup label.mailclinet .mail-icon {
    font-size: 2rem;
  }
}

.form-wizard .emial-setup label.mailclinet .mail-text {
  font-size: 1rem;
  text-align: center;
  margin-top: .5rem;
}

@media only screen and (max-width: 575px) {
  .form-wizard .emial-setup label.mailclinet .mail-text {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.form-wizard .emial-setup label.mailclinet input[type="radio"] {
  display: none;
}

@media only screen and (max-width: 767px) {
  .form-wizard .nav-wizard {
    flex-direction: unset !important;
  }

  .form-wizard .tab-content {
    height: 100% !important;
  }
}

@media only screen and (max-width: 575px) {
  .form-wizard .nav-wizard li .nav-link {
    padding: 0;
  }
}

.wizard-style-one {
  border: 0;
  padding: 0 100px;
}

.wizard-style-one .nav-wizard {
  margin-bottom: 40px;
  box-shadow: none;
}

.wizard-style-one label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.wizard-style-one .tab-content {
  overflow: visible;
}

.custom-ekeditor ul {
  padding-left: 1.25rem;
}

.custom-ekeditor ul li {
  list-style: unset;
}

.custom-ekeditor ol li {
  list-style: decimal;
}

#world-datamap {
  padding-bottom: 46% !important;
}

.datamaps-hoverover {
  background: #fff;
  padding: 0.3125rem;
  border-radius: 0.3125rem;
  font-family: 'Roboto' !important;
  color: #99c990;
  border: 1px solid rgba(30, 63, 180, 0.3);
}

@media only screen and (max-width: 1440px) {
  .world_map_card ul.list-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 35px;
  }
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  height: 20px;
  width: 20px;
  line-height: 14px;
  background-color: #99c990;
  color: #fff;
}

.jqvmap-zoomout {
  top: 35px;
}

#world-map,
#usa {
  height: 400px;
}

@media only screen and (max-width: 991px) {

  #world-map,
  #usa {
    height: 350px;
  }
}

@media only screen and (max-width: 575px) {

  #world-map,
  #usa {
    height: 230px;
  }
}

.blockUI.blockMsg.blockPage {
  border: 0 !important;
}

#loginForm {
  cursor: auto;
}

.blockMsg {
  border: 0px !important;
  width: 20% !important;
}

.blockMsg h1,
.blockMsg .h1 {
  font-size: 16px;
  padding: 8px 0;
  margin-bottom: 0;
}

.bootstrap-select {
  margin-bottom: 0;
}

.bootstrap-select .btn {
  border: 1px solid #E6E6E6 !important;
  background-color: transparent !important;
  font-weight: 400;
  color: #7e7e7e !important;
}

[data-theme-version="dark"] .bootstrap-select .btn {
  border-color: #333a54 !important;
}

.bootstrap-select .btn:active,
.bootstrap-select .btn:focus,
.bootstrap-select .btn:hover {
  outline: none !important;
  outline-offset: 0;
}

[data-theme-version="dark"] .bootstrap-select .btn:active,
[data-theme-version="dark"] .bootstrap-select .btn:focus,
[data-theme-version="dark"] .bootstrap-select .btn:hover {
  color: #7e7e7e !important;
}

.bootstrap-select .dropdown-menu {
  border-color: #E6E6E6 !important;
  box-shadow: 0px 0Epx 40px 0px rgba(82, 63, 105, 0.1);
}

.bootstrap-select .dropdown-menu .dropdown-item {
  padding: 0.25rem 1rem;
}

[data-theme-version="dark"] .bootstrap-select .dropdown-menu {
  border-color: #E6E6E6 !important;
}

.input-group>.bootstrap-select:not(:first-child) .dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group>.bootstrap-select:not(:last-child) .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.clipboard-btn {
  transition: all .1s ease-in-out;
}

.clipboard-btn:hover {
  background-color: #99c990;
  color: #fff;
}

.twitter-typeahead {
  width: 100%;
}

.twitter-typeahead .tt-dataset.tt-dataset-states {
  border: 1px solid #E6E6E6;
}

.twitter-typeahead .tt-menu {
  width: 100%;
  background-color: #fff;
}

.twitter-typeahead .tt-menu .tt-suggestion {
  padding: 0.625rem;
  cursor: pointer;
}

.twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #99c990;
  color: #fff;
}

.noUi-target {
  border-color: transparent;
  border-radius: 0;
}

.noUi-connect {
  background-color: #99c990;
}

.noUi-connects {
  background-color: #D2D6DE;
}

.noUi-connect.c-1-color {
  background-color: #2BC155;
}

.noUi-connect.c-2-color {
  background-color: #1EA7C5;
}

.noUi-connect.c-3-color {
  background-color: #99c990;
}

.noUi-connect.c-4-color {
  background-color: #FFBC11;
}

.noUi-vertical {
  width: 0.375rem;
}

.noUi-horizontal {
  height: 0.375rem;
  margin-bottom: 10px;
}

.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle {
  height: 12px;
  width: 12px;
  border-radius: 50px;
  box-shadow: none;
  border: none;
  background-color: #99c990;
}

.noUi-horizontal .noUi-handle::after,
.noUi-horizontal .noUi-handle::before,
.noUi-vertical .noUi-handle::after,
.noUi-vertical .noUi-handle::before {
  display: none;
}

.noUi-vertical .noUi-handle {
  right: -4px;
  bottom: -11px;
}

.noUi-horizontal .noUi-handle {
  top: -4px;
}

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -6px;
}

#slider-toggle {
  height: 50px;
}

#slider-toggle.off .noUi-handle {
  border-color: #99c990;
}

.colorpicker-slider .sliders.noUi-target#red,
.colorpicker-slider .sliders.noUi-target#green,
.colorpicker-slider .sliders.noUi-target#blue {
  margin: 10px;
  display: inline-block;
  height: 200px;
}

.colorpicker-slider .sliders.noUi-target#red .noUi-connect {
  background: #c0392b;
}

.colorpicker-slider .sliders.noUi-target#green .noUi-connect {
  background: #27ae60;
}

.colorpicker-slider .sliders.noUi-target#blue .noUi-connect {
  background: #2980b9;
}

.colorpicker-slider #result {
  margin: 60px 26px;
  height: 100px;
  width: 100px;
  display: inline-block;
  vertical-align: top;
  color: #7f7f7f;
  background: #7f7f7f;
  border: 1px solid #fff;
  box-shadow: 0 0 10px;
}

.slider-vertical {
  height: 18rem;
}

.nestable-cart {
  overflow: hidden;
}

.dd-handle {
  color: #fff;
  background: #99c990;
  border-radius: 5px;
  padding: 8px 15px;
  height: auto;
  border: 1px solid #E6E6E6;
  cursor: all-scroll;
}

.dd-handle:hover {
  color: #fff;
  background: #99c990;
}

.dd3-content:hover {
  color: #fff;
  background: #99c990;
}

.dd3-content {
  color: #fff;
}

.dd-item>button {
  line-height: 28px;
  color: #fff;
}

.nestable .dd-list {
  margin-top: 0;
}

.pignose-calendar {
  box-shadow: none;
  width: 100%;
  max-width: none;
  border-color: #99c990;
}

.pignose-calendar .pignose-calendar-top-date {
  background-color: #99c990;
}

.pignose-calendar .pignose-calendar-top-date .pignose-calendar-top-month {
  color: #fff;
}

.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #99c990;
  box-shadow: none;
}

.pignose-calendar .pignose-calendar-top {
  box-shadow: none;
  border-bottom: 0;
}

.pignose-calendar.pignose-calendar-blue {
  background-color: rgba(0, 0, 0, 0.15);
}

.pignose-calendar .pignose-calendar-unit {
  height: 4.8em;
}

.cd-h-timeline {
  opacity: 0;
  transition: opacity 0.2s;
}

.cd-h-timeline--loaded {
  opacity: 1;
}

.cd-h-timeline__container {
  position: relative;
  height: 100px;
  max-width: 800px;
}

.cd-h-timeline__dates {
  position: relative;
  height: 100%;
  margin: 0 40px;
  overflow: hidden;
}

.cd-h-timeline__dates::after,
.cd-h-timeline__dates::before {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100%;
  width: 20px;
}

.cd-h-timeline__dates::before {
  left: 0;
  background: #99c990;
}

.cd-h-timeline__dates::after {
  right: 0;
  background: #99c990;
}

.cd-h-timeline__line {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 49px;
  height: 2px;
  background-color: #99c990;
  transition: transform 0.4s;
}

.cd-h-timeline__filling-line {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #2BC155;
  transform: scaleX(0);
  transform-origin: left center;
  transition: transform 0.3s;
}

.cd-h-timeline__date {
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-align: center;
  font-size: 0.8em;
  padding-bottom: var(--space-sm);
  color: var(--cd-color-1);
  user-select: none;
  text-decoration: none;
}

.cd-h-timeline__date::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: #1f40b8;
  background-color: #99c990;
  transition: background-color 0.3s, border-color .3s;
}

.cd-h-timeline__date:hover::after {
  background-color: #2BC155;
  border-color: #2BC155;
}

.cd-h-timeline__date--selected {
  pointer-events: none;
}

.cd-h-timeline__date--selected::after {
  background-color: #2BC155;
  border-color: #2BC155;
}

.cd-h-timeline__date--older-event::after {
  border-color: #2BC155;
}

.cd-h-timeline__navigation {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: #1f40b8;
  transition: border-color 0.3s;
}

.cd-h-timeline__navigation::after {
  content: '';
  position: absolute;
  height: 16px;
  width: 16px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.cd-h-timeline__navigation:hover {
  border-color: #2BC155;
}

.cd-h-timeline__navigation--prev {
  left: 0;
  transform: translateY(-50%) rotate(180deg);
}

.cd-h-timeline__navigation--next {
  right: 0;
}

.cd-h-timeline__navigation--inactive {
  cursor: not-allowed;
}

.cd-h-timeline__navigation--inactive::after {
  background-position: 0 -16px;
}

.cd-h-timeline__navigation--inactive:hover {
  border-color: #1f40b8;
}

.cd-h-timeline__events {
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: height .4s;
}

.cd-h-timeline__event {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  transform: translateX(-100%);
  padding: 1px 5%;
  opacity: 0;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
}

.cd-h-timeline__event--selected {
  position: relative;
  z-index: 2;
  opacity: 1;
  transform: translateX(0);
}

.cd-h-timeline__event--enter-right,
.cd-h-timeline__event--leave-right {
  animation-name: cd-enter-right;
}

.cd-h-timeline__event--enter-left,
.cd-h-timeline__event--leave-left {
  animation-name: cd-enter-left;
}

.cd-h-timeline__event--leave-right,
.cd-h-timeline__event--leave-left {
  animation-direction: reverse;
}

.cd-h-timeline__event-content {
  max-width: 800px;
}

.cd-h-timeline__event-title {
  color: var(--cd-color-1);
  font-family: var(--font-secondary);
  font-weight: 700;
  font-size: var(--text-xxxl);
}

.cd-h-timeline__event-date {
  display: block;
  font-style: italic;
  margin: var(--space-xs) auto;
}

.cd-h-timeline__event-date::before {
  content: '- ';
}

@keyframes cd-enter-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes cd-enter-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.toast-success {
  background-color: #99c990;
}

.toast-info {
  background-color: #1EA7C5;
}

.toast-warning {
  background-color: #FFBC11;
}

.toast-error {
  background-color: #F94687;
}

#toast-container>div {
  box-shadow: none;
  border-radius: 0;
  width: auto;
  max-width: 250px;
  opacity: 1;
}

[direction="rtl"] #toast-container>div {
  padding: 15px 50px 15px 15px;
  background-position: calc(100% - 15px);
  text-align: right;
}

#toast-container>div:hover {
  box-shadow: none;
}

#toast-container .toast-title {
  margin-bottom: 5px;
  font-weight: 600;
}

#toast-container .toast-message {
  font-size: 12px;
}

#toast-container .toast-close-button {
  opacity: 1;
  font-size: 20px;
  font-weight: normal;
  text-shadow: none;
}

[direction="rtl"] .toast-top-right.demo_rtl_class {
  left: 12px;
  right: auto;
}

/* Light Gallery */
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar {
  background-color: rgba(30, 30, 30, 0.6);
}

.lg-outer .lg-toogle-thumb,
.lg-outer .lg-thumb-outer,
.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  background-color: transparent;
}

.lg-thumb-outer.lg-grab,
.lg-toogle-thumb.lg-icon {
  background-color: rgba(30, 30, 30, 0.6);
}

.lg-backdrop {
  background-color: rgba(30, 30, 30, 0.9);
}

.lg-outer .lg-toogle-thumb,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar .lg-icon,
#lg-counter {
  color: #fff;
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: #99c990;
}

.lightimg {
  cursor: pointer;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  position: absolute;
  left: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #000000;
  padding: 3px;
  color: white;
  width: 17px;
  height: 17px;
  cursor: pointer;
  line-height: 10px;
  text-align: center;
}

.jqvmap-zoomin {
  top: 10px;
}

.jqvmap-zoomout {
  top: 30px;
}

.ck.ck-editor .ck.ck-button {
  padding: 6px 8px;
  background-color: #3a7afe1a;
  color: #99c990;
  font-weight: 900;
  cursor: pointer;
}

.ck.ck-editor .ck.ck-toolbar {
  background-color: var(--bs-card-bg);
  border: 0;
  padding: 5px 10px;
}

.ck.ck-editor .ck.ck-toolbar__separator {
  display: none !important;
}

.ck.ck-editor .ck-content {
  background-color: #f7f7f7;
  border-width: 1px 0 0;
  border-color: #e7e5ef;
}

.ck-editor__editable {
  background-color: var(--bs-card-bg) !important;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
  border-color: #E6E6E6;
  padding: 0.865rem var(--ck-spacing-standard);
}

.ck.ck-toolbar .ck-toolbar__items .ck.ck-toolbar__separator {
  background: #E6E6E6;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
  min-height: 9.375rem;
}

button.ck.ck-button.ck-off:hover {
  background-color: #E6E6E6;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  min-height: 9.375rem;
}

.editor-title .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred,
.editor-title .ck-focused {
  width: 57.313rem;
}

@media only screen and (max-width: 1400px) {

  .editor-title .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred,
  .editor-title .ck-focused {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {

  .editor-title .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred,
  .editor-title .ck-focused {
    width: 500px;
  }
}

@media only screen and (max-width: 575px) {

  .editor-title .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred,
  .editor-title .ck-focused {
    width: 100%;
  }
}

.fc-h-event,
.fc-v-event {
  background: #99c990;
  border-radius: .42rem;
}

.fc-h-event .fc-event-title {
  color: #fff;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border-color: #ebedf3;
}

.fc-unthemed .fc-h-event,
.fc-unthemed .fc-event-dot {
  padding: 0;
  border-radius: .42rem;
}

.fc-theme-standard th {
  padding: .75rem .5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #b5b5c3;
}

@media only screen and (max-width: 575px) {
  .fc-theme-standard th {
    font-size: 14px;
    font-weight: 400;
    padding: 3px 0px;
  }
}

.fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid,
.fc-scrollgrid,
table {
  border-color: #ebedf3;
}

.fc-daygrid-dot-event {
  background: #fff;
  border: 1px solid #ebedf3;
  -webkit-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
}

.fc-daygrid-dot-event .fc-daygrid-event-dot {
  border-color: #99c990;
}

@media only screen and (max-width: 1199px) {
  .fc-daygrid-dot-event {
    width: auto;
    height: auto;
    flex-wrap: wrap;
  }
}

.fc-daygrid-dot-event .fc-event-title {
  font-weight: 500;
}

.fc-event.bg-primary,
.fc-event.bg-success,
.fc-event.bg-warning,
.fc-event.bg-secondary,
.fc-event.bg-dark,
.fc-event.bg-info {
  color: #fff !important;
  border-radius: 8px;
}

.fc-event.bg-primary .fc-daygrid-event-dot,
.fc-event.bg-success .fc-daygrid-event-dot,
.fc-event.bg-warning .fc-daygrid-event-dot,
.fc-event.bg-secondary .fc-daygrid-event-dot,
.fc-event.bg-dark .fc-daygrid-event-dot,
.fc-event.bg-info .fc-daygrid-event-dot {
  border-color: #fff;
}

.fc .fc-scroller-liquid-absolute,
.fc-scroller {
  position: relative;
  overflow: visible !important;
}

.fc .fc-button-group>.fc-button {
  color: #b5b5c3;
  background: 0 0;
  border: 1px solid #ebedf3;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.fc .fc-button-group>.fc-button:focus,
.fc .fc-button-group>.fc-button:active,
.fc .fc-button-group>.fc-button:hover,
.fc .fc-button-group>.fc-button.fc-button-active {
  background: #99c990;
  color: #fff;
  border-color: #99c990;
}

.fc-button.fc-button-primary.fc-today-button {
  background: #99c990;
  color: #fff;
  border: 0;
  opacity: 1;
}

.fc-unthemed .fc-toolbar .fc-button.fc-button-active,
.fc-unthemed .fc-toolbar .fc-button:active,
.fc-unthemed .fc-toolbar .fc-button:focus {
  background: #99c990;
  color: #fff;
  border: 1px solid #99c990;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
}

.fc .fc-toolbar-title {
  font-size: 20px;
  margin: 0;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5em;
}

.external-event {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.external-event:hover:before {
  background: #fff !important;
}

.fc-event {
  overflow: hidden;
}

.fc .fc-view-harness {
  height: 800px !important;
  overflow: auto;
}

@media only screen and (max-width: 991px) {
  .fc .fc-view-harness {
    width: 800px !important;
  }
}

@media only screen and (max-width: 575px) {
  .fc .fc-toolbar.fc-header-toolbar {
    display: block;
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk {
    display: flex;
    justify-content: center;
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:first-child {
    justify-content: space-between;
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-title {
    margin-bottom: 8px;
  }
}

#external-events .external-event {
  text-align: left;
  font-size: 16px;
}

@media only screen and (max-width: 575px) {
  .fc.app-fullcalendar .fc-timegrid-slot-label {
    width: 40px !important;
    font-size: 10px;
  }

  .fc.app-fullcalendar .fc-event,
  .fc.app-fullcalendar .external-event {
    font-size: 10px;
    margin: 0;
    padding: 2px 0;
    text-align: center;
    line-height: 1.3;
  }

  .fc.app-fullcalendar .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 2px 4px;
    font-size: 10px;
  }
}

.fc-media-screen .fc-timegrid-event-harness {
  height: 61px;
}

.fc .fc-media-screen {
  width: 100%;
  overflow: auto;
}

.app-fullcalendar {
  overflow: auto;
}

.accordion-button:not(.collapsed) {
  color: inherit;
  background: #fff;
}

.accordion-button.collapsed {
  border-bottom-width: 1px;
}

.accordion-primary .accordion-button {
  background: #99c990;
  border-color: #99c990;
  color: #fff;
  box-shadow: rgba(11, 42, 151, 0.2) 0px 8px 24px;
}

.accordion-primary .accordion-button.collapsed {
  background: #d8e0f9;
  border-color: #d8e0f9;
  color: #99c990;
  box-shadow: none;
}

[data-theme-version="dark"] .accordion-primary .accordion-button.collapsed {
  background: rgba(11, 42, 151, 0.1);
  border-color: rgba(11, 42, 151, 0.1);
  color: #7e7e7e;
}

.accordion-primary-solid .accordion-button {
  background: #99c990;
  border-color: #99c990;
  color: #fff;
  box-shadow: 0 -0.625rem 1.25rem 0 #d8e0f9;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-primary-solid .accordion-button.collapsed {
  background: #d8e0f9;
  border-color: #d8e0f9;
  color: #99c990;
  box-shadow: none;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

[data-theme-version="dark"] .accordion-primary-solid .accordion-button.collapsed {
  background: #d8e0f9;
  border-color: #d8e0f9;
  color: #7e7e7e;
}

.accordion-primary-solid .accordion-body {
  border: 0.125rem solid #99c990;
  border-top: none;
  box-shadow: 0 0.9375rem 1.25rem 0 #d8e0f9;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.accordion-danger .accordion-button {
  background: #F94687;
  border-color: #F94687;
  color: #fff;
  box-shadow: 0 0.9375rem 1.25rem 0 rgba(249, 70, 135, 0.15);
}

.accordion-danger .accordion-button.collapsed {
  background: #fff3f7;
  border-color: #fff3f7;
  color: #211c37;
  box-shadow: none;
}

.accordion-danger-solid .accordion-button {
  background: #F94687;
  border-color: #F94687;
  color: #fff;
  box-shadow: 0 -0.625rem 1.25rem 0 rgba(249, 70, 135, 0.15);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-danger-solid .accordion-button.collapsed {
  background: #fff3f7;
  border-color: #fff3f7;
  color: #211c37;
  box-shadow: none;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

[data-theme-version="dark"] .accordion-danger-solid .accordion-button.collapsed {
  background: rgba(249, 70, 135, 0.15);
  border-color: rgba(249, 70, 135, 0.15);
  color: #7e7e7e;
}

.accordion-danger-solid .accordion-body {
  border: 0.125rem solid #F94687;
  border-top: none;
  box-shadow: 0 0.9375rem 1.25rem 0 rgba(249, 70, 135, 0.15);
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.accordion-item {
  margin-bottom: 1.25rem;
  border: 0;
  background-color: transparent;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.accordion-button {
  padding: 1rem 1.75rem;
  border: 0.0625rem solid #E6E6E6;
  cursor: pointer;
  position: relative;
  color: #333;
  font-weight: 400;
  border-radius: 0.625rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

[data-theme-version="dark"] .accordion-button {
  color: #fff !important;
  border-color: #333a54;
}

.accordion-button-indicator.indicator_bordered {
  display: inline-block;
  width: 1.5625rem;
  text-align: center;
  height: 1.5625rem;
  border: 0.0625rem solid #E6E6E6;
  border-radius: 50%;
  line-height: 1.5625rem;
}

.accordion-button:not(.collapsed)::after {
  content: "\e622";
  background-image: none;
  transform: rotate(0) translateY(-50%);
}

.accordion-button:not(.collapsed).style_two::after {
  content: "\e648";
}

.accordion-button::after {
  content: "\e61a";
  font-family: 'themify';
  position: absolute;
  right: 1.5625rem;
  top: 50%;
  transform: translateY(-50%);
  background-image: none !important;
  width: auto;
  height: auto;
}

[direction="rtl"] .accordion-button::after {
  right: auto;
  left: 1.5625rem;
}

.accordion-button.collapsed.style_two::after {
  content: "\e64b";
}

.accordion-body {
  padding: 0.875rem 1.25rem;
}

.accordion-collapse {
  border: 0;
}

.accordion-bordered .accordion-body {
  border: 0.0625rem solid #E6E6E6;
  border-top: none;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

[data-theme-version="dark"] .accordion-bordered .accordion-body {
  border-color: #333a54;
}

.accordion-bordered .accordion-button.collapsed {
  border-radius: 0.625rem;
}

.accordion-bordered .accordion-button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-no-gutter .accordion-item {
  margin-bottom: 0;
}

.accordion-no-gutter .accordion-item .accordion-button.collapsed {
  border-bottom: none;
}

.accordion-no-gutter .accordion-item:last-child .accordion-button {
  border-bottom: 0.0625rem solid #E6E6E6;
}

[data-theme-version="dark"] .accordion-no-gutter .accordion-item:last-child .accordion-button {
  border-color: #333a54;
}

.accordion-no-gutter.accordion-bordered .accordion-item:not(:last-child) .accordion-body {
  border-bottom: none;
}

.accordion-no-gutter.accordion-bordered .accordion-item .accordion-button {
  box-shadow: none;
}

.accordion-left-indicator .accordion-button {
  box-shadow: none;
}

[data-theme-version="dark"] .accordion-left-indicator .accordion-button {
  background-color: #111217;
}

.accordion-left-indicator .accordion-button-text {
  padding-left: 2.5rem;
}

.accordion-left-indicator .accordion-button:after {
  left: 1rem;
}

.accordion-left-indicator .accordion-button .accordion-header-text {
  padding-left: 2rem;
}

.accordion-bordered .accordion-button {
  box-shadow: none;
}

.accordion-with-icon .accordion-button {
  box-shadow: none;
}

[data-theme-version="dark"] .accordion-with-icon .accordion-button {
  background-color: #111217;
}

.accordion-with-icon .accordion-button-text {
  padding-left: 2.5rem;
}

[direction="rtl"] .accordion-with-icon .accordion-button-text {
  padding-left: 0;
  padding-right: 2.5rem;
}

.accordion-with-icon .accordion-button .accordion-header-icon {
  position: absolute;
  right: auto;
  left: 1.5625rem;
  font-family: 'themify';
}

[direction="rtl"] .accordion-with-icon .accordion-button .accordion-header-icon {
  left: auto;
  right: 1.5625rem;
}

.accordion-with-icon .accordion-button .accordion-header-icon::before {
  content: "\e645";
}

.accordion-with-icon .accordion-button .accordion-header-text {
  padding-left: 2rem;
}

.accordion-header-bg .accordion-header .accordion-button {
  background-color: #F4F5F9;
}

[data-theme-version="dark"] .accordion-header-bg .accordion-header .accordion-button {
  background-color: #222327;
}

.accordion-header-bg .accordion-header-primary .accordion-button {
  background-color: #99c990;
  color: #fff;
  border-color: #99c990;
}

[data-theme-version="dark"] .accordion-header-bg .accordion-header-primary .accordion-button {
  background-color: #99c990;
}

.accordion-header-bg .accordion-header-info .accordion-button {
  background-color: #1EA7C5;
  color: #fff;
  border-color: #1EA7C5;
}

[data-theme-version="dark"] .accordion-header-bg .accordion-header-info .accordion-button {
  background-color: #1EA7C5;
}

.accordion-header-bg .accordion-header-success .accordion-button {
  background-color: #2BC155;
  color: #fff;
  border-color: #2BC155;
}

[data-theme-version="dark"] .accordion-header-bg .accordion-header-success .accordion-button {
  background-color: #2BC155;
}

.accordion-header-bg.accordion-no-gutter .accordion-button {
  border-color: transparent;
  border-radius: 0;
  box-shadow: none;
}

.accordion-header-bg.accordion-no-gutter .accordion-item:first-child .accordion-button {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}

.accordion-header-bg.accordion-no-gutter .accordion-item:last-child .accordion-button {
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.accordion.accordion-no-gutter .accordion-button {
  border-radius: 0;
  box-shadow: none;
}

[data-theme-version="dark"] .accordion.accordion-no-gutter .accordion-button {
  background-color: #111217;
}

.accordion.accordion-no-gutter .accordion-button.collapsed {
  border-radius: 0;
}

.accordion.accordion-no-gutter .accordion-body {
  border-radius: 0;
}

.accordion.accordion-no-gutter .accordion-item:first-child .accordion-button {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}

.accordion.accordion-no-gutter .accordion-item:last-child .accordion-button.collapsed {
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.accordion.accordion-no-gutter .accordion-item:last-child .accordion-body {
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.accordion-solid-bg .accordion-button {
  box-shadow: none;
  border-color: transparent;
  background-color: #d8e0f9;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

[data-theme-version="dark"] .accordion-solid-bg .accordion-button {
  background-color: #222327;
}

.accordion-solid-bg .accordion-button.collapsed {
  border-radius: 0.625rem;
}

.accordion-solid-bg .accordion-body {
  border-color: transparent;
  background-color: #d8e0f9;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

[data-theme-version="dark"] .accordion-solid-bg .accordion-body {
  background-color: #222327;
}

.accordion-active-header .accordion-button {
  box-shadow: none;
}

[data-theme-version="dark"] .accordion-active-header .accordion-button {
  background-color: #111217;
}

.accordion-active-header .accordion-button:not(.collapsed) {
  background-color: #1EA7C5;
  border-color: #1EA7C5;
  color: #fff;
}

.accordion-header-shadow .accordion-button {
  border: none;
  box-shadow: 0 0 0.9375rem -0.1875rem rgba(0, 0, 0, 0.3);
}

[data-theme-version="dark"] .accordion-header-shadow .accordion-button {
  background-color: #111217;
}

.accordion-rounded-stylish .accordion-button {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  box-shadow: none;
}

[data-theme-version="dark"] .accordion-rounded-stylish .accordion-button {
  background-color: #111217;
}

.accordion-rounded-stylish .accordion-body {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.accordion-rounded .accordion-button {
  border-radius: 0.3125rem;
}

.accordion-gradient .accordion-button {
  color: #fff;
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
  border-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;
}

.accordion-gradient .accordion-button.collapsed {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.accordion-gradient .accordion-body {
  color: #fff;
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
  border-color: transparent;
}

.alert {
  border-radius: 0.625rem;
  padding: 1rem 3rem 1rem 1.5rem;
}

.alert p {
  line-height: 1.5;
}

.alert-square {
  border-radius: 0;
}

.alert-rounded {
  border-radius: 30px;
}

.alert-primary {
  background: #d8e0f9;
  border-color: #d8e0f9;
  color: #99c990;
}

[data-theme-version="dark"] .alert-primary {
  background: rgba(30, 63, 180, 0.2);
  border-color: rgba(30, 63, 180, 0.2);
}

.alert-secondary {
  background: #f1dffe;
  border-color: #f1dffe;
  color: #A02CFA;
}

[data-theme-version="dark"] .alert-secondary {
  background: rgba(160, 44, 250, 0.5);
  border-color: rgba(160, 44, 250, 0.5);
  color: #7e7e7e;
}

.alert-success {
  background: #e3f9e9;
  border-color: #e3f9e9;
  color: #2BC155;
}

[data-theme-version="dark"] .alert-success {
  background: rgba(43, 193, 85, 0.1);
  border-color: rgba(43, 193, 85, 0.1);
}

.alert-warning {
  background: #fff5dd;
  border-color: #fff5dd;
  color: #FFBC11;
}

[data-theme-version="dark"] .alert-warning {
  background: rgba(255, 188, 17, 0.1);
  border-color: rgba(255, 188, 17, 0.1);
}

.alert-danger {
  background: #fff3f7;
  border-color: #fff3f7;
  color: #F94687;
}

[data-theme-version="dark"] .alert-danger {
  background: rgba(249, 70, 135, 0.15);
  border-color: rgba(249, 70, 135, 0.15);
}

.alert-info {
  background: #a4e3f1;
  border-color: #a4e3f1;
  color: #1EA7C5;
}

[data-theme-version="dark"] .alert-info {
  background: rgba(30, 167, 197, 0.1);
  border-color: rgba(30, 167, 197, 0.1);
}

.alert-dark {
  background: #f1f1f1;
  border-color: #f1f1f1;
  color: #B1B1B1;
}

[data-theme-version="dark"] .alert-dark {
  background: rgba(177, 177, 177, 0.35);
  border-color: rgba(177, 177, 177, 0.35);
  color: #7e7e7e;
}

.alert-light {
  background: #F4F5F9;
  border-color: #F4F5F9;
  color: #B1B1B1;
}

.alert-alt.alert-primary {
  border-left: 4px solid #99c990;
}

.alert-alt.alert-secondary {
  border-left: 4px solid #A02CFA;
}

.alert-alt.alert-success {
  border-left: 4px solid #2BC155;
}

.alert-alt.alert-warning {
  border-left: 4px solid #FFBC11;
}

.alert-alt.alert-danger {
  border-left: 4px solid #F94687;
}

.alert-alt.alert-info {
  border-left: 4px solid #1EA7C5;
}

.alert-alt.alert-dark {
  border-left: 4px solid #B1B1B1;
}

.alert-alt.alert-light {
  border-left: 4px solid #c3c8de;
}

.alert-alt.alert-primary.solid {
  border-left: 4px solid #0c1947 !important;
}

.alert-alt.alert-secondary.solid {
  border-left: 4px solid #5d04a3 !important;
}

.alert-alt.alert-success.solid {
  border-left: 4px solid #145927 !important;
}

.alert-alt.alert-warning.solid {
  border-left: 4px solid #916800 !important;
}

.alert-alt.alert-danger.solid {
  border-left: 4px solid #b90647 !important;
}

.alert-alt.alert-info.solid {
  border-left: 4px solid #0d4956 !important;
}

.alert-alt.alert-dark.solid {
  border-left: 4px solid #717171 !important;
}

.alert-alt.alert-light.solid {
  border-left: 4px solid #a2aacc !important;
}

.alert-dismissible.solid .close:hover {
  color: #fff;
  opacity: 1;
}

.alert.alert-primary.solid {
  background: #99c990;
  color: #fff;
  border-color: #99c990;
}

.alert.alert-secondary.solid {
  background: #A02CFA;
  color: #fff;
  border-color: #A02CFA;
}

.alert.alert-success.solid {
  background: #2BC155;
  color: #fff;
  border-color: #2BC155;
}

.alert.alert-warning.solid {
  background: #FFBC11;
  color: #fff;
  border-color: #FFBC11;
}

.alert.alert-danger.solid {
  background: #F94687;
  color: #fff;
  border-color: #F94687;
}

.alert.alert-info.solid {
  background: #1EA7C5;
  color: #fff;
  border-color: #1EA7C5;
}

.alert.alert-dark.solid {
  background: #B1B1B1;
  color: #fff;
  border-color: #B1B1B1;
}

.alert.alert-light.solid {
  background: #F4F5F9;
  color: #B1B1B1;
  border-color: #F4F5F9;
}

.alert-right-icon>span i {
  font-size: 18px;
  margin-right: 5px;
}

.alert-right-icon .close i {
  font-size: 16px;
}

.alert.alert-outline-primary {
  background: transparent;
  color: #99c990;
  border-color: #99c990;
}

.alert.alert-outline-secondary {
  background: transparent;
  color: #7e7e7e;
  border-color: #A02CFA;
}

.alert.alert-outline-success {
  background: transparent;
  color: #2BC155;
  border-color: #2BC155;
}

.alert.alert-outline-info {
  background: transparent;
  color: #1EA7C5;
  border-color: #1EA7C5;
}

.alert.alert-outline-warning {
  background: transparent;
  color: #FFBC11;
  border-color: #FFBC11;
}

.alert.alert-outline-danger {
  background: transparent;
  color: #F94687;
  border-color: #F94687;
}

.alert.alert-outline-dark {
  background: transparent;
  color: #7e7e7e;
  border-color: #B1B1B1;
}

.alert.alert-outline-light {
  background: transparent;
  color: #B1B1B1;
  border-color: #F4F5F9;
}

.alert-social {
  color: #fff;
}

.alert-social .alert-social-icon {
  align-self: center;
  margin-right: 0.9375rem;
}

.alert-social .alert-social-icon i {
  font-size: 42px;
}

.alert-social.facebook {
  background-color: #3b5998;
}

.alert-social.twitter {
  background-color: #1da1f2;
}

.alert-social.linkedin {
  background-color: #007bb6;
}

.alert-social.google-plus {
  background-color: #db4439;
}

.alert-social .close:hover {
  opacity: 1 !important;
  color: #fff !important;
}

.left-icon-big .alert-left-icon-big {
  align-self: center;
  margin-right: 0.9375rem;
}

.left-icon-big .alert-left-icon-big i {
  font-size: 35px;
  line-height: 1;
}

[direction="rtl"] .left-icon-big .alert-left-icon-big,
[direction="rtl"] .alert-social .alert-social-icon {
  margin-left: 0;
  margin-right: 0.9375rem;
}

.btn-close:focus {
  box-shadow: unset;
}

.badge {
  line-height: 1.5;
  border-radius: 0.625rem;
  font-size: 14px;
  font-weight: 600;
  /* added by kaji */
  cursor: pointer;
  padding: 4px 11px;
  background-color: #99c990 !important;
  border: 1px solid transparent;
}

.badge.text-bg-secondary,
.badge.text-bg-success,
.badge.text-bg-danger,
.badge.text-bg-info,
.badge.text-bg-warning {
  color: #fff !important;
}

.badge-rounded {
  border-radius: 20px;
  padding: 3px 13px;
}

.badge-circle {
  border-radius: 50%;
  padding: 0;
  min-width: unset;
  width: 1.75rem;
  height: 1.75rem;
  line-height: 1.75rem;
  display: inline-block;
  text-align: center;
}

.badge-outline-primary {
  border: 1px solid #99c990;
  color: #99c990;
}

.badge-outline-secondary {
  border: 1px solid #A02CFA;
  color: #A02CFA;
}

[data-theme-version="dark"] .badge-outline-secondary {
  color: #7e7e7e;
}

.badge-outline-success {
  border: 1px solid #2BC155;
  color: #2BC155;
}

.badge-outline-info {
  border: 1px solid #1EA7C5;
  color: #1EA7C5;
}

.badge-outline-warning {
  border: 1px solid #FFBC11;
  color: #FFBC11;
}

.badge-outline-danger {
  border: 1px solid #F94687;
  color: #F94687;
}

.badge-outline-light {
  border: 1px solid #E6E6E6;
  color: #B1B1B1;
}

[data-theme-version="dark"] .badge-outline-light {
  color: #7e7e7e;
}

.badge-outline-dark {
  border: 1px solid #B1B1B1;
  color: #B1B1B1;
}

[data-theme-version="dark"] .badge-outline-dark {
  color: #7e7e7e;
}

.badge-xs {
  font-size: 10px;
  padding: 0px 5px;
  line-height: 18px;
}

.badge-sm {
  font-size: 11px;
  padding: 5px 8px;
  line-height: 11px;
}

.badge-lg {
  font-size: 14px;
  padding: 0px 10px;
  line-height: 30px;
}

.badge-xl {
  font-size: 15px;
  padding: 0px 15px;
  line-height: 35px;
}

.badge-default {
  background: #ADB6C7;
}

.badge-success {
  background-color: #2BC155;
}

.badge-info {
  background-color: #1EA7C5;
}

.badge-primary {
  background-color: #99c990;
}

.badge-secondary {
  background-color: #A02CFA;
}

.badge-warning {
  background-color: #FFBC11;
}

.badge-danger {
  background-color: #F94687;
}

.badge-dark {
  background-color: #B1B1B1;
}

.badge-light {
  background-color: #F4F5F9;
  color: #000;
}

.light.badge-default {
  background: #ADB6C7;
}

.light.badge-success {
  background-color: #e3f9e9;
  color: #FFFFFF;
}

[data-theme-version="dark"] .light.badge-success {
  background-color: rgba(43, 193, 85, 0.1);
}

.light.badge-info {
  background-color: #a4e3f1;
  color: #1EA7C5;
}

[data-theme-version="dark"] .light.badge-info {
  background-color: rgba(30, 167, 197, 0.1);
}

.light.badge-primary {
  background-color: #d8e0f9;
  color: #99c990;
}

[data-theme-version="dark"] .light.badge-primary {
  background-color: rgba(30, 63, 180, 0.2);
}

.light.badge-secondary {
  background-color: #f1dffe;
  color: #A02CFA;
}

[data-theme-version="dark"] .light.badge-secondary {
  background-color: rgba(160, 44, 250, 0.5);
  color: #7e7e7e;
}

.light.badge-warning {
  background-color: #fff5dd;
  color: #FFBC11;
}

[data-theme-version="dark"] .light.badge-warning {
  background-color: rgba(255, 188, 17, 0.1);
}

.light.badge-danger {
  background-color: #fff3f7;
  color: #F94687;
}

[data-theme-version="dark"] .light.badge-danger {
  background-color: rgba(249, 70, 135, 0.15);
}

.light.badge-dark {
  background-color: #f1f1f1;
  color: #B1B1B1;
}

[data-theme-version="dark"] .light.badge-dark {
  background-color: rgba(177, 177, 177, 0.35);
  color: #7e7e7e;
}

.bootstrap-label .label {
  display: inline-block;
  margin-right: 1rem;
}

.bootstrap-label .label:last-child {
  margin-right: 0;
}

.badge-demo .badge {
  margin-right: 5px;
  margin-bottom: 5px;
}

.badge-demo .badge:last-child {
  margin-right: 0;
}

.bootstrap-badge-buttons button {
  margin-right: .2rem;
  margin-bottom: 1rem;
}

.bootstrap-badge-buttons button:last-child {
  margin-right: 0;
}

.badge-xs {
  font-size: 0.5rem;
  padding: 0rem 0.3125rem;
  line-height: 1rem;
  height: 16px;
  border-radius: 5px;
}

.welcome-text span,
.welcome-text p {
  display: none;
}

.page-titles {
  margin-bottom: 25px;
  display: block;
  margin-left: -40px;
  margin-right: -40px;
  padding: 20px 40px;
  margin-top: -15px;
  background: #f8f8f9;
  display: block;
}

.page-titles [class*="col-"] {
  display: block !important;
  max-width: 100%;
  width: 100%;
  margin: 0;
}

@media only screen and (max-width: 1600px) {
  .page-titles {
    margin-left: -30px;
    margin-top: 0px;
    margin-right: -30px;
    margin-bottom: 30px;
    padding: 15px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .page-titles {
    margin-left: -15px;
    margin-top: -15px;
    margin-right: -15px;
    margin-bottom: 0px;
    padding: 15px;
  }
}

.page-titles .justify-content-sm-end {
  align-items: center;
}

.page-titles h4,
.page-titles .h4 {
  margin-bottom: 10px;
  margin-top: 0;
  color: #000;
  font-size: 1.75rem;
  font-weight: 600;
}

.page-titles h4 span,
.page-titles .h4 span {
  font-size: 1.25rem;
}

.page-titles .breadcrumb {
  margin-bottom: 0;
  padding: 0;
  background: transparent;
}

.page-titles .breadcrumb li {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .page-titles .breadcrumb li {
    font-size: 15px;
  }
}

.page-titles .breadcrumb li a {
  color: #656773;
}

.page-titles .breadcrumb li.active a {
  color: #99c990;
}

.page-titles .breadcrumb .breadcrumb-item+.breadcrumb-item:before {
  color: #99c990;
}

.page-titles .breadcrumb-datepicker {
  font-size: 0.75rem;
  color: #89879f;
}

.page-titles .breadcrumb-datepicker__icon {
  font-size: 0.875rem;
}

.page-titles .breadcrumb-widget .border-dark {
  border-color: #dee2e6 !important;
}

.page-titles .breadcrumb-widget h4,
.page-titles .breadcrumb-widget .h4 {
  color: #646c9a;
  font-weight: 600;
}

@media only screen and (max-width: 575px) {
  .page-titles .breadcrumb-widget {
    text-align: left !important;
    margin-bottom: 0.9375rem;
  }
}

button {
  cursor: pointer;
}

button:focus {
  outline: 0;
  box-shadow: none;
}

.btn-primary {
  color: #141414;
  width: 36%;
}

.btn {
  /* padding: 0.938rem 1.5rem; */
  padding: 10px;
  border-radius: 0.625rem;
  font-weight: 500;
  font-size: 1rem;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active {
  outline: 0 !important;
}

@media only screen and (max-width: 1400px) {
  .btn {
    padding: 0.625rem 1rem;
    font-size: 0.813rem;
  }
}

.btn-transparent {
  background-color: transparent;
}

.btn.btn-success,
.btn.btn-warning,
.btn.btn-info,
.btn.btn-danger {
  color: #fff;
}

.btn.btn-success:active,
.btn.btn-warning:active,
.btn.btn-info:active,
.btn.btn-danger:active {
  color: #fff;
}

.btn.btn-outline-success:hover,
.btn.btn-outline-danger:hover,
.btn.btn-outline-info:hover,
.btn.btn-outline-warning:hover {
  color: #fff;
}

.sharp {
  min-width: 40px;
  padding: 7px;
  height: 40px;
  min-height: 40px;
}

.sharp.btn-xs {
  padding: 3px;
  width: 26px;
  height: 26px;
  min-width: 26px;
  min-height: 26px;
}

.light.tp-btn {
  background-color: transparent;
}

.light.btn-default {
  background: #ADB6C7;
}

.light.btn-success {
  background-color: #e3f9e9;
  border-color: #e3f9e9;
  color: #2BC155;
}

.light.btn-success g [fill] {
  fill: #2BC155;
}

[data-theme-version="dark"] .light.btn-success {
  background-color: rgba(43, 193, 85, 0.1);
  border-color: transparent;
}

.light.btn-success:hover {
  background-color: #2BC155;
  border-color: #2BC155;
  color: #fff;
}

.light.btn-success:hover g [fill] {
  fill: #fff;
}

.light.btn-info {
  background-color: #a4e3f1;
  border-color: #a4e3f1;
  color: #1EA7C5;
}

.light.btn-info g [fill] {
  fill: #1EA7C5;
}

[data-theme-version="dark"] .light.btn-info {
  background-color: rgba(30, 167, 197, 0.1);
  border-color: transparent;
}

.light.btn-info:hover {
  background-color: #1EA7C5;
  border-color: #1EA7C5;
  color: #fff;
}

.light.btn-info:hover g [fill] {
  fill: #fff;
}

.light.btn-primary {
  background-color: #d8e0f9;
  border-color: #d8e0f9;
  color: #99c990;
}

.light.btn-primary g [fill] {
  fill: #99c990;
}

[data-theme-version="dark"] .light.btn-primary {
  background-color: rgba(30, 63, 180, 0.2);
  border-color: transparent;
}

.light.btn-primary:hover {
  background-color: #99c990;
  border-color: #99c990;
  color: #fff;
}

.light.btn-primary:hover g [fill] {
  fill: #fff;
}

.light.btn-primary:hover svg path {
  fill: #fff;
}

.light.btn-secondary {
  background-color: #f1dffe;
  border-color: #f1dffe;
  color: #A02CFA;
}

.light.btn-secondary g [fill] {
  fill: #A02CFA;
}

[data-theme-version="dark"] .light.btn-secondary {
  background-color: rgba(160, 44, 250, 0.5);
  border-color: transparent;
  color: #fff;
}

.light.btn-secondary:hover {
  background-color: #A02CFA;
  border-color: #A02CFA;
  color: #fff;
}

.light.btn-secondary:hover g [fill] {
  fill: #fff;
}

.light.btn-warning {
  background-color: #fff5dd;
  border-color: #fff5dd;
  color: #FFBC11;
}

.light.btn-warning g [fill] {
  fill: #FFBC11;
}

[data-theme-version="dark"] .light.btn-warning {
  background-color: rgba(255, 188, 17, 0.1);
  border-color: transparent;
}

.light.btn-warning:hover {
  background-color: #FFBC11;
  border-color: #FFBC11;
  color: #fff;
}

.light.btn-warning:hover g [fill] {
  fill: #fff;
}

.light.btn-danger {
  background-color: #fff3f7;
  border-color: #fff3f7;
  color: #F94687;
}

.light.btn-danger g [fill] {
  fill: #F94687;
}

[data-theme-version="dark"] .light.btn-danger {
  background-color: rgba(249, 70, 135, 0.15);
  border-color: transparent;
}

.light.btn-danger:hover {
  background-color: #F94687;
  border-color: #F94687;
  color: #fff;
}

.light.btn-danger:hover g [fill] {
  fill: #fff;
}

.light.btn-danger:hover svg path {
  fill: #fff;
}

.light.btn-dark {
  background-color: #f1f1f1;
  border-color: #f1f1f1;
  color: #B1B1B1;
}

.light.btn-dark g [fill] {
  fill: #B1B1B1;
}

[data-theme-version="dark"] .light.btn-dark {
  background-color: rgba(177, 177, 177, 0.35);
  border-color: transparent;
  color: #fff;
}

.light.btn-dark:hover {
  background-color: #B1B1B1;
  border-color: #B1B1B1;
  color: #fff;
}

.light.btn-dark:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn {
  background-color: transparent;
  border-color: transparent;
}

.btn.tp-btn.btn-default {
  background: #ADB6C7;
}

.btn.tp-btn.btn-success {
  color: #2BC155;
}

.btn.tp-btn.btn-success g [fill] {
  fill: #2BC155;
}

.btn.tp-btn.btn-success:hover {
  background-color: #2BC155;
  border-color: #2BC155;
  color: #fff;
}

.btn.tp-btn.btn-success:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-info {
  color: #1EA7C5;
}

.btn.tp-btn.btn-info g [fill] {
  fill: #1EA7C5;
}

.btn.tp-btn.btn-info:hover {
  background-color: #1EA7C5;
  border-color: #1EA7C5;
  color: #fff;
}

.btn.tp-btn.btn-info:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-primary {
  color: #99c990;
}

.btn.tp-btn.btn-primary g [fill] {
  fill: #99c990;
}

.btn.tp-btn.btn-primary:hover {
  background-color: #99c990;
  border-color: #99c990;
  color: #fff;
}

.btn.tp-btn.btn-primary:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-secondary {
  color: #A02CFA;
}

.btn.tp-btn.btn-secondary g [fill] {
  fill: #A02CFA;
}

.btn.tp-btn.btn-secondary:hover {
  background-color: #A02CFA;
  border-color: #A02CFA;
  color: #fff;
}

.btn.tp-btn.btn-secondary:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-warning {
  color: #FFBC11;
}

.btn.tp-btn.btn-warning g [fill] {
  fill: #FFBC11;
}

.btn.tp-btn.btn-warning:hover {
  background-color: #FFBC11;
  border-color: #FFBC11;
  color: #fff;
}

.btn.tp-btn.btn-warning:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-danger {
  color: #F94687;
}

.btn.tp-btn.btn-danger g [fill] {
  fill: #F94687;
}

.btn.tp-btn.btn-danger:hover {
  background-color: #F94687;
  border-color: #F94687;
  color: #fff;
}

.btn.tp-btn.btn-danger:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-light {
  color: #B1B1B1;
}

.btn.tp-btn.btn-light g [fill] {
  fill: #B1B1B1;
}

.btn.tp-btn.btn-light:hover {
  background-color: #F4F5F9;
  border-color: #F4F5F9;
  color: #B1B1B1;
}

.btn.tp-btn.btn-light:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-dark {
  color: #B1B1B1;
}

.btn.tp-btn.btn-dark g [fill] {
  fill: #B1B1B1;
}

.btn.tp-btn.btn-dark:hover {
  background-color: #B1B1B1;
  border-color: #B1B1B1;
  color: #fff;
}

.btn.tp-btn.btn-dark:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn-light {
  background-color: transparent;
  border-color: transparent;
}

.btn.tp-btn-light.btn-success {
  color: #2BC155;
}

.btn.tp-btn-light.btn-success g [fill] {
  fill: #2BC155;
}

.btn.tp-btn-light.btn-success:hover {
  background-color: #e3f9e9;
  border-color: #e3f9e9;
  color: #2BC155;
}

.btn.tp-btn-light.btn-success:hover g [fill] {
  fill: #2BC155;
}

.btn.tp-btn-light.btn-info {
  color: #1EA7C5;
}

.btn.tp-btn-light.btn-info g [fill] {
  fill: #1EA7C5;
}

.btn.tp-btn-light.btn-info:hover {
  background-color: #a4e3f1;
  border-color: #a4e3f1;
  color: #1EA7C5;
}

.btn.tp-btn-light.btn-info:hover g [fill] {
  fill: #1EA7C5;
}

.btn.tp-btn-light.btn-primary {
  color: #99c990;
}

.btn.tp-btn-light.btn-primary g [fill] {
  fill: #99c990;
}

.btn.tp-btn-light.btn-primary:hover {
  background-color: #d8e0f9;
  border-color: #d8e0f9;
  color: #99c990;
}

.btn.tp-btn-light.btn-primary:hover g [fill] {
  fill: #99c990;
}

.btn.tp-btn-light.btn-secondary {
  color: #A02CFA;
}

.btn.tp-btn-light.btn-secondary g [fill] {
  fill: #A02CFA;
}

.btn.tp-btn-light.btn-secondary:hover {
  background-color: #f1dffe;
  border-color: #f1dffe;
  color: #A02CFA;
}

.btn.tp-btn-light.btn-secondary:hover g [fill] {
  fill: #A02CFA;
}

.btn.tp-btn-light.btn-warning {
  color: #FFBC11;
}

.btn.tp-btn-light.btn-warning g [fill] {
  fill: #FFBC11;
}

.btn.tp-btn-light.btn-warning:hover {
  background-color: #fff5dd;
  border-color: #fff5dd;
  color: #FFBC11;
}

.btn.tp-btn-light.btn-warning:hover g [fill] {
  fill: #FFBC11;
}

.btn.tp-btn-light.btn-danger {
  color: #F94687;
}

.btn.tp-btn-light.btn-danger g [fill] {
  fill: #F94687;
}

.btn.tp-btn-light.btn-danger:hover {
  background-color: #fff3f7;
  border-color: #fff3f7;
  color: #F94687;
}

.btn.tp-btn-light.btn-danger:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn-light.btn-dark {
  color: #B1B1B1;
}

.btn.tp-btn-light.btn-dark g [fill] {
  fill: #B1B1B1;
}

.btn.tp-btn-light.btn-dark:hover {
  background-color: #f1f1f1;
  border-color: #f1f1f1;
  color: #B1B1B1;
}

.btn.tp-btn-light.btn-dark:hover [fill] {
  fill: #fff;
}

.shadow.btn-primary {
  -webkit-box-shadow: 0 5px 15px 0 rgba(30, 63, 180, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(30, 63, 180, 0.2) !important;
}

.shadow.btn-secondary {
  -webkit-box-shadow: 0 5px 15px 0 rgba(160, 44, 250, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(160, 44, 250, 0.2) !important;
}

.shadow.btn-warning {
  -webkit-box-shadow: 0 5px 15px 0 rgba(255, 188, 17, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(255, 188, 17, 0.2) !important;
}

.shadow.btn-danger {
  -webkit-box-shadow: 0 5px 15px 0 rgba(249, 70, 135, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(249, 70, 135, 0.2) !important;
}

.shadow.btn-info {
  -webkit-box-shadow: 0 5px 15px 0 rgba(30, 167, 197, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(30, 167, 197, 0.2) !important;
}

.shadow.btn-success {
  -webkit-box-shadow: 0 5px 15px 0 rgba(43, 193, 85, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(43, 193, 85, 0.2) !important;
}

.btn-xxs {
  padding: 6px 15px;
  font-size: 11px;
  line-height: 1.3;
}

.btn-xs {
  font-size: 0.75rem;
  padding: 0.438rem 1rem;
  font-weight: 600;
}

@media only screen and (max-width: 1400px) {
  .btn-xs {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    font-weight: 500;
  }
}

.btn-sm,
.btn-group-sm>.btn {
  font-size: 0.813rem !important;
  padding: 0.625rem 1rem;
}

@media only screen and (max-width: 1400px) {

  .btn-sm,
  .btn-group-sm>.btn {
    padding: 0.375rem 1rem;
    font-size: 0.75rem;
  }
}

.btn-md {
  font-size: 0.813rem !important;
  padding: 0.875rem 1.25rem;
}

@media only screen and (max-width: 1400px) {
  .btn-md {
    padding: 0.5rem 1rem;
    font-size: 0.813rem;
  }
}

.btn-lg,
.btn-group-lg>.btn {
  padding: 1rem 2rem;
  font-size: 1.125rem !important;
}

@media only screen and (max-width: 1400px) {

  .btn-lg,
  .btn-group-lg>.btn {
    padding: 0.75rem 1.5rem;
    font-size: 1rem !important;
  }
}

.btn-xl {
  padding: 0.6rem 1rem;
}

.btn-xl.btn-default {
  font-weight: 600;
}

.btn-square {
  border-radius: 0;
}

.btn-rounded {
  border-radius: 30px !important;
}

.btn-icon-right {
  border-left: 0.0625rem solid rgba(255, 255, 255, 0.4);
  display: inline-block;
  margin: -.8rem 0 -.8rem 1rem;
  padding: 0.4375rem 0 0.4375rem 1rem;
  margin: -1rem -0.25rem -1rem 1rem;
  padding: 1rem 0 1rem 1.25rem;
}

@media only screen and (max-width: 1400px) {
  .btn-icon-right {
    padding: 0.6rem 0 0.6rem 0.6rem;
  }
}

.btn-icon-left {
  background: #fff;
  border-radius: 10rem;
  display: inline-block;
  margin: -0.5rem 0.75rem -0.5rem -1.188rem;
  padding: 0.5rem 0.80rem 0.5rem;
  float: left;
}

@media only screen and (max-width: 1199px) {
  .btn-icon-left {
    margin: -0.5rem 0.75rem -0.5rem -0.800rem;
  }
}

[direction="rtl"] .btn-icon-left {
  margin: -.5rem -1.5rem -.5rem 1rem;
}

[direction="rtl"] .btn-icon-right {
  border-left: 0px solid white;
  display: inline-block;
  margin: -.8rem 1rem -.8rem 0;
  padding: 0.4375rem 1rem 0.4375rem 0;
  border-right: 1px solid white;
}

.toggle-dropdown::after {
  margin-left: 0.755em;
}

.social-btn-icon .btn {
  min-width: 7.5rem;
  margin-bottom: 1.5rem;
}

.social-icon .btn {
  padding: .7rem 1.4rem;
}

.btn-circle {
  height: 5rem;
  width: 5rem;
  border-radius: 50% !important;
}

.btn-circle-sm {
  width: 4.5rem;
  height: 4.5rem;
  font-size: 1.8rem;
}

.btn-circle-md {
  width: 6rem;
  height: 6rem;
  font-size: 2.5rem;
}

.btn-circle-md i {
  font-size: 2.4rem;
}

.btn-circle-lg {
  width: 8rem;
  height: 8rem;
  font-size: 3.2rem;
}

.btn-circle-lg i {
  font-size: 3.1rem;
}

.btn-page .btn {
  min-width: 110px;
  margin-right: 4px;
  margin-bottom: 8px;
}

.size-1 {
  min-width: 160px !important;
  font-size: 24px;
  padding: 0.68rem 0.75rem;
}

.size-2 {
  font-size: 20px;
  min-width: 130px !important;
  padding: 0.57rem 0.75rem;
}

.size-3 {
  font-size: 14px;
  min-width: 110px !important;
  padding: 0.536rem 0.75rem;
}

.size-4 {
  font-size: 14px;
  min-width: 100px !important;
}

.size-5 {
  font-size: 14px;
  min-width: 90px !important;
  padding: .22rem 0.75rem;
}

.size-6 {
  font-size: 13px;
  min-width: 80px !important;
  padding: 0.097rem 0.75rem;
}

.size-7 {
  font-size: 12px;
  min-width: 60px !important;
  padding: 0.001rem 0.75rem;
}

.btn-light {
  background: #F4F5F9;
  border-color: #F4F5F9;
}

.btn-light:active,
.btn-light:focus,
.btn-light:hover {
  background: white;
  color: #B1B1B1;
  border-color: white;
}

.btn-outline-primary:hover {
  color: #fff;
}

.btn-outline-light {
  color: #B1B1B1;
}

[data-theme-version="dark"] .btn-outline-light {
  color: #7e7e7e;
}

[data-theme-version="dark"] .btn-outline-secondary {
  color: #7e7e7e;
}

[data-theme-version="dark"] .btn-outline-dark {
  color: #7e7e7e;
}

.btn-dark {
  background: #B1B1B1;
  border-color: #B1B1B1;
  color: #fff;
}

.btn-dark:active,
.btn-dark:focus,
.btn-dark:hover {
  background: #989898;
  color: #fff;
  border-color: #989898;
}

.btn-group.btn-rounded .btn:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.btn-group.btn-rounded .btn:last-child {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.btn-facebook {
  background: #3b5998;
  border-color: #3b5998;
  color: #fff;
}

.btn-facebook:active,
.btn-facebook:focus,
.btn-facebook:hover {
  background: #2d4373;
  color: #fff;
  border-color: #2d4373;
}

.btn-twitter {
  background: #1da1f2;
  border-color: #1da1f2;
  color: #fff;
}

.btn-twitter:active,
.btn-twitter:focus,
.btn-twitter:hover {
  background: #0c85d0;
  color: #fff;
  border-color: #0c85d0;
}

.btn-youtube {
  background: #FF0000;
  border-color: #FF0000;
  color: #fff;
}

.btn-youtube:active,
.btn-youtube:focus,
.btn-youtube:hover {
  background: #cc0000;
  color: #fff;
  border-color: #cc0000;
}

.btn-instagram {
  background: #c32aa3;
  border-color: #c32aa3;
  color: #fff;
}

.btn-instagram:active,
.btn-instagram:focus,
.btn-instagram:hover {
  background: #992180;
  color: #fff;
  border-color: #992180;
}

.btn-pinterest {
  background: #bd081c;
  border-color: #bd081c;
  color: #fff;
}

.btn-pinterest:active,
.btn-pinterest:focus,
.btn-pinterest:hover {
  background: #8c0615;
  color: #fff;
  border-color: #8c0615;
}

.btn-linkedin {
  background: #007bb6;
  border-color: #007bb6;
  color: #fff;
}

.btn-linkedin:active,
.btn-linkedin:focus,
.btn-linkedin:hover {
  background: #005983;
  color: #fff;
  border-color: #005983;
}

.btn-google-plus {
  background: #db4439;
  border-color: #db4439;
  color: #fff;
}

.btn-google-plus:active,
.btn-google-plus:focus,
.btn-google-plus:hover {
  background: #be2d23;
  color: #fff;
  border-color: #be2d23;
}

.btn-google {
  background: #4285f4;
  border-color: #4285f4;
  color: #fff;
}

.btn-google:active,
.btn-google:focus,
.btn-google:hover {
  background: #1266f1;
  color: #fff;
  border-color: #1266f1;
}

.btn-snapchat {
  background: #fffc00;
  border-color: #fffc00;
  color: #000;
}

.btn-snapchat:active,
.btn-snapchat:focus,
.btn-snapchat:hover {
  background: #ccca00;
  color: #000;
  border-color: #ccca00;
}

.btn-whatsapp {
  background: #25d366;
  border-color: #25d366;
  color: #fff;
}

.btn-whatsapp:active,
.btn-whatsapp:focus,
.btn-whatsapp:hover {
  background: #1da851;
  color: #fff;
  border-color: #1da851;
}

.btn-tumblr {
  background: #35465d;
  border-color: #35465d;
  color: #fff;
}

.btn-tumblr:active,
.btn-tumblr:focus,
.btn-tumblr:hover {
  background: #222e3d;
  color: #fff;
  border-color: #222e3d;
}

.btn-reddit {
  background: #ff4500;
  border-color: #ff4500;
  color: #fff;
}

.btn-reddit:active,
.btn-reddit:focus,
.btn-reddit:hover {
  background: #cc3700;
  color: #fff;
  border-color: #cc3700;
}

.btn-spotify {
  background: #1ed760;
  border-color: #1ed760;
  color: #fff;
}

.btn-spotify:active,
.btn-spotify:focus,
.btn-spotify:hover {
  background: #18aa4c;
  color: #fff;
  border-color: #18aa4c;
}

.btn-yahoo {
  background: #430297;
  border-color: #430297;
  color: #fff;
}

.btn-yahoo:active,
.btn-yahoo:focus,
.btn-yahoo:hover {
  background: #2d0165;
  color: #fff;
  border-color: #2d0165;
}

.btn-dribbble {
  background: #ea4c89;
  border-color: #ea4c89;
  color: #fff;
}

.btn-dribbble:active,
.btn-dribbble:focus,
.btn-dribbble:hover {
  background: #e51e6b;
  color: #fff;
  border-color: #e51e6b;
}

.btn-skype {
  background: #00aff0;
  border-color: #00aff0;
  color: #fff;
}

.btn-skype:active,
.btn-skype:focus,
.btn-skype:hover {
  background: #008abd;
  color: #fff;
  border-color: #008abd;
}

.btn-quora {
  background: #aa2200;
  border-color: #aa2200;
  color: #fff;
}

.btn-quora:active,
.btn-quora:focus,
.btn-quora:hover {
  background: #771800;
  color: #fff;
  border-color: #771800;
}

.btn-vimeo {
  background: #1ab7ea;
  border-color: #1ab7ea;
  color: #fff;
}

.btn-vimeo:active,
.btn-vimeo:focus,
.btn-vimeo:hover {
  background: #1295bf;
  color: #fff;
  border-color: #1295bf;
}

.card {
  margin-bottom: 1.875rem;
  background-color: #fff;
  transition: all .5s ease-in-out;
  position: relative;
  border: 0px solid transparent;
  border-radius: 1.25rem;
  box-shadow: 0px 12px 23px 0px rgba(160, 44, 250, 0.04);
  height: calc(100% - 30px);
}

@media only screen and (max-width: 575px) {
  .card {
    margin-bottom: 0.938rem;
    height: calc(100% - 0.938rem);
  }
}

.card-body {
  padding: 1.875rem;
}

@media only screen and (max-width: 575px) {
  .card-body {
    padding: 1rem;
  }
}

.card-title {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  text-transform: capitalize;
}

@media only screen and (max-width: 575px) {
  .card-title {
    font-size: 1rem;
  }
}

.card-title--large {
  font-size: 1.5rem;
}

.card-title--medium {
  font-size: 1rem;
}

.card-title--small {
  font-size: 0.875rem;
}

.card-header {
  border-color: #E6E6E6;
  position: relative;
  background: transparent;
  padding: 1.5rem 1.875rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .card-header {
    padding: 1.25rem 1rem 1.25rem;
  }
}

[data-theme-version="dark"] .card-header {
  border-color: #333a54;
}

.card-header .card-title {
  margin-bottom: 0px;
}

.card-header .subtitle {
  padding-top: 5px;
  font-size: 14px;
  line-height: 1.5;
}

.card-footer {
  border-color: #E6E6E6;
  background: transparent;
  padding: 1.25rem 1.875rem 1.25rem;
}

[data-theme-version="dark"] .card-footer {
  border-color: #333a54;
}

@media only screen and (max-width: 575px) {
  .card-footer {
    padding: 1rem;
  }
}

.transparent-card.card {
  background: transparent;
  border: 1px solid transparent;
  box-shadow: none;
}

.card-action>a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  border-color: transparent;
  text-align: center;
  background: black;
  color: #fff;
  margin-right: 8px;
}

[data-theme-version="dark"] .card-action>a {
  background: #222327;
}

.card-action>a:last-child {
  margin-right: 0;
}

.card-action>a:hover,
.card-action>a:focus {
  background: black;
}

[data-theme-version="dark"] .card-action>a:hover,
[data-theme-version="dark"] .card-action>a:focus {
  background: #222327;
}

.card-action>a i,
.card-action>a span {
  font-size: 1rem;
}

.card-action .dropdown {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border-color: transparent;
  text-align: center;
  margin-right: 8px;
  top: -2px;
  position: relative;
  display: inline-block;
  background: black;
  color: #99c990;
}

[data-theme-version="dark"] .card-action .dropdown {
  background: #222327;
}

.card-action .dropdown:hover,
.card-action .dropdown:focus {
  background: black;
}

[data-theme-version="dark"] .card-action .dropdown:hover,
[data-theme-version="dark"] .card-action .dropdown:focus {
  background: #222327;
}

.card-action .dropdown .btn {
  padding: 0;
  line-height: 27px;
  color: #fff;
}

.card-action .dropdown .btn:focus {
  box-shadow: none;
}

.card-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  overflow: auto;
}

.card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

.card-loader i {
  margin: 0 auto;
  background: #193495;
  padding: 10px;
  border-radius: 50%;
  color: #fff;
  font-size: 1rem;
}

.rotate-refresh {
  -webkit-animation: mymove 0.8s infinite linear;
  animation: mymove 0.8s infinite linear;
  display: inline-block;
}

.card-header .date_picker {
  display: inline-block;
  padding: 8px;
  border: 1px solid #E6E6E6;
  cursor: pointer;
  border-radius: .375rem;
}

.card-header .border-0 {
  padding-bottom: 0;
}

@-webkit-keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.card-link:hover {
  color: #99c990;
}

.card-ui-default-1 .card-header {
  border-color: rgba(255, 255, 255, 0.5);
}

.carousel-custom-prev,
.carousel-custom-next {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  text-align: center;
}

.carousel-custom-prev i,
.carousel-custom-next i {
  font-size: 1rem;
}

.carousel-custom-prev:hover,
.carousel-custom-next:hover {
  background: linear-gradient(to right, rgba(245, 60, 121, 0.99) 0%, rgba(246, 104, 47, 0.99) 100%);
  color: #fff;
}

.carousel-custom-next {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}

.carousel-custom-prev {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
}

.carousel-caption {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.78);
  z-index: 0;
}

.carousel-caption h5,
.carousel-caption .h5 {
  color: #fff;
  font-size: 1.8rem;
}

.carousel-caption p {
  margin-bottom: 0;
}

.dropdown-toggle:focus {
  box-shadow: none !important;
}

.dropdown-outline {
  border: 0.1rem solid #99c990;
}

.dropdown-menu {
  font-size: inherit;
  border: 0;
  box-shadow: 0 10px 40px 0 rgba(32, 28, 69, 0.1);
  margin-top: 0;
  border-radius: 0.625rem;
  overflow: hidden;
}

.dropdown-menu .dropdown-item {
  font-size: 16px;
  color: #7e7e7e;
  padding: 0.5rem 1.75rem;
}

@media only screen and (max-width: 1400px) {
  .dropdown-menu .dropdown-item {
    padding: 0.375rem 1rem;
    font-size: 14px;
  }
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item.active {
  color: #99c990;
  background: #F4F5F9;
}

.dropdown-menu .dropdown-item.active,
.dropdown-menu .dropdown-item:active {
  color: #99c990 !important;
}

[direction="rtl"] .dropdown-menu {
  right: auto !important;
}

.dropdown-toggle-split {
  padding: 0 10px;
  opacity: 0.85;
}

.dropdown-toggle-split:after {
  margin-left: 0 !important;
}

.dropdown-toggle-split:active,
.dropdown-toggle-split:focus,
.dropdown-toggle-split:hover {
  opacity: 1;
}

.dropleft .dropdown-toggle:before,
.dropright .dropdown-toggle:before,
.dropup .dropdown-toggle:after,
.dropdown-toggle:after {
  content: "\f107";
  font-family: FontAwesome;
  border: 0;
  vertical-align: middle;
  margin-left: 0.25em;
  line-height: 1;
}

.dropup .dropdown-toggle:after {
  content: "\f106";
}

.dropleft .dropdown-toggle::before {
  content: "\f104";
}

.dropright .dropdown-toggle::before {
  content: "\f105";
}

.dropright .dropdown-toggle::after {
  content: none;
}

.custom-dropdown {
  display: inline-block;
  margin-bottom: 1rem;
}

.custom-dropdown .dropdown-menu {
  border: 0px;
  min-width: 160px;
}

.card-action .custom-dropdown {
  margin: 0px;
  background: #8198ea;
}

.card-action .custom-dropdown.show,
.card-action .custom-dropdown:focus,
.card-action .custom-dropdown:hover {
  background: #99c990;
  color: #fff;
}

.card-action .custom-dropdown i {
  display: inline-block;
  padding-top: 9px;
}

.dropdown .dropdown-dots {
  position: relative;
  height: 5px;
  width: 5px;
  background: rgba(177, 177, 177, 0.4);
  border-radius: 5px;
  display: block;
}

.dropdown .dropdown-dots:after,
.dropdown .dropdown-dots:before {
  content: "";
  height: 5px;
  width: 5px;
  background: rgba(177, 177, 177, 0.4);
  position: absolute;
  border-radius: 5px;
}

.dropdown .dropdown-dots:after {
  right: -8px;
}

.dropdown .dropdown-dots:before {
  left: -8px;
}

.dropdown .dropdown-dots.text-white {
  background: rgba(255, 255, 255, 0.7);
}

.dropdown .dropdown-dots.text-white:after,
.dropdown .dropdown-dots.text-white:before {
  background: rgba(255, 255, 255, 0.7);
}

.grid-col {
  padding: 0.5rem !important;
  background: #f2f4fa;
}

.row.grid {
  margin-bottom: 1.5rem;
  text-align: center;
}

.row.grid .grid-col:first-child {
  text-align: left;
}

.label {
  display: inline-block;
  text-align: center;
  font-size: 0.75rem;
  padding: .2rem .8rem;
}

.label-fixed {
  width: 7.5rem;
  padding: .6rem 0;
}

.label-fixed-lg {
  width: 9.5rem;
  padding: .6rem 0;
}

.label-big {
  width: 16.8rem;
  font-size: 1.4rem;
  padding: 1.1rem 0;
}

.label-xl {
  width: 10.5rem;
  padding: 1.1rem 0;
  font-size: 1.5rem;
}

.label-lg {
  width: 9.5rem;
  padding: 1.1rem 0;
}

.label-md {
  width: 8.5rem;
  padding: 1.1rem 0;
}

.label-sm {
  width: 7.5rem;
  padding: 1.1rem 0;
}

.label-default {
  background: #ADB6C7;
}

.label-primary {
  background: #99c990;
  color: #fff;
}

.label-secondary {
  background: #A02CFA;
  color: #fff;
}

.label-info {
  background: #1EA7C5;
  color: #fff;
}

.label-success {
  background: #2BC155;
  color: #fff;
}

.label-warning {
  background: #FFBC11;
  color: #fff;
}

.label-danger {
  background: #F94687;
  color: #fff;
}

.label-light {
  background: #F4F5F9;
  color: #000;
}

.label-dark {
  background: #B1B1B1;
  color: #fff;
}

code {
  word-break: break-word;
  padding: 2px 5px;
  border-radius: 3px;
  background: #fee9f1;
  color: #F94687;
}

[data-theme-version="dark"] code {
  background: rgba(249, 70, 135, 0.1);
}

.heading-labels {
  color: #333333;
}

.heading-labels>* {
  margin-bottom: .8rem;
}

.heading-labels h1 .label,
.heading-labels .h1 .label {
  font-size: 18px;
  font-weight: normal;
  padding: .4rem .9rem;
}

.heading-labels h2 .label,
.heading-labels .h2 .label {
  font-size: 16px;
  font-weight: normal;
  padding: 0.3rem .9rem;
}

.heading-labels h3 .label,
.heading-labels .h3 .label {
  font-size: 14px;
  font-weight: normal;
}

.heading-labels h4 .label,
.heading-labels .h4 .label {
  font-size: 12px;
  font-weight: normal;
}

.heading-labels h5 .label,
.heading-labels .h5 .label {
  font-size: 12px;
  font-weight: normal;
}

.heading-labels h6 .label,
.heading-labels .h6 .label {
  font-size: 12px;
  font-weight: normal;
}

.list-group-item {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #E6E6E6;
  padding: 1rem 1.5rem;
  border-radius: 0;
}

[data-theme-version="dark"] .list-group-item {
  border-color: #333a54;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #fff;
  background-color: #A02CFA;
  border-color: #A02CFA;
}

[class*="bg-"] .list-group-item {
  border-color: rgba(255, 255, 255, 0.3);
  color: #fff;
}

.bg-warning .list-group-item {
  border-color: rgba(255, 255, 255, 0.3);
}

.media img {
  border-radius: 7.625rem;
}

.vertical-card__menu:hover {
  box-shadow: none;
}

.vertical-card__menu--image {
  text-align: center;
}

.vertical-card__menu--image img {
  width: 100%;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.vertical-card__menu--status.closed {
  background: #F94687;
}

.vertical-card__menu--status.closed:after {
  border-top: 10px solid #F94687;
}

.vertical-card__menu--status.closed .ribbon-curve {
  border-top: 11px solid #F94687;
  border-bottom: 10px solid #F94687;
}

.vertical-card__menu--desc p {
  margin-bottom: 0.625rem;
}

.vertical-card__menu--price {
  font-size: 1.25rem;
  margin: 0;
  font-weight: 700;
}

.vertical-card__menu--rating {
  font-size: 0.75rem;
}

.vertical-card__menu--title {
  font-weight: 700;
}

.vertical-card__menu--button button {
  font-size: 0.75rem;
}

.vertical-card__menu .card-footer {
  padding: 15px 20px;
  background-color: #E6E6E6;
  border-top: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .vertical-card__menu .card-footer {
    padding: 15px;
  }
}

.vertical-card__menu .card-footer span {
  color: #B1B1B1;
  margin-right: 0.3125rem;
}

.horizontal-card__menu {
  box-shadow: 0 0 7px rgba(173, 173, 173, 0.32);
  display: flex;
  flex-direction: row;
  display: block;
}

.horizontal-card__menu .card-body {
  padding: 0;
  padding: 20px 30px;
}

.horizontal-card__menu--image {
  flex-basis: 30%;
  max-width: 30%;
  max-width: unset;
  flex-basis: 100%;
}

.horizontal-card__menu--image img {
  height: 100%;
  width: 100%;
}

.horizontal-card__menu--title {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.3125rem;
}

.horizontal-card__menu--fav {
  margin-right: 0.5rem;
}

.horizontal-card__menu--price {
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
}

.horizontal-card__menu--rating {
  font-size: 0.625rem;
}

.horizontal-card__menu--footer {
  margin-top: 10px;
}

.prev_price {
  text-decoration: line-through;
  font-size: 80%;
  opacity: 0.5;
}

.modal-header {
  padding: 1rem 1.875rem;
}

@media only screen and (max-width: 575px) {
  .modal-header {
    padding: 1rem;
  }
}

.modal-header .close {
  padding: 0.875rem 1.815rem;
  margin: 0;
  position: absolute;
  right: 0;
  float: none;
  top: 0;
  font-size: 30px;
  font-weight: 100;
}

.modal-body {
  padding: 1.875rem;
}

@media only screen and (max-width: 575px) {
  .modal-body {
    padding: 1rem;
  }
}

.modal-footer {
  padding: 1rem 1.875rem;
}

@media only screen and (max-width: 575px) {
  .modal-footer {
    padding: 1rem;
  }
}

.modal-content {
  border-radius: 0.625rem;
}

.modal-backdrop {
  z-index: 10 !important;
}

.pagination .page-item .page-link:hover {
  background: #99c990;
  border-color: #99c990;
  color: #fff;
}

.pagination .page-item.active .page-link {
  background: #99c990;
  border-color: #99c990;
  color: #fff;
}

.pagination {
  margin-bottom: 20px;
}

.pagination .page-item.page-indicator .page-link {
  padding: .65rem .8rem;
  font-size: 14px;
}

.pagination .page-item.page-indicator:hover .page-link {
  color: #B1B1B1;
}

.pagination .page-item .page-link {
  text-align: center;
  z-index: 2;
  padding: 0.55rem 1rem;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.15);
  color: #B1B1B1;
  border: 1px solid #E6E6E6;
}

[data-theme-version="dark"] .pagination .page-item .page-link {
  border-color: #333a54;
  color: #828690;
  background: rgba(255, 255, 255, 0);
}

.pagination .page-item .page-link:hover i,
.pagination .page-item .page-link span {
  color: #fff;
}

.pagination .page-item .page-link:focus {
  outline: 0;
  box-shadow: none;
}

.pagination .page-item .page-link:hover {
  background: #99c990;
  color: #fff;
  border-color: #99c990;
}

.pagination .page-item.active .page-link {
  background-color: #99c990;
  border-color: #99c990;
  color: #fff;
  box-shadow: 0 10px 20px 0px rgba(30, 63, 180, 0.2);
}

[data-theme-version="dark"] .pagination .page-item.active .page-link {
  color: #fff;
}

.pagination .page-item .page-link {
  color: #B1B1B1;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.pagination .page-item:last-child .page-link {
  margin-right: 0;
}

[direction="rtl"] .pagination .page-item:first-child .page-link {
  margin-right: 0;
}

.pagination.no-bg li:not(.page-indicator):not(.active) .page-link {
  background: transparent;
  border-color: transparent;
}

.pagination.no-bg.pagination-primary li:not(.page-indicator):not(.active):hover .page-link {
  background: #99c990;
  border-color: #99c990;
}

[data-theme-version="dark"] .pagination.no-bg.pagination-primary li:not(.page-indicator):not(.active):hover .page-link {
  background: #99c990;
  border-color: #99c990;
}

.pagination.no-bg.pagination-danger li:not(.page-indicator):not(.active):hover .page-link {
  background: #F94687;
  border-color: #F94687;
}

[data-theme-version="dark"] .pagination.no-bg.pagination-danger li:not(.page-indicator):not(.active):hover .page-link {
  background: #F94687;
  border-color: #F94687;
}

.pagination.no-bg.pagination-info li:not(.page-indicator):not(.active):hover .page-link {
  background: #1EA7C5;
  border-color: #1EA7C5;
}

[data-theme-version="dark"] .pagination.no-bg.pagination-info li:not(.page-indicator):not(.active):hover .page-link {
  background: #1EA7C5;
  border-color: #1EA7C5;
}

.pagination.no-bg.pagination-warning li:not(.page-indicator):not(.active):hover .page-link {
  background: #FFBC11;
  border-color: #FFBC11;
}

[data-theme-version="dark"] .pagination.no-bg.pagination-warning li:not(.page-indicator):not(.active):hover .page-link {
  background: #FFBC11;
  border-color: #FFBC11;
}

.pagination-primary .page-item .page-link {
  background: #d8e0f9;
  border-color: #d8e0f9;
  color: #99c990;
}

[data-theme-version="dark"] .pagination-primary .page-item .page-link {
  background: rgba(30, 63, 180, 0.2);
  border-color: transparent;
  color: #99c990;
}

.pagination-primary .page-item:hover .page-link,
.pagination-primary .page-item.active .page-link {
  background: #99c990;
  border-color: #99c990;
  box-shadow: 0 10px 20px 0px rgba(30, 63, 180, 0.2);
}

[data-theme-version="dark"] .pagination-primary .page-item:hover .page-link,
[data-theme-version="dark"] .pagination-primary .page-item.active .page-link {
  color: #fff;
}

.pagination-danger .page-item .page-link {
  background: #fff3f7;
  border-color: #fff3f7;
  color: #F94687;
}

[data-theme-version="dark"] .pagination-danger .page-item .page-link {
  background: rgba(249, 70, 135, 0.15);
  border-color: transparent;
  color: #F94687;
}

.pagination-danger .page-item:hover .page-link,
.pagination-danger .page-item.active .page-link {
  background: #F94687;
  border-color: #F94687;
  box-shadow: 0 10px 20px 0px rgba(249, 70, 135, 0.2);
}

[data-theme-version="dark"] .pagination-danger .page-item:hover .page-link,
[data-theme-version="dark"] .pagination-danger .page-item.active .page-link {
  color: #fff;
}

.pagination-info .page-item .page-link {
  background: #a4e3f1;
  border-color: #a4e3f1;
  color: #1EA7C5;
}

[data-theme-version="dark"] .pagination-info .page-item .page-link {
  background: rgba(30, 167, 197, 0.1);
  border-color: transparent;
  color: #1EA7C5;
}

.pagination-info .page-item:hover .page-link,
.pagination-info .page-item.active .page-link {
  background: #1EA7C5;
  border-color: #1EA7C5;
  box-shadow: 0 10px 20px 0px rgba(30, 167, 197, 0.2);
}

.pagination-warning .page-item .page-link {
  background: #fff5dd;
  border-color: #fff5dd;
  color: #FFBC11;
}

[data-theme-version="dark"] .pagination-warning .page-item .page-link {
  background: rgba(255, 188, 17, 0.1);
  border-color: transparent;
  color: #FFBC11;
}

.pagination-warning .page-item:hover .page-link,
.pagination-warning .page-item.active .page-link {
  background: #FFBC11;
  border-color: #FFBC11;
  box-shadow: 0 10px 20px 0px rgba(255, 188, 17, 0.2);
}

.pagination-gutter .page-item {
  margin-right: 7px;
}

.pagination-gutter .page-item .page-link {
  border-radius: 0.625rem !important;
}

.pagination-circle .page-item {
  margin-right: 7px;
}

.pagination-circle .page-item .page-link,
.pagination-circle .page-item.page-indicator .page-link {
  width: 40px;
  height: 40px;
  padding: 0;
  line-height: 40px;
  border-radius: 50% !important;
  padding: 0;
}

.pagination.pagination-md .page-item .page-link {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}

.pagination.pagination-sm .page-item.page-indicator .page-link {
  font-size: 12px;
}

.pagination.pagination-sm .page-item .page-link {
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}

.pagination.pagination-xs .page-item.page-indicator .page-link {
  font-size: 10px;
}

.pagination.pagination-xs .page-item .page-link {
  padding: 0;
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
}

.popover {
  border: 0.125rem solid #A02CFA;
  min-width: 13.125rem;
  box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.1);
}

[data-theme-version="dark"] .popover {
  background-color: #222327;
}

.popover-header {
  background: #A02CFA;
  color: #fff;
  font-weight: 300;
}

.popover-header::before {
  border-bottom: 0 !important;
}

.popover-body {
  font-size: 0.75rem;
}

[data-theme-version="dark"] .popover .popover-header {
  border-color: #111217;
}

@media only screen and (max-width: 767px) {
  .popover {
    z-index: 1;
  }
}

.bootstrap-popover-wrapper .bootstrap-popover:not(:last-child) {
  margin-right: 0.5rem;
}

.bootstrap-popover-wrapper .bootstrap-popover {
  margin-bottom: .5rem;
}

.bootstrap-popover-wrapper .bootstrap-popover button:hover,
.bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #99c990;
  color: #fff;
  box-shadow: none;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  border-bottom-color: #A02CFA;
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  border-right-color: #A02CFA;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  border-top-color: #A02CFA;
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  border-left-color: #A02CFA;
}

.note-popover {
  display: none;
}

.toltip-popover .tooltip-inner {
  background: #ffffff;
  padding: 0;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  border: 0.125rem solid #A02CFA;
}

.toltip-popover .tooltip-inner strong {
  color: #777777;
}

.popover-header {
  background: #A02CFA;
  border-top-left-radius: 0.1rem;
  border-top-right-radius: 0.1rem;
  padding: 10px;
}

.progress-bar {
  background-color: #99c990;
}

.progress-bar-primary {
  background-color: #99c990;
}

.progress-bar-success {
  background-color: #2BC155;
}

.progress-bar-info {
  background-color: #1EA7C5;
}

.progress-bar-danger {
  background-color: #F94687;
}

.progress-bar-warning {
  background-color: #FFBC11;
}

.progress-bar-pink {
  background-color: #e83e8c;
}

.progress {
  height: 6px;
  overflow: hidden;
  border-radius: 0.625rem;
}

[data-theme-version="dark"] .progress {
  background-color: #222327;
}

.progress-bar {
  border-radius: 4px;
}

.progress-vertical {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative;
}

@media (max-width: 767.98px) {
  .progress-vertical {
    margin-right: 1rem;
  }
}

.progress-vertical-bottom {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative;
  transform: rotate(180deg);
}

@media (max-width: 767.98px) {
  .progress-vertical-bottom {
    margin-right: 1rem;
  }
}

.progress-animated {
  animation-duration: 5s;
  animation-name: myanimation;
  transition: all 5s ease 0s;
}

@keyframes myanimation {
  0% {
    width: 0;
  }
}

@keyframes myanimation {
  0% {
    width: 0;
  }
}

.ribbon {
  position: absolute;
  z-index: 1;
  text-transform: uppercase;
}

.ribbon__one {
  top: 15px;
  left: -11px;
  min-height: 20px;
  min-width: 52px;
  text-align: center;
  padding: 3px 10px;
  background: #3AB54B;
  color: #fff;
  font-size: 0.625rem;
}

.ribbon__one::after {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid #239132;
  border-left: 11px solid transparent;
  left: 0;
  content: "";
  bottom: -10px;
}

.ribbon__two {
  width: 50px;
  height: 50px;
  display: inline-block;
  background: #FFBC11;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  right: 15px;
  top: 15px;
  border-radius: 3px;
}

.ribbon__three {
  left: -1.875rem;
  top: 0.875rem;
  width: 6.875rem;
  height: 1.5625rem;
  background-color: #F94687;
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
  transform: rotate(-45deg);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.ribbon__four {
  left: 8px;
  top: -8px;
  width: 110px;
  height: 50px;
  background-color: #99c990;
  z-index: auto;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ribbon__four:after {
  right: -5px;
}

.ribbon__four:before {
  left: -5px;
}

.ribbon__four:after,
.ribbon__four:before {
  z-index: -1;
  background-color: #5574e3;
  top: 3px;
  transform: rotate(45deg);
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
}

.ribbon__five {
  left: -1.875rem;
  top: 0.625rem;
  width: 6.875rem;
  height: 1.875rem;
  background-color: #99c990;
  transform: rotate(-45deg);
  font-size: 0.75rem;
  color: #fff;
  padding-bottom: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.ribbon__five::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50px 50px 50px;
  border-color: transparent transparent #99c990 transparent;
  left: 0.25rem;
  top: -2.8125rem;
}

.ribbon__six {
  left: 0;
  top: 1.125rem;
  width: 6.875rem;
  height: 2.375rem;
  background-color: #99c990;
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
  display: flex;
  font-size: 1.25rem;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.ribbon-curve {
  position: absolute;
  top: 0;
  right: -6px;
  width: 10px;
  height: 11px;
  border-top: 11px solid #3AB54B;
  border-bottom: 10px solid #3AB54B;
  border-right: 5px solid transparent;
}

.jsgrid-grid-body::-webkit-scrollbar,
.dataTables_scrollBody::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar {
  background-color: #F5F5F5;
  width: 8px;
  height: 8px;
}

.jsgrid-grid-body::-webkit-scrollbar-track,
.dataTables_scrollBody::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.jsgrid-grid-body::-webkit-scrollbar-thumb,
.dataTables_scrollBody::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #7e7e7e;
}

.multi-steps>li.is-active:before,
.multi-steps>li.is-active~li:before {
  content: counter(stepNum);
  font-family: inherit;
  font-weight: 400;
}

.multi-steps>li.is-active:after,
.multi-steps>li.is-active~li:after {
  background-color: #E6E6E6;
}

.multi-steps {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.multi-steps>li {
  counter-increment: stepNum;
  text-align: center;
  display: table-cell;
  position: relative;
  color: #99c990;
  font-size: 0.75rem;
}

.multi-steps>li:before {
  content: '\2713';
  display: block;
  margin: 0 auto 4px;
  background-color: #fff;
  width: 25px;
  height: 25px;
  line-height: 22px;
  text-align: center;
  font-weight: bold;
  position: relative;
  z-index: 1;
  width: 25px;
  height: 25px;
  line-height: 21px;
  border-width: 2px;
  border-style: solid;
  border-color: #99c990;
  border-radius: 5px;
}

.multi-steps>li:after {
  content: '';
  height: 2px;
  width: 100%;
  background-color: #99c990;
  position: absolute;
  top: 12px;
  left: 50%;
  top: 12px;
}

[direction="rtl"] .multi-steps>li:after {
  left: auto;
  right: 50%;
}

.multi-steps>li:last-child:after {
  display: none;
}

.multi-steps>li.is-active:before {
  background-color: #fff;
  border-color: #99c990;
}

.multi-steps>li.is-active~li {
  color: #7e7e7e;
}

.multi-steps>li.is-active~li:before {
  background-color: #E6E6E6;
  border-color: #E6E6E6;
}

.nav-pills .nav-link {
  border-radius: 0.625rem;
  padding: 0.75rem 1.25rem;
}

.default-tab .nav-link {
  background: transparent;
  border-radius: 0px;
  font-weight: 500;
}

.default-tab .nav-link i {
  display: inline-block;
  transform: scale(1.5);
  color: #99c990;
}

.default-tab .nav-link:focus,
.default-tab .nav-link:hover,
.default-tab .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff #ebeef6;
  border-radius: 0.625rem 0.625rem 0 0;
  color: #99c990;
}

[data-theme-version="dark"] .default-tab .nav-link:focus,
[data-theme-version="dark"] .default-tab .nav-link:hover,
[data-theme-version="dark"] .default-tab .nav-link.active {
  background-color: rgba(30, 63, 180, 0.2);
  border-color: transparent transparent #333a54 transparent;
}

.custom-tab-1 .nav-link {
  background: transparent;
  border-radius: 0px;
  font-weight: 500;
  border-bottom: 3px solid transparent;
  border-width: 0 0px 3px 0;
}

.custom-tab-1 .nav-link i {
  display: inline-block;
  transform: scale(1.5);
  color: #99c990;
}

.custom-tab-1 .nav-link:focus,
.custom-tab-1 .nav-link:hover,
.custom-tab-1 .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #99c990;
  border-radius: 0;
  color: #99c990;
  border-width: 0 0px 3px 0;
}

[data-theme-version="dark"] .custom-tab-1 .nav-link:focus,
[data-theme-version="dark"] .custom-tab-1 .nav-link:hover,
[data-theme-version="dark"] .custom-tab-1 .nav-link.active {
  background-color: rgba(30, 63, 180, 0.2);
}

.nav-pills.light .nav-link.active,
.nav-pills.light .show>.nav-link {
  background: #d8e0f9;
  color: #99c990;
  box-shadow: none;
}

[data-theme-version="dark"] .nav-pills.light .nav-link.active,
[data-theme-version="dark"] .nav-pills.light .show>.nav-link {
  background: rgba(30, 63, 180, 0.2);
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  box-shadow: 0 0 20px 0 rgba(30, 63, 180, 0.2);
}

.tooltip-wrapper button:not(:last-child) {
  margin-right: 8px;
}

.tooltip-wrapper button:hover {
  background: #99c990;
  color: #fff;
}

.tooltip-wrapper button {
  margin-bottom: .5rem;
}

.tooltip-wrapper button:focus {
  box-shadow: none;
}

.tooltip-inner {
  border-radius: 0;
  background: #333333;
  font-size: 12px;
  font-weight: 300;
  padding: 0.35rem 0.7rem;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #333333;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #333333;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #333333;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #333333;
}

.widget-timeline .timeline {
  list-style: none;
  position: relative;
}

.widget-timeline .timeline:before {
  top: 20px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  left: 9px;
  margin-right: -1.5px;
  background: #F4F5F9;
}

[data-theme-version="dark"] .widget-timeline .timeline:before {
  background: #333a54;
}

[direction="rtl"] .widget-timeline .timeline:before {
  left: auto;
  right: 9px;
  margin-right: auto;
  margin-left: -1.5px;
}

.widget-timeline .timeline>li {
  margin-bottom: 15px;
  position: relative;
}

.widget-timeline .timeline>li:before {
  content: " ";
  display: table;
}

.widget-timeline .timeline>li:after {
  content: " ";
  display: table;
  clear: both;
}

.widget-timeline .timeline>li>.timeline-panel {
  border-radius: 0.625rem;
  padding: 15px 20px;
  position: relative;
  display: block;
  margin-left: 40px;
  border-width: 1px;
  border-style: solid;
}

.widget-timeline .timeline>li>.timeline-panel span {
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
  opacity: 0.8;
  letter-spacing: 1px;
}

.widget-timeline .timeline>li>.timeline-panel p {
  font-size: 14px;
}

.widget-timeline .timeline>li>.timeline-panel:after {
  content: "";
  width: 10px;
  height: 10px;
  background: inherit;
  border-color: inherit;
  border-style: solid;
  border-width: 0px 0px 1px 1px;
  display: block;
  position: absolute;
  left: -5px;
  transform: rotate(45deg);
  top: 15px;
}

.widget-timeline .timeline>li>.timeline-badge {
  border-radius: 50%;
  height: 22px;
  left: 0;
  position: absolute;
  top: 10px;
  width: 22px;
  border-width: 2px;
  border-style: solid;
  background: #fff;
  padding: 4px;
}

[data-theme-version="dark"] .widget-timeline .timeline>li>.timeline-badge {
  background-color: #111217;
}

.widget-timeline .timeline>li>.timeline-badge:after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: block;
}

[direction="rtl"] .widget-timeline .timeline>li>.timeline-badge {
  left: auto;
  right: 19px;
}

.widget-timeline .timeline-body>p {
  font-size: 12px;
}

.widget-timeline .timeline-badge.primary {
  border-color: #d8e0f9;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.primary {
  border-color: rgba(30, 63, 180, 0.2);
}

.widget-timeline .timeline-badge.primary:after {
  background-color: #99c990 !important;
  box-shadow: 0 5px 10px 0 rgba(30, 63, 180, 0.2);
}

.widget-timeline .timeline-badge.primary+.timeline-panel {
  background: #d8e0f9;
  border-color: #d8e0f9;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.primary+.timeline-panel {
  border-color: transparent;
  background-color: rgba(30, 63, 180, 0.2);
}

.widget-timeline .timeline-badge.success {
  border-color: #e3f9e9;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.success {
  border-color: rgba(43, 193, 85, 0.1);
}

.widget-timeline .timeline-badge.success:after {
  background-color: #2BC155 !important;
  box-shadow: 0 5px 10px 0 rgba(43, 193, 85, 0.2);
}

.widget-timeline .timeline-badge.success+.timeline-panel {
  background: #e3f9e9;
  border-color: #e3f9e9;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.success+.timeline-panel {
  background-color: rgba(43, 193, 85, 0.1);
  border-color: transparent;
}

.widget-timeline .timeline-badge.warning {
  border-color: #fff5dd;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.warning {
  border-color: rgba(255, 188, 17, 0.1);
}

.widget-timeline .timeline-badge.warning:after {
  background-color: #FFBC11 !important;
  box-shadow: 0 5px 10px 0 rgba(255, 188, 17, 0.2);
}

.widget-timeline .timeline-badge.warning+.timeline-panel {
  background: #fff5dd;
  border-color: #fff5dd;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.warning+.timeline-panel {
  background-color: rgba(255, 188, 17, 0.1);
  border-color: transparent;
}

.widget-timeline .timeline-badge.danger {
  border-color: #fff3f7;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.danger {
  border-color: rgba(249, 70, 135, 0.15);
}

.widget-timeline .timeline-badge.danger:after {
  background-color: #F94687 !important;
  box-shadow: 0 5px 10px 0 rgba(249, 70, 135, 0.2);
}

.widget-timeline .timeline-badge.danger+.timeline-panel {
  background: #fff3f7;
  border-color: #fff3f7;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.danger+.timeline-panel {
  background-color: rgba(249, 70, 135, 0.15);
  border-color: transparent;
}

.widget-timeline .timeline-badge.info {
  border-color: #a4e3f1;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.info {
  border-color: rgba(30, 167, 197, 0.1);
}

.widget-timeline .timeline-badge.info:after {
  background-color: #1EA7C5 !important;
  box-shadow: 0 5px 10px 0 rgba(30, 167, 197, 0.2);
}

.widget-timeline .timeline-badge.info+.timeline-panel {
  background: #a4e3f1;
  border-color: #a4e3f1;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.info+.timeline-panel {
  background-color: rgba(30, 167, 197, 0.1);
  border-color: transparent;
}

.widget-timeline .timeline-badge.dark {
  border-color: #f1f1f1;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.dark {
  border-color: rgba(177, 177, 177, 0.35);
}

.widget-timeline .timeline-badge.dark:after {
  background-color: #B1B1B1 !important;
  box-shadow: 0 5px 10px 0 rgba(177, 177, 177, 0.2);
}

.widget-timeline .timeline-badge.dark+.timeline-panel {
  background: #f1f1f1;
  border-color: #f1f1f1;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.dark+.timeline-panel {
  background-color: rgba(177, 177, 177, 0.35);
  border-color: transparent;
}

.widget-timeline.style-1 .timeline-panel {
  background: transparent;
}

.widget-timeline.style-1 .timeline .timeline-badge.timeline-badge+.timeline-panel {
  background: transparent !important;
  border-style: solid;
  border-width: 0 0 0 5px;
  border-radius: 0;
  padding: 5px 10px 5px 15px;
}

.widget-timeline.style-1 .timeline .timeline-badge.timeline-badge+.timeline-panel:after {
  border: 0;
  left: -9px;
  width: 7px;
  height: 7px;
}

.widget-timeline.style-1 .timeline .timeline-badge.primary+.timeline-panel {
  border-color: #99c990;
}

.widget-timeline.style-1 .timeline .timeline-badge.primary+.timeline-panel:after {
  background: #99c990;
}

.widget-timeline.style-1 .timeline .timeline-badge.success+.timeline-panel {
  border-color: #2BC155;
}

.widget-timeline.style-1 .timeline .timeline-badge.success+.timeline-panel:after {
  background: #2BC155;
}

.widget-timeline.style-1 .timeline .timeline-badge.warning+.timeline-panel {
  border-color: #FFBC11;
}

.widget-timeline.style-1 .timeline .timeline-badge.warning+.timeline-panel:after {
  background: #FFBC11;
}

.widget-timeline.style-1 .timeline .timeline-badge.danger+.timeline-panel {
  border-color: #F94687;
}

.widget-timeline.style-1 .timeline .timeline-badge.danger+.timeline-panel:after {
  background: #F94687;
}

.widget-timeline.style-1 .timeline .timeline-badge.info+.timeline-panel {
  border-color: #1EA7C5;
}

.widget-timeline.style-1 .timeline .timeline-badge.info+.timeline-panel:after {
  background: #1EA7C5;
}

.widget-timeline.style-1 .timeline .timeline-badge.dark+.timeline-panel {
  border-color: #B1B1B1;
}

.widget-timeline.style-1 .timeline .timeline-badge.dark+.timeline-panel:after {
  background: #B1B1B1;
}

#chart_widget_4 {
  height: 255px !important;
}

#chart_widget_5 .ct-series-a .ct-line,
#chart_widget_5 .ct-series-a .ct-point {
  stroke: #46ffc8;
}

#chart_widget_5 .ct-line {
  stroke-width: 1px;
}

#chart_widget_5 .ct-point {
  stroke-width: 2px;
}

#chart_widget_5 .ct-series-a .ct-area {
  fill: #43d66c;
}

#chart_widget_5 .ct-area {
  fill-opacity: 1;
}

#chart_widget_6 .ct-series-a .ct-line,
#chart_widget_6 .ct-series-a .ct-point {
  stroke: #1EA7C5;
}

#chart_widget_6 .ct-line {
  stroke-width: 2px;
}

#chart_widget_6 .ct-point {
  stroke-width: 5px;
}

#chart_widget_6 .ct-series-a .ct-area {
  fill: #1EA7C5;
}

#chart_widget_6 .ct-area {
  fill-opacity: .5;
}

#chart_widget_8 {
  height: 255px;
}

#chart_widget_8 .ct-series-a .ct-line,
#chart_widget_8 .ct-series-a .ct-point {
  stroke: #1EA7C5;
}

#chart_widget_8 .ct-line {
  stroke-width: 2px;
}

#chart_widget_8 .ct-point {
  stroke-width: 5px;
}

#chart_widget_8 .ct-series-a .ct-area {
  fill: #1EA7C5;
}

#chart_widget_8 .ct-area {
  fill-opacity: .5;
}

#chart_widget_9,
#chart_widget_10 {
  height: 250px !important;
}

#chart_widget_11 .ct-slice-donut,
#chart_widget_12 .ct-slice-donut,
#chart_widget_13 .ct-slice-donut {
  stroke-width: 25px !important;
}

#chart_widget_11 {
  height: 270px !important;
}

#chart_widget_17 {
  height: 150px !important;
}

.chart_widget_tab_one .nav-link {
  border: 1px solid #ddd;
}

.chart_widget_tab_one .nav-link.active {
  background-color: #99c990;
  border: 1px solid #99c990;
  color: #fff;
}

.chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #99c990;
}

.chart_widget_tab_one .nav-link:hover {
  border: 1px solid #ddd;
}

[data-theme-version="dark"] .ccc-widget>div {
  background: #111217 !important;
  border-color: #333a54 !important;
}

.social-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  text-align: center;
  background: #fff;
  margin-bottom: 0.5rem;
  font-size: 20px;
}

.social-icon i {
  color: #fff;
}

.social-icon.youtube {
  background: #FF0000;
}

.social-icon.facebook {
  background: #3b5998;
}

.social-icon.twitter {
  background: #1da1f2;
}

.social-icon2 a {
  border: 0.1rem solid #99c990;
  display: block;
  padding: 1rem;
  margin-top: 1.5rem;
  margin-bottom: .5rem;
  border-radius: .3rem;
  font-size: 1.6rem;
}

.social-icon2 i {
  font-size: 12px;
  color: #99c990;
}

.social-icon3 ul li {
  display: inline-block;
}

.social-icon3 ul li a {
  display: block;
}

.social-icon3 ul li a i {
  font-size: 1.4rem;
  padding: 1rem .7rem;
  color: #464a53;
}

.social-icon3 ul li a:hover i {
  color: #99c990;
}

.social-icons-muted ul li a i {
  color: #89879f;
}

.social-links a {
  padding: .5rem;
}

.widget-stat .media {
  padding: 0px 0;
  align-items: center;
}

.widget-stat .media>span {
  height: 85px;
  width: 85px;
  border-radius: 100%;
  padding: 10px 12px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #464a53;
}

@media only screen and (max-width: 1400px) {
  .widget-stat .media>span {
    height: 70px;
    width: 70px;
    min-width: 70px;
  }
}

.widget-stat .media .media-body p {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
}

@media only screen and (max-width: 1400px) {
  .widget-stat .media .media-body p {
    font-size: 14px;
  }
}

[data-theme-version="dark"] .widget-stat .media .media-body p {
  color: #c4c9d5;
}

.widget-stat .media .media-body small,
.widget-stat .media .media-body .small,
.widget-stat .media .media-body .small {
  font-size: 75%;
}

.widget-stat .media .media-body h3,
.widget-stat .media .media-body .h3 {
  font-size: 30px;
  font-weight: 600;
  margin: 0;
  line-height: 1.2;
}

.widget-stat .media .media-body h4,
.widget-stat .media .media-body .h4 {
  font-size: 24px;
  display: inline-block;
  vertical-align: middle;
}

.widget-stat .media .media-body span {
  margin-left: 5px;
}

.widget-stat[class*="bg-"] .media>span {
  background-color: rgba(255, 255, 255, 0.25);
  color: #fff;
}

.widget-stat[class*="bg-"] .progress {
  background-color: rgba(255, 255, 255, 0.25) !important;
}

[direction="rtl"] .widget-stat .media .media-body span {
  margin-left: 0;
  margin-right: 10px;
}

.dez-widget-1 .card {
  background: #FFE7DB;
}

.dez-widget-1 .card .card-body p {
  color: #f87533;
}

.dez-widget-1 .icon {
  float: right;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 28px;
}

.bgl-primary {
  background: #d8e0f9;
  border-color: #d8e0f9;
}

[data-theme-version="dark"] .bgl-primary {
  background-color: rgba(30, 63, 180, 0.2);
  border-color: rgba(30, 63, 180, 0.2);
}

.bgl-secondary {
  background: #f1dffe;
  border-color: #f1dffe;
}

[data-theme-version="dark"] .bgl-secondary {
  background-color: rgba(160, 44, 250, 0.5);
  border-color: rgba(160, 44, 250, 0.5);
}

.bgl-success {
  background: #e3f9e9;
  border-color: #e3f9e9;
}

[data-theme-version="dark"] .bgl-success {
  background-color: rgba(43, 193, 85, 0.1);
  border-color: rgba(43, 193, 85, 0.1);
}

.bgl-warning {
  background: #fff5dd;
  border-color: #fff5dd;
}

[data-theme-version="dark"] .bgl-warning {
  background-color: rgba(255, 188, 17, 0.1);
  border-color: rgba(255, 188, 17, 0.1);
}

.bgl-danger {
  background: #fff3f7;
  border-color: #fff3f7;
}

[data-theme-version="dark"] .bgl-danger {
  background-color: rgba(249, 70, 135, 0.15);
  border-color: rgba(249, 70, 135, 0.15);
}

.bgl-info {
  background: #a4e3f1;
  border-color: #a4e3f1;
}

[data-theme-version="dark"] .bgl-info {
  background-color: rgba(30, 167, 197, 0.1);
  border-color: rgba(30, 167, 197, 0.1);
}

.bg-primary-light {
  background: rgba(216, 224, 249, 0.5);
}

[data-theme-version="dark"] .bg-primary-light {
  background-color: rgba(30, 63, 180, 0.05);
}

.bg-secondary-light {
  background: rgba(241, 223, 254, 0.5);
}

[data-theme-version="dark"] .bg-secondary-light {
  background-color: rgba(160, 44, 250, 0.05);
}

.bg-success-light {
  background: rgba(227, 249, 233, 0.5);
}

[data-theme-version="dark"] .bg-success-light {
  background-color: rgba(43, 193, 85, 0.05);
}

.bg-warning-light {
  background: rgba(255, 245, 221, 0.5);
}

[data-theme-version="dark"] .bg-warning-light {
  background-color: rgba(255, 188, 17, 0.05);
}

.bg-danger-light {
  background: rgba(255, 243, 247, 0.5);
}

[data-theme-version="dark"] .bg-danger-light {
  background-color: rgba(249, 70, 135, 0.05);
}

.bg-info-light {
  background: rgba(164, 227, 241, 0.5);
}

[data-theme-version="dark"] .bg-info-light {
  background-color: rgba(30, 167, 197, 0.05);
}

.bgl-dark {
  background: #f1f1f1;
  border-color: #f1f1f1;
}

.bgl-light {
  background: #F4F5F9;
  border-color: #F4F5F9;
}

.overlay-box {
  position: relative;
  z-index: 1;
}

.overlay-box:after {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  opacity: 0.85;
  background: #99c990;
  z-index: -1;
}

.rating-bar {
  font-size: 13px;
}

.tdl-holder {
  margin: 0 auto;
}

.tdl-holder ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tdl-holder li {
  background-color: #fff;
  border-bottom: 1px solid #E6E6E6;
  list-style: none none;
  margin: 0;
  padding: 0;
}

.tdl-holder li span {
  margin-left: 35px;
  font-size: 1rem;
  vertical-align: middle;
  transition: all 0.2s linear;
}

[direction="rtl"] .tdl-holder li span {
  margin-left: auto;
  margin-right: 35px;
}

.tdl-holder label {
  cursor: pointer;
  display: block;
  line-height: 50px;
  padding-left: 1.5rem;
  position: relative;
  margin: 0 !important;
}

[direction="rtl"] .tdl-holder label {
  padding-left: 0;
  padding-right: 1.5rem;
}

.tdl-holder label:hover {
  background-color: #eef1f6;
  color: #B1B1B1;
}

.tdl-holder label:hover a {
  color: #F94687;
}

.tdl-holder label a {
  color: #fff;
  display: inline-block;
  line-height: normal;
  height: 100%;
  text-align: center;
  text-decoration: none;
  width: 50px;
  -webkit-transition: all .2s linear;
  -moz-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all 0.2s linear;
  padding: 18px 0px;
  font-size: 18px;
  position: absolute;
  right: 0;
}

[direction="rtl"] .tdl-holder label a {
  right: auto;
  left: 0;
}

.tdl-holder input[type=checkbox] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.tdl-holder input[type=checkbox]+i {
  background-color: #fff;
  border: 1px solid #e7e7e7;
  display: block;
  height: 20px;
  position: absolute;
  top: 15px;
  width: 20px;
  z-index: 1;
  border-radius: 00px;
  font-size: 11px;
  border-radius: 4px;
}

.tdl-holder input[type=checkbox]:checked+i {
  background: #99c990;
  border-color: transparent;
}

.tdl-holder input[type=checkbox]:checked+i::after {
  content: "\f00c";
  font-family: 'fontAwesome';
  display: block;
  left: 2px;
  position: absolute;
  top: -14px;
  z-index: 2;
  color: #fff;
}

.tdl-holder input[type=checkbox]:checked~span {
  text-decoration: line-through;
  position: relative;
}

.tdl-holder input[type=text] {
  background-color: #fff;
  height: 50px;
  margin-top: 29px;
  border-radius: 3px;
}

.tdl-holder input[type=text]::placeholder {
  color: #B1B1B1;
}

.widget-message p {
  font-size: 14px;
  line-height: 1.5;
}

.picker .picker__frame {
  min-width: 450px !important;
  max-width: 450px !important;
}

.picker .picker__frame .picker__box {
  padding: 20px;
  border: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.picker .picker__frame .picker__box .picker__header {
  margin-top: 0;
}

.picker .picker__frame .picker__box .picker__header .picker__year {
  color: #99c990;
  font-style: inherit;
  font-size: 20px;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev,
.picker .picker__frame .picker__box .picker__header .picker__nav--next {
  width: 40px;
  height: 40px;
  padding: 0;
  line-height: 40px;
  border-radius: 2px;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:before,
.picker .picker__frame .picker__box .picker__header .picker__nav--next:before {
  content: none !important;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:after,
.picker .picker__frame .picker__box .picker__header .picker__nav--next:after {
  font-family: fontawesome;
  border: 0;
  margin: 0 auto;
  font-size: 22px;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:hover,
.picker .picker__frame .picker__box .picker__header .picker__nav--next:hover {
  background-color: #8198ea !important;
  color: #99c990 !important;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev {
  left: 0;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:after {
  content: "\f0d9";
}

.picker .picker__frame .picker__box .picker__header .picker__nav--next {
  right: 0;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--next:after {
  content: "\f0da";
}

.picker .picker__frame .picker__box .picker__table .picker__weekday {
  padding: 10px 0;
  font-size: 16px;
}

.picker .picker__frame .picker__box .picker__table .picker__day {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  padding: 0 !important;
  font-size: 16px;
  line-height: 40px;
  margin: auto;
  border: 0 !important;
}

.picker .picker__frame .picker__box .picker__table .picker__day.picker__day--today:before {
  content: none !important;
}

.picker .picker__frame .picker__box .picker__table .picker__day.picker__day--highlighted {
  border: 0 !important;
  padding: 0;
  background-color: #8198ea !important;
  color: #fff !important;
}

.picker .picker__frame .picker__box .picker__table .picker__day:hover {
  background-color: #8198ea !important;
  color: #fff !important;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--today {
  background-color: #8198ea !important;
  color: #fff !important;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--today:before {
  content: none !important;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--clear,
.picker .picker__frame .picker__box .picker__footer .picker__button--close,
.picker .picker__frame .picker__box .picker__footer .picker__button--today {
  border: 0;
  border-radius: 2px;
  font-size: 16px;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--clear:hover,
.picker .picker__frame .picker__box .picker__footer .picker__button--close:hover,
.picker .picker__frame .picker__box .picker__footer .picker__button--today:hover {
  background-color: #8198ea !important;
  color: #fff !important;
}

@media only screen and (max-width: 575px) {
  .picker .picker__frame {
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .picker .picker__frame .picker__box {
    padding: 15px;
    margin: 0 10px;
  }
}

.card-list {
  overflow: unset;
  height: calc(100% - 50px);
}

.card-list .card-header {
  padding-top: 0;
  padding-bottom: 0;
}

.card-list .card-header .photo {
  overflow: hidden;
  border-radius: 5px;
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .card-list {
    height: calc(100% - 40px);
  }
}

.card-list.list-left {
  margin-top: 15px;
  margin-left: 15px;
}

.card-list.list-left .card-header {
  padding-left: 0;
}

.card-list.list-left .card-header .photo {
  margin: -15px 15px 0 -15px;
}

.card-list.list-right {
  margin-top: 15px;
  margin-right: 15px;
  text-align: right;
}

.card-list.list-right .card-header {
  padding-right: 0;
}

.card-list.list-right .card-header .photo {
  margin: -15px -15px 0 15px;
}

.card-list.list-right .card-header .photo img {
  width: 100%;
}

.card-list.list-center {
  margin-top: 15px;
}

.card-list.list-center .card-header .photo {
  margin: -15px 0 0 0;
}

.card-list .photo img {
  width: 100%;
}

.card-profile .profile-photo {
  margin-top: -20px;
}

.widget-media .timeline .timeline-panel {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.widget-media .timeline .timeline-panel .media {
  width: 50px;
  height: 50px;
  background: #eee;
  border-radius: 0.625rem;
  overflow: hidden;
  font-size: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  align-self: start;
}

.widget-media .timeline .timeline-panel .media-primary {
  background: #d8e0f9;
  color: #99c990;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-primary {
  background-color: rgba(30, 63, 180, 0.2);
}

.widget-media .timeline .timeline-panel .media-info {
  background: #a4e3f1;
  color: #1EA7C5;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-info {
  background-color: rgba(30, 167, 197, 0.1);
}

.widget-media .timeline .timeline-panel .media-warning {
  background: #fff5dd;
  color: #FFBC11;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-warning {
  background-color: rgba(255, 188, 17, 0.1);
}

.widget-media .timeline .timeline-panel .media-danger {
  background: #fff3f7;
  color: #F94687;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-danger {
  background-color: rgba(249, 70, 135, 0.15);
}

.widget-media .timeline .timeline-panel .media-success {
  background: #e3f9e9;
  color: #2BC155;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-success {
  background-color: rgba(43, 193, 85, 0.1);
}

.widget-media .timeline .timeline-panel .media-body p {
  font-size: 14px;
  line-height: 1.5;
}

.widget-media .timeline .timeline-panel .dropdown {
  align-self: self-end;
  margin-top: 5px;
}

.widget-media .timeline li:last-child .timeline-panel {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.card[class*="bg-"] .timeline .timeline-panel {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

@media only screen and (max-width: 1199px) {
  .table {
    font-size: 0.875rem !important;
  }
}

.table strong {
  color: #B1B1B1;
}

.table td,
.table th {
  border-color: #E6E6E6;
  padding: 12px 20px;
}

[data-theme-version="dark"] .table td,
[data-theme-version="dark"] .table th {
  border-color: #333a54;
}

.table.shadow-hover tbody tr:hover {
  background-color: #fff;
}

[data-theme-version="dark"] .table.shadow-hover tbody tr:hover {
  background-color: #222327;
}

.table.bg-secondary-hover th,
.table.bg-secondary-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-secondary-hover tr:hover {
  background: #A02CFA;
}

.table.bg-secondary-hover tr:hover td,
.table.bg-secondary-hover tr:hover th {
  color: #fff !important;
}

.table.bg-info-hover th,
.table.bg-info-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-info-hover tr:hover {
  background: #1EA7C5;
}

.table.bg-info-hover tr:hover td,
.table.bg-info-hover tr:hover th {
  color: #fff !important;
}

.table.bg-warning-hover th,
.table.bg-warning-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-warning-hover tr:hover {
  background: #FFBC11;
}

.table.bg-warning-hover tr:hover td,
.table.bg-warning-hover tr:hover th {
  color: #fff !important;
}

.table thead th {
  border-bottom-width: 1px;
  font-weight: 600;
  color: #000;
  padding: 20px 20px;
  letter-spacing: 0.5px;
  border-color: #E6E6E6;
}

[data-theme-version="dark"] .table thead th {
  border-color: #333a54;
}

.table tbody tr td {
  vertical-align: middle;
  border-color: #E6E6E6;
}

[data-theme-version="dark"] .table tbody tr td {
  border-color: #333a54;
}

.table:not(.table-bordered) thead th {
  border-top: none;
}

.table .thead-primary th {
  background-color: #99c990;
  color: #fff;
}

.table .thead-info th {
  background-color: #1EA7C5;
  color: #fff;
}

.table .thead-warning th {
  background-color: #FFBC11;
  color: #fff;
}

.table .thead-danger th {
  background-color: #F94687;
  color: #fff;
}

.table .thead-success th {
  background-color: #2BC155;
  color: #fff;
}

.table.primary-table-bordered {
  border: 1px solid #E6E6E6;
}

[data-theme-version="dark"] .table.primary-table-bordered {
  border-color: #333a54;
}

.table.primary-table-bg-hover thead th {
  background-color: #1a379e;
  color: #fff;
  border-bottom: none;
}

.table.primary-table-bg-hover tbody tr {
  background-color: #99c990;
  color: #fff;
  transition: all .1s ease;
}

.table.primary-table-bg-hover tbody tr:hover {
  background-color: #2247ca;
}

.table.primary-table-bg-hover tbody tr td,
.table.primary-table-bg-hover tbody tr th {
  background-color: transparent;
}

.table.primary-table-bg-hover tbody tr:not(:last-child) td,
.table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 1px solid #1a379e;
}

.table-responsive-tiny {
  min-width: 18.75rem;
}

.table-responsive-sm {
  min-width: 30rem !important;
}

.table-responsive-md {
  min-width: 36rem !important;
}

.table-responsive-lg {
  min-width: 60.9375rem !important;
}

.table-responsive-xl {
  min-width: 70.9375rem !important;
}

.table-primary,
.table-primary>th,
.table-primary>td {
  background-color: #d8e0f9;
  color: #99c990;
}

[data-theme-version="dark"] .table-primary,
[data-theme-version="dark"] .table-primary>th,
[data-theme-version="dark"] .table-primary>td {
  background-color: rgba(30, 63, 180, 0.2);
}

.table-success,
.table-success>th,
.table-success>td {
  background-color: #e3f9e9;
  color: #2BC155;
}

[data-theme-version="dark"] .table-success,
[data-theme-version="dark"] .table-success>th,
[data-theme-version="dark"] .table-success>td {
  background-color: rgba(43, 193, 85, 0.1);
}

.table-info,
.table-info>th,
.table-info>td {
  background-color: #a4e3f1;
  color: #1EA7C5;
}

[data-theme-version="dark"] .table-info,
[data-theme-version="dark"] .table-info>th,
[data-theme-version="dark"] .table-info>td {
  background-color: rgba(30, 167, 197, 0.1);
}

.table-warning,
.table-warning>th,
.table-warning>td {
  background-color: #fff5dd;
  color: #FFBC11;
}

[data-theme-version="dark"] .table-warning,
[data-theme-version="dark"] .table-warning>th,
[data-theme-version="dark"] .table-warning>td {
  background-color: rgba(255, 188, 17, 0.1);
}

.table-danger,
.table-danger>th,
.table-danger>td {
  background-color: #fff3f7;
  color: #F94687;
}

[data-theme-version="dark"] .table-danger,
[data-theme-version="dark"] .table-danger>th,
[data-theme-version="dark"] .table-danger>td {
  background-color: rgba(249, 70, 135, 0.15);
}

.table-active,
.table-active>th,
.table-active>td {
  background-color: #f2f4fa;
}

[data-theme-version="dark"] .table-active,
[data-theme-version="dark"] .table-active>th,
[data-theme-version="dark"] .table-active>td {
  background-color: #222327;
}

.card-table th:first-child,
.card-table td:first-child {
  padding-left: 30px;
}

@media only screen and (max-width: 575px) {

  .card-table th:first-child,
  .card-table td:first-child {
    padding-left: 15px;
  }
}

.card-table th:last-child,
.card-table td:last-child {
  padding-right: 30px;
}

@media only screen and (max-width: 575px) {

  .card-table th:last-child,
  .card-table td:last-child {
    padding-right: 15px;
  }
}

.bootgrid-header {
  padding: 0 !important;
  margin: 0;
}

@media only screen and (max-width: 575px) {
  .bootgrid-header .actionBar {
    padding: 0;
  }

  .bootgrid-header .search {
    margin: 0 0 10px 0;
  }
}

table#example {
  padding: 0rem 0 2rem 0;
}

table.dataTable {
  color: #000;
  border-radius: 0.625rem;
}

#example2_wrapper .dataTables_scrollBody {
  max-height: 33.25rem !important;
}

#employees,
#custommers {
  padding: .5rem 0 1rem 0;
}

table.dataTable thead th,
table.dataTable thead td {
  padding: 20px 15px;
  border-bottom: 1px solid #E6E6E6;
}

@media only screen and (max-width: 1400px) {

  table.dataTable thead th,
  table.dataTable thead td {
    padding: 8px 15px;
  }
}

table.dataTable tfoot th,
table.dataTable tfoot td {
  border-top: 0;
}

table.dataTable tbody td {
  background: #fff !important;
}

table.dataTable thead th {
  color: #000;
  white-space: nowrap;
  font-weight: 600;
}

[data-theme-version="dark"] table.dataTable thead th {
  color: #fff;
}

table.dataTable tbody td {
  padding: 15px 10px;
}

@media only screen and (max-width: 1400px) {
  table.dataTable tbody td {
    padding: 8px 10px;
  }
}

table.dataTable tr.selected {
  color: #99c990;
}

table.dataTable tfoot th {
  color: #B1B1B1;
  font-weight: 600;
}

[data-theme-version="dark"] table.dataTable tfoot th {
  color: #fff;
}

.dataTables_wrapper input[type="search"],
.dataTables_wrapper input[type="text"],
.dataTables_wrapper select {
  border: 1px solid #e2e2e2;
  padding: .3rem 0.5rem;
  color: #715d5d;
  border-radius: 5px;
}

[data-theme-version="dark"] .dataTables_wrapper input[type="search"],
[data-theme-version="dark"] .dataTables_wrapper input[type="text"],
[data-theme-version="dark"] .dataTables_wrapper select {
  background: #222327;
  border-color: #333a54;
  color: #fff;
}

.dataTables_wrapper .dataTables_length {
  margin-bottom: 15px;
}

.dataTables_wrapper .dataTables_length .bootstrap-select {
  width: 90px !important;
  margin: 0 5px;
}

table.dataTable.no-footer {
  border-bottom: 0;
}

.min-w850 {
  min-width: 850px;
}

.rounded-lg {
  min-width: 30px;
}

.dataTables_scroll {
  padding: 1rem 0;
}

.dataTables_scrollFoot {
  padding-top: 1rem;
}

@media (max-width: 767.98px) {

  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
    text-align: left;
  }
}

.dataTablesCard {
  background-color: #fff;
  box-shadow: 0px 12px 23px 0px rgba(62, 73, 84, 0.04);
  border-radius: 0;
}

.dataTablesCard tbody tr:hover {
  background: #fff !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.dataTablesCard tbody tr:hover td {
  background: transparent !important;
}

table.dataTable.display tbody tr:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

table.dataTable.display tbody tr:hover td {
  background: transparent !important;
}

.dataTables_wrapper .dataTables_info {
  padding: 25px 0;
}

@media only screen and (max-width: 1400px) {
  .dataTables_wrapper .dataTables_info {
    padding: 8px 0;
  }
}

table.dataTable.display.white-border td {
  border-color: #fff;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
  border-color: #EEEEEE;
}

[data-theme-version="dark"] table.dataTable.row-border tbody th,
[data-theme-version="dark"] table.dataTable.row-border tbody td,
[data-theme-version="dark"] table.dataTable.display tbody th,
[data-theme-version="dark"] table.dataTable.display tbody td {
  border-color: #333a54;
}

table.dataTable thead .sorting {
  background-position: center right 15px;
}

.dataTables_wrapper .dataTables_paginate {
  border-radius: 0.625rem;
  font-size: 14px;
}

@media only screen and (max-width: 1199px) {
  .dataTables_wrapper .dataTables_paginate {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .dataTables_wrapper .dataTables_paginate {
    white-space: nowrap;
  }
}

.dataTables_wrapper .dataTables_paginate span {
  border-radius: 0.625rem;
  display: inline-block;
  background: rgba(30, 63, 180, 0.08);
  border: 0px solid #99c990;
  margin: 0 0;
}

.dataTables_wrapper .dataTables_paginate span a {
  color: #fff;
  background: transparent !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  border: 0 !important;
  padding: 15px 24px;
  background: transparent !important;
  border-radius: 0.625rem;
  color: #99c990 !important;
  font-size: 16px;
  margin: 0;
  display: inline-block;
}

@media only screen and (max-width: 1400px) {
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 8px 15px;
    font-size: 14px;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #fff !important;
  background: #99c990 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous,
.dataTables_wrapper .dataTables_paginate .paginate_button.next {
  background: #99c990 !important;
  color: #fff !important;
  margin: 0 10px;
  border: 0px solid #99c990 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.next:hover {
  background: #99c990 !important;
  color: #fff !important;
}

table.dataTable thead>tr>th.sorting:after,
table.dataTable thead>tr>th.sorting_desc:after,
table.dataTable thead>tr>th.sorting_asc:after {
  content: "";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  background-image: none;
  margin-left: 5px;
  font-size: calc(100% - 2px);
  opacity: 0.5;
}

table.dataTable thead>tr>th.sorting:after {
  content: "\f0dc";
}

table.dataTable thead>tr>th.sorting_asc:after {
  content: "\f0de";
  opacity: 0.5;
}

table.dataTable thead>tr>th.sorting_desc:after {
  content: "\f0dd";
  opacity: 0.5;
}

table.dataTable.display>tbody>tr.odd>.sorting_1,
table.dataTable.display>tbody>tr.even>.sorting_1,
table.dataTable.display>tbody>tr.even>.sorting_1,
table.dataTable.display>tbody>tr.odd>* {
  box-shadow: unset;
}

table.dataTable.hover>tbody>tr:hover>*,
table.dataTable.display>tbody>tr:hover>* {
  box-shadow: unset !important;
}

table.dataTable thead>tr>th.sorting:before,
table.dataTable thead>tr>th.sorting_asc:before,
table.dataTable thead>tr>th.sorting_desc:before,
table.dataTable thead>tr>th.sorting_asc_disabled:before,
table.dataTable thead>tr>th.sorting_desc_disabled:before,
table.dataTable thead>tr>td.sorting:before,
table.dataTable thead>tr>td.sorting_asc:before,
table.dataTable thead>tr>td.sorting_desc:before,
table.dataTable thead>tr>td.sorting_asc_disabled:before,
table.dataTable thead>tr>td.sorting_desc_disabled:before {
  content: "";
}

.fooicon {
  font-size: 1.25rem;
  color: #B1B1B1;
}

.jsgrid-table .jsgrid-header-row>.jsgrid-header-cell {
  color: #B1B1B1;
}

.jsgrid-table>tbody>tr>td {
  padding: 1.2em;
}

.jsgrid-table .jsgrid-insert-row input,
.jsgrid-table .jsgrid-insert-row select,
.jsgrid-table .jsgrid-edit-row input,
.jsgrid-table .jsgrid-edit-row select {
  border: 1px solid #DDDFE1;
}

.jsgrid .jsgrid-button {
  border: 0 !important;
  margin-left: 10px;
}

.svg-icons-ov {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  align-items: center;
  padding: 15px 15px 75px;
  position: relative;
  margin-bottom: 10px;
  border: 1px solid #E6E6E6;
  border-radius: 5px;
  margin-bottom: 30px;
  display: flex;
}

.svg-icons-ov .svg-icons-prev {
  margin-right: 15px;
}

.svg-icons-ov .svg-icons-prev svg {
  width: 25px;
  height: 25px;
}

.svg-icons-ov .svg-icons-prev svg path[fill] {
  fill: #99c990;
}

.svg-icons-ov .svg-icons-prev .feather {
  stroke: #99c990;
}

.svg-icons-ov .svg-icons-prev i {
  font-size: 21px;
  color: #99c990;
}

.svg-icons-ov .svg-icon-popup {
  position: absolute;
  bottom: 15px;
  width: 100%;
  display: flex;
  left: 10px;
}

.svg-icons-ov .svg-icon-popup.style-1 {
  left: 0;
}

.svg-icons-ov .svg-icon-popup.style-1 .btn {
  width: 100%;
}

.svg-icons-ov .svg-icon-popup .btn {
  padding: 8px;
  background: #e6e9f4;
  margin: 0 5px;
  color: #99c990;
  font-size: 14px !important;
  display: inline-block;
  width: 40%;
  min-width: 40%;
  height: 40px;
}

.svg-icons-ov .svg-icon-popup .btn:hover {
  background-color: #99c990;
  color: #fff;
}

.svg-icons-ov:hover {
  border-color: #99c990;
}

.svg-icons-ov.style-1 {
  padding: 15px;
  flex-direction: column;
  cursor: pointer;
}

.svg-icons-ov.style-1 .svg-icons-prev {
  display: inline-block;
  width: 3.125rem;
  height: 3.125rem;
  line-height: 3.263rem;
  text-align: center;
  background-color: #d8e0f9;
  border-radius: 50%;
  margin-bottom: 0.688rem;
  margin-right: 0;
}

.svg-icons-ov.style-1 .svg-classname {
  font-size: 0.713rem;
}

.svg-area .row {
  margin: 0;
}

.svg-area .col-md-2 {
  padding: 0;
}

.svg-area .card-header {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  margin-top: 50px;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.svg-area .card-header:first-child {
  margin-top: 0;
}

.svg-classname {
  font-size: 14px;
}

.error-page .error-text {
  font-size: 150px;
  line-height: 1;
  color: #A02CFA;
}

@media only screen and (max-width: 575px) {
  .error-page .error-text {
    font-size: 80px;
  }
}

.error-page h4,
.error-page .h4 {
  font-size: 40px;
  margin-bottom: 5px;
  color: #A02CFA;
}

@media only screen and (max-width: 575px) {

  .error-page h4,
  .error-page .h4 {
    font-size: 20px;
  }
}

.error-page p {
  font-size: 16px;
}

@media only screen and (max-width: 575px) {
  .error-page p {
    font-size: 14px;
  }
}

.authincation {
  background: #fff;
  /* background: var(--rgba-primary-1); */
  display: flex;
  min-height: 100vh;
  align-items: center;
}

.logo-abbr-me-1 {
  position: relative;
  left: 10%;
  bottom: 50%;
}

brand-title-ms-2 {
  position: relative;
  left: 50%;
  bottom: 10%;
}

.authincation .login-aside {
  background: #fff;
  padding-top: 2rem;
  max-width: 35rem;
  width: 100%;
  z-index: 1;
  position: relative;
  left: 10%;
}

.authincation .login-aside:after {
  content: "";
  clip-path: polygon(0% 100%, 100% 0%, 0% 0%);
  width: 8.75rem;
  height: 100%;
  position: relative;
  right: -8.75rem;
  z-index: -1;
  bottom: 10px;
  background: #a15cff;
  box-shadow: 0.125rem 0rem 1.875rem rgba(0, 0, 0, 0.15);
}

.authincation .login-aside .aside-image {
  min-height: 28.125rem;
  margin: auto 0;
  min-width: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 1400px) {
  .authincation .login-aside {
    max-width: 22.5rem;
  }
}

@media only screen and (max-width: 991px) {
  .authincation .login-aside {
    max-width: 100%;
    padding-top: 0;
  }

  .authincation .login-aside:after {
    content: none;
  }
}

@media only screen and (max-width: 575px) {
  .authincation .login-aside .aside-image {
    min-height: 18.75rem;
  }
}

.authincation-content {
  background: #99c990;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  border-radius: 5px;
}

[data-theme-version="dark"] .authincation-content {
  background: #111217;
  box-shadow: none;
}

.authincation-content.style-1 {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
}

.authincation-content.style-1 .form-control {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
}

.authincation-content.style-1 .user-icon {
  height: 100px;
  background: #99c990;
  width: 100px;
  text-align: center;
  border-radius: 100px;
  line-height: 100px;
  margin-left: auto;
  font-size: 60px;
  text-align: center;
  color: white;
  margin-right: auto;
  margin-top: -100px;
  margin-bottom: 20px;
}

.authincation-content.style-2 {
  background: transparent;
  box-shadow: none;
  max-width: 530px;
  width: 100%;
}

.authincation-content.style-2 .form-control {
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
}

@media only screen and (max-width: 575px) {
  .authincation-content.style-2 .auth-form {
    padding: 30px 0px;
  }
}

.welcome-content {
  background: url("./../images/1.jpg");
  background-size: cover;
  background-position: center;
  height: 100%;
  padding: 75px 50px;
  position: relative;
  z-index: 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.welcome-content::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #99c990;
  opacity: 0.75;
  z-index: -1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.welcome-content .welcome-title {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
}

.welcome-content p {
  color: #fff;
}

.welcome-content .brand-logo a {
  display: inline-block;
  margin-bottom: 20px;
  font-weight: 700;
  color: #fff;
  font-size: 20px;
}

.welcome-content .brand-logo a img {
  width: 100px;
}

.welcome-content .intro-social {
  position: absolute;
  bottom: 75px;
}

.welcome-content .intro-social ul {
  margin-bottom: 0;
}

.welcome-content .intro-social ul li {
  display: inline-block;
}

.welcome-content .intro-social ul li a {
  color: #fff;
  font-size: 14px;
  padding: 0px 7px;
}

.auth-form {
  padding: 50px 50px;
}

@media only screen and (max-width: 575px) {
  .auth-form {
    padding: 30px;
  }
}

.auth-form .btn {
  font-weight: 700;
}

.auth-form .page-back {
  display: inline-block;
  margin-bottom: 15px;
}

.pricing_table_content {
  background: #fff;
  text-align: center;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  padding: 25px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.pricing_table_content .package {
  font-weight: 700;
  font-size: 18px;
}

.pricing_table_content .price {
  font-weight: 700;
  font-size: 50px;
  line-height: 100px;
  color: #B1B1B1;
}

.pricing_table_content hr {
  margin: 0;
}

.pricing_table_content .price_list {
  padding: 30px 0;
  text-align: left;
  max-width: 175px;
  margin: 0 auto;
}

.pricing_table_content .price_list li {
  color: #909093;
  font-size: 14px;
  line-height: 25px;
  padding: 7px 0;
}

.pricing_table_content .price_list li i {
  margin-right: 15px;
}

.pricing_table_content .price-btn {
  padding: 15px 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 25px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.pricing_table_content:focus,
.pricing_table_content:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.175);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.175);
}

.pricing_table_content:focus .price-btn,
.pricing_table_content:hover .price-btn {
  color: #F94687;
}

.page-timeline .timeline {
  list-style: none;
  padding: 40px 0;
  position: relative;
}

.page-timeline .timeline-workplan.page-timeline .timeline {
  padding-bottom: 0;
  margin-bottom: 0;
}

.page-timeline .timeline-workplan.page-timeline .timeline.timeline>li>.timeline-badge {
  top: 4.5rem;
}

.page-timeline .timeline-workplan.page-timeline .timeline>li {
  margin-bottom: 2.6rem;
}

@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .page-timeline .timeline-workplan.page-timeline .timeline li>p {
    max-width: 8rem;
  }
}

@media only screen and (max-width: 1199px) {
  .page-timeline .timeline-workplan.page-timeline .timeline li>p {
    max-width: 7rem;
  }
}

@media only screen and (max-width: 991px) {
  .page-timeline .timeline-workplan.page-timeline .timeline li>p {
    max-width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .page-timeline .timeline-workplan.page-timeline .timeline li>p {
    max-width: 7rem;
  }
}

.page-timeline .timeline-workplan.page-timeline .timeline::before {
  left: 20%;
  top: 6rem;
}

[direction="rtl"] .page-timeline .timeline-workplan.page-timeline .timeline::before {
  right: 22%;
  left: auto;
}

@media only screen and (max-width: 575px) {
  .page-timeline .timeline-workplan.page-timeline .timeline::before {
    left: 22%;
  }
}

.page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
  left: 21.4%;
  height: 0.9rem;
  width: 0.9rem;
  background-color: #99c990;
}

[direction="rtl"] .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
  right: 19.2%;
  left: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
    left: 22.4%;
  }
}

@media only screen and (max-width: 1199px) {
  .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
    left: 22.4%;
  }
}

@media only screen and (max-width: 991px) {
  .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
    left: 21.7%;
  }
}

@media only screen and (max-width: 767px) {
  .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
    left: 19.5%;
  }
}

@media only screen and (max-width: 575px) {
  .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
    left: 21.4%;
  }
}

.page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge::after {
  position: absolute;
  width: 1.9rem;
  height: 1.9rem;
  background-color: rgba(30, 63, 180, 0.4);
  content: "";
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.page-timeline .timeline-workplan.page-timeline .timeline .timeline-panel {
  width: 70%;
}

.page-timeline .timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 2px;
  background-color: #ECEFF2;
  left: 50%;
  margin-left: -1.5px;
}

.page-timeline .timeline>li {
  margin-bottom: 20px;
  position: relative;
}

.page-timeline .timeline>li:after,
.page-timeline .timeline>li:before {
  content: " ";
  display: table;
}

.page-timeline .timeline>li:after {
  clear: both;
}

.page-timeline .timeline>li:after,
.page-timeline .timeline>li:before {
  content: " ";
  display: table;
}

.page-timeline .timeline>li:after {
  clear: both;
}

.page-timeline .timeline>li>.timeline-panel {
  width: 46%;
  float: left;
  border-radius: 2px;
  position: relative;
}

.page-timeline .timeline>li>.timeline-badge {
  background-color: #f2f4fa;
  border: 1px solid #DDDFE1;
  border-radius: 50%;
  color: #B1B1B1;
  height: 40px;
  left: 50%;
  line-height: 40px;
  margin-left: -13px;
  position: absolute;
  text-align: center;
  top: 30px;
  width: 40px;
  z-index: 1;
  transform: translate(-25%, -3rem);
}

@media (min-width: 576px) {
  .page-timeline .timeline>li>.timeline-badge {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

.page-timeline .timeline>li.timeline-inverted>.timeline-panel {
  float: right;
}

.page-timeline .timeline>li.timeline-inverted>.timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.page-timeline .timeline>li.timeline-inverted>.timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.page-timeline .timeline-title {
  margin-top: 0;
  color: inherit;
}

.page-timeline .event_time {
  font-size: 14px;
  font-weight: 600;
}

.page-timeline .event_vanue {
  font-size: 14px;
  margin: 5px 0;
  font-weight: 600;
}

.page-timeline .timeline_img {
  height: 100px;
  width: 100px;
}

.page-timeline .timeline-body>p,
.page-timeline .timeline-body>ul {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .page-timeline ul.timeline:before {
    left: 40px;
  }

  .page-timeline ul.timeline>li>.timeline-panel {
    width: calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
  }

  .page-timeline ul.timeline>li>.timeline-badge {
    left: 28px;
    margin-left: 0;
    top: 16px;
  }

  .page-timeline ul.timeline>li>.timeline-panel {
    float: right;
  }

  .page-timeline ul.timeline>li>.timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }

  .page-timeline ul.timeline>li>.timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }

  .page-timeline .timeline_img {
    height: 30%;
    width: 30%;
  }
}

.page-timeline .timeline-timestamp {
  text-align: center;
}

.page-timeline .timeline-timestamp .badge {
  padding: .8rem 2rem;
  border-radius: 50px;
  font-size: 0.8125rem;
}

.full-map-area {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}

.full-map-area a {
  position: absolute;
  bottom: 30px;
  left: 30px;
}

.full-map-area i {
  background: #3e4954;
  position: absolute;
  top: 30px;
  right: 30px;
  width: 56px;
  height: 56px;
  text-align: center;
  line-height: 56px;
  font-size: 24px;
  color: #fff;
  border-radius: 56px;
}

.full-map-area img {
  width: 100%;
}

.iconbox {
  position: relative;
  padding-left: 70px;
}

.iconbox i {
  background: #99c990;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50px;
  text-align: center;
  font-size: 28px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
}

.iconbox p {
  margin: 0;
  color: #484848;
  font-size: 18px;
  line-height: 1.3;
  font-weight: 500;
}

.iconbox small,
.iconbox .small {
  margin-bottom: 5px;
  font-size: 16px;
  display: block;
}

.widget-timeline-icon li {
  padding-left: 25px;
  position: relative;
  margin-left: 11px;
  border-left: 3px solid #99c990;
  min-height: 80px;
  padding-top: 1px;
}

.widget-timeline-icon li .icon {
  position: absolute;
  width: 22px;
  height: 22px;
  font-size: 24px;
  color: #fff;
  text-align: center;
  line-height: 22px;
  border-radius: 22px;
  left: -13px;
  top: 0;
}

.widget-timeline-icon li:last-child {
  border-left: 3px solid transparent;
}

.plan-list .list-row {
  border-radius: 1.25rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.plan-list .list-row .list-icon {
  height: 76px;
  min-width: 76px;
  width: 76px;
  font-weight: 600;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  line-height: 20px;
  text-align: center;
  border-radius: 0.625rem;
}

@media only screen and (max-width: 767px) {
  .plan-list .list-row .list-icon {
    height: 60px;
    width: 60px;
    min-width: 60px;
    line-height: 18px;
  }

  .plan-list .list-row .list-icon p {
    font-size: 18px !important;
  }

  .plan-list .list-row .list-icon span {
    font-size: 12px !important;
  }
}

.plan-list .list-row .play-button {
  color: #99c990;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background: #F4F5F9;
  margin-left: auto;
}

@media only screen and (max-width: 1600px) {
  .plan-list .list-row .play-button {
    margin-left: 0;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 1199px) {
  .plan-list .list-row .play-button {
    margin-left: auto;
    margin-right: 1rem !important;
  }
}

.plan-list .list-row .more-dropdown {
  margin-left: auto;
}

@media only screen and (max-width: 1199px) {
  .plan-list .list-row .more-dropdown {
    margin-left: auto;
  }
}

@media only screen and (max-width: 575px) {
  .plan-list .list-row .more-dropdown {
    margin-left: auto;
  }
}

.plan-list .list-row .more-button {
  height: 56px;
  width: 56px;
  display: block;
  border-radius: 0.625rem;
  line-height: 56px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  text-align: center;
  background: #F4F5F9;
}

@media only screen and (max-width: 1400px) {
  .plan-list .list-row .more-button {
    height: 40px;
    width: 40px;
    line-height: 40px;
  }

  .plan-list .list-row .more-button svg {
    width: 4px;
  }
}

.plan-list .list-row .activities svg path,
.plan-list .list-row .activities span {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.plan-list .list-row .info h4 a,
.plan-list .list-row .info .h4 a,
.plan-list .list-row .info span {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 767px) {

  .plan-list .list-row .info h4,
  .plan-list .list-row .info .h4 {
    font-size: 15px !important;
  }

  .plan-list .list-row .info span {
    font-size: 13px !important;
  }
}

.plan-list .list-row:hover {
  background: #99c990;
}

.plan-list .list-row:hover .info h4 a,
.plan-list .list-row:hover .info .h4 a,
.plan-list .list-row:hover .info span {
  color: #fff !important;
}

.plan-list .list-row:hover .play-button {
  color: #fff;
  background: #FFBC11;
}

.plan-list .list-row:hover .more-button {
  background: rgba(255, 255, 255, 0.3);
}

.plan-list .list-row:hover .more-button svg path {
  fill: #fff;
}

.plan-list .list-row:hover .activities svg path {
  fill: #fff;
}

.plan-list .list-row:hover .activities span {
  color: #fff !important;
}

.event-calender .bootstrap-datetimepicker-widget table th.picker-switch {
  width: 145px;
  font-size: 20px;
  font-weight: 500;
  color: #000;
}

.event-calender .bootstrap-datetimepicker-widget table th {
  height: 20px;
  line-height: 20px;
  width: 20px;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
}

.event-calender .bootstrap-datetimepicker-widget table td.day {
  height: 46px;
  line-height: 30px;
  width: 46px;
  color: #000;
  font-size: 15px;
}

.event-calender .bootstrap-datetimepicker-widget .picker-switch td i {
  line-height: 2.5;
  height: 2.5em;
  width: 100%;
  display: none;
}

.event-calender .datepicker table tr td.today {
  background: #EEEEEE !important;
  box-shadow: none;
}

.event-calender .datepicker table tr td.active.active {
  background: #EEEEEE !important;
  box-shadow: none;
  color: #99c990 !important;
}

.event-calender .bootstrap-datetimepicker-widget table td.day:hover {
  box-shadow: none;
  border: 0;
  background: #FFBC11 !important;
  color: #fff !important;
}

.event-calender .bootstrap-datetimepicker-widget table td.today:before {
  content: '';
  display: inline-block;
  position: absolute;
  top: -5px;
  border: 3px solid #fff;
  right: -5px;
  height: 17px;
  width: 17px;
  background: #99c990;
  border-radius: 0.625rem;
}

.event-calender .bootstrap-datetimepicker-widget table td,
.event-calender .bootstrap-datetimepicker-widget table th {
  border-radius: 0.625rem;
}

.event-calender .bootstrap-datetimepicker-widget table thead tr:first-child th {
  cursor: pointer;
  padding: 30px 10px;
  font-size: 1rem;
}

.event-calender .bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
  background-color: transparent;
}

.event-calender .bootstrap-datetimepicker-widget table td span.active {
  color: #99c990;
}

.event-calender .bootstrap-datetimepicker-widget .datepicker-decades .decade {
  width: 50%;
  float: left;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-icon {
  height: 60px;
  width: 60px;
  font-size: 24px;
  line-height: 60px;
  text-align: center;
  color: #99c990;
  background: rgba(30, 63, 180, 0.2);
  border-radius: 0.625rem;
}

.review-table {
  padding: 25px;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid #eee;
  height: auto;
  margin-bottom: 0;
}

.review-table .star-review i {
  font-size: 20px;
}

.review-table .media-body p {
  color: #3e4954;
  font-size: 18px;
  line-height: 1.5;
}

.review-table .disease {
  border-left: 1px solid #EEEEEE;
  padding-left: 20px;
}

.review-table .media-footer {
  min-width: 500px;
  margin-left: 10px;
}

@media only screen and (max-width: 1400px) {
  .review-table .media-footer {
    min-width: 273px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .review-table .star-review i {
    font-size: 16px;
  }

  .review-table .custom-control {
    float: right;
    right: -20px;
  }

  .review-table .media {
    display: block !important;
  }

  .review-table .media-body p {
    font-size: 14px;
  }

  .review-table img {
    float: left;
    width: 80px;
  }

  .review-table .media-footer {
    margin-left: 0;
    margin-top: 25px;
  }

  .review-table .disease {
    border-left: 0;
    padding-left: 0;
    margin-right: 10px !important;
    margin-left: 0;
  }
}

@media only screen and (max-width: 575px) {
  .review-table .media-footer {
    flex-flow: wrap;
  }

  .review-table .media-footer .disease {
    margin-right: auto !important;
  }

  .review-table .media-footer .edit {
    margin-left: 0 !important;
  }
}

.review-tab .nav-link {
  border-radius: 0;
  font-weight: 600;
  border-bottom: 2px solid #D9D9D9;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.review-tab .nav-link.active {
  background: transparent;
  color: #99c990;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  box-shadow: none;
  border-color: #99c990;
}

@media only screen and (max-width: 575px) {
  .review-tab .nav-link {
    padding: 10px 16px;
    font-size: 13px;
  }
}

.man-chart {
  position: relative;
}

.man-chart svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.radialBar p {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 1199px) {
  .radialBar #radialBar {
    margin-bottom: -40px;
  }
}

.mapouter iframe {
  width: 100%;
}

.activity-icon {
  height: 50px;
  width: 50px;
  min-width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 100%;
}

.diet-search {
  width: 360px;
}

.diet-search .input-group-append {
  margin-left: -20px;
}

.diet-search .input-group-append button {
  border-top-left-radius: 0.625rem !important;
  border-bottom-left-radius: 0.625rem !important;
  font-size: 25px;
  padding: 0 20px;
  position: relative;
  z-index: 3;
}

@media only screen and (max-width: 1400px) {
  .diet-search {
    width: 300px;
  }

  .diet-search .input-group-append button {
    font-size: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .diet-search {
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .menu-list>a>img {
    float: left;
  }
}

@media only screen and (max-width: 575px) {
  .menu-list>a>img {
    width: 80px;
  }
}

.plus-icon {
  height: 50px;
  width: 50px;
  min-width: 50px;
  display: inline-block;
  line-height: 53px;
  text-align: center;
  border-radius: 0.625rem;
  background-color: #99c990;
}

.plus-icon i {
  font-size: 18px;
  color: #fff;
}

@media only screen and (max-width: 100rem) {
  .media .food-default-flex {
    flex: 1;
  }
}

.nav-tabs.diet-tabs {
  border: 0;
}

.nav-tabs.diet-tabs .nav-link {
  border: 0;
  padding: 0.938rem 1.5rem;
  background-color: #d8e0f9;
  margin-right: 5px;
  color: #000;
  border-radius: 0.625rem;
}

@media only screen and (max-width: 767px) {
  .nav-tabs.diet-tabs .nav-link {
    margin-bottom: 5px;
  }
}

.nav-tabs.diet-tabs .nav-link.active {
  background-color: #99c990;
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .diet-content .card-body {
    padding: 1rem 0;
  }
}

.cm-content-box {
  background: #fff;
  border-radius: 0.6rem;
  margin-bottom: 1rem;
}

.cm-content-box.filter {
  border-top: 0;
}

.cm-content-box .content-title {
  padding: 0.5rem 1.875rem;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cm-content-box .content-title .cpa {
  color: #99c990;
  padding: 11px 0 9px 0;
  font-weight: 600;
  font-size: 1rem;
}

@media only screen and (max-width: 575px) {
  .cm-content-box .content-title {
    padding: 0.5rem 0.938rem;
  }
}

.cm-content-box .content-title {
  border-top-left-radius: 0.425rem;
  border-top-right-radius: 0.425rem;
}

.cm-content-box.style-1 .content-title {
  border-radius: 0.625rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 1px solid #E6E6E6;
}

.cm-content-box .tools a {
  display: inline-block;
}

.cm-content-box .tools a:hover i {
  opacity: 0.5;
}

.cm-content-box .tools a i {
  font-family: 'Font Awesome 6 Free';
  font-weight: 600;
  color: #c2c2c2;
  font-size: 1.25rem;
}

.cm-content-box .tools a.expand {
  transform: rotate(-180deg);
}

.cm-content-box .cm-content-body {
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-top: 1px solid #E6E6E6;
}

.cm-content-box .cm-content-body .table thead tr th .custm-select {
  height: 45px;
  background-color: transparent;
  border-radius: 0.625rem;
}

.cm-content-box .cm-content-body .table thead tr th .custm-select.input-small {
  width: 9.063rem !important;
}

.cm-content-box .cm-content-body .table thead tr th .form-control {
  border-radius: 0.625rem;
}

.cm-content-box .cm-content-body .table thead tr th .form-control.input-large {
  width: 20rem !important;
}

.cm-content-box .cm-content-body .table thead tr:nth-child(2) {
  background-color: #000;
  box-shadow: 10px 10px 8px #888888;
}

.cm-content-box .cm-content-body .table.table-striped th {
  padding: 1rem;
  color: #7e7e7e;
}

.cm-content-box .cm-content-body .table.table-striped td {
  padding: 1rem;
  color: #504f4f;
  white-space: nowrap;
}

.cm-content-box .cm-content-body .table.table-striped td:first-child {
  padding-left: 2rem;
}

.cm-content-box .cm-content-body .table.table-bordered thead {
  background-color: unset;
}

.cm-content-box .cm-content-body .table .btn {
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0.313rem;
}

.cm-content-box .cm-content-body.excerpt .textarea {
  padding: 0.438rem 0.25rem;
}

.cm-content-box .cm-content-body.custom-fields .name-value {
  line-height: 2.375rem;
  font-size: 1rem;
  text-align: center;
}

.cm-content-box .cm-content-body .contact {
  border-radius: 0.625rem !important;
}

.contents-list .btn-icon-only {
  height: 2.25rem;
  width: 2.25rem;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  line-height: 1;
}

.contents-list .btn-icon-only i {
  font-size: 1rem;
}

.contents-list .table tbody tr td {
  padding: 0.4375rem 0.625rem;
}

.fatch-date {
  display: block;
}

.new-scroll {
  background-color: #F4F5F9;
  padding: 0.625rem;
  height: 9.375rem;
  overflow-y: auto;
  border-radius: 0.625rem;
}

.content-icon {
  width: 2.188rem;
  height: 2.188rem;
  display: inline-block;
  text-align: center;
  line-height: 2.125rem;
  font-size: 0.625rem;
  padding: 0px;
}

.dd-handle.move-media {
  padding: 0.5rem 1.125rem;
  display: flex;
  margin: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 100%;
  border: 0;
  z-index: 2;
  background: none;
  position: absolute;
  background-color: #99c990;
  top: 0;
  font-size: 1.25rem;
  left: 0;
  color: #fff;
  align-items: center;
}

.dd-handle.move-media+.accordion-button {
  padding-left: 4.063rem;
  background: #fff;
  position: relative;
  z-index: 1;
}

.dd-list {
  margin-top: 20px;
}

.menu-ac-item {
  position: relative;
}

.menu-ac-item .dd-collapse {
  height: 3.338rem;
  width: 3.338rem;
  border: 1px solid #E6E6E6;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  font-size: 1.75rem;
  color: black;
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.menu-ac-item .dd-collapse+.accordion {
  margin-left: 3.25rem;
}

@media only screen and (max-width: 1600px) {
  .menu-ac-item .dd-collapse {
    height: 3rem;
    width: 3rem;
  }
}

.menu-ac-item .dd-expand {
  display: none;
}

.dz-menu-btn {
  margin-top: 0.625rem;
}

@media only screen and (max-width: 1600px) {
  .dz-menu-btn {
    margin-top: 0;
    margin-left: 0.313rem;
  }
}

@media only screen and (max-width: 1480px) {
  .dz-menu-btn {
    margin-top: 0.625rem;
    margin-left: 0;
  }
}

.dz-terms {
  border: 1px solid #EEEEEE;
  border-radius: 0.625rem;
  padding: 0.625rem;
}

/* menu-scss */
.required {
  color: #F94687;
}

.tab-my.nav-tabs {
  border-bottom: unset;
}

.tab-my.nav-tabs .nav-link {
  background-color: #A02CFA;
  border-radius: 0.625rem;
  border: 1px solid transparent;
  margin: 8px 2px;
  color: #fff;
}

.tab-my.nav-tabs .nav-link.active {
  background-color: #99c990;
  color: #fff;
}

.menu-tabs {
  border: 1px solid #E6E6E6;
  margin-bottom: 1rem;
  padding: 0.625rem;
  border-radius: 0.625rem;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 0.75rem;
  z-index: 1;
  top: 0.625rem;
}

.avatar-upload .avatar-preview {
  border: 1px solid #EEEEEE;
  padding: 0.5rem;
  margin-bottom: 0.625rem;
  border-radius: 0.625rem;
}

.avatar-upload .avatar-preview>div {
  display: block;
  width: 100%;
  height: 7.5rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.main-check {
  border: 1px solid #99c990;
  padding: 2.125rem;
  margin-bottom: 1rem;
  border-radius: 0.625rem;
}

/* view-content */
.view-bottom {
  flex-direction: column;
  justify-content: end;
  height: 88%;
  display: flex;
}

/* add-email */
.publish-content ul {
  padding: 0.625rem;
}

.dd .accordion-body {
  border: 1px solid #EEEEEE;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top: 0;
  margin-top: -5px;
}

@media only screen and (max-width: 575px) {
  .dd .dd-list {
    padding-left: 0;
  }
}

.dd .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.publish-drop ul:hover {
  background-color: unset;
}

input.form-control {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.right-sidebar-sticky {
  position: sticky;
  top: 7.5rem;
}

.content-title.box-primary,
.content-title.box-secondary,
.content-title.box-success,
.content-title.box-danger,
.content-title.box-warning,
.content-title.box-info,
.content-title.box-dark,
.content-title.box-light {
  background-color: #99c990;
}

.content-title.box-primary .cpa,
.content-title.box-primary i,
.content-title.box-secondary .cpa,
.content-title.box-secondary i,
.content-title.box-success .cpa,
.content-title.box-success i,
.content-title.box-danger .cpa,
.content-title.box-danger i,
.content-title.box-warning .cpa,
.content-title.box-warning i,
.content-title.box-info .cpa,
.content-title.box-info i,
.content-title.box-dark .cpa,
.content-title.box-dark i,
.content-title.box-light .cpa,
.content-title.box-light i {
  color: #fff !important;
}

.content-title.box-secondary {
  background-color: #A02CFA;
}

.content-title.box-success {
  background-color: #2BC155;
}

.content-title.box-danger {
  background-color: #F94687;
}

.content-title.box-warning {
  background-color: #FFBC11;
}

.content-title.box-info {
  background-color: #1EA7C5;
}

.content-title.box-dark {
  background-color: #B1B1B1;
}

.content-title.box-light {
  background-color: #F4F5F9;
}

/* data-picker */
.datepicker {
  padding: 10px;
}

.datepicker.datepicker-dropdown {
  background-color: #fff;
  border-radius: 0.625rem;
}

.datepicker.datepicker-dropdown th {
  font-size: 16px;
  font-weight: 500 !important;
}

.datepicker table tr td.today {
  color: #fff;
  background-image: none;
  background-color: #99c990;
}

.datepicker table tr td.today:hover:hover {
  color: #fff;
  background-color: #99c990;
  background-image: none;
}

.datepicker table tr td.new,
.datepicker table tr td.old {
  opacity: 0.2;
}

.datepicker table tr td.selected,
.datepicker table tr td.active.active,
.datepicker.datepicker-dropdown td.day:hover,
.datepicker.datepicker-dropdown th.next:hover,
.datepicker.datepicker-dropdown th.prev:hover {
  box-shadow: unset;
  background-image: none !important;
  background-color: #99c990 !important;
  border-radius: 0.625rem;
}

.datepicker.datepicker-dropdown td.day,
.datepicker.datepicker-dropdown th.next,
.datepicker.datepicker-dropdown th.prev {
  font-size: 15px;
  font-weight: 500;
}

.overflow-unset {
  overflow: unset !important;
}

.datepicker table tr td.today {
  background-image: none !important;
  box-shadow: unset;
  background-color: #99c990 !important;
  border-radius: 0.625rem !important;
}

.content-body.default-height {
  min-height: calc(100vh - 60px);
}

.items-table tbody tr {
  padding: 0;
  border-radius: 0.625rem;
  position: relative;
  box-shadow: 0 1px 0 0 #f8f8f8;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.items-table tbody tr .dropdown {
  opacity: 0;
}

.items-table tbody tr.active,
.items-table tbody tr:hover {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.items-table tbody tr.active .dropdown,
.items-table tbody tr:hover .dropdown {
  opacity: 1;
}

.items-table tbody tr th,
.items-table tbody tr td {
  border: 0;
  padding: 18px 8px;
}

@media only screen and (max-width: 575px) {
  .items-table {
    width: 500px;
  }
}

.star-review i {
  font-size: 16px;
}

.star-review i.text-gray {
  color: #d9d9d9;
}

.star-review span {
  color: #000;
}

.line-para-height {
  line-height: 22px;
}

input[type=number] {
  -moz-appearance: textfield;
}

.form-head .breadcrumb {
  background: transparent;
  padding: 0;
}

.form-head .search-area {
  max-width: 300px;
  border-radius: 0;
}

@media only screen and (max-width: 1400px) {
  .form-head .search-area {
    max-width: 250px;
  }
}

@media only screen and (max-width: 575px) {
  .form-head .search-area {
    max-width: 250px;
  }
}

.form-head .search-area .form-control {
  border: 0;
  height: 56px;
  padding: 10px 15px;
  font-size: 16px;
  background: #f1f1f1;
  border-radius: 0;
}

@media only screen and (max-width: 1400px) {
  .form-head .search-area .form-control {
    height: 41px;
    padding: 10px 15px;
    font-size: 13px;
  }
}

.form-head .search-area .input-group-append .input-group-text {
  background: #f1f1f1;
  padding: 0 5px 0 30px;
}

@media only screen and (max-width: 1400px) {
  .form-head .search-area .input-group-append .input-group-text {
    padding: 0 5px 0 30px;
  }
}

.form-head .search-area .input-group-append .input-group-text i {
  font-size: 20px;
  color: #7e7e7e;
}

@media only screen and (max-width: 1400px) {
  .form-head .search-area .input-group-append .input-group-text i {
    font-size: 18px;
  }
}

.form-head .dropdown .btn i {
  transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  display: inline-block;
}

.form-head .dropdown:after {
  color: #99c990;
  font-size: 25px;
}

@media only screen and (max-width: 767px) {
  .form-head>* {
    margin-right: 5px !important;
    margin-bottom: 10px;
    vertical-align: baseline;
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .form-head {
    display: block !important;
  }
}

.rounded {
  border-radius: 0.625rem !important;
}

.btn-link {
  font-weight: 500;
  font-size: 16px;
}

@media only screen and (max-width: 1400px) {
  .btn-link {
    font-size: 14px;
  }
}

.deznav .copyright {
  font-size: 14px;
  padding: 0 45px 60px;
  margin-top: 40px;
  color: #969BA0;
  margin-bottom: 40px;
}

.deznav .copyright p {
  margin-bottom: 10px;
}

.deznav .copyright strong {
  display: block;
  font-weight: 500;
}

@media only screen and (max-width: 1400px) {
  .deznav .copyright {
    padding: 0 25px 60px;
  }
}

@media only screen and (max-width: 767px) {
  .deznav .copyright {
    padding-bottom: 60px;
  }
}

.widget-buyer .media img {
  width: 30px;
}

.widget-buyer .media .media-body h5,
.widget-buyer .media .media-body .h5 {
  font-size: 14px;
  margin-bottom: 0px;
}

.widget-buyer .media .media-body p {
  font-size: 13px;
}

.activity_overview .nav-tabs {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.activity_overview .custom-tab-1 .nav-link {
  color: rgba(255, 255, 255, 0.5);
  padding-left: 0;
  padding-right: 0;
  margin-right: 30px;
}

.activity_overview .custom-tab-1 .nav-link:hover,
.activity_overview .custom-tab-1 .nav-link.active {
  background: transparent;
  color: #fff;
  border-color: #fff;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: transparent !important;
  opacity: 0.9;
}

@media only screen and (max-width: 575px) {
  .chart-link {
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 1199px) {
  .chart-link {
    display: flex;
    align-items: center;
  }
}

@media only screen and (min-width: 1200px) {
  .chart-link {
    display: flex;
    align-items: center;
  }
}

.chart-link .week-link {
  display: inline-block;
}

.chart-link a {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;
}

.chart-link a i {
  font-size: 13px;
}

.chart-link a i.text-primary {
  color: #99c990;
}

.chart-link a i.text-muted {
  color: #DAE2F3 !important;
}

.chart-link .form-control {
  max-width: 97px;
  display: inline-block;
}

.card-tabs .nav-tabs {
  border-bottom: 0px;
  background: #E8E8E8;
  padding: 5px;
  border-radius: 0.625rem;
  flex-wrap: unset;
}

.card-tabs .nav-tabs .nav-link {
  border-radius: 0.625rem;
  padding: 12px 20px;
  font-weight: 500;
  font-size: 16px;
  border: 0px solid #EEEEEE;
  color: #000;
}

.card-tabs .nav-tabs .nav-link.active {
  background: #fff;
  color: #99c990;
}

@media only screen and (max-width: 1400px) {
  .card-tabs .nav-tabs .nav-link {
    padding: 8px 15px;
    font-weight: 400;
    font-size: 14px;
  }
}

.card-tabs .nav-tabs.tabs-lg .nav-link {
  padding: 23px 30px;
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .card-tabs .nav-tabs.tabs-lg .nav-link {
    padding: 10px 15px;
    font-size: 14px;
  }
}

.card-tabs.icontabs .nav-tabs {
  background: transparent;
}

.card-tabs.icontabs .nav-tabs .nav-item .nav-link {
  height: 42px;
  width: 42px;
  line-height: 42px;
  text-align: center;
  background: rgba(137, 137, 137, 0.6);
  color: #fff;
  margin-right: 15px;
  border-radius: 50px;
  padding: 0;
}

.card-tabs.icontabs .nav-tabs .nav-item .nav-link.active {
  background: #FFBC11;
}

.card-tabs.icontabs .nav-tabs .nav-item .nav-link i {
  font-size: 25px;
  line-height: 42px;
}

.card-tabs.style-1 .nav-tabs {
  background: transparent;
}

.card-tabs.style-1 .nav-tabs .nav-link {
  font-size: 16px;
  color: #000;
  position: relative;
}

.card-tabs.style-1 .nav-tabs .nav-link:after {
  content: none;
}

.card-tabs.style-1 .nav-tabs .nav-link span {
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translatex(-50%);
  content: "";
  height: 4px;
  width: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.card-tabs.style-1 .nav-tabs .nav-link.active span {
  width: 100%;
}

.revenue-chart .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series {
  display: flex !important;
  margin-right: 30px !important;
  margin-bottom: 30px !important;
}

.revenue-chart-bar {
  margin-bottom: -30px;
}

@media only screen and (max-width: 575px) {
  .revenue-chart-bar {
    margin-bottom: 20px;
    float: none;
    padding-left: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .revenue-chart-bar {
    margin-bottom: 20px;
    float: none;
    padding-left: 20px;
  }
}

#activeUser {
  height: 215px !important;
}

#activeUser {
  height: 180px !important;
}

span#counter {
  font-size: 30px;
  font-weight: 700;
  color: #99c990;
}

.social-graph-wrapper {
  text-align: center;
  padding: 20px;
  position: relative;
  color: #fff;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}

.social-graph-wrapper.widget-facebook {
  background-color: #3b5998;
}

.social-graph-wrapper.widget-twitter {
  background-color: #1da1f2;
}

.social-graph-wrapper.widget-linkedin {
  background-color: #007bb6;
}

.social-graph-wrapper.widget-googleplus {
  background-color: #db4439;
}

.social-graph-wrapper .s-icon {
  font-size: 24px;
  position: relative;
  padding: 0 10px;
}

.recent-stats i {
  font-size: 11px;
  position: relative;
  top: -1px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  #lineChart_3Kk {
    height: 140px !important;
  }
}

/* add menu sidebar */
.add-menu-sidebar {
  background: #99c990;
  padding: 20px 18px;
  display: flex;
  align-items: center;
  border-radius: 0.625rem;
  position: relative;
  margin: 50px 50px;
}

.add-menu-sidebar a {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.add-menu-sidebar a:hover {
  color: #fff;
}

@media only screen and (max-width: 1400px) {
  .add-menu-sidebar {
    margin: 50px 25px 50px;
  }

  .add-menu-sidebar p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .add-menu-sidebar {
    margin: 20px 25px 20px;
  }
}

.donut-chart-sale {
  position: relative;
}

.donut-chart-sale small,
.donut-chart-sale .small {
  font-size: 16px;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  display: flex;
  align-items: center;
  top: 0;
  justify-content: center;
  font-weight: 600;
}

.profile-bx .profile-image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.profile-bx .profile-image img {
  margin: 18px;
  width: 118px;
}

.search-area {
  width: 300px;
}

.search-area .form-control {
  background: #EFEFEF !important;
  border-width: 0px;
  padding-right: 20px;
  min-height: 46px;
  height: 46px;
  border-radius: 0;
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.search-area .form-control::placeholder {
  color: #757575;
}

.search-area .input-group-text {
  background: #EFEFEF;
  border-width: 0px;
  height: 46px;
  border-top-left-radius: 0.625rem !important;
  border-bottom-left-radius: 0.625rem !important;
  padding-right: 0;
  padding-left: 20px;
}

.search-area .input-group-text i {
  font-size: 20px;
}

@media only screen and (max-width: 1400px) {
  .search-area .input-group-text {
    height: 43px;
  }
}

@media only screen and (max-width: 1600px) {
  .search-area {
    width: 230px;
  }
}

@media only screen and (max-width: 1400px) {
  .search-area .form-control {
    min-height: 43px;
    height: 43px;
  }

  .search-area .input-group-text i {
    font-size: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .search-area {
    width: 200px;
  }
}

.donut-chart-sale {
  z-index: 1;
}

.donut-chart-sale .circle {
  height: 50px;
  width: 50px;
  z-index: -1;
  content: "";
  position: absolute;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.donut-chart-sale small,
.donut-chart-sale .small {
  font-size: 14px;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  display: flex;
  align-items: center;
  top: 0;
  justify-content: center;
  font-weight: 600;
}

.min-w24 {
  min-width: 24px;
}

.min-w30 {
  min-width: 30px;
}

.min-w32 {
  min-width: 32px;
}

.min-w42 {
  min-width: 42px;
}

.min-w46 {
  min-width: 46px;
}

.min-w50 {
  min-width: 50px;
}

.form-group.style-1 .form-control {
  border-color: transparent;
  margin-right: -45px;
  z-index: 0;
  color: #000;
  background: #F0F0F0;
  border-radius: 0.625rem;
}

.previous-transactions tbody tr td:first-child {
  width: 100px;
}

.rating-widget .rating-stars ul li i {
  font-size: 25px !important;
}

.heart {
  width: 60px;
  height: 60px;
  display: inline-block;
  background: url("../images/like.png");
  cursor: pointer;
  margin: -25px -15px;
}

.heart-blast {
  background-position: -1680px 0;
  transition: background 1s steps(28);
}

.avtivity-card {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.avtivity-card .activity-icon {
  height: 80px;
  width: 80px;
  min-width: 80px;
  display: block;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 100%;
  line-height: 80px;
  text-align: center;
}

.avtivity-card .title {
  /* font-size: 28px; */
}

.avtivity-cardststa .titlestta {
  font-size: 15px;
}

.avtivity-card .progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.avtivity-card .effect {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  transition: width .4s ease-in-out, height .4s ease-in-out;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}

.avtivity-card p,
.avtivity-card .media-body span {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.avtivity-card:hover .activity-icon {
  background: #fff !important;
}

.avtivity-card:hover .effect {
  width: 225%;
  height: 562px;
}

.avtivity-card:hover p {
  color: #fff;
}

.avtivity-card:hover .media-body span {
  color: #fff !important;
}

.avtivity-card:hover .progress {
  background: rgba(255, 255, 255, 0.2) !important;
}

.avtivity-card:hover .progress .progress-bar {
  background: #fff !important;
}

@media only screen and (max-width: 767px) {
  .avtivity-card .title {
    font-size: 20px;
  }

  .avtivity-card .activity-icon {
    height: 65px;
    width: 65px;
    min-width: 65px;
    line-height: 65px;
  }

  .avtivity-card .activity-icon svg {
    width: 35px;
    height: 35px;
  }
}

.testimonial-one .card {
  box-shadow: none;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  margin-bottom: 0;
}

.testimonial-one .card img {
  height: 113px;
  width: 113px;
  margin-left: auto;
  margin-bottom: 15px;
  margin-right: auto;
  border-radius: 100%;
}

.testimonial-one .owl-nav .owl-prev,
.testimonial-one .owl-nav .owl-next {
  height: 45px;
  width: 45px;
  cursor: pointer;
  line-height: 45px;
  border-radius: 100%;
  position: absolute;
  top: 40%;
  background: #fff !important;
  color: #99c990 !important;
  text-align: center;
  font-size: 18px !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.testimonial-one .owl-nav .owl-prev:hover,
.testimonial-one .owl-nav .owl-next:hover {
  background-color: #99c990 !important;
  color: #fff !important;
  transition: all 0.5s;
}

.testimonial-one .owl-nav .owl-next {
  right: -51px;
}

.testimonial-one .owl-nav .owl-prev {
  left: -51px;
}

@media only screen and (max-width: 575px) {

  .testimonial-one .owl-nav .owl-next,
  .testimonial-one .owl-nav .owl-prev {
    height: 50px;
    width: 50px;
    line-height: 50px;
    font-size: 20px;
  }

  .testimonial-one .owl-nav .owl-next {
    right: -10px;
  }

  .testimonial-one .owl-nav .owl-prev {
    left: -10px;
  }
}

.owl-carousel .owl-nav.disabled {
  display: block;
}

.card-footer.style-1 {
  position: relative;
}

.card-footer.style-1 a {
  position: absolute;
  height: 45px;
  width: 45px;
  line-height: 45px;
  font-size: 20px;
  left: 50%;
  border-radius: 100%;
  background: #fff;
  color: #99c990;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transform: translateX(-50%);
  bottom: -10px;
}

.eye {
  position: absolute;
  right: 20px;
  bottom: 16px;
}

@media only screen and (max-width: 1400px) {
  .eye {
    bottom: 7px;
  }
}

/* typography */
.text-justify {
  text-align: justify;
}

.list-style li {
  list-style-type: unset;
}

.form-label {
  color: var(--bs-heading-color);
}

.default-select.style-1 {
  width: 125px !important;
  background-color: #d8e0f9;
  left: 0;
  height: auto;
}

.default-select.style-1 .btn {
  font-size: 13px;
  border: 0 !important;
  color: #99c990 !important;
  font-weight: 500;
}

.default-select.style-1 .dropdown-menu .dropdown-item {
  font-size: 13px;
}

.table.shadow-hover tr:last-child td {
  border: 0;
}

a:hover {
  color: #99c990;
}

@media only screen and (max-width: 1199px) {
  .width-defult {
    width: 89%;
  }
}

@media only screen and (max-width: 100rem) {
  .shopping-cart {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 991px) {
  .input-btn {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 100rem) {
  .distance-swich {
    padding-left: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .distance-swich {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .distance-swich {
    padding-left: 3px;
  }
}

@media only screen and (max-width: 575px) {
  .personal-seperator {
    display: none;
  }
}

.select-drop-btn .change-style {
  background-color: transparent;
  color: #7e7e7e;
  border-color: #E6E6E6;
}

.select-drop-btn .change-style.btn {
  background-color: transparent;
  color: #7e7e7e;
  border-color: #E6E6E6;
}

.image-placeholder {
  position: relative;
  max-width: 130px;
  margin: 10px auto;
}

.image-placeholder .avatar-edit {
  position: absolute;
  right: -10px;
  z-index: 1;
  top: 0px;
}

.image-placeholder .avatar-edit input {
  display: none;
}

.image-placeholder .avatar-edit input+label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #FFFFFF;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all .2s ease-in-out;
}

.image-placeholder .avatar-edit input+label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.image-placeholder .avatar-edit input+label:after {
  content: "\f040";
  font-family: 'FontAwesome';
  font-weight: 600;
  color: #757575;
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.image-placeholder .avatar-preview {
  width: 130px;
  height: 95px;
  position: relative;
  border-radius: 30px;
  border: 3px solid #F8F8F8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.image-placeholder .avatar-preview>div {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}

.image-placeholder .avatar-preview>div img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.project-boxed {
  overflow: hidden;
}

.custom-react-select>div:nth-child(4)>div {
  background-color: #fff;
}

.custom-react-select>div:nth-child(4)>div>div {
  background-color: #fff;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.custom-react-select>div:nth-child(4)>div>div:hover {
  background-color: var(--rgba-primary-1);
  color: #000;
}

.custom-react-select>div:nth-child(3) {
  height: 3.5rem;
  background-color: #fff;
  border-radius: .5rem;
  border-color: #e9e2f8 !important;
}

@media only screen and (max-width: 1400px) {
  .custom-react-select>div:nth-child(3) {
    height: 2.5rem;
  }
}

.custom-react-select>div:nth-child(4) {
  border-radius: 10px;
  min-width: 150px;
  overflow: hidden;
  box-shadow: 0px 10px 40px 0px rgba(21, 50, 93, 0.1);
}

.custom-react-select>div>div:first-child div {
  font-size: 0.875rem;
  color: #6e6e6e;
}

.custom-react-select>div>div:nth-child(2)>span {
  display: none;
}

.nestable.accord-data .nestable-list.nestable-group {
  padding-left: 0;
}

.nestable.accord-data .menu-accord .accordion-button {
  padding-left: 4.063rem;
}

/*
===================================
    Product detail
===================================*/
.produtct-detail-tag {
  display: inline-block;
}

.produtct-detail-tag a {
  font-size: 13px;
  color: #B1B1B1;
}

.product-detail-content .item-tag {
  background: #828690;
  border-radius: 6px;
  display: inline-block;
  font-size: 12px;
  margin-right: 4px;
  padding: 2px 12px;
  color: #fff;
}

.filtaring-area h4,
.filtaring-area .h4 {
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 400;
  text-transform: lowercase;
}

.plus-minus-input .input-icon {
  font-size: 13px;
  color: #B1B1B1;
}

.plus-minus-input {
  display: flex;
  width: 120px;
}

.plus-minus-input .custom-btn {
  border-radius: 0;
  height: 40px;
  padding: 12px 8px;
  background: #fff;
  border: 1px solid #E6E6E6;
}

.plus-minus-input .form-control:hover,
.plus-minus-input .form-control:focus,
.plus-minus-input .form-control:active {
  border: 1px solid #E6E6E6;
}

.btn-reveal-trigger .avatar-xl {
  min-width: 30px;
}

.share-view {
  display: inline-block;
}

.share-view ul li {
  display: inline-block;
}

.share-view .share-icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  border: 1px solid #E6E6E6;
  text-align: center;
  line-height: 40px;
  font-style: 16px;
  color: #E6E6E6;
  margin-right: 8px;
}

/*--------tab----------*/
.veritical-line {
  padding: 20px 30px;
  border-top: 1px solid #E6E6E6;
  border-right: 1px solid #E6E6E6;
  border-bottom: 1px solid #E6E6E6;
  position: relative;
}

.veritical-line:before {
  background: #E6E6E6;
  bottom: 0;
  content: "";
  height: 100%;
  left: -1px;
  max-height: 40%;
  position: absolute;
  width: 1px;
}

.tab-content-text p {
  color: #B1B1B1;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 25px;
}

.tab-item-list li a {
  background: #fff;
  border-top: 1px solid #E6E6E6;
  border-left: 1px solid #E6E6E6;
  border-right: 1px solid #E6E6E6;
  color: #B1B1B1;
  display: block;
  font-size: 16px;
  padding: 16px;
  text-transform: uppercase;
}

.tab-item-list li a:hover,
.tab-item-list li a:focus {
  background: #fff;
  color: #B1B1B1;
  border-right: 0px;
}

.tab-item-list li:last-child {
  border-bottom: 1px solid #E6E6E6;
}

.tab-list li {
  margin-bottom: 7px;
  font-size: 13px;
}

.tab-list li i {
  font-size: 13px;
  margin-right: 14px;
}

.slide-item-list {
  text-align: center;
  margin: 0 -5px;
  border: 0;
}

.slide-item-list li {
  display: inline-block;
  flex: 0 0 25%;
  width: 25%;
  padding: 0 5px;
}

.slide-item-list li a {
  display: inline-block;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  background: transparent;
  padding-bottom: 0px;
}

.slide-item-list li a:hover,
.slide-item-list li a:focus {
  background: transparent;
}

.slide-item-list li a img {
  width: 100%;
}

.slide-item-list .nav-link {
  border: 0;
}

.product-detail-text {
  padding-top: 28px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 70px;
}

.star-rating .product-review {
  font-style: 13px;
  color: #B1B1B1;
  font-weight: 400;
  text-decoration: underline !important;
}

.product-detail .tab-content img {
  display: inline-block;
  width: 100%;
}

.popular-tag ul {
  margin: 0px;
  padding: 0px;
}

.popular-tag ul li {
  display: inline-block;
  padding: 8px 15px;
  background: #f8f8f8;
  font-size: 13px;
  color: #fff;
  margin-right: 10px;
  margin-bottom: 10px;
}

.size-filter ul li {
  display: inline-block;
}

.intro {
  border: 1px solid red;
  color: #1d1d1d;
}

#listResults .slider {
  margin: 25px 0;
}

#listResults .slider-box {
  width: 90%;
  margin: 25px auto;
}

#listResults input {
  width: 10%;
}

#listResults label {
  border: none;
  display: inline-block;
  margin-right: -4px;
  vertical-align: top;
  width: 30%;
}

.plus-minus-input .input-icon {
  font-size: 13px;
  color: #aaaaaa;
}

.plus-minus-input .custom-btn {
  border-radius: 0;
  height: 40px;
  padding: 8px 12px;
  background: #ffffff;
  border: 1px solid #c8c8c8;
}

.plus-minus-input .custom-btn:hover,
.plus-minus-input .custom-btn:focus,
.plus-minus-input .custom-btn.active {
  box-shadow: none;
  outline: none;
}

.plus-minus-input .form-control {
  height: 40px;
  border-top: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  border-left: 0px solid #c8c8c8;
  border-right: 1px solid #c8c8c8;
}

.plus-minus-input .form-control:hover,
.plus-minus-input .form-control:focus,
.plus-minus-input .form-control:active {
  border-top: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  border-left: 0px solid #c8c8c8;
  border-right: 0px solid #c8c8c8;
}

.new-arrival-product .new-arrivals-img-contnent {
  overflow: hidden;
}

.new-arrival-product .new-arrivals-img-contnent img {
  width: 100%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.new-arrival-product:hover .new-arrivals-img-contnent img {
  transform: scale(1.2) translateY(12%);
  -moz-transform: scale(1.2) translateY(12%);
  -webkit-transform: scale(1.2) translateY(12%);
  -ms-transform: scale(1.2) translateY(12%);
  -o-transform: scale(1.2) translateY(12%);
}

/*
===================================
    list view
===================================*/
.new-arrival-content .item {
  font-size: 12px;
  color: #B1B1B1;
}

.new-arrival-content h4,
.new-arrival-content .h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.new-arrival-content h4 a,
.new-arrival-content .h4 a {
  color: #000;
}

.new-arrival-content .price {
  font-weight: 600;
  color: #99c990;
  font-size: 1rem;
  margin-bottom: 0;
  float: right;
}

@media only screen and (max-width: 575px) {
  .new-arrival-content .price {
    float: none;
    margin-top: 10px;
  }
}

.new-arrival-content .discount {
  font-size: 0.875rem;
  margin-right: 8px;
  opacity: 0.8;
  color: #F94687;
}

.new-arrival-content p {
  font-size: 14px;
  color: #828690;
  margin-bottom: 6px;
  line-height: 24px;
}

.new-arrival-content .text-content {
  margin-top: 18px;
}

.new-arrival-content.text-center .price {
  float: unset;
}

.success-icon {
  color: #2BC155;
  font-size: 16px;
}

.comment-review {
  margin-bottom: 15px;
  display: table;
  width: 100%;
}

.comment-review .client-review {
  color: #828690;
  padding-right: 20px;
  text-decoration: underline !important;
  font-size: 14px;
}

.comment-review .span {
  color: #828690;
  font-size: 14px;
}

@media only screen and (max-width: 575px) {
  .comment-review {
    margin-bottom: 0;
  }
}

.star-rating li {
  display: inline-block;
}

.star-rating li i {
  color: gold;
}

[data-theme-version="dark"] {
  background: #222327;
  color: #828690;
  --bs-body-bg: #111217;
}

[data-theme-version="dark"] h1,
[data-theme-version="dark"] .h1,
[data-theme-version="dark"] h2,
[data-theme-version="dark"] .h2,
[data-theme-version="dark"] h3,
[data-theme-version="dark"] .h3,
[data-theme-version="dark"] h4,
[data-theme-version="dark"] .h4,
[data-theme-version="dark"] h5,
[data-theme-version="dark"] .h5,
[data-theme-version="dark"] h6,
[data-theme-version="dark"] .h6 {
  color: #fff;
}

[data-theme-version="dark"] a.link {
  color: #ddd;
}

[data-theme-version="dark"] a.link:focus,
[data-theme-version="dark"] a.link:hover {
  color: #1EA7C5;
}

[data-theme-version="dark"] a:hover {
  color: #99c990;
}

[data-theme-version="dark"] .border-right {
  border-right: 1px solid #333a54 !important;
}

[data-theme-version="dark"] .border-left {
  border-left: 1px solid #333a54 !important;
}

[data-theme-version="dark"] .border-top {
  border-top: 1px solid #333a54 !important;
}

[data-theme-version="dark"] .border-bottom {
  border-bottom: 1px solid #333a54 !important;
}

[data-theme-version="dark"] .border {
  border: 1px solid #333a54 !important;
}

[data-theme-version="dark"] {
  /* cms-dark */
}

[data-theme-version="dark"] .dropdown-menu {
  background-color: #222327;
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.1);
}

[data-theme-version="dark"] .dropdown-menu .dropdown-item.selected,
[data-theme-version="dark"] .dropdown-menu .dropdown-item.selected.active,
[data-theme-version="dark"] .dropdown-menu .dropdown-item.active,
[data-theme-version="dark"] .dropdown-menu .dropdown-item:hover,
[data-theme-version="dark"] .dropdown-menu .dropdown-item:focus {
  background-color: #111217;
  color: #7e7e7e;
}

[data-theme-version="dark"] .form-control {
  background-color: #111217 !important;
  border-color: #333a54;
  color: #fff;
}

[data-theme-version="dark"] .search-area .form-control {
  background-color: #222327 !important;
}

[data-theme-version="dark"] .search-area .input-group-text {
  background-color: #222327;
}

[data-theme-version="dark"] .search-area i {
  color: #fff;
}

[data-theme-version="dark"] #chartBar line,
[data-theme-version="dark"] #chartTimeline line,
[data-theme-version="dark"] #chartBar2 line,
[data-theme-version="dark"] #chartBar3 line,
[data-theme-version="dark"] #chartBarCycle line,
[data-theme-version="dark"] #chartBarRunning line {
  stroke: #333a54;
}

[data-theme-version="dark"] .card {
  background-color: #111217;
  box-shadow: none;
}

[data-theme-version="dark"] .btn-outline-dark:hover {
  background-color: #222327;
  border-color: #222327;
}

[data-theme-version="dark"] .tdl-holder input[type=text],
[data-theme-version="dark"] .tdl-holder li {
  background-color: #111217;
  border-color: #333a54;
}

[data-theme-version="dark"] .tdl-holder input[type=text]::placeholder {
  color: #828690;
}

[data-theme-version="dark"] .tdl-holder label:hover {
  background-color: #333a54;
  color: #fff;
}

[data-theme-version="dark"] .text-muted {
  color: #828690 !important;
}

[data-theme-version="dark"] .modal-content {
  background: #111217;
}

[data-theme-version="dark"] .modal-footer,
[data-theme-version="dark"] .modal-header {
  border-color: #333a54;
}

[data-theme-version="dark"] .close {
  font-weight: 400;
  color: #fff;
  text-shadow: none;
}

[data-theme-version="dark"] .close:hover {
  color: #fff;
}

[data-theme-version="dark"] .table strong {
  color: #fff;
}

[data-theme-version="dark"] .text-dark {
  color: #828690 !important;
}

[data-theme-version="dark"] .star-rating .product-review,
[data-theme-version="dark"] .new-arrival-content .item {
  color: #fff;
}

[data-theme-version="dark"] .custom-dropdown .dropdown-menu {
  border-color: #333a54;
}

[data-theme-version="dark"] .widget-stat .media>span {
  background: #333a54;
  border-color: #333a54;
  color: #fff;
}

[data-theme-version="dark"] .plus-minus-input .custom-btn {
  background: #222327;
  border-color: #333a54;
}

[data-theme-version="dark"] .dropdown-divider,
[data-theme-version="dark"] .size-filter ul li {
  border-color: #333a54;
}

[data-theme-version="dark"] .custom-select {
  border-color: #333a54;
  color: #828690;
  background: #222327;
}

[data-theme-version="dark"] .nav-tabs {
  border-color: #333a54;
}

[data-theme-version="dark"] .mail-list .list-group-item.active i {
  color: #fff;
}

[data-theme-version="dark"] hr {
  border-color: #333a54;
}

[data-theme-version="dark"] .grid-col {
  background: #222327;
}

[data-theme-version="dark"] .noUi-target {
  border-color: #333a54;
  border-radius: 8px;
  box-shadow: none;
}

[data-theme-version="dark"] .noUi-target .noUi-connects {
  background: #333a54;
}

[data-theme-version="dark"] .noUi-marker-large,
[data-theme-version="dark"] .noUi-marker {
  background: #333a54;
}

[data-theme-version="dark"] .input-group-text {
  background: #222327;
  color: #7e7e7e;
}

[data-theme-version="dark"] .border-end {
  border-color: #333a54 !important;
}

[data-theme-version="dark"] .note-editor.note-frame {
  border-color: #333a54;
}

[data-theme-version="dark"] .note-editor.note-frame .btn {
  color: #fff;
}

[data-theme-version="dark"] .note-editor.note-frame .note-editing-area .note-editable {
  color: #fff;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel {
  border-color: #333a54;
}

[data-theme-version="dark"] .notification_dropdown .dropdown-menu-right .all-notification {
  border-color: #333a54;
}

[data-theme-version="dark"] #user-activity .nav-tabs .nav-link {
  border-color: #333a54;
}

[data-theme-version="dark"] .list-group-item-action {
  color: #7e7e7e;
}

[data-theme-version="dark"] .list-group-item-action:focus,
[data-theme-version="dark"] .list-group-item-action:hover,
[data-theme-version="dark"] .list-group-item-action:focus {
  background-color: #222327;
  border-color: #222327;
}

[data-theme-version="dark"] .list-group-item.active {
  color: #fff;
  border-color: #99c990;
}

[data-theme-version="dark"] .list-group-item.active:focus,
[data-theme-version="dark"] .list-group-item.active:hover,
[data-theme-version="dark"] .list-group-item.active:focus {
  background-color: #99c990;
  border-color: #99c990;
  color: #fff;
}

[data-theme-version="dark"] .swal2-popup {
  background: #111217;
}

[data-theme-version="dark"] .dashboard_bar {
  color: #fff;
}

[data-theme-version="dark"] .avtivity-card .text-black {
  color: #fff !important;
}

[data-theme-version="dark"] .text-black {
  color: #fff !important;
}

[data-theme-version="dark"] .card-footer.style-1 a {
  background-color: #222327;
  color: #fff !important;
}

[data-theme-version="dark"] #SvgjsText1300 {
  fill: #fff;
}

[data-theme-version="dark"] .card-action.card-tabs .nav-tabs {
  background-color: #222327;
  color: #fff;
}

[data-theme-version="dark"] .card-action.card-tabs .nav-tabs .nav-link {
  color: #fff;
}

[data-theme-version="dark"] .card-action.card-tabs .nav-tabs .nav-link.active {
  background-color: #111217;
  color: #fff;
}

[data-theme-version="dark"] .card-tabs.style-1 .nav-tabs {
  background-color: #111217;
}

[data-theme-version="dark"] .plan-list .list-row .play-button,
[data-theme-version="dark"] .plan-list .list-row .more-button {
  background-color: #222327;
}

[data-theme-version="dark"] .event-calender .bootstrap-datetimepicker-widget table td.day {
  color: #fff;
}

[data-theme-version="dark"] .event-calender .datepicker table tr td.active.active {
  background-color: #222327 !important;
}

[data-theme-version="dark"] .event-calender .bootstrap-datetimepicker-widget table th.picker-switch {
  color: #fff;
}

[data-theme-version="dark"] .bg-light .text-black {
  color: #000 !important;
}

[data-theme-version="dark"] .bg-white {
  background-color: #111217 !important;
}

[data-theme-version="dark"] table.dataTable tbody td {
  background-color: #111217 !important;
  color: #fff;
}

[data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate .paginate_button.previous,
[data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate .paginate_button.next {
  background-color: #222327 !important;
  color: #fff !important;
}

[data-theme-version="dark"] .btn-link g [fill] {
  fill: #fff;
}

[data-theme-version="dark"] .page-titles {
  background-color: #222327;
}

[data-theme-version="dark"] .table thead th {
  color: #fff;
  border-color: #333a54;
}

[data-theme-version="dark"] .fc-theme-standard td,
[data-theme-version="dark"] .fc-theme-standard th,
[data-theme-version="dark"] .fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid,
[data-theme-version="dark"] .fc-scrollgrid,
[data-theme-version="dark"] table,
[data-theme-version="dark"] .fc .fc-button-group>.fc-button,
[data-theme-version="dark"] .form-check-input[type="checkbox"],
[data-theme-version="dark"] .form-check-input[type="radio"] {
  border-color: #333a54 !important;
}

[data-theme-version="dark"] table.dataTable thead th {
  border-color: #333a54;
}

[data-theme-version="dark"] .daterangepicker {
  background: #222327;
  border-color: #99c990;
}

[data-theme-version="dark"] .daterangepicker .calendar-table {
  border-color: #99c990;
  background: #222327;
}

[data-theme-version="dark"] .daterangepicker .calendar-table .table-condensed td:hover {
  background-color: #99c990;
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker:after {
  border-bottom: 6px solid #222327;
}

[data-theme-version="dark"] .daterangepicker select.hourselect,
[data-theme-version="dark"] .daterangepicker select.minuteselect,
[data-theme-version="dark"] .daterangepicker select.secondselect,
[data-theme-version="dark"] .daterangepicker select.ampmselect {
  background: #222327;
  border: 1px solid #333a54;
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker td.off,
[data-theme-version="dark"] .daterangepicker td.off.in-range,
[data-theme-version="dark"] .daterangepicker td.off.start-date,
[data-theme-version="dark"] .daterangepicker td.off.end-date {
  background-color: transparent;
}

[data-theme-version="dark"] .daterangepicker td.off:hover,
[data-theme-version="dark"] .daterangepicker td.off.in-range:hover,
[data-theme-version="dark"] .daterangepicker td.off.start-date:hover,
[data-theme-version="dark"] .daterangepicker td.off.end-date:hover {
  background-color: #99c990;
  color: #fff;
}

[data-theme-version="dark"] button.cancelBtn.btn.btn-sm.btn-inverse,
[data-theme-version="dark"] .btn-group-sm>button.cancelBtn.btn.btn-inverse {
  background-color: #111217;
}

[data-theme-version="dark"] .clockpicker-plate {
  background-color: #222327;
  border-color: #333a54;
}

[data-theme-version="dark"] .dtp>.dtp-content {
  background-color: #222327;
}

[data-theme-version="dark"] .dtp table.dtp-picker-days tr>td>a,
[data-theme-version="dark"] .dtp .dtp-picker-time>a {
  color: #7e7e7e;
}

[data-theme-version="dark"] .daterangepicker .drp-buttons,
[data-theme-version="dark"] .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
  border-color: #333a54;
}

[data-theme-version="dark"] .clockpicker-popover .popover-content {
  background-color: #111217;
}

[data-theme-version="dark"] .popover-title {
  background-color: #222327;
}

[data-theme-version="dark"] .note-editor.note-frame .note-statusbar,
[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link span,
[data-theme-version="dark"] .svg-icons-ov.style-1 .svg-icons-prev,
[data-theme-version="dark"] .svg-icons-ov .svg-icon-popup .btn {
  background-color: #222327;
}

[data-theme-version="dark"] .dd-handle {
  border-color: #333a54;
}

[data-theme-version="dark"] .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill='white' d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}

[data-theme-version="dark"] .svg-area .card-header,
[data-theme-version="dark"] .new-arrival-content h4 a,
[data-theme-version="dark"] .new-arrival-content .h4 a,
[data-theme-version="dark"] .new-arrival-content .h4 a {
  color: #fff;
}

[data-theme-version="dark"] .svg-icons-ov,
[data-theme-version="dark"] .cm-content-box .cm-content-body,
[data-theme-version="dark"] .menu-tabs,
[data-theme-version="dark"] .cm-content-box.style-1 .content-title,
[data-theme-version="dark"] .dd .accordion-body,
[data-theme-version="dark"] .dz-terms,
[data-theme-version="dark"] .compose-content .dropzone {
  border-color: #333a54;
}

[data-theme-version="dark"] .new-scroll,
[data-theme-version="dark"] .compose-content .dropzone {
  background-color: #222327 !important;
}

[data-theme-version="dark"] .cm-content-box,
[data-theme-version="dark"] .dd-handle.move-media+.accordion-button {
  background-color: #111217;
}

[data-theme-version="dark"] .btn-icon-right {
  border-color: #333a54;
}

[data-theme-version="dark"] .accordion-danger-solid .accordion-button {
  border-color: #F94687;
}

[data-theme-version="dark"] .deznav .metismenu>li.mm-active>a {
  background-color: #27282D !important;
}

[data-theme-version="dark"] .testimonial-one .owl-nav .owl-prev,
[data-theme-version="dark"] .testimonial-one .owl-nav .owl-next {
  background-color: #222327 !important;
}

[data-theme-version="dark"] .datepicker.datepicker-dropdown {
  border-color: #333a54;
}

[data-theme-version="dark"] .event-calender .bootstrap-datetimepicker-widget table th {
  color: #fff;
}

[data-theme-version="dark"] .nav-tabs.diet-tabs .nav-link {
  background-color: #222327;
  color: #fff;
}

[data-theme-version="dark"] .nav-tabs.diet-tabs .nav-link.active {
  background-color: #99c990;
}

[data-theme-version="dark"] .brand-title {
  filter: brightness(35.9);
}

[data-theme-version="dark"] .tab-icon .nav-link.active {
  border-color: #333a54;
}

[data-theme-version="dark"] .tab-icon .nav-link.active i {
  color: #fff;
}

[data-theme-version="dark"] .tab-icon .nav-link:hover {
  border-color: #333a54;
}

[data-theme-version="dark"] .notification_dropdown .dropdown-menu-end .all-notification,
[data-theme-version="dark"] .header-profile .dropdown-menu-end .all-notification {
  border-color: #333a54;
}

[data-theme-version="dark"] svg text {
  fill: #fff;
}

[data-theme-version="dark"] #chart line {
  stroke: #333a54;
}

[data-theme-version="dark"] .react-calendar {
  background: transparent;
}

[data-theme-version="dark"] .react-calendar .react-calendar__navigation__label__labelText {
  color: #fff;
}

[data-theme-version="dark"] .react-calendar .react-calendar__navigation__label__labelText:hover {
  color: #000;
}

[data-theme-version="dark"] .react-calendar .react-calendar__navigation__arrow {
  color: #fff;
}

[data-theme-version="dark"] .react-calendar .react-calendar__tile {
  color: #fff;
}

[data-theme-version="dark"] .react-calendar .react-calendar__tile:hover {
  color: #000;
}

[data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul {
  background: #111217;
}

[data-theme-version="dark"] .email-left-body.active {
  background: #1b1c1f;
}

[data-theme-version="dark"] .header {
  background-color: #111217;
}

[data-theme-version="dark"] .header-left input {
  background: #222327 !important;
  border-color: transparent;
  color: #fff !important;
}

[data-theme-version="dark"] .header-left input:focus {
  box-shadow: none;
  border-color: #99c990;
}

[data-theme-version="dark"] .header-left input::placeholder {
  color: #fff;
}

[data-theme-version="dark"] .header-left .search_icon {
  background: #222327 !important;
}

[data-theme-version="dark"] .header-left .search_icon i {
  color: #fff;
}

[data-theme-version="dark"] .header-right .right-sidebar a {
  color: #99c990;
  border-color: #333a54;
}

[data-theme-version="dark"] .header-right .header-profile>a.nav-link .header-info strong {
  color: #fff;
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link {
  background: #222327;
}

[data-theme-version="dark"] .header-right .dropdown .nav-link {
  color: #fff;
}

[data-theme-version="dark"] .header-right .dropdown .nav-link:hover {
  color: #fff;
}

[data-theme-version="dark"] .header-right .notification_dropdown .dropdown-item a {
  color: #fff;
}

[data-theme-version="dark"] .nav-header,
[data-theme-version="dark"] .nav-control {
  background-color: #111217;
}

[data-theme-version="dark"] .nav-control {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="dark"] .nav-header,
[data-theme-version="dark"] .nav-control {
  background-color: #111217;
}

[data-theme-version="dark"] .nav-control {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li>ul {
  background-color: #111217;
}

[data-theme-version="dark"] .fixed-content-box {
  background-color: #111217;
}

[data-theme-version="dark"] .fixed-content-box .head-name {
  background: #111217;
  color: #fff;
  border-color: #333a54;
}

[data-theme-version="dark"] .fixed-content-box+.header+.deznav {
  background-color: #222327;
}

[data-theme-version="dark"] .deznav {
  background-color: #111217;
}

[data-theme-version="dark"] .deznav .metismenu>li>a {
  color: #b3b3b3;
}

[data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-theme-version="dark"] .deznav .metismenu>li:focus>a,
[data-theme-version="dark"] .deznav .metismenu>li.mm-active>a {
  color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu>li:hover>a::after,
[data-theme-version="dark"] .deznav .metismenu>li:focus>a::after,
[data-theme-version="dark"] .deznav .metismenu>li.mm-active>a::after {
  border-color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu>li.mm-active ul ul {
  background-color: transparent;
}

[data-theme-version="dark"] .deznav .metismenu ul:after {
  background-color: #333a54;
}

[data-theme-version="dark"] .deznav .metismenu ul a:hover,
[data-theme-version="dark"] .deznav .metismenu ul a:focus,
[data-theme-version="dark"] .deznav .metismenu ul a.mm-active {
  color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu ul a:before {
  background-color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu a {
  color: #b3b3b3;
}

[data-theme-version="dark"] .deznav .metismenu .has-arrow:after {
  border-color: #fff;
}

[data-theme-version="dark"] .sidebar-right {
  background-color: #2a2b30;
}

[data-theme-version="dark"] .sidebar-right .nav-tabs {
  background-color: #2a2b30;
  border-bottom: 1px solid #222327;
}

[data-theme-version="dark"] .sidebar-right .nav-tabs .nav-link.active {
  background-color: #222327;
}

[data-theme-version="dark"] .footer {
  background-color: transparent;
}

[data-theme-version="dark"] .footer .copyright {
  background-color: transparent;
}

[data-theme-version="dark"] .footer .copyright p {
  color: rgba(255, 255, 255, 0.7);
}

.tab-container {
  margin-bottom: 15px;
  display: flex;
}

.tab {
  margin-bottom: 5px;
  min-width: 40%;
  cursor: pointer;
  background-color: #f0f0f0;
  border: 0px solid #ccc;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}

.tab.active {
  background-color: #8ef37c;
}

.meal-data {
  display: none;
}

.meal-data.active {
  display: block;
}



.add-icon {
  font-size: 200%; /* Increase the size of the icon */
  font-weight: bold; /* Make the icon bold */
  padding: 5px; /* Add some padding around the icon */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition on hover */
}

.reason-text {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Show only 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Ensure text wraps to the next line if it exceeds the width */
}


.custom-tabs {
  .nav {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #ddd;

    .nav-item {
      width: 50%; 
    }

    .nav-link {
      width: 100%;
      text-align: center;
      font-size: 1rem;
      padding: 10px 15px;
      color: #555;
      background-color: #f8f9fa;
      border: 1px solid transparent;
      border-radius: 0;
      transition: background-color 0.3s ease, border 0.3s ease;
      
      &:hover {
        background-color: #f1f1f1;
        color: #000;
      }
    }

    .active-tab {
      background-color: #007bff;
      color: #fff;
      border-bottom: 2px solid #007bff;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      
      &:hover {
        background-color: #0069d9;
      }
    }
  }
}

